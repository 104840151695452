import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import s from './ImageCropper.module.scss';
import { TImageCropperProps } from 'src/components/ImageCropper/ImageCropper.types';

export const ImageCropper: React.FC<TImageCropperProps> = ({
  enableSelectImage = false,
  showCroppedImage = false,
  triggerGetCroppedFile,
  file,
  parentCallback,
  maxSizes,
}) => {
  const [src, setSrc] = useState<string | null>(null);
  const [fileInfo, setFileInfo] = useState<File>();
  const [crop, setCrop] = useState({
    x: 10,
    y: 10,
    width: 140,
    height: 140,
  });
  //canvas
  const imgRef = useRef<any>(null);
  const previewCanvasRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      setFileInfo(undefined);
    };
  }, []);

  useEffect(() => {
    onSelectFile(file);
  }, [file]);

  const onSelectFile = (newFile) => {
    if (newFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result as string), false);
      reader.readAsDataURL(newFile);
      setFileInfo(newFile);
    }
  };

  const onImageLoaded = (image) => {
    imgRef.current = image;
  };

  const onCropComplete = (crop) => {
    //
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  //show cropped image on canvas
  useEffect(() => {
    if (!onCropComplete || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    // const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    const scaledCropWidth = crop.width * scaleX;
    const scaledCropHeight = crop.height * scaleY;
    let outputRatio = 1;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    //if we have maxSizes, we have to calculate outputRatio according to that
    if (maxSizes) {
      const scaledWidthToMaxWidthRatio = scaledCropWidth / maxSizes.width;
      const scaledHeightToMaxHeightRatio = scaledCropHeight / maxSizes.height;
      outputRatio = Math.max(scaledWidthToMaxWidthRatio, scaledHeightToMaxHeightRatio);
    }

    canvas.width = scaledCropWidth / outputRatio;
    canvas.height = scaledCropHeight / outputRatio;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      scaledCropWidth,
      scaledCropHeight,
      0,
      0,
      scaledCropWidth / outputRatio,
      scaledCropHeight / outputRatio,
    );
  }, [crop, maxSizes]);

  const convertUrlToFile = useCallback(
    (url) => {
      const filename = fileInfo?.name || 'avatar';
      const mimeType = fileInfo?.type || (url.match(/^data:([^;]+);/) || '')[1];
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    },
    [fileInfo?.name, fileInfo?.type],
  );

  /**
   * Convert canvas image to Base64 url
   * @returns Base64 url
   */
  const convertCanvasToURL = useCallback(() => {
    const canvas = previewCanvasRef.current;
    const url = canvas?.toDataURL();
    return url;
  }, []);

  const getCroppedFile = useCallback(() => {
    const url: string = convertCanvasToURL();
    const file = convertUrlToFile(url);
    return file;
  }, [convertCanvasToURL, convertUrlToFile]);

  useEffect(() => {
    if (triggerGetCroppedFile) {
      const croppedFile = getCroppedFile();
      parentCallback(croppedFile);
    }
  }, [triggerGetCroppedFile, getCroppedFile, parentCallback]);

  return (
    <div className={s.imageCropper}>
      <div className={enableSelectImage ? '' : s.hidden}>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              onSelectFile(e.target.files[0]);
            }
          }}
        />
      </div>
      {src && (
        <>
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
          {/* canvas */}
          <div className={showCroppedImage ? '' : s.hidden}>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(crop?.width ?? 0),
                height: Math.round(crop?.height ?? 0),
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
