import { Plugin } from 'chart.js';

export const ChartSimulationProgressPlugin: Plugin = {
  id: 'simulationprogressplugin',

  beforeDraw(chart, args, options): void {
    chart.ctx.save();
    drawFullProgressLine(chart);
    chart.ctx.restore();
  },
};

function drawFullProgressLine({ ctx, chartArea }): void {
  ctx.beginPath();
  ctx.lineCap = 'round';
  ctx.moveTo(0, chartArea.height);
  ctx.lineWidth = 3;
  ctx.strokeStyle = '#EAEAEA';
  ctx.lineTo(chartArea.width, chartArea.height);
  ctx.stroke();
}
