import {
  EActiveTabState,
  EResultsTab,
} from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import { ELsKey, ls } from 'src/utils/localStorage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  TBillsDifference,
  TSCMHomeKpi,
  TSCMSimulationGraphData,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';

import { APP_FLOW } from 'src/hooks/useAppFlow';
import { DataAggregationResolution } from 'src/graphql';
import { TKpi } from 'src/typings/simulation.types';
import { TSCMCommunityKpi } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity';

export type TSCMHome = {
  address: string;
  assetCount: number;
  coefficientPercentage: number;
  email: string;
  feedInTariff: number;
  fixedMonthlyFee: number;
  gridFeeConstant: number;
  marketMakerRate: number;
  marketplaceMonthlyFee: number;
  name: string;
  taxesSurcharges: number;
  uuid: string;
  zipCode: string;
};

export type TMembers = {
  id?: string;
  name: string;
  email: string;
  address: string;
};

export type TSCMState = {
  dataSheetResponse: any;
  activeSCMStep: number;
  isSCMFlow: boolean;
  scmCommunityManager: string | undefined;
  scmSidebarTab: EActiveTabState | undefined;
  scmHomeDetails: TSCMHome[];
  homeBillsDifferences: TBillsDifference | undefined;
  communityBillsDifferences: TBillsDifference | undefined;
  homeKpi: TSCMSimulationGraphData | undefined;
  communityKpi: TSCMSimulationGraphData | undefined;
  kpiDifference: TSCMHomeKpi | undefined;
  communityKpiDifference: TSCMCommunityKpi | undefined;
  dataResolution: DataAggregationResolution.Daily | DataAggregationResolution.Monthly;
  activeTabResults: EResultsTab;
};

const scmFlow = ls.get(ELsKey.APP_FLOW) === APP_FLOW.SCM;

export const initialSCMState: TSCMState = {
  dataSheetResponse: null,
  activeSCMStep: 0,
  isSCMFlow: scmFlow,
  scmCommunityManager: 'contact@gridsingularity.com',
  scmSidebarTab: undefined,
  scmHomeDetails: [],
  homeBillsDifferences: undefined,
  communityBillsDifferences: undefined,
  homeKpi: undefined,
  communityKpi: undefined,
  kpiDifference: undefined,
  communityKpiDifference: undefined,
  dataResolution: DataAggregationResolution.Daily,
  activeTabResults: EResultsTab.Home,
};

const scmSlice = createSlice({
  name: 'scm',
  initialState: initialSCMState,
  reducers: {
    setDataSheetResponse(state, action: PayloadAction<TSCMState['dataSheetResponse']>) {
      state.dataSheetResponse = action.payload;
    },
    setActiveSCMStep(state, action: PayloadAction<TSCMState['activeSCMStep']>) {
      state.activeSCMStep = action.payload;
      ls.set(ELsKey.SCM_STEP, String(action.payload));
    },
    setSCMFlow(state, action: PayloadAction<TSCMState['isSCMFlow']>) {
      state.isSCMFlow = action.payload;
    },
    setSCMCommunityManager(state, action: PayloadAction<TSCMState['scmCommunityManager']>) {
      state.scmCommunityManager = action.payload;
    },
    setSCMsidebarTab(state, action: PayloadAction<TSCMState['scmSidebarTab']>) {
      state.scmSidebarTab = action.payload;
    },
    setSCMhomeDetails(state, action: PayloadAction<TSCMState['scmHomeDetails']>) {
      state.scmHomeDetails = action.payload;
    },
    setHomeBillsDifferences(state, action: PayloadAction<TSCMState['homeBillsDifferences']>) {
      state.homeBillsDifferences = action.payload;
    },
    setHomeKpi(state, action: PayloadAction<TSCMState['homeKpi']>) {
      state.homeKpi = action.payload;
    },
    setCommunityKpi(state, action: PayloadAction<TSCMState['communityKpi']>) {
      state.communityKpi = action.payload;
    },
    setKpiDifference(state, action: PayloadAction<TSCMState['kpiDifference']>) {
      state.kpiDifference = action.payload;
    },
    setDataResolution(state, action: PayloadAction<TSCMState['dataResolution']>) {
      state.dataResolution = action.payload;
    },
    setCommunityBillsDifferences(
      state,
      action: PayloadAction<TSCMState['communityBillsDifferences']>,
    ) {
      state.communityBillsDifferences = action.payload;
    },
    setCommunityKpiDifference(state, action: PayloadAction<TSCMState['communityKpiDifference']>) {
      state.communityKpiDifference = action.payload;
    },
    setActiveTabResults(state, action: PayloadAction<TSCMState['activeTabResults']>) {
      state.activeTabResults = action.payload;
    },
  },
});

// Actions
export const {
  setDataSheetResponse,
  setActiveSCMStep,
  setSCMFlow,
  setSCMCommunityManager,
  setSCMsidebarTab,
  setSCMhomeDetails,
  setHomeBillsDifferences,
  setCommunityBillsDifferences,
  setHomeKpi,
  setCommunityKpi,
  setKpiDifference,
  setCommunityKpiDifference,
  setDataResolution,
  setActiveTabResults,
} = scmSlice.actions;

// Reducer
export const scmReducer = scmSlice.reducer;
