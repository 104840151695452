import { LibraryTypesGraphql, ListLibrariesQuery, useListLibrariesQuery } from 'src/graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TLibrary, TLibraryChooserProps, TSelectedLibraryType } from './LibraryChooser.types';
import {
  customHouse,
  customPV,
  selectLibraryOptions,
} from 'src/components/LibraryChooser/LibraryChooserConstants';
import { formatSubtitle, generateIcon } from 'src/components/LibraryChooser/LibraryChooserHelpers';

import { AssetsListWithSearch } from 'src/components/AssetsListWithSearch';
import { BaseSelect } from 'src/components/BaseSelect';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { NN } from 'src/typings/helpers';
import { TAssetType } from 'src/typings/base-types';
import { debounce } from 'lodash';
import { produce } from 'immer';
import s from './LibraryChooser.module.scss';
import { useGraphQLMessage } from 'src/hooks/useGraphQLMessage';

export const LibraryChooser: React.FC<TLibraryChooserProps> = ({
  onLibraryChoose,
  isAllButtonRequiredInFilter,
  ...rest
}: TLibraryChooserProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchVariable, setSearchVariable] = useState(''); // We use this second state to achieve search debouncing
  const [selectedLibraryType, setSelectedLibraryType] = useState<TSelectedLibraryType>(
    LibraryTypesGraphql.GsyLibrary,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchVariableDebounced = useCallback(
    debounce((v: typeof searchVariable) => setSearchVariable(v), 300),
    [],
  );

  const { data: queriedData, loading: loadingA, error: errorA } = useListLibrariesQuery({
    variables: {
      search: searchVariable,
      tags: rest.activeFilter,
      libraryTypes: selectedLibraryType,
    },
  });

  const { data: houseTypeData, loading: loadingB, error: errorB } = useListLibrariesQuery({
    variables: {
      tags: LIBRARY_FILTERS_MAPPING.Area,
      libraryTypes: selectedLibraryType,
    },
  });

  const filteredAssets = useMemo(() => {
    if (!queriedData || !houseTypeData) return [];

    if (
      rest.activeFilter === LIBRARY_FILTERS_MAPPING.All &&
      rest.disabledFilters?.includes(LIBRARY_FILTERS_MAPPING.Area)
    ) {
      const output: NN<ListLibrariesQuery['listLibraries']>['libraries'] = [];
      const houses = houseTypeData.listLibraries?.libraries?.map((p) => p?.uuid) || [];

      queriedData.listLibraries?.libraries?.map((item) => {
        if (houses.includes(item?.uuid)) return;
        output.push(item);
      });

      return output;
    }

    return queriedData.listLibraries?.libraries || [];
  }, [queriedData, houseTypeData, rest.disabledFilters, rest.activeFilter]);

  const mappedAssets = useMemo((): TLibrary[] => {
    return filteredAssets.map((item) => {
      const { areaCountNumber, deviceCountNumber, representation } = item?.scenarioData || {};

      const libraryUuid = item?.uuid || '';

      const library: TLibrary = {
        libraryUuid,
        uuid: representation?.uuid || '',
        icon: generateIcon(item?.name, representation?.type as TAssetType),
        title: item?.name || '',
        subtitle: formatSubtitle(areaCountNumber, deviceCountNumber),
        description: item?.description,
        type: representation?.type as TAssetType,
        scenarioData: item?.scenarioData,
        key: libraryUuid,
      };

      return library;
    });
  }, [filteredAssets]);

  const mappedAssetsExtended = useMemo(() => {
    if (
      searchVariable &&
      !customHouse.title.toLowerCase().includes(searchVariable.trim().toLowerCase())
    ) {
      return mappedAssets;
    }

    if (
      (rest.activeFilter === LIBRARY_FILTERS_MAPPING.All &&
        !rest.disabledFilters?.includes(LIBRARY_FILTERS_MAPPING.Area)) ||
      rest.activeFilter === LIBRARY_FILTERS_MAPPING.Area
    ) {
      // Append "Custom House" asset
      return produce(mappedAssets, (draftState) => {
        draftState.splice(0, 0, customHouse);
      });
    } else if (rest.activeFilter === LIBRARY_FILTERS_MAPPING.PV) {
      // Append "Custom PV" asset and remove the one coming from BE
      const BEcustomPVIndex = mappedAssets.findIndex((x) => x.title === 'Custom Solar Panel');
      if (BEcustomPVIndex >= 0) {
        mappedAssets.splice(BEcustomPVIndex, 1);
      }
      return produce(mappedAssets, (draftState) => {
        draftState.splice(0, 0, customPV);
      });
    }

    return mappedAssets;
  }, [mappedAssets, rest.activeFilter, rest.disabledFilters, searchVariable]);

  useEffect(() => {
    setSearchVariableDebounced(searchInputValue);
  }, [searchInputValue, setSearchVariableDebounced]);

  const errorComposed = mappedAssetsExtended ? undefined : (errorA || errorB); // prettier-ignore
  const errorFormatted = useGraphQLMessage({ error: errorComposed });

  const handleSelectChange = ({ value }) => {
    setSelectedLibraryType(value);
  };
  const containerProps = {
    showTooltip: false,
    tooltipText: '',
    key: 'librarytype',
  };

  return (
    <>
      <FieldContainer {...containerProps} className={s.selectField}>
        <BaseSelect
          name="librarytype"
          value={selectedLibraryType}
          options={selectLibraryOptions}
          // onChange={(val) => handleSelectChange(val)}
          onChange={({ value }) => setSelectedLibraryType(value as TSelectedLibraryType)}
          theme="filled-gray"
          className={s.selectWrapper}
        />
      </FieldContainer>
      <AssetsListWithSearch
        assets={mappedAssetsExtended}
        isAllButtonRequiredInFilter={isAllButtonRequiredInFilter}
        searchQuery={searchInputValue}
        onSearchQueryChange={(v) => setSearchInputValue(v)}
        onAssetChoose={onLibraryChoose}
        loading={loadingA || loadingB}
        error={errorFormatted}
        {...rest}
      />
    </>
  );
};
