import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TMarketDesign = {
  className?: string;
};

export type TStylesScrolling = {
  maxSize?: number;
  minSize?: number;
  minBright?: number;
  darker?: boolean;
};

export type TMenuLayers = {
  title: string;
  layer: number;
  icon: TIconNames;
};

export type TLayers = {
  Component: React.ComponentType<any>;
  customClass?: string;
  config: {
    sticky?: {
      start: number;
      end: number;
    };
    className?: string;
    offset?: number;
    speed?: number;
    factor?: number;
  };
  props?: {
    withLayers: number[];
  };
};

export enum ELayersGroup {
  INITIAL = 'initial',
  EXCHANGES = 'exchanges',
  MARKETS = 'markets',
  APIS = 'apis',
  SIMULATION = 'simulation',
  DEPLOYMENT = 'deployment',
  ECOSYSTEM = 'ecosystem',
}

export enum EMenus {
  FIRST = 'first',
  SECOND = 'second',
}
