import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BaseInput } from 'src/components/BaseInput';
import { EOrientationParam } from '../Orientation';
import { OrientationAdvancedController } from '../OrientationAdvancedController';
import { TOrientationAdvancedProps } from './OrientationAdvanced.types';
import classnames from 'classnames';
import s from './OrientationAdvanced.module.scss';

export const OrientationAdvanced: React.FC<TOrientationAdvancedProps> = ({
  theme,
  className,
  orientationParam,
  setOrientationParam,
}) => {
  const rendererRef = useRef<HTMLDivElement>(null);
  const controllerRef = useRef<OrientationAdvancedController>();

  // Listener of azimuth value change
  const handleOrientationParamChange = useCallback(
    ({ name, value }) => {
      const re = new RegExp(/^-?\d{0,3}(?:,\d{3})*(?:\.\d+)?$/);
      if (re.test(String(value))) {
        setOrientationParam({
          ...orientationParam,
          [name]: value,
        });
      }
    },
    [orientationParam, setOrientationParam],
  );

  useEffect(() => {
    if (controllerRef.current) controllerRef.current.update(orientationParam);
  }, [orientationParam]);

  // Initialize 3D container (only once call)
  useEffect(() => {
    if (rendererRef.current) {
      controllerRef.current = new OrientationAdvancedController(
        rendererRef.current,
        orientationParam,
        theme,
      );
    }

    return () => {
      if (controllerRef.current) controllerRef.current.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classnames(s.orientationAdvanced, className, {
        [s.light]: theme === 'light',
      })}>
      <div className={s.orientationAdvancedContainer} ref={rendererRef} />
      <div
        className={classnames(s.orientationAdvancedInputContainer, {
          [s.orientationSidebarInputWrapper]: theme === 'light',
        })}>
        <div className={s.orientationAdvancedInputWrapper}>
          <BaseInput
            type="text"
            name={EOrientationParam.AZIMUTH}
            value={`${orientationParam.azimuth}`}
            onChange={handleOrientationParamChange}
            label="Azimuth"
            placeholder=""
            theme={theme === 'light' ? 'filled-dark-gray' : 'line-light'}
            className={s.orientationAdvancedInput}
          />
          <span>º</span>
        </div>
        <div className={s.orientationAdvancedInputWrapper}>
          <BaseInput
            type="text"
            name={EOrientationParam.TILT}
            value={`${orientationParam.tilt}`}
            onChange={handleOrientationParamChange}
            label="Tilt"
            placeholder=""
            theme={theme === 'light' ? 'filled-dark-gray' : 'line-light'}
            className={s.orientationAdvancedInput}
          />
          <span>º</span>
        </div>
      </div>
    </div>
  );
};
