import { EAppMode, getAppMode } from 'src/utils/appMode';

import { BaseIcon } from 'src/components/BaseIcon';
import { MapSidebarResults } from 'src/components/MapSidebar/components/MapSidebarResults';
import { MobileHeader } from 'src/components/MobileView/components/MobileHeader';
import { MobileWarning } from 'src/pages/MobileWarning';
import { ModalMobileWarning } from 'src/components/ModalMobileWarning';
import React from 'react';
import { TCommunityDetailProps } from './CommunityDetail.types';
import s from './CommunityDetail.module.scss';
import { selectCommunityName } from 'src/redux/configuration/configuration.selectors';
import { useConfigurationEffects } from 'src/hooks/useConfigurationEffects';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export const CommunityDetail: React.FC<TCommunityDetailProps> = () => {
  const history = useHistory();
  useConfigurationEffects();
  const communityName = useSelector(selectCommunityName);
  const appMode: EAppMode = getAppMode();

  const handleBackClicked = () => {
    history.push('/my-communities');
  };

  if (appMode === EAppMode.Mobile) return <MobileWarning />;

  return (
    <div className={s.container}>
      <MobileHeader />
      <div className={s.header}>
        <BaseIcon icon="back-arrow" size={18} onClick={handleBackClicked} />
        <p className={s.communityName}>{communityName}</p>
        <div className={s.separator}></div>
        <BaseIcon icon="share-arrow" size={14} />
      </div>
      <MapSidebarResults showHeaderTabs={false} />
    </div>
  );
};
