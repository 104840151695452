import { ConfigType, useRemoveConfigurationAreaMutation } from 'src/graphql';
import React, { useMemo, useRef, useState } from 'react';
import {
  TCanaryAssetsTree,
  TCanaryRegistryAssetsListProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectIsLoggedIn, selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';

import { BaseClose } from 'src/components/BaseClose';
import { CanaryRegistryAssetTile } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetTile';
import { ContextMenu } from 'src/components/ContextMenu';
import { EUserRoles } from 'src/typings/base-types';
import { TCanaryAssetState } from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import { TCanaryOrderedAssetTree } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryList.types';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { Toast } from 'src/components/Toast';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './CanaryRegistryList.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

export const CanaryRegistryAssetsList: React.FC<TCanaryRegistryAssetsListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
  selectedItems,
  searchValue,
  onItemClick,
  getParentUuid,
  getOwners,
  getAppliedUser,
  onAssetRemove,
}) => {
  const { id: modalId } = useModal();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [removeConfigurationArea] = useRemoveConfigurationAreaMutation({
    onError: (err) => {
      const msg = JSON.parse(err.message).remove_configuration_area;
      setErrorMessage(msg);
      setTimeout(() => setErrorMessage(null), 5000);
    },
  });

  const dispatch = useAppDispatch();
  const modalIsOpen = useSelector(selectIsModalOpened(modalId));
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const [menuModalTriggerRef, setMenuModalTriggerRef] = useState<
    React.RefObject<HTMLButtonElement | null>
  >({
    current: null,
  });
  const userName = useSelector(selectUsername);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userRole = useSelector(selectUserRole);
  const [openedAssetConfigUuid, setOpenedAssetConfigUuid] = useState<string | null>(null);
  //const activeItemRef = useRef(null);
  const isAdmin = userRole === EUserRoles.Admin;
  //const isOwner =  === userName;

  const assetsTreeData = useMemo<TCanaryAssetsTree>(
    () =>
      assetsData.reduce<TCanaryAssetsTree>((acc, asset) => {
        const isSearched =
          searchValue.length > 0
            ? asset.name.toLowerCase().includes(searchValue.toLowerCase())
            : true;

        if (!isSearched) return acc;

        if (asset.name === 'Community') return acc;

        const parentUuid = getParentUuid(asset.uuid);
        const owners = getOwners(asset.uuid);
        const appliedUser = getAppliedUser(asset.uuid);
        const assetIcon = asset.name === 'Grid Market' ? 'market-list' : get2dAssetIcon(asset.type);

        const data = {
          uuid: asset.uuid,
          name: asset.name,
          icon: assetIcon,
          type: asset.type,
          owners: owners,
          status: (appliedUser ? 'Applied' : usersToAssetRelations[userName]) as TCanaryAssetState,
          appliedUserName: appliedUser?.userName,
          appliedUserAvatar: appliedUser?.avatar,
          parentUuid: parentUuid || null,
        };

        if (asset.type === 'Area' || asset.type === 'InfiniteBus') {
          acc[asset.uuid] = { data, children: [] };
        } else {
          if (parentUuid && acc[parentUuid]) {
            acc[parentUuid].children.push(data);
          } else {
            acc[asset.uuid] = { data, children: [] };
          }
        }

        return acc;
      }, {}),
    [
      assetsData,
      getAppliedUser,
      getOwners,
      getParentUuid,
      searchValue,
      userName,
      usersToAssetRelations,
    ],
  );

  const orderedAssetTree = useMemo<TCanaryOrderedAssetTree>(() => {
    const assetsNamesAndKeys = Object.keys(assetsTreeData).map((item) => ({
      name: assetsTreeData[item].data.name,
      item: assetsTreeData[item],
    }));
    const orderedAssetNames = assetsNamesAndKeys.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );

    const staticItemNames = ['Community', 'Grid Market'];

    const staticItems = orderedAssetNames.filter((item) => staticItemNames.includes(item.name));

    const orderedList = [
      ...staticItems,
      ...orderedAssetNames
        .filter((item) => !staticItemNames.includes(item.name))
        .map((item, i) => ({ ...item, homeNumber: i })),
    ];

    return orderedList;
  }, [assetsTreeData]);

  const selectboxUsersList = useMemo(
    () =>
      usersData
        .filter((user) => !user.isAggregator && !user.isGridOperator)
        .map(({ name, uuid }) => ({ name, uuid })),
    [usersData],
  );

  const dropDownItems: {
    title: string;
    icon: TIconNames;
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  }[] = [];

  if (isLoggedIn && isAdmin) {
    dropDownItems.push({
      title: 'Delete',
      icon: 'custom-trash',
      onClick: (e) => {
        if (configUuid && openedAssetConfigUuid)
          onAssetRemove({ assetUuid: openedAssetConfigUuid });
        dispatch(closeModal(modalId));
        // removeConfigurationArea({
        //   variables: {
        //     configurationUuid: configUuid,
        //     areaUuid: openedAssetConfigUuid,
        //   },
        // });
      },
    });
  }

  const closeActiveModal = () => {
    setOpenedAssetConfigUuid(null);
    setMenuModalTriggerRef({ current: null });
    dispatch(closeModal(modalId));
  };

  const onMenuClick = (menuTriggerRef, data) => {
    if (modalIsOpen) {
      closeActiveModal();
    } else {
      setOpenedAssetConfigUuid(data.itemUuid);
      setMenuModalTriggerRef({ current: menuTriggerRef });
      dispatch(openModal(modalId));
    }
  };

  return (
    <div className={s.assetsList}>
      {orderedAssetTree.map((assetTree, i) => {
        const asset = assetTree.item;
        const isSelected = selectedItems.includes(asset.data.uuid);
        const isChildSelected = asset.children.some((item) => selectedItems.includes(item.uuid));

        const childrenCount =
          asset.data.name !== 'Grid Market' ? asset.children.length : orderedAssetTree.length;

        return (
          <React.Fragment key={i}>
            <CanaryRegistryAssetTile
              asset={asset}
              data={asset.data}
              //homeNumber={assetTree.homeNumber !== undefined ? assetTree.homeNumber + 1 : null}
              childrenCount={childrenCount}
              usersList={selectboxUsersList}
              selected={isSelected}
              onClick={onItemClick}
              onMenuClick={onMenuClick}
            />
            {(isSelected || isChildSelected) &&
              asset.children.map((childAsset) => {
                const selected = selectedItems.includes(childAsset.uuid);
                return (
                  <CanaryRegistryAssetTile
                    asset={asset}
                    key={childAsset.uuid}
                    data={childAsset}
                    isChildren
                    usersList={selectboxUsersList}
                    selected={selected}
                    onClick={onItemClick}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
      <ContextMenu
        relativeElement={menuModalTriggerRef}
        modalId={modalId}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        items={dropDownItems}
      />
      {errorMessage && (
        <div
          className={s.customToast}
          style={{
            top: menuModalTriggerRef.current?.getBoundingClientRect().top
              ? menuModalTriggerRef.current?.getBoundingClientRect().top - 45
              : 0,
          }}>
          {errorMessage} <BaseClose className={s.closeBtn} onClick={() => setErrorMessage(null)} />
        </div>
      )}
    </div>
  );
};
