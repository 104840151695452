import { EChartSelfType, TChartSelfData, TChartSelfProps } from './ChartSelf.type';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Chart } from 'chart.js';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartRoundedDoughnutColors } from 'src/constants/chart';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { InfoHelper } from 'src/components/InfoHelper';
import classnames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './ChartSelf.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useSelector } from 'react-redux';

const GRADIENT: { [key in keyof typeof EChartSelfType]: any[] } = {
  [EChartSelfType.Consumption]: [
    ChartRoundedDoughnutColors.resultColor.sc[0],
    ChartRoundedDoughnutColors.resultColor.sc[1],
    ChartRoundedDoughnutColors.resultColor.sc[2],
  ],
  [EChartSelfType.Sufficiency]: [
    ChartRoundedDoughnutColors.resultColor.ss[0],
    ChartRoundedDoughnutColors.resultColor.ss[1],
  ],
};

export const ChartSelf: React.FC<TChartSelfProps> = ({
  kpi,
  type,
  className,
  isDecimalPercentage = false,
  isItemInfoActive = true,
  isTitleInfoActive = false,
  titleInfo,
}) => {
  const { currency } = useSelector(selectSettingsData);

  const { progress, data } = useMemo(() => {
    if (kpi) {
      switch (type) {
        case EChartSelfType.Sufficiency:
          return {
            progress: isDecimalPercentage
              ? Math.floor(kpi.self_sufficiency * 100)
              : kpi.self_sufficiency,
            data: {
              energyProduced: kpi.total_energy_produced_wh,
              energyDemanded: kpi.total_energy_demanded_wh,
              selfConsumed: kpi.total_self_consumption_wh,
            } as TChartSelfData,
          };
        case EChartSelfType.Consumption:
          return {
            progress: Math.floor(kpi.self_consumption),
            data: {
              energyProduced: kpi.total_energy_produced_wh,
              energyDemanded: kpi.total_energy_demanded_wh,
              selfConsumed: kpi.total_self_consumption_wh,
            } as TChartSelfData,
          };
      }
    }
    return {
      progress: 0,
      data: {
        energyProduced: 0,
        energyDemanded: 0,
        selfConsumed: 0,
      },
    };
  }, [kpi, type, isDecimalPercentage]);

  const simulationItems = [
    {
      title: 'Self-Consumed',
      value: data.selfConsumed,
      info: getChartTooltip(
        EChartTooltips.TOOLTIP_SELF_CONSUMED,
        formatter.getCurrencySymbol(currency),
      ),
    },
    {
      title: type === EChartSelfType.Sufficiency ? 'Total Demanded' : 'Total Self-Produced',
      value: type === EChartSelfType.Sufficiency ? data.energyDemanded : data.energyProduced,
      info:
        type === EChartSelfType.Sufficiency
          ? getChartTooltip(
              EChartTooltips.TOOLTIP_TOTAL_DEMANDED,
              formatter.getCurrencySymbol(currency),
            )
          : getChartTooltip(
              EChartTooltips.TOOLTIP_TOTAL_PRODUCED,
              formatter.getCurrencySymbol(currency),
            ),
    },
  ];

  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const createGradient = useCallback(
    (context: CanvasRenderingContext2D): CanvasGradient => {
      const gradient = context.createLinearGradient(0, 64 * 0.1, 0, 64 * 0.9);
      let count = 0;

      GRADIENT[type].forEach((item) => {
        gradient.addColorStop(count, item);
        count += 1 / (GRADIENT[type].length - 1);
      });
      return gradient;
    },
    [type],
  );

  const datasets = useMemo(() => {
    return [
      {
        data: [progress, 100 - progress],
        backgroundColor: [ChartRoundedDoughnutColors.emptyLineColor],
        borderWidth: 0,
      },
    ];
  }, [progress]);

  useChartJS(EChartName.Self, canvasRef, chartRef, {
    datasets,
  });

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data.datasets[0].backgroundColor = [
        createGradient(chartRef.current.canvas.getContext('2d') as CanvasRenderingContext2D),
        ChartRoundedDoughnutColors.emptyLineColor,
      ];
      chartRef.current.update();
    }
  }, [createGradient]);

  return (
    <ChartWrapper
      title={type === EChartSelfType.Sufficiency ? 'Self-Sufficiency' : 'Self-Consumption'}
      titleRightSlotComponent={isTitleInfoActive && <InfoHelper info={titleInfo} />}
      className={className}>
      <ChartDataLoadingWrapper loading={!kpi}>
        <div
          className={classnames(s.content, {
            [s.hideContent]: !kpi,
          })}>
          <div className={s.doughnut}>
            <canvas ref={canvasRef} data-type={type} data-progress={progress} />
            <p className={s.doughnutText}>{`${Math.round(progress * 10) / 10}%`}</p>
          </div>
          <div className={s.simulationInfo}>
            {simulationItems.map((item, index) => (
              <div
                key={index}
                className={classnames(s.simulationItem, {
                  [s.simulationItemMarginBottom]: index !== simulationItems.length - 1,
                })}>
                <div
                  className={classnames(s.simulationItemDot, {
                    [s.red]: index === 1,
                  })}
                />
                <p className={classnames({ [s.height18]: index === 1 })}>{item.title}</p>
                <span>{formatter.toOptionalFixed(item.value / 1000)} kWh</span>
                {isItemInfoActive && <InfoHelper info={item.info} />}
              </div>
            ))}
          </div>
        </div>
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
