import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import React, { useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { TCanaryUserInviteProps } from './CanaryUserInvite.types';
import s from './CanaryUserInvite.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useSelector } from 'react-redux';

const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const CanaryUserInvite: React.FC<TCanaryUserInviteProps> = ({ onInviteClick }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const onValueChange: TBaseInputProps['onChange'] = ({ value }) => {
    if (typeof value === 'string') {
      value = value.replaceAll(' ', ';');
      setInputValue(value);
    }
  };

  const inviteClick = () => {
    const emails = inputValue.split(';').filter((item) => item.length > 0);

    const inInvalid = emails.map(validateEmail).some((item) => item === null);

    if (inInvalid) {
      if (emails.length > 1) {
        setInputError('Some of given emails is not valid');
      } else {
        setInputError('Email is not valid');
      }
    } else {
      if (emails.length === 0) {
        setInputError('Input is empty');
      } else {
        setInputError('');
        onInviteClick(emails);
        // emails.forEach((email) => CanaryNetworkContext.inviteUserToCn(configUuid, email));
      }
    }
  };

  return (
    <div className={s.container}>
      <div className={s.emailInvitation}>
        <div>
          <h3>Invite people</h3>
          <p>
            <BaseIcon icon="lock" size={12} />
            Only people invited can access
          </p>
        </div>
        <div>
          <BaseInput
            inputHeight="1"
            className={s.input}
            theme="filled-gray"
            type="text"
            name="email"
            placeholder="Email"
            error={inputError}
            value={inputValue}
            onChange={onValueChange}
          />
          <BaseButton size="medium" theme="primary" onClick={inviteClick}>
            Add
          </BaseButton>
        </div>
      </div>
      <div className={s.line}></div>
    </div>
  );
};
