import { TBaseIconProps } from 'src/components/BaseIcon';
import { Maybe } from 'src/graphql';
import { TAssetType } from 'src/typings/base-types';

export function formatSubtitle(
  areaCountNumber: Maybe<number> | undefined,
  deviceCountNumber: Maybe<number> | undefined,
): string {
  return `${typeof areaCountNumber === 'number' ? `Markets: ${areaCountNumber}` : ''} ${
    typeof deviceCountNumber === 'number' ? `Assets: ${deviceCountNumber}` : ''
  }`.trim();
}

export function defaultIcons(type?: TAssetType | undefined) {
  switch (type) {
    case 'Area':
      return 'house-3d';
    case 'PV':
      return 'solar-3d';
    case 'Load':
      return 'plug-3d';
    case 'Storage':
      return 'battery-3d';
    default:
      return 'multi-party-house-3d';
  }
}

// TODO: - temporary solution, ask backend team to modify the endpoint
// important - do not change names
export function generateIcon(
  name: string | null | undefined,
  type?: TAssetType | undefined,
): TBaseIconProps['icon'] {
  switch (name) {
    case '6 apartments building+PV':
      return 'multi-party-house-3d';
    case '6 apartments building':
      return 'multi-party-house-3d';
    case 'Flatsharing Student':
      return 'house-3d';
    case 'Retired couple':
      return 'house-3d';
    case 'Multigenerational house':
      return 'house-3d';
    case 'Young Couple':
      return 'house-3d';
    case 'Family 3 children+PV+Batt':
      return 'multi-party-house-3d';
    case 'Family 2 children':
      return 'multi-party-house-3d';
    case 'Family 2 children+PV':
      return 'multi-party-house-3d';
    case 'DefaultLib PV Berlin':
      return 'solar-3d';
    case 'DefaultLib Load':
      return 'plug-3d';
    case 'DefaultLib Storage':
      return 'battery-3d';
    default:
      return defaultIcons(type);
  }
}
