import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import ContentfulContext, { IWatchEntry } from 'src/contexts/ContentfulContext';
import s from './Watch.module.scss';
import { orderByDate, orderByText, ResourcesFilter } from '../../components/ResourcesFilter';
import { TFilterDropdownOption } from 'src/components/ResourcesFilter/ResourcesFilter.types';
import { BaseLink } from 'src/components/BaseLink';
import { EAppMode } from 'src/utils/appMode';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

const filters = [
  {
    id: 1,
    name: 'Tutorials',
  },
  {
    id: 2,
    name: 'Events',
  },
  {
    id: 3,
    name: 'Event Horizon',
  },
];

const paramsOrderBy = {
  ['Newest']: { orderBy: 'desc', field: 'createdAt' },
  ['A-Z']: { orderBy: 'asc', field: 'title' },
  ['Z-A']: { orderBy: 'desc', field: 'title' },
  ['default']: { orderBy: 'desc', field: 'date' },
};

const YOUTUBE_CHANNEL_URL = 'https://www.youtube.com/channel/UCzTDIz9cd7MuH8XUHB_Li6w';

export const Watch: React.FC = () => {
  const { watchEntry } = useContext(ContentfulContext);
  const appMode = useSelector(selectAppMode);
  const [filter, setFilter] = useState<number[]>([]);
  const [sort, setSort] = useState<TFilterDropdownOption>({ label: 'default', value: 'default' });
  const [data, setData] = useState<IWatchEntry[]>([]);
  const filterMemo = useMemo(() => filter, [filter]);
  const params = useMemo(() => paramsOrderBy?.[sort?.label] || null, [sort]);

  function sortBy(a, b, params) {
    switch (params?.field) {
      case 'title':
        return orderByText(a.title, b.title, params?.orderBy);
      case 'createdAt':
        return orderByDate(a.createdAt, b.createdAt, params?.orderBy);
      default:
        return 0;
    }
  }

  const applyFilter = useCallback(
    (value: number[]) => {
      let filtersData: any = [];

      value.forEach((e) => {
        const entries = watchEntry.filter(
          (entry) =>
            entry.category.toLowerCase() === filters.find((f) => f.id === e)?.name?.toLowerCase(),
        );

        filtersData = [...filtersData, ...entries];
      });
      setData(filtersData);
    },
    [setData, watchEntry],
  );

  // Initial filter
  useEffect(() => {
    setFilter([1]);
  }, [setFilter]);

  useEffect(() => {
    applyFilter(filterMemo);
  }, [filterMemo, applyFilter]);

  function youTubeEmbedVideo(url) {
    let id = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    } else {
      id = url;
    }
    return `https://www.youtube.com/embed/${id}`;
  }

  return (
    <div className={s.container}>
      <ResourcesFilter
        setFilter={setFilter}
        filters={filters}
        initialFilter={filter}
        setSort={setSort}
      />
      {data
        ?.sort((a, b) => sortBy(a, b, params))
        ?.map((video, key) => (
          <div key={key.toString()} className={s.videoContainer}>
            <iframe
              width="560"
              height="315"
              src={youTubeEmbedVideo(video?.videoLink)}
              title={video?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
        ))}
      <div className={s.watchMoreContainer}>
        <div className={s.text}>
          For more, please visit the
          {appMode === EAppMode.Desktop && <br />}
          Grid Singularity
          <BaseLink
            to={YOUTUBE_CHANNEL_URL}
            target="_blank"
            className={classnames(s.youtubeLink, 'click-area')}
            theme="2">
            YouTube
          </BaseLink>
          channel.
        </div>
      </div>
    </div>
  );
};
