import {
  EActiveTabState,
  MapSidebarResults,
} from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import {
  EConfigurationFlowState,
  useConfigurationFlowState,
} from 'src/hooks/useConfigurationFlowState';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, matchPath, useHistory } from 'react-router';
import { batch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import {
  selectActiveConfigurationUuid,
  selectCommunityAsset,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsEmbed,
  selectModalMapHeroDismissed,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  setModalMapHeroDismissed,
  setSidebarExpanded,
} from 'src/redux/application/application.slice';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { ConfigurationManagementCentral } from '../ConfigurationManagementCentral';
import { MapSidebarCommunitiesList } from 'src/components/MapSidebarSCM/components/MapSidebarCommunitiesList';
import { MapSidebarConfigurationSettings } from 'src/components/MapSidebarSCM/components/MapSidebarConfigurationSettings';
import { MapSidebarControls } from 'src/components/MapSidebarSCM/components/MapSidebarControls';
import { MapSidebarNavigationBar } from 'src/components/MapSidebarSCM/components/MapSidebarNavigationBar';
import { Redirect } from 'react-router-dom';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TMapSidebarProps } from './MapSidebarSCM.types';
import classnames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './MapSidebarSCM.module.scss';
import { selectActiveModals } from 'src/redux/modals/modals.selectors';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { selectTutorialStep } from 'src/redux/application/application.selectors';
import { setSCMsidebarTab } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';

export const MapSidebarSCM: React.FC<TMapSidebarProps> = ({
  showResultsTabOnClick,
  hideResultsTab,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const scmLocation = useSelector(selectSelectedLocation);
  const communityAssetUuid = useSelector(selectCommunityAsset)?.uuid || '';
  const simulationResults = useSelector(selectSimulationResults);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const sidebarModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR),
  );
  const { sidebarOpen, configurationFlowState } = useConfigurationFlowState();
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const tutorialStep = useSelector(selectTutorialStep);
  const [containerWidth, setContainerWidth] = useState<any>(EModalSize.L370);
  const [activeTab, setActiveTab] = useState<EActiveTabState>(EActiveTabState.Community);
  const activeModals = useSelector(selectActiveModals);
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const toggleModal = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
      } else {
        batch(() => {
          dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
          dispatch(setSCMsidebarTab(undefined));
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !activeModals.includes(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY) &&
      !sidebarModalOpened
    ) {
      dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    }
  }, [sidebarModalOpened, dispatch, activeModals]);
  useEffect(() => {
    if (sidebarModalOpened && !modalMapHeroDismissed) {
      dispatch(setModalMapHeroDismissed(true));
    }
  }, [dispatch, modalMapHeroDismissed, sidebarModalOpened]);

  const closeButtonControl = [
    {
      icon: 'arrow-left' as TIconNames,
      iconSize: 12,
      onClick: () => toggleModal(!sidebarModalOpened),
      disabled:
        configurationFlowState === EConfigurationFlowState.CreateNewCommunity && !scmLocation,
    },
  ];

  const viewControls = useMemo(() => {
    if (!isEmbed) {
      return [
        {
          icon: 'list' as TIconNames,
          iconSize: 17,
          active: !!matchPath(location.pathname, {
            path: routesConfig.scmMap(),
            exact: true,
          }),
          disabled: !!activeConfigurationUuid,
        },
        {
          icon: 'bar-chart' as TIconNames,
          iconSize: 17,
          onClick: () => {
            history.push(
              isEmbed
                ? routesConfig.embed(activeConfigurationUuid)
                : routesConfig.singularityMapResults(activeConfigurationUuid, communityAssetUuid),
            );
          },
          active: !!matchPath(location.pathname, {
            path: isEmbed
              ? routesConfig.embed(activeConfigurationUuid)
              : routesConfig.singularityMapResults(activeConfigurationUuid, communityAssetUuid),
          }),
          disabled: !activeConfigurationUuid && !simulationResults,
          disabledTooltip: 'Select a community to view results',
        },
      ];
    }

    return [];
  }, [
    isEmbed,
    location.pathname,
    activeConfigurationUuid,
    communityAssetUuid,
    simulationResults,
    history,
  ]);

  useEffect(() => {
    toggleModal(sidebarOpen);
  }, [sidebarOpen, toggleModal]);

  useEffect(() => {
    if (!isLoggedIn) dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  });

  const handleSettingsTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  const getContainerSize = useCallback(() => {
    if (sidebarExpanded && activeTab == EActiveTabState.Community) {
      return 'perc';
    } else if (sidebarExpanded) {
      return EModalSize.XL795;
    } else {
      return EModalSize.L370;
    }
  }, [sidebarExpanded, activeTab]);

  useEffect(() => {
    let containerSize = getContainerSize();
    setContainerWidth(containerSize);
  }, [activeTab, getContainerSize]);

  useEffect(() => {
    const isSimulationResultsPath = !!matchPath(location.pathname, {
      path: routesConfig.singularityMapResults('', ''),
    });

    if (!isSimulationResultsPath) {
      dispatch(setSidebarExpanded(false));
    }
  }, [dispatch, location.pathname]);

  return (
    <>
      <Switch>
        <Route path={`(${routesConfig.scmMap()}|${routesConfig.singularityMap()})`}>
          <BaseGenericModal
            className={classnames(s.container, {
              [s.containerEmbed]: isEmbed,
              [s.sidebarWithoutNav]: !!matchPath(location.pathname, {
                path: [routesConfig.singularityMapCreate(), routesConfig.singularityMapResults()],
              }),
            })}
            closedClassName={s.closed}
            size={containerWidth}
            style={{
              zIndex: tutorialStep === 1 ? 99999 : 100,
              pointerEvents: tutorialStep === 1 ? 'none' : 'auto',
              top: isUserACommunityMember ? '75px' : '120px',
            }}
            modalId={EPredefinedModalIds.MODAL_MAP_SIDEBAR}>
            {/* {sidebarModalOpened && (
              <MapSidebarControls className={s.viewControls} buttons={viewControls} />
            )} */}
            {/* <MapSidebarControls className={s.closeControls} buttons={closeButtonControl} /> */}
            <>
              <Switch>
                <Route
                  // Use simulations list also for /create route
                  path={`(${routesConfig.scmMap()}|${routesConfig.singularityMap()})`}
                  // path={`(${routesConfig.singularityMap()}|${routesConfig.singularityMapCreate()})`}
                  exact>
                  {/* <MapSidebarNavigationBar /> */}
                  <MapSidebarCommunitiesList />
                </Route>
                <ConfigurationManagementCentral>
                  {({
                    handleSettingsDataSave,
                    handleCommunityRemove,
                    handleAssetValuesSavePromise,
                    handleAssetValuesSave,
                    handleAddNewAsset,
                    handleAddAssetUnderUuid,
                    handleAssetRemove,
                  }) => {
                    return (
                      <>
                        <Route path={routesConfig.singularityMapResults('', '')}>
                          {/* {isLoggedIn ? ( */}
                          <MapSidebarResults
                            hideResultsTab={hideResultsTab}
                            showResultsTabOnClick={showResultsTabOnClick}
                            onSettingsDataSave={handleSettingsDataSave}
                            onChangeSettingsTab={handleSettingsTab}
                            onCommunityRemove={handleCommunityRemove}
                            onAssetValuesSave={handleAssetValuesSave}
                            onAddNewAsset={handleAddNewAsset}
                            onAddNewAssetUnderUuid={handleAddAssetUnderUuid}
                            onAssetValuesSavePromise={handleAssetValuesSavePromise}
                            onAssetRemove={handleAssetRemove}
                          />
                          {/* ) : (
                            <Redirect to={{ pathname: '/scm-map' }} />
                           )
                          }*/}
                        </Route>
                        <Route path={routesConfig.singularityMapCreate()}>
                          <MapSidebarConfigurationSettings
                            onSettingsDataSave={handleSettingsDataSave}
                          />
                        </Route>
                        {/* <Route path={routesConfig.embed('')}>
                          <MapSidebarResults
                            onSettingsDataSave={handleSettingsDataSave}
                            onCommunityRemove={handleCommunityRemove}
                          />
                        </Route> */}
                      </>
                    );
                  }}
                </ConfigurationManagementCentral>
              </Switch>
            </>
          </BaseGenericModal>
        </Route>
      </Switch>
    </>
  );
};
