// BE - BackEnd
// FE - FrontEnd
type TForBE = {
  value: number[] | null;
  direction: 'forBE';
};

type TForFE = {
  value: [number, number] | null;
  direction: 'forFE';
};

export function convertHrsOfDay({ value, direction }: TForBE | TForFE): number[] | null {
  if (!value) return value;

  if (direction === 'forBE') {
    const output: TForBE['value'] = [];

    for (let i = value[0]; i <= value[1]; i++) {
      output.push(i);
    }

    return output;
  }

  return [value[0], value[value.length - 1]];
}
