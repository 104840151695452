import ContentfulContext, { ITrendingAndNewsEntry } from 'src/contexts/ContentfulContext';
import { TTrendingProps } from 'src/pages/Trending/Trending.types';
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  ResourcesFilter,
  orderByDate,
  orderByText,
  sortByChronoLogicalOrder,
} from '../../components/ResourcesFilter';

import { NewsDetails } from 'src/components/LandingPage/NewsDetails';
import { TFilterDropdownOption } from 'src/components/ResourcesFilter/ResourcesFilter.types';
import { UTCMoment } from 'src/utils/UTCMoment';
import classnames from 'classnames';
import s from './Trending.module.scss';

const filters = [
  {
    id: 1,
    name: 'Trending',
  },
  {
    id: 2,
    name: 'Past Events',
  },
  {
    id: 3,
    name: 'Archived News',
  },
];

const paramsOrderBy = {
  ['Newest']: { orderBy: 'desc', type: 'createdAt' },
  ['A-Z']: { orderBy: 'asc', type: 'title' },
  ['Z-A']: { orderBy: 'desc', type: 'title' },
  ['default']: { orderBy: 'desc', type: 'date' },
};

export const Trending: React.FC<TTrendingProps> = ({ slug }) => {
  const { trendingEntry } = useContext(ContentfulContext);
  const [filter, setFilter] = useState<number[]>([]);
  const [sort, setSort] = useState<TFilterDropdownOption>({ label: 'default', value: 'default' });
  const [newsSelected, setNewsSelected] = useState<ITrendingAndNewsEntry | null>(null);
  const history = useHistory();

  const onSelectItem = useCallback(
    (data) => {
      if (data) {
        history.push(`/resources/trending/${encodeURI(data?.title)}`);
      } else {
        history.push(`/resources/trending`);
      }
      setNewsSelected(data);
    },
    [history],
  );

  useEffect(() => {
    if (slug && trendingEntry) {
      setNewsSelected(trendingEntry.filter((entry) => entry.title == slug)[0]);
    }
  }, [slug, onSelectItem, trendingEntry]);

  const params = useMemo(() => paramsOrderBy?.[sort?.label] || null, [sort]);

  // Initial filter
  useEffect(() => {
    setFilter([1]);
  }, [setFilter]);

  const sortBy = (a, b, params, isNews = false, customSortLabel?: string | undefined) => {
    switch (params?.type) {
      case 'title':
        return orderByText(a.title, b.title, params?.orderBy);
      case 'date':
        return !isNews
          ? orderByDate(
              a?.startDate || a.endDate,
              b?.startDate || b.endDate,
              customSortLabel ? customSortLabel : params?.orderBy,
            )
          : orderByDate(a?.date, b?.date, customSortLabel ? customSortLabel : params?.orderBy);
      case 'createdAt':
        return orderByDate(a.createdAt, b.createdAt, params?.orderBy);
      default:
        return 0;
    }
  };

  const filterNameSelected = filters.find((val) => val.id === filter?.[0])?.name;

  const trendingSortByChronologicalOrder = sortByChronoLogicalOrder(trendingEntry);
  const trendingEntries =
    trendingSortByChronologicalOrder
      .filter((e) => !e.highlighted)
      .filter((e) => filterNameSelected && e?.section?.includes(filterNameSelected))
      .sort((a, b) => sortBy(a, b, params)) || [];

  const trendingEntryHighlighted =
    trendingSortByChronologicalOrder
      .filter((e) => e.highlighted)
      .sort((a, b) => sortBy(a, b, params, false, 'desc')) || [];

  const redirect = (link?: string) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const formatEvent = (event: ITrendingAndNewsEntry) => {
    if (event?.startDate && event?.endDate) {
      if (
        UTCMoment.utc(event.startDate).format('MMMM') ===
        UTCMoment.utc(event.endDate).format('MMMM')
      ) {
        return (
          UTCMoment.utc(event.startDate).format('DD') +
          ' - ' +
          UTCMoment.utc(event.endDate).format('DD MMMM yyyy')
        );
      } else {
        return (
          UTCMoment.utc(event.startDate).format('DD MMM') +
          ' - ' +
          UTCMoment.utc(event.endDate).format('DD MMM yyyy')
        );
      }
    }
    return UTCMoment.utc(event?.startDate || event?.date).format('DD MMMM yyyy');
  };

  const bigBox = (data: ITrendingAndNewsEntry, key: number) => {
    return (
      <div key={key.toString()} className={classnames(s.box, s.border)}>
        {data?.banner && <img src={data?.banner} alt="" />}
        <div className={s.boxText}>
          <h2>{data.title}</h2>
          <div className={s.dateLocal}>
            {data?.description && data?.shortDescription && (
              <span className={s.eventDetail}>
                {documentToReactComponents(data?.shortDescription as any)}
              </span>
            )}
          </div>
        </div>
        <div className={s.buttonPosition}>
          <button
            className={s.buttonApply}
            onClick={() => (data?.description ? onSelectItem(data) : redirect(data?.link))}>
            <span className={s.text_uppercaseButton}>See More</span>
          </button>
        </div>
      </div>
    );
  };

  const smallBox = (data: ITrendingAndNewsEntry, key: number) => {
    return (
      <div key={key.toString()} className={classnames(s.boxHalf, s.border)}>
        <div className={s.boxTextHalf}>
          <h2>{data.title}</h2>
          <div className={s.dateLocal}>
            <span>{formatEvent(data)}</span>
            <span className={s.textSmall}>{data.isOnline ? 'ONLINE' : data?.locale || ''}</span>
          </div>
        </div>
        <div className={s.buttonPosition}>
          <button
            className={s.buttonApply}
            onClick={() => (data?.description ? onSelectItem(data) : redirect(data?.link))}>
            <span className={s.text_uppercaseButton}>See More</span>
          </button>
        </div>
      </div>
    );
  };

  const topFunction = () => {
    const scrollToTopBtn = document.getElementById('container');
    if (scrollToTopBtn) scrollToTopBtn.scrollTop = 0;
  };

  useEffect(() => {
    if (newsSelected) {
      topFunction();
    }
  }, [newsSelected]);

  const splitEntries = trendingEntries.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <div id="container" className={s.container} style={newsSelected ? { paddingBottom: 0 } : {}}>
      {newsSelected ? (
        <NewsDetails back={() => onSelectItem(null)} data={newsSelected} />
      ) : (
        <>
          <ResourcesFilter
            setFilter={setFilter}
            filters={filters}
            initialFilter={filter}
            setSort={setSort}
          />
          {filterNameSelected === 'Trending' && trendingEntryHighlighted.length > 0
            ? trendingEntryHighlighted.map((e, key) => bigBox(e, key))
            : null}

          {splitEntries.map((chunk) => {
            return (
              <div key={Math.random()} className={s.trendingRow}>
                {chunk.map((e, key) => smallBox(e, key))}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
