// eslint-disable-next-line @typescript-eslint/ban-types
export enum EMenuColor {
  AlternativeOne = 'ALT_ONE',
  AlternativeTwo = 'ALT_TWO',
}

export type THeaderProps = {
  isLandingPage?: boolean;
  headerColorMode?: string;
  textColorLandingpage?: EMenuColor | null;
};
