import { createClient } from 'contentful';

export const client = createClient({
  space: 'dxw7kygvpvf1',
  accessToken: 'bRhWvnFJKV6nWzpCCEF_t6ofT6HJ8dJbDyhdmTZo6pk',
});

export async function fetchEntries() {
  try {
    const response = await client.getEntries({
      limit: 300,
    });

    return response;
  } catch (error) {
    throw error;
  }
}
