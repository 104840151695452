import { BaseGenericModal } from '../BaseGenericModal';
import { EModalSize } from '../../constants/modals';
import React from 'react';
import { TBaseOverflowMenuProps } from './BaseOverflowMenu.types';
import classnames from 'classnames';
import s from './BaseOverflowMenu.module.scss';

export const BaseOverflowMenu: React.FC<TBaseOverflowMenuProps> = ({
  children,
  relativeElement,
  modalId,
  position,
  size = EModalSize.S115,
  className,
  singleActionModal = true,
}) => {
  return (
    <BaseGenericModal
      modalId={modalId}
      size={size}
      relativeElement={relativeElement}
      position={position}
      theme="dark"
      anchorArrow
      singleActionModal={singleActionModal}
      className={className}>
      <div className={classnames(s.menuContainer, 'gradient-dark')}>{children}</div>
    </BaseGenericModal>
  );
};
