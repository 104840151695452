import { EFormVariant, TAssetType } from 'src/typings/base-types';
import { TLibrary, TLibraryChooserProps } from 'src/components/LibraryChooser';

import { NN } from 'src/typings/helpers';
import { TAsset } from 'src/typings/configuration.types';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TFormSettingsDataProps } from 'src/components/FormSettingsData';
import { TLocationSearchValue } from 'src/components/LocationSearch';
import { TMapboxLocation } from 'src/services/api/mapbox.api';

export type TModalAssetsManagerProps = {
  coords?: TLocationSearchValue['coords'];
  placeName?: TMapboxLocation['place_name'] | undefined;
  summaryIcon?: 'house' | 'market';
  hostAssetUuid?: TAsset['uuid'];
  view: EModalAssetsManagerView;
  // viewOnDestroy:EModalAssetsManagerView;
  viewOnDestroy:
    | EModalAssetsManagerView.AddHouse
    | EModalAssetsManagerView.Summary
    | EModalAssetsManagerView.AddCommunity
    | EModalAssetsManagerView.SaveOrSettings;
  formVariant?: EFormVariant;
  disallowReturnToSummary?: boolean | 'hide-button' | (() => void);
  onAddNewAsset?(payload: TNewAsset & { addUnderUuid?: string; noStoreUpdate?: boolean }): void;
  onAddNewCustomHouse?(
    payload: {
      addUnderUuid?: string;
      nextView: EModalAssetsManagerView.Form | EModalAssetsManagerView.ChooseAsset;
    } & Parameters<NN<TModalAssetsManagerProps['onAddNewAsset']>>[0],
  ): void;
  onAddNewCustomPV?(
    payload: {
      addUnderUuid?: string;
      nextView: EModalAssetsManagerView.Form | EModalAssetsManagerView.ChooseAsset;
    } & Parameters<NN<TModalAssetsManagerProps['onAddNewAsset']>>[0],
  ): void;
  onViewChange?(view: EModalAssetsManagerView): void;
  onFormVariantChange?(formVariant: EFormVariant): void;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
  onAssetRemove?(payload: { assetUuid: TAsset['uuid'] }): void;
  onAssetDuplicate?(payload: { assetUuid: TAsset['uuid'] }): void;
  onCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
  onCNCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
  onAddAssetUnderUuid?(
    payload: { addUnderUuid: string } & Parameters<
      NN<TModalAssetsManagerProps['onAddNewAsset']>
    >[0],
  ): void;
  addSaveKey?(key: string): void;
  rootElRefProp?: React.RefObject<HTMLDivElement | null>;
};

export type TState = {
  chosenLibrary: TLibrary | null;
  activeFilter: TLibraryChooserProps['activeFilter'];
};

export type TNewAsset = {
  chosenAsset: TLibrary;
  values: TConfigurationState['assetsValues']['assetType'];
};

export enum EModalAssetsManagerView {
  Wait = 'WAIT',
  AddHouse = 'ADD_HOUSE',
  Summary = 'SUMMARY',
  ChooseAsset = 'CHOOSE_ASSET',
  AssetsInside = 'ASSETS_INSIDE',
  Form = 'FORM',
  SaveOrSettings = 'SAVE_OR_SETTINGS',
  AddCommunity = 'ADD_COMMUNITY',
  AssetLoading = 'ASSET_LOADING',
  GridMarketAndAgent = 'GRID_MARKET_AND_AGENT',
  SaveToLibrary = 'SAVE_TO_LIBRARY',
}

export type TAssetsInside = Array<{
  icon: 'house' | 'pv' | 'plug' | 'battery' | 'factory';
  amount: number;
  type: TAssetType;
}>;
