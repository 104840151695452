import React from 'react';
import s from './TableSCMBills.module.scss';

export const TableSCMBillsHeader: React.FC = () => (
  <thead>
    <tr className={s.darkTableHeader}>
      <th style={{ width: '20%' }}></th>
      <th className={s.rightBorder} colSpan={3}>
        Bought
      </th>
      <th className={s.rightBorder} colSpan={2}>
        Sold
      </th>
      <th colSpan={2}>Total Balance</th>
    </tr>
    <tr className={s.columnTitlesHeader}>
      <th className={s.textLeft}>Asset</th>
      <th className={s.greyBackground}>Energy (kWh)</th>
      <th colSpan={2}>Paid (€)</th>
      <th className={s.greyBackground}>Energy (kWh)</th>
      <th>Revenue (€)</th>
      <th className={s.greyBackground}>Energy (kWh)</th>
      <th>Total (€)</th>
    </tr>
    <tr className={s.informationsHeader}>
      <th></th>
      <th></th>
      <th className={s.informationCell}>Trade Cost (€)</th>
      <th className={s.informationCell}>Grid Fees (€)</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
);
