import React, { useState } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/validation';

import { BaseButton } from 'src/components/BaseButton';
import { BaseInput } from 'src/components/BaseInput';
import { PASSWORD_MIN_LENGTH } from 'src/constants/application';
import { TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { TFormChangePassword } from './FormChangePassword.types';
import s from './FormChangePassword.module.scss';
import { useConfirmPasswordResetMutation } from 'src/graphql';

const initFormData = { password: '', passwordRepeat: '' };

export const FormChangePassword: React.FC<TFormChangePassword> = ({
  resetId,
  resetKey,
  handleClose,
}) => {
  const [formData, setFormData] = useState(initFormData);
  const [loading, setLoading] = useState(false);
  const [alreadyReset, setAlreadyReset] = useState(false)
  const [requestResult, setRequestResult] = useState<{
    type: 'error' | 'success';
    message: string;
  }>({ type: 'success', message: '' });

  const [confirmPasswordReset] = useConfirmPasswordResetMutation();

  const validators = () => ({
    password: [
      ...((n) => [rules.required(n), rules.minLength(n, PASSWORD_MIN_LENGTH)])('Password'),
    ],
    passwordRepeat: [
      ...((n) => [
        rules.required(n),
        rules.minLength(n, PASSWORD_MIN_LENGTH),
        rules.passwordMatch(formData.password),
      ])('Password'),
    ],
  });

  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const handleFieldBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) => {
    setRequestResult({ ...requestResult, message: '' });
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  };

  const parseError = (errorJson: string): string => {
    let output = `Something doesn't seem right, Please try again!`;

    if (!errorJson) {
      return output;
    }
    const parsedJson = JSON.parse(errorJson);

    try {
      console.log('logz JSON', parsedJson)
      // @ts-ignore
      output = Object.keys(parsedJson).includes('confirm_password_reset') ? parsedJson['confirm_password_reset'] : Object.values(Object.values(parsedJson)[0][0])[0][0];
    } catch (error) {
      console.log('logz ERR', error)
    }

    return output;
  };

  const handleFieldChange = ({ value, name }) => {
    setFormData({ ...formData, [name]: value });
  };

  const redirectToSingularityMapPage = () => {
    setTimeout(() => {
      handleClose?.();
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { errors } = validateFields({
      validators: validators(),
      formData,
    });
    if (errors) return;

    setLoading(true);

    try {
      await confirmPasswordReset({
        variables: {
          uid: resetId,
          key: resetKey,
          ...formData,
        },
      });

      setRequestResult({
        type: 'success',
        message: 'Password has been changed.',
      });
      setAlreadyReset(true)
      // close modal & redirect to /singularity-map page after successful password reset
      redirectToSingularityMapPage();
    } catch ({ message }) {
      if (message) {
        setRequestResult({ type: 'error', message: parseError(message as string) });
      }
    } finally {
      setLoading(false);
    }
  };

  console.log('logz RES', requestResult)

  return (
    <form onSubmit={handleSubmit}>
      <BaseInput
        type="password"
        name="password"
        value={formData.password}
        onChange={handleFieldChange}
        label="Your new password"
        placeholder="Type here"
        theme="line-light"
        className={s.inputEmail}
        error={validationErrors.password}
        disabled={loading}
        onBlur={(e) => handleFieldBlur(e, validators().password)}
      />
      <BaseInput
        type="password"
        name="passwordRepeat"
        value={formData.passwordRepeat}
        onChange={handleFieldChange}
        label="Repeat new password"
        placeholder="Type here"
        theme="line-light"
        className={s.inputEmail}
        error={validationErrors.passwordRepeat}
        disabled={loading}
        onBlur={(e) => handleFieldBlur(e, validators().passwordRepeat)}
      />
      {requestResult.message && (
        <p className={requestResult.type === 'success' ? s.successMessage : s.errorMessage}>
          {requestResult.message}
        </p>
      )}
      <BaseButton disabled={loading || alreadyReset} type="submit" className={s.buttonReset}>
        Reset password
      </BaseButton>
    </form>
  );
};
