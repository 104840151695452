import { FetchResult } from '@apollo/client';
import { THandleAssetValuesParameters } from 'src/components/ConfigurationManagementCentral';
import { TLibrarySaveForm } from 'src/components/ModalAssetsManager/AddToLibrary/AddToLibraryForm.types';
import { CreateLibraryMutation, useCreateLibraryMutation } from 'src/graphql';
import useBuildSavingTreeBranch from 'src/hooks/useBuildSavingTreeBranch';
import { setAssetLoadingInfo } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { TLibrariesTags } from 'src/typings/base-types';

export default function useSaveToLibrary() {
  const {
    createAreaInputFromSavingTreeBranch,
    createSavingTreeBranch,
    createAreaInputFromSaveProps,
  } = useBuildSavingTreeBranch();
  const dispatch = useAppDispatch();
  const [createLibrary] = useCreateLibraryMutation({ errorPolicy: 'all' });

  const handleAddToLibraryForSaved = (
    payload: TLibrarySaveForm,
  ): Promise<FetchResult<CreateLibraryMutation, Record<string, any>, Record<string, any>>> => {
    const areaInput = createAreaInputFromSaveProps(payload);
    const tags: Array<TLibrariesTags> = [];
    if (payload.isDefaultLib) tags.push('DefaultLib');
    if (!payload.isPrivate) tags.push('Public');
    if (payload.assetType && ['PV', 'Storage', 'Load', 'Area'].includes(payload.assetType))
      tags.push(payload.assetType);

    return createLibrary({
      variables: {
        name: payload.libraryName,
        description: payload.libraryDescription,
        isPrivate: payload.isPrivate,
        scenarioData: {
          representation: areaInput,
        },
        tags: tags,
      },
    });
  };

  return {
    handleAddToLibraryForSaved,
  };
}
