import { EModalAssetsManagerView, ModalAssetsManager } from 'src/components/ModalAssetsManager';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { TLngLat, TLngLatArray } from 'src/typings/base-types';
import { TMapboxLocation, getLocationDetails } from 'src/services/api/mapbox.api';
import { TOnClickListenerParams, TWorldMapProps } from './WorldMap.types';
import { TViewport, WorldMapService } from 'src/services/map/map.service';
import { batch, useSelector } from 'react-redux';
import {
  convertLngLat,
  getLocationDetailsByPriority,
  getPlaceTypeZoom,
} from 'src/utils/worldMap/helpers';
import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectAssets,
  selectAssetsAmountBelowCommunity,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigurationCenter,
  selectIsCommunityCreatedInDB,
  selectRawSelectedAssetUuid,
  selectReadOnly,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectModalAssetManagerActiveView,
  selectModalCommunitySummaryView,
  selectModalMapHeroDismissed,
  selectOnboardingStep,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import {
  setModalAssetManagerActiveView,
  setModalCommunitySummaryView,
  setModalMapHeroDismissed,
} from 'src/redux/application/application.slice';
import { updateSelectedLocation, updateVisibleLocation } from 'src/redux/map/map.slice';

import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral/ConfigurationManagementCentral';
import { EPredefinedModalIds } from 'src/constants/modals';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { WorldMapSelectedCommunityAssets } from 'src/components/WorldMapSelectedCommunityAssets';
import classnames from 'classnames';
import { compareOrdinaryJSONObjects } from 'src/utils/validation';
import { locationsCache } from 'src/cache/locations';
import { openModal } from 'src/redux/modals/modals.slice';
import { routesConfig } from 'src/routes/routes.config';
import s from './WorldMap.module.scss';
import { selectCommunitiesList } from 'src/redux/communities/communities.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { selectUsername } from 'src/redux/auth/auth.selectors';
import { setActiveConfigurationUuid } from 'src/redux/configuration/configuration.slice';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useBuildings } from 'src/hooks/useBuildings';
import { useCommunitySummaryResults } from 'src/hooks/useCommunitySummaryResults';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useHistory } from 'react-router';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { viewport } from '@mapbox/geo-viewport';

const MAP_SETTINGS: Partial<mapboxgl.MapboxOptions> = {
  minZoom: 0,
  maxZoom: 20,
  minPitch: 0,
  maxPitch: 50,
  style: process.env.REACT_APP_D3A_MAPBOX_STYLE,
};

export const WorldMap: React.FC<TWorldMapProps> = React.memo(({ className }) => {
  const isSCMFlow = useSelector(selectSCMFlow);
  const step = useSelector(selectOnboardingStep);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useAppLocation();
  const { mapService, setMapService } = useContext(WorldMapContext);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  // sidebarExpandedRef is used to prevent the map from re-rendering when the sidebar is expanded/collapsed
  const sidebarExpandedRef = useRef<boolean>(sidebarExpanded);

  const { isUserACommunityMember } = useIsUserACommunityMember();
  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const rawSelectedAssetUuid = useSelector(selectRawSelectedAssetUuid);
  const communitiesList = useSelector(selectCommunitiesList);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const configurationCenter = useSelector(selectConfigurationCenter);
  const assets = useSelector(selectAssets);
  const assetsValues = useSelector(selectAssetsValues);
  const mapboxRef = useRef<HTMLDivElement>(null);
  const modalCommunitySummaryView = useSelector(selectModalCommunitySummaryView);
  //const isCreateOrEditHouseFlow = useSelector(selectIsCreateOrEditHouseFlow);
  const modalAssetManagerActiveView = useSelector(selectModalAssetManagerActiveView);
  const communityAsset = useSelector(selectCommunityAsset);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const modalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const { discardCurrentConfiguration } = useConfigurationUtils();
  const { isBuildMode } = useConfigurationFlowState();
  const readOnly = useSelector(selectReadOnly);
  const assetsAmountBelowCommunity = useSelector(selectAssetsAmountBelowCommunity);
  const userMustSaveConfiguration =
    selectedAssetUuid === communityAsset?.uuid &&
    modalCommunitySummaryView !== EModalAssetsManagerView.Summary &&
    !isCommunityCreatedInDB &&
    assetsAmountBelowCommunity === 2;
  const username = useSelector(selectUsername);
  const prevAssetsValues = useRef<TConfigurationState['assetsValues']>({});
  const {
    buildings: activeBuildings,
    findByUuid: findActiveBuildingsByUuid,
    add: addActiveBuildings,
    remove: removeActiveBuildings,
    reset: resetActiveBuildings,
  } = useBuildings();
  const prevSelectedAssetUuid = useRef(selectedAssetUuid);
  const prevSelectedLocation = useRef(selectedLocation);
  const setSelectedLocation = useCallback(
    async (lngLat: TLngLat) => {
      dispatch(updateSelectedLocation({ ...lngLat }));

      const details = await getLocationDetails(lngLat);
      if (details) {
        const locationDetails = getLocationDetailsByPriority(details.features);
        locationsCache.set(locationsCache.convertToKey(lngLat), locationDetails);

        dispatch(
          updateSelectedLocation({
            ...lngLat,
            place_name: locationDetails?.place_name,
            place_type: locationDetails?.place_type,
            text: locationDetails?.text,
            bbox: locationDetails?.bbox,
          }),
        );
      }
    },
    [dispatch],
  );

  const onClickListener = useCallback(
    /**
     *
     * Map States
     * - None selected
     * - Build Mode
     * - Simulation Results
     * - Simulation Edit (build mode + simulation results)
     *
     */
    async ({ lngLat, target }: TOnClickListenerParams) => {
      // disable user clicking on map if scm flow
      if (isSCMFlow) {
        return;
      }
      const noneSelected = !isBuildMode && !selectedLocation && !activeConfigurationUuid;
      const defaultBuildMode = isBuildMode && !activeConfigurationUuid;
      const simulationResults = !isBuildMode && !!activeConfigurationUuid;
      const simulationEdit = isBuildMode && !!activeConfigurationUuid;
      const clickedConfigUuid = target.overedObject?._configuration?.configurationUuid;
      const clickedAssetUuid = target.overedObject?._asset?.uuid;
      const clickedParentUuid = target.overedObject?._communityAsset?.parentUuid;

      if (!modalMapHeroDismissed) {
        dispatch(setModalMapHeroDismissed(true));
      }

      //if (isCreateOrEditHouseFlow) return;

      // Click on the 3D plot/pin community model
      if (clickedConfigUuid) {
        //const isOwner = target.overedObject?._configuration?.owner === username;

        if (!activeConfigurationUuid) {
          batch(() => {
            dispatch(updateSelectedLocation(null));
            dispatch(setActiveConfigurationUuid(clickedConfigUuid));
          });
        }
        return;
      }
      // Click on the 3D asset model
      if (clickedAssetUuid) {
        if (clickedAssetUuid !== selectedAssetUuid) {
          dispatch(setSelectedAssetUuid(clickedAssetUuid));

          const clickedAssetCenter = target.overedObject?._asset?.center;

          if (clickedAssetCenter) {
            mapService?.flyTo({ center: clickedAssetCenter });
          }
        }

        return;
      }

      // Click on the 3D community ring model
      if (clickedParentUuid) {
        dispatch(setSelectedAssetUuid(clickedParentUuid));
        dispatch(openModal(EPredefinedModalIds.MODAL_COMMUNITY_ASSETS));
        return;
      }

      // Click on the map in DefaultPreview mode
      if (noneSelected) {
        discardCurrentConfiguration();
        await setSelectedLocation(lngLat);
        history.push(routesConfig.singularityMapCreate());
        return;
      }

      // Clsick on the map durgin build mode
      if (defaultBuildMode || simulationEdit) {
        if (readOnly) {
          dispatch(setSelectedAssetUuid(undefined));
          dispatch(updateSelectedLocation(null));
        } else if (selectedLocation) {
          const viewport = mapService?.getViewport();
          if (viewport) {
            mapService?.threeboxController.viewportChanged(viewport);
          }
          dispatch(updateSelectedLocation(null));
          if (assetsAmountBelowCommunity === 0) {
            discardCurrentConfiguration();
          }
        } else {
          await setSelectedLocation(lngLat);
        }
      }

      // Click on the map in simulation preview
      if (simulationResults) {
        if (selectedLocation) {
          dispatch(updateSelectedLocation(null));
        } else {
          await setSelectedLocation(lngLat);
        }
        return;
      }
    },
    [
      isBuildMode,
      selectedLocation,
      activeConfigurationUuid,
      modalMapHeroDismissed,
      //isCreateOrEditHouseFlow,
      dispatch,
      selectedAssetUuid,
      mapService,
      discardCurrentConfiguration,
      setSelectedLocation,
      history,
      readOnly,
      assetsAmountBelowCommunity,
      isSCMFlow,
    ],
  );

  const onMouseMoveListener = useCallback(
    async ({ target }: { target: any }) => {
      //const isOwner = target.overedObject?._configuration?.owner === username;
      const assetUuid = target.overedObject?._asset?.uuid;
      const parentUuid = target.overedObject?._communityAsset?.parentUuid;
      const configurationUuid = target.overedObject?._configuration?.configurationUuid;

      if (isSCMFlow || (!assetUuid && !parentUuid && !configurationUuid))
        mapService?.map.getCanvasContainer().classList.add(s.cursorDefault);
      else mapService?.map.getCanvasContainer().classList.remove(s.cursorDefault);
    },
    [mapService, isSCMFlow],
  );

  const onMoveEndListener = useCallback(
    async (viewport: TViewport) => {
      const data = await getLocationDetails({ lng: viewport.lng, lat: viewport.lat });
      if (!data) return;
      dispatch(
        updateVisibleLocation(
          data.features.map((item) => ({
            ...convertLngLat(item.center as TLngLatArray),
            place_name: item.place_name,
            place_type: item.place_type,
            text: item.text,
            bbox: item.bbox,
          })),
        ),
      );
    },
    [dispatch],
  );

  const onZoomListener = useCallback(
    async (viewport: TViewport) => {
      if (!modalMapHeroDismissed) {
        dispatch(setModalMapHeroDismissed(true));
      }
      mapService?.threeboxController.viewportChanged(viewport);
    },
    [dispatch, mapService?.threeboxController, modalMapHeroDismissed],
  );

  const geolocateListener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      setSelectedLocation({
        lng: e.coords.longitude,
        lat: e.coords.latitude,
      });
    },
    [setSelectedLocation],
  );

  const renderListener = useCallback(
    (e) => {
      if (e.target.getPitch() < (MAP_SETTINGS.minPitch as number)) {
        e.target.setPitch(MAP_SETTINGS.minPitch);
      } else if (e.target.getPitch() > (MAP_SETTINGS.maxPitch as number)) {
        e.target.setPitch(MAP_SETTINGS.maxPitch);
      }

      const viewport = mapService?.getViewport();

      // Highlighting buildings
      if (viewport && viewport?.zoom >= 16 && mapService?.map.getLayer('3d-buildings')) {
        // Extract non-highlighted buildings
        const buidlingAssets = Object.entries(assetsValues).filter((asset) => {
          const [uuid, values] = asset;
          // TODO Determine what specific fields are for community buildings
          const { exchangeInformation, fitAreaBoundary, geoTagLocation } = values;
          // If above specific fields are involved, asset is building.
          return (
            ((fitAreaBoundary && geoTagLocation) ||
              (exchangeInformation === 'spot' && geoTagLocation)) &&
            !findActiveBuildingsByUuid(uuid)
          );
        });

        // Get selected buildings
        const selectedBuildings = buidlingAssets
          .map((building) => {
            const [uuid, values] = building;
            const { geoTagLocation } = values;

            // Select buildings
            let bFeatures = mapService?.selected3DBuildings(geoTagLocation);
            if (!bFeatures) return [];

            // Remove buildings without id and attach uuid property to each feature
            bFeatures = bFeatures
              .filter((feature) => feature.id)
              .map((feature) => {
                feature['uuid'] = uuid;
                return feature;
              });

            return [...bFeatures];
          })
          .flat(1);
        // If no selected building, stop
        if (!selectedBuildings.length) return;

        addActiveBuildings(selectedBuildings);

        mapService?.highlight3DBuildings(activeBuildings.current);
      }
    },
    [activeBuildings, addActiveBuildings, assetsValues, findActiveBuildingsByUuid, mapService],
  );

  const styleLoadListener = useCallback(() => {
    // On direct enter on configuration page, models were displayed and caused issues
    if (!activeConfigurationUuid) {
      mapService?.threeboxController.setUsername(username);
      mapService?.threeboxController.setCommunityList(communitiesList);
    }
  }, [activeConfigurationUuid, communitiesList, mapService?.threeboxController, username]);

  useEffect(() => {
    if (
      !mapService ||
      (compareOrdinaryJSONObjects(prevAssetsValues.current, assetsValues) &&
        prevSelectedAssetUuid.current == selectedAssetUuid) ||
      selectedAssetUuid == ':assetUuid'
    )
      return;

    prevAssetsValues.current = assetsValues;
    prevSelectedAssetUuid.current = selectedAssetUuid;
    resetActiveBuildings();
    if (mapService?.map.isStyleLoaded()) mapService?.highlight3DBuildings(activeBuildings.current);
    let assetValuesFinal =
      isSCMFlow && selectedAssetUuid && isUserACommunityMember && selectedAssetUuid != ':assetUuid'
        ? { [selectedAssetUuid]: assetsValues[selectedAssetUuid] }
        : assetsValues;
    let assetsFinal =
      isSCMFlow && selectedAssetUuid && isUserACommunityMember && selectedAssetUuid != ':assetUuid'
        ? { [selectedAssetUuid]: assets[selectedAssetUuid] }
        : assets;

    mapService?.threeboxController.setCommunityAssets(
      assetsFinal,
      assetValuesFinal,
      communityAsset,
      configurationCenter,
    );
  }, [
    assets,
    isSCMFlow,
    isUserACommunityMember,
    mapService,
    assetsValues,
    configurationCenter,
    selectedAssetUuid,
    communityAsset,
    resetActiveBuildings,
    activeBuildings,
  ]);

  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);

  const { data: communitySummaryResults } = useCommunitySummaryResults({
    communityUuid: communityAsset?.uuid,
    jobId: activeConfigurationJobUuid,
  });

  useEffect(() => {
    mapService?.threeboxController.setSimulationResults(communitySummaryResults);
  }, [mapService, communitySummaryResults]);

  useEffect(() => {
    // On direct enter on configuration page, models were displayed and caused issuess
    if (!activeConfigurationUuid) {
      mapService?.threeboxController.setCommunityList(communitiesList);
    }
  }, [activeConfigurationUuid, communitiesList, mapService?.threeboxController]);

  useEffect(() => {
    mapService?.threeboxController.setUsername(username);
  }, [mapService?.threeboxController, username]);

  useEffect(() => {
    // Add house pin temporarily
    mapService?.threeboxController.addTempHousePin(selectedLocation);

    if (selectedLocation && selectedLocation?.lat && selectedLocation?.lng) {
      // TODO Determine when higlight picked buildings.
      // Highlight picked buildings
      // Remove preivous picked building
      removeActiveBuildings('none');
      // Select buildings
      const selectedBuildings = mapService?.selected3DBuildings([
        selectedLocation.lng,
        selectedLocation.lat,
      ]);
      // If exist buildings selected, highlight them.
      if (selectedBuildings) {
        addActiveBuildings(
          selectedBuildings.map((building) => {
            building['uuid'] = 'none';
            return building;
          }),
        );
        mapService?.highlight3DBuildings(activeBuildings.current);
      }
    }
  }, [activeBuildings, addActiveBuildings, mapService, removeActiveBuildings, selectedLocation]);

  useEffect(() => {
    if (mapboxRef.current && !mapService) {
      mapboxgl.accessToken = process.env.REACT_APP_D3A_MAPBOX_KEY as string;

      if (selectedLocation && selectedLocation.lng && selectedLocation.lat) {
        MAP_SETTINGS.center = [selectedLocation.lng, selectedLocation.lat];
        MAP_SETTINGS.zoom = getPlaceTypeZoom(
          (selectedLocation.place_type && selectedLocation.place_type[0]) || '',
        );
      }

      const service = new WorldMapService(mapboxRef.current, MAP_SETTINGS as MapboxOptions);

      setMapService(service);
    }
  }, [mapService, selectedLocation, setMapService]);

  useEffect(() => {
    if (isBuildMode || activeConfigurationUuid) {
      mapService?.threeboxController.setBuildMode(true);
    } else {
      mapService?.threeboxController.setBuildMode(false);
    }
    const viewport = mapService?.getViewport();
    if (viewport) mapService?.threeboxController.viewportChanged(viewport);
  }, [isBuildMode, activeConfigurationUuid, mapService]);

  useEffect(() => {
    return () => {
      mapService?.threeboxController.dispose();
    };
  }, [mapService]);

  useEffect(() => {
    mapService?.addListener('render', renderListener);
    mapService?.addListener('click', onClickListener);
    mapService?.addListener('mousemove', onMouseMoveListener);
    mapService?.addListener('moveend', onMoveEndListener);
    mapService?.addListener('zoom', onZoomListener);
    mapService?.addListener('geolocate', geolocateListener);
    mapService?.addListener('styleLoad', styleLoadListener);
    return () => {
      mapService?.removeListener('render', renderListener);
      mapService?.removeListener('click', onClickListener);
      mapService?.removeListener('mousemove', onMouseMoveListener);
      mapService?.removeListener('moveend', onMoveEndListener);
      mapService?.removeListener('zoom', onZoomListener);
      mapService?.removeListener('geolocate', geolocateListener);
      mapService?.removeListener('styleLoad', styleLoadListener);
    };
  }, [
    geolocateListener,
    mapService,
    onClickListener,
    onMouseMoveListener,
    onMoveEndListener,
    onZoomListener,
    renderListener,
    styleLoadListener,
  ]);

  useEffect(() => {
    // This effect toggles between "selected location" and "selected asset" in order to avoid displaying two modals at once.
    const selectedAssetUuidChangedToTruthy = prevSelectedAssetUuid.current !== selectedAssetUuid && selectedAssetUuid // prettier-ignore
    const selectedLocationChangedToTruthy = prevSelectedLocation.current !== selectedLocation && selectedLocation // prettier-ignore

    if (selectedAssetUuidChangedToTruthy) {
      dispatch(updateSelectedLocation(null));
    }

    if (selectedLocationChangedToTruthy) {
      dispatch(setSelectedAssetUuid(undefined));
    }

    prevSelectedAssetUuid.current = selectedAssetUuid;
    prevSelectedLocation.current = selectedLocation;
  }, [dispatch, selectedAssetUuid, selectedLocation]);

  useEffect(() => {
    history.listen((e) => {
      if (mapService) {
        mapService.threeboxController.viewportChanged(mapService.getViewport());
      }
    });
  }, [history, mapService]);

  const communityHasAssets = useMemo(() => Object.keys(assets).length, [assets]);

  useEffect(() => {
    if (isSCMFlow && isCommunityCreatedInDB && communityHasAssets) {
      batch(() => {
        dispatch(setSelectedAssetUuid(communityAsset?.uuid));
        dispatch(setModalCommunitySummaryView(EModalAssetsManagerView.Summary));
        dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.Summary));
      });
    }
    if (isSCMFlow) {
      mapService?.map.setZoom(3);
      mapService?.set3DView(true);
    }
  }, [communityAsset, communityHasAssets, isCommunityCreatedInDB, dispatch, isSCMFlow, mapService]);

  // renew the threebox controller when the sidebar is opened or closed
  useEffect(() => {
    if (sidebarExpanded === sidebarExpandedRef.current) return;
    if (!mapService) return;

    sidebarExpandedRef.current = sidebarExpanded;

    mapService.threeboxController.createThreebox();
    mapService.threeboxController.setBuildMode(true);
    mapService.threeboxController.updateCommunityAssetsLayer();
    mapService.threeboxController.updateCommunityRingLayer();
    mapService.threeboxController.updateCommunityListLayer();
  }, [mapService, sidebarExpanded]);

  return (
    <div className={classnames(s.container, className)}>
      {/* The map */}
      <div ref={mapboxRef} className={s.theMap} />

      {mapService && (isBuildMode || activeConfigurationUuid) && (
        <ConfigurationManagementCentral>
          {({
            handleAddNewAsset,
            handleAddNewCustomHouse,
            handleAssetValuesSave,
            handleSettingsDataSave,
            handleAssetRemove,
            handleCommunityRemove,
            handleAssetDuplicate,
            handleAddAssetUnderUuid,
            handleAddNewCustomPV,
            handleAssetValuesSavePromise,
            addSaveKey,
          }) => (
            <>
              {selectedLocation &&
                (!selectedAssetUuid || selectedAssetUuid === communityAsset?.uuid) && (
                  <Popup lng={selectedLocation.lng} lat={selectedLocation.lat} higherZIndex>
                    {selectedLocation.lng && selectedLocation.lat && (
                      <ModalAssetsManager
                        view={modalAssetManagerActiveView}
                        viewOnDestroy={
                          step == 0
                            ? EModalAssetsManagerView.AddCommunity
                            : EModalAssetsManagerView.AddHouse
                        }
                        coords={convertLngLat(selectedLocation as NonNullable<TMapboxLocation>)}
                        placeName={selectedLocation.place_name}
                        onAddNewAsset={handleAddNewAsset}
                        onAddNewCustomHouse={handleAddNewCustomHouse}
                        onAddNewCustomPV={handleAddNewCustomPV}
                        onViewChange={(v) => dispatch(setModalAssetManagerActiveView(v))}
                        onAssetValuesSavePromise={handleAssetValuesSavePromise}
                        onAssetRemove={handleAssetRemove}
                        addSaveKey={addSaveKey}
                      />
                    )}
                  </Popup>
                )}

              <WorldMapSelectedCommunityAssets
                selectedAssetUuid={selectedAssetUuid}
                rawSelectedAssetUuid={rawSelectedAssetUuid}
                disableInteraction={userMustSaveConfiguration}
                onAddNewCustomHouse={handleAddNewCustomHouse}
                onSettingsDataSave={handleSettingsDataSave}
                onAssetValuesSave={handleAssetValuesSave}
                onAssetRemove={handleAssetRemove}
                onCommunityRemove={handleCommunityRemove}
                onAssetDuplicate={handleAssetDuplicate}
                onAddAssetUnderUuid={handleAddAssetUnderUuid}
                onGridMarketClick={onClickListener}
                onAssetValuesSavePromise={handleAssetValuesSavePromise}
                addSaveKey={addSaveKey}
              />

              {/* TODO: Dev test components >>> */}
              {/* <TestTree
                  underUuid={underUuid}
                  setUnderUuid={(uuid: TConfigurationState['selectedAssetUuid']) => {
                    dispatch(setSelectedAssetUuid(uuid));
                  }}
                  onAssetRemove={handleAssetRemove}
                /> */}
              {/* TODO: <<< Dev test components */}
            </>
          )}
        </ConfigurationManagementCentral>
      )}
    </div>
  );
});
