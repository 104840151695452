// a generic function to convert object keys to snake case
// and return the object with the new keys
// and with the generic type T

import { snakeCase } from 'lodash';

export const objectSnakeCase = <T>(obj: Record<string, any>): T => {
  let tmpObject: Record<string, any> = {};
  Object.keys(obj).forEach((item) => {
    tmpObject[snakeCase(item)] = obj[item];
  });
  return tmpObject as T;
};
