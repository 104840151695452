import {
  ConfigurationOutput,
  useBulkAreaLiveEventMutation,
  useCreateAreaLiveEventMutation,
  useDeleteAreaLiveEventMutation,
  usePartialUpdateConfigurationMutation,
  useUpdateAreaLiveEventMutation,
  useUpdateConfigurationTreeMutation,
} from 'src/graphql';
import { setAssetLoadingInfo, setConfiguration } from 'src/redux/configuration/configuration.slice';

import { TSyncConfigurationArgs } from 'src/hooks/useSyncConfigurationWithBE.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';
import { openToast } from 'src/redux/toast/toast.slice';
import { useAppDispatch } from 'src/redux/store';
import { useCallback } from 'react';

type TSyncConfigurationWithBEOutput = {
  data: ConfigurationOutput | undefined | null;
  error: string | null;
};

type TuseSyncConfigurationWithBEOutput = {
  syncConfigurationWithBE(args: TSyncConfigurationArgs): Promise<TSyncConfigurationWithBEOutput>;
};

export function useSyncConfigurationWithBE(): TuseSyncConfigurationWithBEOutput {
  const dispatch = useAppDispatch();
  // 'delete'
  const [deleteAreaLiveEvent] = useDeleteAreaLiveEventMutation({
    onError: (err) => {
      const msg = err?.message;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });
  // 'create'
  const [createAreaLiveEvent] = useCreateAreaLiveEventMutation({
    onError: (err) => {
      // const msg = JSON.parse(err.message).create_area_live_event;
      const msg = err?.message;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });
  // 'singleUpdate'
  const [updateAreaLiveEvent] = useUpdateAreaLiveEventMutation({
    onError: (err) => {
      // const msg = JSON.parse(err.message).create_area_live_event;
      const msg = err?.message;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });
  // 'partialUpdate'
  const [partialUpdateConfiguration] = usePartialUpdateConfigurationMutation();
  // 'fullUpdate'
  const [updateConfigurationTree] = useUpdateConfigurationTreeMutation();
  // 'bulk'
  const [bulkAreaLiveEvent] = useBulkAreaLiveEventMutation();

  const syncConfigurationWithBE: TuseSyncConfigurationWithBEOutput['syncConfigurationWithBE'] =
    useCallback(
      async (args) => {
        const output: TSyncConfigurationWithBEOutput = {
          data: null,
          error: null,
        };

        switch (args.actionType) {
          case 'delete': {
            const { configurationUuid, assetUuid } = args;
            const variables = {
              configurationUuid,
              areaUuid: assetUuid,
            };

            deleteAreaLiveEvent({
              variables,
            });

            break;
          }

          case 'create': {
            const { configurationUuid, parentUuid, configTree, library, assetParams } = args;
            let variable = library?.libraryUuid
              ? {
                  configurationUuid,
                  parentUuid,
                  library,
                }
              : {
                  configurationUuid,
                  parentUuid,
                  areaRepresentation: assetParams,
                };

            // Not passing library bcz backend does not expect to receive both
            // areaRepresentation and library simulateneously and will throw an error
            createAreaLiveEvent({
              variables: variable,
            }).then((data) => {
              let results = data.data?.createAreaLiveEvent?.latestConfig;
              if (results) {
                const { scenarioData, name, description, timezone, project, settingsData, user } =
                  results;
                const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
                const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;
                const communityAssetUuid = getCommunityAsset({
                  assets,
                  rootAssetUuid,
                  assetsTreeRelations,
                  assetsValues,
                })?.uuid;

                dispatch(
                  setConfiguration({
                    name: name || '',
                    user: user || '',
                    description: description || '',
                    timezone: timezone || '',
                    projectUuid: project?.uuid || '',
                    locationVisible: true,
                    assets,
                    assetsTreeRelations,
                    assetsValues,
                    rootAssetUuid,
                    selectedAssetUuid: communityAssetUuid,
                    // TODO: Type has to be fixed
                    settingsData: settingsData || (undefined as any),
                  }),
                );
              }
            });

            break;
          }

          case 'singleUpdate': {
            const { configurationUuid, assetUuid, assetParams } = args;

            const variables = {
              configurationUuid,
              areaUuid: assetUuid,
              areaParams: assetParams,
            };
            updateAreaLiveEvent({
              variables,
            });

            break;
          }

          case 'partialUpdate': {
            const { configurationUuid, configTree } = args;

            const { data } = await partialUpdateConfiguration({
              variables: {
                configurationUuid,
                partialScenario: configTree,
              },
            });

            output.data = data?.partialUpdateConfiguration;
            break;
          }

          case 'fullUpdate': {
            const { configurationUuid, configTree } = args;

            const { data } = await updateConfigurationTree({
              variables: {
                configurationUuid,
                scenarioData: {
                  representation: configTree,
                },
              },
            });

            output.data = data?.updateConfiguration;
            break;
          }

          case 'bulk': {
            const { configurationUuid, eventList } = args;

            const { data } = await bulkAreaLiveEvent({
              variables: {
                configurationUuid,
                eventList,
              },
            });

            output.data = data?.bulkAreaLiveEvent?.latestConfig;
            break;
          }
        }

        return output;
      },
      [
        bulkAreaLiveEvent,
        createAreaLiveEvent,
        deleteAreaLiveEvent,
        partialUpdateConfiguration,
        updateAreaLiveEvent,
        updateConfigurationTree,
        dispatch,
      ],
    );

  return { syncConfigurationWithBE };
}
