import { MapSidebarCommunitiesHeaderProps } from 'src/components/MapSidebarSCM/components/MapSidebarCommunitiesList/MapSidebarCommunitiesList.types';
import React from 'react';
import { formatter } from 'src/utils/formatter';
import s from './MapSidebarCommunitiesList.module.scss';
import { selectCommunitiesCount } from 'src/redux/communities/communities.selectors';
import { useSelector } from 'react-redux';

export const MapSidebarCommunitiesHeader: React.FC<MapSidebarCommunitiesHeaderProps> = ({
  activeFilter,
  setActiveFilter,
}) => {
  const communitiesCount = useSelector(selectCommunitiesCount);

  return (
    <>
      <div className={s.header}>
        <h2>My Communities</h2>
        <h3>{formatter.prefixWithZeros(communitiesCount)}</h3>
      </div>
    </>
  );
};
