import { BaseIcon } from 'src/components/BaseIcon';
import { EnergyBadge } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadge/EnergyBadge';
import { EnergyBageDot } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadgeDot';
import React from 'react';
import s from './EnergyBadgeContainer.module.scss';

export const EnergyBadgeContainer: React.FC = () => (
  <div className={s.badgeRow}>
    <EnergyBadge
      title="Importing Energy"
      size="S"
      icon={<EnergyBageDot backgroundClassname={s.bgRed} />}
    />
    <EnergyBadge
      title="Exporting Energy"
      size="S"
      icon={<EnergyBageDot backgroundClassname={s.bgGreen} />}
    />
    <EnergyBadge title="Neutral" size="S" icon={<EnergyBageDot backgroundClassname={s.bgBlue} />} />
    <EnergyBadge title="Energy Flow" size="S" icon={<BaseIcon icon="arrow-feature" size={6} />} />
  </div>
);
