import { EFormVariant, EUserRoles } from 'src/typings/base-types';
import { FormFieldsGenerator, TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TAllFieldNames, TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';
import {
  getAllFieldTemplatesWithValuesForAsset,
  getAssetValues,
} from 'src/utils/assetsFields/fieldTemplatesWithValues';
import {
  selectAssets,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectReadOnly,
  selectSettingsData,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';

import { EAssetType } from 'src/components/WorldMap/components/ThreeboxController/ThreeboxController.types';
import { GRID_MARKET_MODE } from 'src/constants/application';
import { TFormAssetsParamsProps } from './FormAssetsParams.types';
import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';
import { isLibrary } from 'src/mocks/configurationSettings';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { updateFields } from 'src/utils/assetsFields/updateFields';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import { useSelector } from 'react-redux';
import { validateFields } from 'src/utils/validation';
import { validatorsByAssetType } from 'src/utils/assetsFields/fieldValidators';

export const FormAssetsParams: React.FC<TFormAssetsParamsProps> = ({
  hasErrorsRef,
  formVariant,
  assetUuid,
  assetType,
  currentValues,
  onSubmit,
  onChange,
  isCustomPV,
  isEdit,
  forceAll = false,
  isSCM,
  ...formFieldsGeneratorProps
}) => {
  const [fields, setFields] = useState<TFormFieldsGeneratorProps['fields'] | null>(null);
  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);
  const usedAssetsNames = useSelector(selectUsedAssetsNames);
  const assets = useSelector(selectAssets);
  const resolvedAssetType = assetType || assets[assetUuid].type;
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const newAssetType = useRef(resolvedAssetType);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);
  const currentRefs = useCurrentRefs({
    configurationCharacteristic,
    currentValues,
    settingsData,
    usedAssetsNames,
  });
  const userRole = useSelector(selectUserRole);
  const isAdmin = userRole === EUserRoles.Admin;

  function handleValidation(fields: TFieldsUnionWithValue[]) {
    return validateFields({
      validators: validatorsByAssetType[resolvedAssetType]({
        settingsData,
        fields,
        isLibrary,
        usedAssetsNames,
        currentValues,
        configurationCharacteristic,
      }),
      fields,
    });
  }

  useEffect(() => {
    if (assetType) newAssetType.current = assetType;
  }, [assetType]);

  const handleChange: TFormFieldsGeneratorProps['onChange'] = ({ name, value }) => {
    if (!fields || !assetUuid || !resolvedAssetType) return;

    const payload = {
      settingsData,
      isLibrary,
      configType,
      configurationCharacteristic,
      isAdmin,
    };
    let newFields;

    if (name === GRID_MARKET_MODE) {
      // This is special case where we completely switch a device
      const _value = value as TValuesByFieldName['GRID_MARKET_MODE'];
      newAssetType.current = _value === 'MarketMakerMode' ? 'MarketMaker' : 'InfiniteBus';

      newFields = getAllFieldTemplatesWithValuesForAsset({
        type: newAssetType.current,
        includeAll: forceAll,
        values: { ...getAssetValues(fields), [name]: _value },
        ...payload,
      });
    } else {
      newFields = updateFields({
        type: resolvedAssetType,
        fields,
        updatedField: { name: name as TAllFieldNames, value },
        ...payload,
      });
    }

    const { errors } = handleValidation(newFields);
    if (onChange) onChange();
    setFields(newFields);
    setErrors(errors);
  };

  const handleBlur: TFormFieldsGeneratorProps['onBlur'] = () => {
    if (!fields) return;
    const { errors } = handleValidation(fields);
    setErrors(errors);
  };

  const handleSubmit: TFormFieldsGeneratorProps['onSubmit'] = () => {
    if (!fields) return;

    const { errors } = handleValidation(fields);

    if (errors) {
      setErrors(errors);
    } else {
      onSubmit({ assetUuid, values: getAssetValues(fields), assetType: newAssetType.current });
    }
  };

  useEffect(
    function setUnfilteredFields() {
      const { configurationCharacteristic, currentValues, settingsData } = currentRefs.current;

      let formFields = getAllFieldTemplatesWithValuesForAsset({
        type: resolvedAssetType,
        settingsData,
        values: currentValues,
        isLibrary,
        configType,
        configurationCharacteristic,
        includeAll: forceAll,
        isSCM,
      });

      if (isCustomPV && formFields.length) {
        // manipulating fields in case of custom PV
        formFields = formFields.map((f) => {
          if (f.name === 'cloudCoverage' && f.type === 'enum') {
            return {
              ...f,
              // default selection: Local Generation Profile if no profile is selected
              // also checks for edit case where profile is already selected
              value: isEdit ? f.value : 5,
            };
          }
          return f;
        });
      }
      setFields(formFields);
    },
    [resolvedAssetType, currentRefs, isSCM, isCustomPV, isEdit, configType, forceAll],
  );

  const renderedFields = useMemo(() => {
    switch (formVariant) {
      case EFormVariant.OnlyAdvanced:
        return fields?.filter((f) => f.formView === EFormVariant.Advanced);
      case EFormVariant.Advanced:
        return fields?.filter(
          (f) => f.formView === EFormVariant.Advanced || f.formView === EFormVariant.Express,
        );

      default:
        return fields?.filter((f) => f.formView === formVariant);
    }
  }, [fields, formVariant]);

  useEffect(() => {
    if (hasErrorsRef) {
      hasErrorsRef.current = Boolean(errors);
    }
  }, [hasErrorsRef, errors]);

  if (!renderedFields) return null;

  return (
    <FormFieldsGenerator
      fields={renderedFields}
      onChange={handleChange}
      onBlur={handleBlur}
      errors={errors}
      onSubmit={handleSubmit}
      focusField="name"
      readOnly={readOnly}
      hasCustomPV={resolvedAssetType === EAssetType.PV && isCustomPV}
      formVariant={formVariant}
      isSCM={isSCM}
      {...formFieldsGeneratorProps}
    />
  );
};
