import { EFormVariant, TAssetType, TFieldValue } from 'src/typings/base-types';
import {
  FormAssetsParams,
  TAssetsSaveProps,
  TFormAssetsParamsProps,
} from 'src/components/FormAssetsParams';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  TFieldsUnionWithValue,
  TTextFieldWithValue,
} from 'src/utils/assetsFields/assetsFields.types';
import { TValidationErrors, rules, validateFields } from 'src/utils/validation';
import {
  UpdateCommunityFeesTariffsMutationVariables,
  useUpdateCommunityFeesTariffsMutation,
} from 'src/graphql';
import {
  getHomeKeys,
  getTheAvarageOfTheProperty,
  transformAssetValuesToArray,
} from 'src/utils/assetsValuesHelpers';
import {
  selectActiveConfigurationUuid,
  selectAssets,
  selectAssetsValues,
  selectRootAssetUuid,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';

import { BaseButton } from 'src/components/BaseButton';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { GridMarketModalHeader } from 'src/components/MapSidebarSCM/components/GridMarket/GridMarketModalHeader';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import { TAsset } from 'src/typings/configuration.types';
import { TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './GridMarket.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useAssetsData } from 'src/hooks/useAssetsData';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

type GridMarketFormProps = {
  hostAssetUuid: TAsset['uuid'];
  handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'];
  theme?: 'dark' | 'light';
  formId?: React.MutableRefObject<string>;
  readOnly?: boolean;
};

export const GridMarketForm: React.FC<GridMarketFormProps> = ({
  hostAssetUuid,
  handleAssetValuesSavePromise,
  theme = 'light',
  formId: formIdProp,
  readOnly,
}) => {
  const ECBKeysForGridMarket = useMemo(
    () => [
      'fixedMonthlyFee',
      'marketplaceMonthlyFee',
      'taxesSurcharges',
      'marketMakerRate',
      'feedInTariff',
      'gridFeeConstant',
    ],
    [],
  );

  // TODO: We have to read ECBKeys from formFields data
  const ECBKeys = useMemo(
    () => [
      'fixedMonthlyFee',
      'marketplaceMonthlyFee',
      'taxesSurcharges',
      'marketMakerRate',
      'feedInTariff',
      'gridFeeConstant',
    ],
    [],
  );

  const dispatch = useAppDispatch();
  let tempFormId = useRef(v4());
  const ECBFormId = useRef(v4());
  const formId = formIdProp ? formIdProp : tempFormId;
  const formHasErrorsRef = useRef(false);
  const firstFormValues = useRef({});
  const submitFormRef = useRef<HTMLInputElement | null>(null);
  // const formVariant = useSelector(selectActiveGridMarketForm);
  // need only GridAgent fields here
  const assetsValues = useSelector(selectAssetsValues);
  const usedAssetsNames = useSelector(selectUsedAssetsNames);
  const gridMarketKey = useSelector(selectRootAssetUuid) as string;
  const configUuid = useSelector(selectActiveConfigurationUuid);
  // const rootUuid = useSelector(selectRootAssetUuid);
  const assets = useSelector(selectAssets);
  const [updateCommunityFeesTariffs] = useUpdateCommunityFeesTariffsMutation();
  const rootUuid = Object.values(assets).find((item) => item.type === 'InfiniteBus')?.uuid || '';
  const [gridMarketValue, setGridMarketValue] = useState<string>(rootUuid || '');

  const formVariant = gridMarketValue === rootUuid ? EFormVariant.GridAgent : EFormVariant.Advanced;

  const [overwriteSettings, setOverwriteSettings] = useState(false);
  const [inputName, setInputName] = useState<string>(assetsValues[hostAssetUuid]?.name ?? '');
  const [errors, setErrors] = useState<null | TValidationErrors>(null);

  const gridMarketTitle = gridMarketValue === rootUuid ? 'Grid Market' : 'Market Settings';
  const energyBillTitle = 'Energy Bill Components';

  const generateInputNameField: (value: TFieldValue) => TTextFieldWithValue = useCallback(
    (value) => ({
      value: value as string,
      type: 'text',
      name: 'name',
      formView: formVariant,
    }),
    [formVariant],
  );

  const ECBSaveHandle = useCallback<({ assetUuid, values, assetType }: TAssetsSaveProps) => void>(
    async ({ assetUuid, values, assetType }) => {
      if (!handleAssetValuesSavePromise) return;

      if (assetType !== 'Area') {
        if (!configUuid || !overwriteSettings) return;

        let newValues: UpdateCommunityFeesTariffsMutationVariables = {
          configUUID: configUuid,
        };

        ECBKeysForGridMarket.forEach((item) => {
          if (firstFormValues.current[item] !== undefined) {
            newValues[item] = firstFormValues.current[item];
            return;
          }
          if (values[item] !== undefined) newValues[item] = values[item];
        });

        updateCommunityFeesTariffs({
          variables: {
            ...newValues,
          },
        }).then((result) => {
          if (result?.data?.updateCommunityFeesTariffs === 'success') {
            dispatch(
              openToast({
                message: 'Energy Bill Components in the community has been updated successfully!',
                type: 'success',
              }),
            );
          }
        });

        return;
      }

      let newValues = {};
      Object.keys(values).forEach((item) => {
        if (ECBKeys.includes(item)) {
          newValues[item] = values[item];
        }
      });

      Object.keys(firstFormValues.current).forEach((key) => {
        newValues[key] = firstFormValues.current[key];
      });

      handleAssetValuesSavePromise({
        assetUuid,
        values: newValues,
        assetType,
      }).then((result) => {
        if (result) {
          dispatch(
            openToast({
              message: 'Changes saved successfully!',
              type: 'success',
            }),
          );
        }
      });
    },
    [
      handleAssetValuesSavePromise,
      dispatch,
      ECBKeys,
      ECBKeysForGridMarket,
      configUuid,
      overwriteSettings,
      updateCommunityFeesTariffs,
    ],
  );

  const handleNameValidation = (inputNameField: Array<TFieldsUnionWithValue>) =>
    validateFields({
      validators: {
        name: [
          ...((n) => [
            rules.required(n),
            rules.noForwardSlash(n),
            rules.isNameUnique(
              usedAssetsNames.filter((item) => item != assetsValues[gridMarketValue].name),
              inputName,
            ),
          ])('Name'),
        ],
      },
      fields: inputNameField,
    });

  const handleNameBlur: TFormFieldsGeneratorProps['onBlur'] = () => {
    const { errors } = handleNameValidation([generateInputNameField(inputName)]);
    setErrors(errors);
  };

  const handleNameChange: TFormFieldsGeneratorProps['onChange'] = ({ value }) => {
    setInputName(value as string);
    const { errors } = handleNameValidation([generateInputNameField(value)]);
    setErrors(errors);
  };

  const triggerNextForm = useCallback<() => void>(() => {
    if (submitFormRef && submitFormRef.current && submitFormRef.current.form) {
      submitFormRef.current.setAttribute('form', ECBFormId.current);
      submitFormRef.current.click();
      submitFormRef.current.setAttribute('form', formId.current);
    }
  }, [submitFormRef, ECBFormId, formId]);

  // @ts-ignore-start
  const gridMarketFormSave = useCallback<
    ({ assetUuid, values, assetType }: TAssetsSaveProps) => void
  >(
    ({ assetUuid, values, assetType }: TAssetsSaveProps) => {
      //if (!handleAssetValuesSavePromise) return;

      if (assetType !== 'Area') {
        /*handleAssetValuesSavePromise({
          assetUuid,
          values: { ...values, name: 'Grid Market' },
          assetType,
        }).then((result) => {
          if (result) {
            dispatch(
              openToast({
                message: 'Changes saved successfully!',
                type: 'success',
              }),
            );
          }
        });
        if (overwriteSettings) {
          triggerNextForm();
        }

        return;*/
      }
      let newValues = values;
      newValues = {};
      // TODO: IMPORTANT, we have to fix here,
      // It was so hurry, sorry so much

      // keys that we need to delete
      Object.keys(values).forEach((item) => {
        //        if (ECBKeys.includes(item) === false || assetType !== 'Area') {
        newValues[item] = values[item];
        //        }
      });

      firstFormValues.current = newValues;

      triggerNextForm();
    },
    [
      //ECBKeys,
      //dispatch,
      //overwriteSettings,
      //handleAssetValuesSavePromise,
      triggerNextForm,
    ],
  );

  const handleSelectChange = ({ value }) => {
    setGridMarketValue(value);
  };

  const handleCheckboxChange = ({ value }) => {
    setOverwriteSettings(value);
  };

  const ECBValuesForGridMarket = useMemo(() => {
    const homeKeys = getHomeKeys(assets, [rootUuid]);

    const assetsValuesArray = transformAssetValuesToArray(assetsValues, homeKeys);

    return {
      marketMakerRate: getTheAvarageOfTheProperty(assetsValuesArray, 'marketMakerRate'),
      feedInTariff: getTheAvarageOfTheProperty(assetsValuesArray, 'feedInTariff'),
      gridFee: getTheAvarageOfTheProperty(assetsValuesArray, 'gridFeeConstant'),
      fixedMonthlyFee: getTheAvarageOfTheProperty(assetsValuesArray, 'fixedMonthlyFee'),
      marketplaceMonthlyFee: getTheAvarageOfTheProperty(assetsValuesArray, 'marketplaceMonthlyFee'),
      taxesSurcharges: getTheAvarageOfTheProperty(assetsValuesArray, 'taxesSurcharges'),
    };
  }, [assetsValues, assets, rootUuid]);

  const { assetsData } = useAssetsData();

  const assetList: Array<{ label: string; value: string; type: TAssetType }> = useMemo(
    () =>
      assetsData
        .filter((item) => item.type === 'Area' && item.name !== 'Community')
        .map((item, index) => ({
          label: item.name === 'Grid Market' ? 'General Grid Market' : item.name,
          value: item.name === 'Grid Market' ? rootUuid : item.uuid,
          type: item.type as TAssetType,
        })),
    [assetsData, rootUuid],
  );

  const formAssetType = useMemo(() => {
    if (gridMarketValue === rootUuid) return 'InfiniteBus';
    const asset = assetsData.find((item) => item.uuid === gridMarketValue);

    return (asset?.type || 'Area') as TAssetType;
  }, [assetsData, gridMarketValue, rootUuid]);

  return (
    <>
      <div className={s.formWrapper}>
        <FieldContainer className={s.selectField}>
          <BaseSelect
            onChange={(val) => handleSelectChange(val)}
            name={'gridMarket'}
            // label=""
            value={gridMarketValue}
            options={assetList}
            theme={'filled-gray'}
            showTooltip
            tooltipText={''}
          />
        </FieldContainer>
        {rootUuid === gridMarketValue && (
          <div className={s.row}>
            <FieldContainer className={s.switchWrapper} inlineAlign="right">
              <BaseSwitch
                onChange={(val) => handleCheckboxChange(val)}
                name={'gridMarket-check'}
                // label=""
                className={s.switch}
                value={overwriteSettings}
                options={[
                  { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false, text: 'Off' },
                  {
                    icon: 'check-mark',
                    iconSize: SWITCHER_ICON_SIZES.tick,
                    value: true,
                    text: 'On',
                  },
                ]}
                theme={'gray-custom'}
                variant="horizontal-edge"
                showTooltip
                tooltipText={''}
              />
            </FieldContainer>
            <div className={s.label}>
              General Grid Market settings will override all home market settings
            </div>
          </div>
        )}
        <GridMarketModalHeader formTitle={gridMarketTitle} />

        <FormAssetsParams
          key={`${formId.current}${gridMarketValue}`}
          hasErrorsRef={formHasErrorsRef}
          id={formId.current}
          assetUuid={gridMarketValue}
          formVariant={formVariant}
          onSubmit={gridMarketFormSave}
          assetType={formAssetType}
          currentValues={
            assets[gridMarketValue].type !== 'Area'
              ? { ...ECBValuesForGridMarket, ...assetsValues[gridMarketValue] }
              : assetsValues[gridMarketValue]
          }
          disableLocationField={false}
          isCustomPV={false}
          isEdit={true}
          theme={theme}
          isSCM={true}
        />

        <GridMarketModalHeader formTitle={energyBillTitle} />

        <FormAssetsParams
          key={`${ECBFormId.current}${gridMarketValue}`}
          hasErrorsRef={formHasErrorsRef}
          id={ECBFormId.current}
          assetUuid={gridMarketValue}
          formVariant={EFormVariant.GridMarketEnergy}
          onSubmit={ECBSaveHandle}
          assetType={formAssetType}
          currentValues={
            assets[gridMarketValue].type !== 'Area'
              ? ECBValuesForGridMarket
              : assetsValues[gridMarketValue]
          }
          disableLocationField={false}
          isCustomPV={false}
          isEdit={true}
          theme={theme}
          forceAll={true}
          isSCM={true}
        />
      </div>
      <div className={s.formButtonsWrapper}>
        <BaseButton
          propRef={(ref) => {
            submitFormRef.current = ref;
          }}
          type="submit"
          className={s.formButton}
          form={formId.current}
          disabled={readOnly}>
          Save
        </BaseButton>
      </div>
    </>
  );
};
