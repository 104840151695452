import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { EnergyFlowRow } from 'src/components/EnergyFlow/components/EnergyFlowRow';
import React from 'react';
import { TEnergyFlowListProps } from './EnergyFlowList.types';
import s from './EnergyFlowList.module.scss';

export const EnergyFlowList: React.FC<TEnergyFlowListProps> = ({ data }) => (
  <div className={s.listWrapper}>
    <CustomScrollbar className={s.scroll}>
      {data.map((item, index) => (
        <EnergyFlowRow key={`${item.title}${index}`} {...item} />
      ))}
    </CustomScrollbar>
  </div>
);
