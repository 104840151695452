import React, { useContext, useEffect, useRef } from 'react';

import { TPopupProps } from './Popup.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import mapboxgl from 'mapbox-gl';
import s from './Popup.module.scss';

export const Popup: React.FC<TPopupProps> = ({
  lng,
  lat,
  children,
  higherZIndex,
  style,
  mapServiceProp,
  mapboxClassNames,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popup = useRef<mapboxgl.Popup>();
  const { mapService: mapServicFromContext } = useContext(WorldMapContext);
  const mapService = mapServiceProp || mapServicFromContext;

  useEffect(() => {
    if (containerRef.current && mapService && lng && lat) {
      const additionalClassnames = mapboxClassNames ? mapboxClassNames : [];
      popup.current = new mapboxgl.Popup({
        className: classnames(s.mapboxWrapper, ...additionalClassnames, {
          [s.higherZIndex]: higherZIndex,
        }),
        closeOnClick: false,
        closeButton: false,
      })
        .setLngLat([lng, lat])
        .setDOMContent(containerRef.current)
        .addTo(mapService?.map);
    }
    return () => {
      popup.current?.remove();
    };
  }, [containerRef, higherZIndex, lat, lng, mapService, mapboxClassNames]);

  return (
    <div>
      <div ref={containerRef} className={s.container} style={style}>
        {children}
      </div>
    </div>
  );
};
