import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  selectInitialCommunity,
  selectIsLoggedIn,
  selectTokenAuth,
} from 'src/redux/auth/auth.selectors';
import { useHistory, useRouteMatch } from 'react-router';

import { EUserRoles } from 'src/typings/base-types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { routesConfig } from 'src/routes/routes.config';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setInitialCommunity } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useListScmLazyQuery } from 'src/graphql';
import { useSelector } from 'react-redux';

export type TUseInitialSCMCommunityReturn = {
  checkInitialCommunity: () => string | null;
};

export const useInitialSCMCommunity = (): TUseInitialSCMCommunityReturn => {
  const dispatch = useAppDispatch();
  const isSCMFlow = useSelector(selectSCMFlow);
  const userInfo = useSelector(selectTokenAuth);
  const isResearcher = useMemo(() => userInfo?.userRole === EUserRoles.Researcher, [userInfo]);
  const initialCommunity = useSelector(selectInitialCommunity);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();

  const [fetchSCMCommunities, { data: personalCommunities }] = useListScmLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const scmResultsMatch = useRouteMatch({
    path: routesConfig.singularityMapResults(''),
    strict: true,
  });

  useEffect(() => {
    if (isResearcher && isSCMFlow && !initialCommunity) {
      fetchSCMCommunities();
    }
  }, [isResearcher, isSCMFlow, fetchSCMCommunities, initialCommunity]);

  useEffect(() => {
    const configurations = personalCommunities?.listScmCommunities?.configurations;
    const lastCommunity = configurations?.[configurations.length - 1];
    if (lastCommunity && lastCommunity.uuid && isLoggedIn) {
      dispatch(setInitialCommunity(lastCommunity.uuid));
    }
  }, [personalCommunities, isLoggedIn, dispatch]);

  const checkInitialCommunity = useCallback(() => {
    if (isResearcher && isSCMFlow && initialCommunity) {
      return initialCommunity;
    }
    return null;
  }, [isResearcher, isSCMFlow, initialCommunity]);

  useEffect(() => {
    if (isResearcher && isSCMFlow && initialCommunity && scmResultsMatch?.isExact !== true) {
      return history.push(routesConfig.singularityMapResults(initialCommunity));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResearcher, isSCMFlow, initialCommunity, history, scmResultsMatch]);

  return { checkInitialCommunity };
};
