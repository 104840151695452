import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { setActiveSCMStep, setDataSheetResponse } from 'src/redux/scm/scm.slice';
import {
  setGuideStep,
  setModalAssetManagerActiveView,
  setOnBoardingStep,
} from 'src/redux/application/application.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import BuildCommunity from 'src/assets/images/build-community.png';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classnames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './ModalBuildCommunity.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectActiveSCMStep } from 'src/redux/scm/scm.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAppFlow } from 'src/hooks/useAppFlow';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useSelector } from 'react-redux';

export const ModalBuildCommunity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { discardCurrentConfiguration } = useConfigurationUtils();
  const { mapService } = useContext(WorldMapContext);

  const appLocation = useAppLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useState<TLocationSearchChangeResult>();

  const isModalOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY),
  );

  const { resetSCMSteps } = useAppFlow();

  const activeSCMStep = useSelector(selectActiveSCMStep);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const selectedLocation = useSelector(selectSelectedLocation);
  const isSidebarOpened = useSelector(selectIsModalOpened(EPredefinedModalIds.MODAL_MAP_SIDEBAR));

  const isCreateCommunityRoute = useMemo(
    () => appLocation.pathname === routesConfig.singularityMapCreate(),
    [appLocation.pathname],
  );

  //TODO: It Should be deleted, it has been added for development purposes
  useEffect(() => {
    if (isCreateCommunityRoute && activeSCMStep === 0) {
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
    }
  }, [dispatch, activeSCMStep, isCreateCommunityRoute]);

  const closeBuildCommunityModal = useCallback(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
  }, [dispatch]);

  const handleCloseButton = useCallback(() => {
    dispatch(setOnBoardingStep(0));
    dispatch(setGuideStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_ONBOARDING));
    discardCurrentConfiguration({ zoomOut: true });

    resetSCMSteps();
    dispatch(setActiveSCMStep(0));
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY));
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    dispatch(setDataSheetResponse(null));
  }, [dispatch, resetSCMSteps, discardCurrentConfiguration]);

  useEffect(() => {
    // close sidebar if already opened and /create route and location not selected
    if (isSidebarOpened && isCreateCommunityRoute && !selectedLocation) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));
    }
  }, [dispatch, isSidebarOpened, isCreateCommunityRoute, selectedLocation]);

  useEffect(() => {
    // close modal on routes other than /singularity-map/create
    if (!isCreateCommunityRoute && isModalOpened) {
      closeBuildCommunityModal();
    }
  }, [closeBuildCommunityModal, isCreateCommunityRoute, isModalOpened]);

  const handleLocationChange = (value) => {
    setLocation(value);
    dispatch(updateSelectedLocation(value));
  };

  const handleNextButtonClick = () => {
    closeBuildCommunityModal();
    dispatch(openModal(EPredefinedModalIds.MODAL_MAP_SIDEBAR));

    if (location && location.place_type.includes('place')) {
      mapService?.map.fitBounds(
        [
          [location.bbox[0], location.bbox[1]],
          [location.bbox[2], location.bbox[3]],
        ],
        {
          speed: 1.5,
        },
      );
    } else {
      mapService?.map.flyTo({
        center: location?.center as [number, number],
        speed: 1.5,
        zoom: 18,
      });
    }
  };

  if (!isModalOpened) return null;

  return (
    <>
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_SCM_BUILD_COMMUNITY}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalBuildCommunity)}>
          <div className={s.imgContainer}>
            <img src={BuildCommunity} alt="build-community" />
          </div>
          <div className={s.container}>
            <BaseClose className={s.closeBtn} onClick={handleCloseButton} />
            <div className={s.heading}>Build your community</div>
            <div className={s.subHeading}>Where is the community located?</div>
            <LocationSearch
              name="location-search"
              // value={scmLocation?.place_name}
              className={s.search}
              onChange={handleLocationChange}
              flyAfterSelect={false}
            />
            <BaseButton
              disabled={!Boolean(location)}
              className={s.nextBtn}
              onClick={handleNextButtonClick}>
              Next
            </BaseButton>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
