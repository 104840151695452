export type TEventsTooltipProps = {
  className?: string;
  style?: React.CSSProperties;
  isActive: boolean;
  data: TDataType[];
  dateTime?: any;
  onClose?(): void;
};

export enum EEventStatusType {
  added = 'Added',
  removed = 'Removed',
  edited = 'Edited',
}

export interface TDataType {
  title: string;
  status: string;
}
