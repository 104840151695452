export const dumpdata = [
  {
    time: '0h',
    consumption: 4000,
    generation: 2400,
  },
  {
    time: '2h',
    consumption: 3000,
    generation: 1398,
  },
  {
    time: '4h',
    consumption: 2000,
    generation: 9800,
  },
  {
    time: '6h',
    consumption: 2780,
    generation: 3908,
  },
  {
    time: '8h',
    consumption: 1890,
    generation: 4800,
  },
  {
    time: '12h',
    consumption: 2390,
    generation: 3800,
  },
  {
    time: '14h',
    consumption: 2000,
    generation: 9800,
  },
  {
    time: '16h',
    consumption: 2780,
    generation: 3908,
  },
  {
    time: '18h',
    consumption: 1890,
    generation: 4800,
  },
  {
    time: '20h',
    consumption: 2390,
    generation: 3800,
  },
  {
    time: '22h',
    consumption: 3000,
    generation: 1398,
  },
  {
    time: '24h',
    consumption: 2000,
    generation: 9800,
  },
];
