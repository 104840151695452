import { THREE } from 'threebox-plugin';

export type TConfigurationProps = {
  __typename: string;
  name: string;
  uuid: string;
  type: string;
  latitude: number;
  longitude: number;
};

export type TProjectProps = {
  __typename: string;
  name: string;
  user: string;
  timestamp: string;
  private: boolean;
  configurations: Array<TConfigurationProps>;
};

export type TCollaborationProps = {
  __typename: string;
  name: string;
  uuid: string;
  user: string | null;
  timestamp: string;
  private: boolean;
  configurations: Array<TConfigurationProps>;
};

export type TCanaryNetworkProps = {
  __typename: string;
  name: string;
  user: string | null;
  timestamp: string;
  private: boolean;
  configurations: Array<TConfigurationProps>;
};

export type TCommunityListProps = Array<TProjectProps | TCollaborationProps | TCanaryNetworkProps>;

export type TLayerEntityInputProps = {
  group: THREE.Group;
  origin?: number[];
  positive?: boolean | null;
  scale?: number;
  additionalProps?: any | null;
  layerType?: number;
};

export type TUpdateLayerScaleInputProps = {
  group: THREE.Group;
  initialScale: number;
  maxScale?: number;
  minScale?: number;
  x?: boolean;
  y?: boolean;
  z?: boolean;
};

export enum EAssetType {
  AREA = 'Area',
  PV = 'PV',
  LOAD = 'Load',
  STORAGE = 'Storage',
}
