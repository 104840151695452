import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';

import { BaseButtonSquare } from '../BaseButtonSquare';
import { LocationSearch, TLocationSearchChangeResult } from 'src/components/LocationSearch';
import { TBaseBreadcrumbs } from './BaseBreadcrumbs.types';
import classnames from 'classnames';
import s from './BaseBreadcrumbs.module.scss';
import useOutsideClick from '../../hooks/useOutsideClick';
import vars from 'src/assets/styles/utils/vars.module.scss';
import { useAppDispatch } from 'src/redux/store';
import WorldMapContext from 'src/contexts/WorldMapContext';
import { updateSelectedLocation } from 'src/redux/map/map.slice';
import { useHistory } from 'react-router';
import { routesConfig } from 'src/routes/routes.config';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { EPredefinedModalIds } from 'src/constants/modals';
import { TLngLat } from 'src/typings/base-types';
import { getLocationDetails } from 'src/services/api/mapbox.api';
import { locationsCache } from 'src/cache/locations';
import { getLocationDetailsByPriority } from 'src/utils/worldMap/helpers';
import {
  setModalAssetManagerActiveView,
  setOnBoardingStep,
} from 'src/redux/application/application.slice';
import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';
import {
  selectOnboardingStep,
  selectUserCreatedCommunityBefore,
} from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

export const BaseBreadcrumbs: React.FC<TBaseBreadcrumbs> = ({ items, onSelect }) => {
  const step = useSelector(selectOnboardingStep);
  const userCreatedCommunityBefore = useSelector(selectUserCreatedCommunityBefore);
  const [activeIndex, setActiveIndex] = useState<number>(items.length - 1);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showInput, setShowInput] = useState<boolean>(false);
  const outsideClickElRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState<TLocationSearchChangeResult>();
  const { mapService } = useContext(WorldMapContext);
  const history = useHistory();

  const handleOnClick = (index: number) => {
    if (onSelect) onSelect(items[index]);
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(null);
    setActiveIndex(items.length - 1);
  }, [items.length]);

  useEffect(() => {
    if (typeof selectedIndex === 'number') {
      setActiveIndex(selectedIndex);
    }
  }, [selectedIndex]);

  useOutsideClick(outsideClickElRef, (event) => {
    if (event.target !== outsideClickElRef?.current && showInput) {
      setShowInput(false);
    }
  });

  const setLocationChange = (data) => {
    history.push(routesConfig.singularityMapCreate());
    setSelectedLocation({ lng: data.lng, lat: data.lat });
  };
  const setSelectedLocation = useCallback(
    async (lngLat: TLngLat) => {
      dispatch(updateSelectedLocation({ ...lngLat }));

      const details = await getLocationDetails(lngLat);
      if (details) {
        const locationDetails = getLocationDetailsByPriority(details.features);
        locationsCache.set(locationsCache.convertToKey(lngLat), locationDetails);

        dispatch(
          updateSelectedLocation({
            ...lngLat,
            place_name: locationDetails?.place_name,
            place_type: locationDetails?.place_type,
            text: locationDetails?.text,
            bbox: locationDetails?.bbox,
          }),
        );
      }

      mapService?.flyTo(
        { lng: lngLat?.lng, lat: (lngLat?.lat as number) + 0 },
        { specificZoom: 17, speed: 1 },
      );
    },
    [dispatch, mapService],
  );

  return (
    <div className={s.container}>
      <div className={s.breadcrumbsWrapper}>
        <button
          type="button"
          className={classnames(s.button, {
            [s.isActive]: 0 === (selectedIndex !== null ? selectedIndex : activeIndex),
          })}
          onClick={() => handleOnClick(0)}>
          {items[0].name}
        </button>
        {items.slice(1).map((item, index) => (
          <button
            type="button"
            className={classnames(s.button, {
              [s.isActive]: index + 1 === (selectedIndex !== null ? selectedIndex : activeIndex),
            })}
            key={index}
            hidden={showInput}
            onClick={() => handleOnClick(index + 1)}>
            {item.name}
          </button>
        ))}
      </div>
      <div className={s.searchLocation} ref={outsideClickElRef}>
        {items.length === 1 || showInput ? (
          <LocationSearch
            // flyAfterSelect
            className={s.locationSearch}
            name="location"
            theme="filled-dark"
            dropdownPosition="bottom"
            iconRightColor={vars['color-ashy']}
            iconLeftColor={vars['color-ashy']}
            dropdownClassName={s.breadcrumbsDropdown}
            onChange={setLocationChange}
            // label="Type the location here"
            // dropdownPosition="bottom"
            // className={s.searchInput}
            // name="type-location"
            // iconLeft="search"
            value={location?.place_name}
            // onChange={setLocation}
          />
        ) : (
          <BaseButtonSquare
            size="breadcrumbs"
            theme="flat-gray-dark"
            icon="search"
            svgSize="1"
            onClick={() => setShowInput(true)}
          />
        )}
      </div>
    </div>
  );
};
