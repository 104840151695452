import 'react-tooltip/dist/react-tooltip.css';

import { AreaUuidApplyMapping, UserRequestsStatusOutput } from 'src/graphql';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  TCanaryRegistryAssetTileProps,
  TCanaryRegistryListFilters,
  TCanaryRegistryListProps,
  TCanaryRegistryUsersListProps,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  selectActiveConfigurationUuid,
  selectAssetsTreeRelations,
} from 'src/redux/configuration/configuration.selectors';
import { selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';

import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { CanaryRegistryAssetsList } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetsList';
import { CanaryRegistryListHeader } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryListHeader';
import { CanaryRegistryUsersList } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryUsersList';
import { EUserRoles } from 'src/typings/base-types';
import ReactDOMServer from 'react-dom/server';
import { TCanaryUserData } from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import { Tooltip } from 'react-tooltip';
import WorldMapContext from 'src/contexts/WorldMapContext';
import s from './CanaryRegistryList.module.scss';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useSelector } from 'react-redux';

export const CanaryRegistryList: React.FC<TCanaryRegistryListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
  setAssetView,
  onAssetRemove,
  setAddAssetView,
  setAssetsList,
  setSelectedMember,
  invitationEmails,
  activeTab,
  setActiveTab,
  wrapperTop,
}) => {
  const dispatch = useAppDispatch();

  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const assetsTree = useSelector(selectAssetsTreeRelations);
  const scmMembers = useSelector(selectSCMHomeDetails);
  const { mapService } = useContext(WorldMapContext);

  const [activeFilter, setActiveFilter] = useState<TCanaryRegistryListFilters>('All');
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isSelectAllTriggered, setIsSelectAllTriggered] = useState<boolean>(false);

  const filteredAssetsData = useMemo(() => {
    const aggregators = usersData.reduce((acc: string[], user) => {
      if (user.isAggregator) {
        acc.push(user.name);
      }

      return acc;
    }, []);
    const aggregatorsToAssetRelations = Object.entries(usersToAssetRelations).reduce(
      (acc: typeof usersToAssetRelations, [user, assets]) => {
        if (aggregators.includes(user)) {
          acc[user] = assets;
        }
        return acc;
      },
      {},
    );

    switch (activeFilter) {
      case 'Unregistered': {
        const assetsToOmit: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid]) => {
            assetsToOmit.push(assetUuid);
          });
        });

        return assetsData.filter(({ uuid }) => !assetsToOmit.includes(uuid));
      }
      case 'Pending': {
        const assetsToInclude: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid, status]) => {
            if (status === 'Applied') {
              assetsToInclude.push(assetUuid);
            }
          });
        });

        return assetsData.filter(({ uuid }) => assetsToInclude.includes(uuid));
      }
      case 'Registered': {
        const assetsToInclude: string[] = [];

        Object.values(aggregatorsToAssetRelations).forEach((item) => {
          Object.entries(item).forEach(([assetUuid, status]) => {
            if (status === 'Approved') {
              assetsToInclude.push(assetUuid);
            }
          });
        });

        return assetsData.filter(({ uuid }) => assetsToInclude.includes(uuid));
      }

      default:
        return assetsData;
    }
  }, [activeFilter, assetsData, usersData, usersToAssetRelations]);

  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });

  const showApplyButton = useMemo(() => {
    const availableAreSelected = selectedItems.filter((uuid) => {
      // Check if user already applied or approved for selected asset
      if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
        return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
      }
      return true;
    });
    return (
      activeTab === 'Community Assets' &&
      userRole === EUserRoles.Aggregator &&
      availableAreSelected.length > 0
    );
  }, [activeTab, selectedItems, userName, userRole, usersToAssetRelations]);

  const toggleTileSelection = (value: string) => {
    if (selectedItems.includes(value)) {
      setSelectedItems([...selectedItems].filter((name) => name !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const selectAllItems = () => {
    const isSelectAll = !isSelectAllTriggered;
    setIsSelectAllTriggered(isSelectAll);
    if (activeTab === 'Community Members') {
      setSelectedItems(isSelectAll ? usersData.map(({ name }) => name) : []);
    } else {
      setSelectedItems(isSelectAll ? filteredAssetsData.map(({ uuid }) => uuid) : []);
    }
  };

  const onUserItemClick: TCanaryRegistryUsersListProps['onItemClick'] = (data) => {
    const { name, isNewUser, subtitle } = data;
    toggleTileSelection(name);

    if (data.uuid) {
      dispatch(setSelectedAssetUuid(data.uuid));
      flyToAsset(data.uuid, assetFlyConfig);
      setSelectedMember(data);
      setAssetView(true);
      setAssetsList({
        data: {
          ...data,
          ...data.assignedAssets,
          name: subtitle,
          owners: [],
        },
        children: data.assignedAssets?.children,
      });
    }
  };

  const onAssetItemClick = (value) => {
    toggleTileSelection(value.data.uuid);

    if (value.data.uuid) {
      dispatch(setSelectedAssetUuid(value.data.uuid));
      flyToAsset(value.data.uuid, assetFlyConfig);
      setAssetView(true);
      setAssetsList(value);
    }
  };

  const getParentUuid = useCallback(
    (assetUuid: string) => {
      return Object.keys(assetsTree).find((key) => assetsTree[key].includes(assetUuid));
    },
    [assetsTree],
  );

  const getOwners = useCallback(
    (assetUuid: string) =>
      Object.keys(usersToAssetRelations).reduce<TCanaryRegistryAssetTileProps['data']['owners']>(
        (acc, key) => {
          if (usersToAssetRelations[key][assetUuid] === 'Approved') {
            const owner = usersData.find((item) => item.name === key);
            if (owner) {
              acc.push({
                avatarUrl: owner.avatarUrl,
                id: owner.uuid,
                name: owner.name,
                isAggregator: owner.isAggregator,
                isGridOperator: owner.isGridOperator,
              });
            }
          }
          return acc;
        },
        [],
      ),
    [usersData, usersToAssetRelations],
  );

  const getAppliedUser = useCallback(
    (assetUuid: string) => {
      for (const userName in usersToAssetRelations) {
        if (usersToAssetRelations[userName][assetUuid] === 'Applied') {
          return {
            userName,
            avatar: usersData.find((item) => item.name === userName)?.avatarUrl,
          };
        }
      }
    },
    [usersData, usersToAssetRelations],
  );

  useEffect(() => {
    setSelectedItems([]);
  }, [activeTab]);

  const scmMembersData = useMemo<TCanaryUserData[]>(() => {
    return scmMembers.map((member) => ({
      uuid: `${member?.uuid}`,
      name: member.email,
      subtitle: member.name,
      avatarUrl: undefined,
      requestStatus: UserRequestsStatusOutput.NotRequested,
      canaryInvitations: [],
      isAggregator: false,
      isGridOperator: false,
      aggregatorInformation: undefined,
    }));
  }, [scmMembers]);

  return (
    <div className={s.container}>
      <Tooltip
        anchorId="manage-anchor-element"
        isOpen={activeTab === 'Community Members'}
        position={{ x: 350, y: 420 - wrapperTop }}
      />
      <div
        id="manage-anchor-element"
        data-tooltip-html={ReactDOMServer.renderToString(
          <div className={s.tooltipContent}>
            <h3> 👈 Manage members </h3>
            <p>Here you can add or remove already added members or edit their information.</p>
          </div>,
        )}
        data-tooltip-place="right">
        <CanaryRegistryListHeader
          activeTab={activeTab}
          setActiveTab={(val) => {
            setActiveTab(val);
            setIsSelectAllTriggered(false);
          }}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectAllItems={selectAllItems}
          isSelectAllTriggered={isSelectAllTriggered}
          showApplyButton={showApplyButton}
          assetsCount={filteredAssetsData.length}
          membersCount={usersData.length}
          onClickApplyButton={() => {
            const applicationMapping: AreaUuidApplyMapping[] = selectedItems
              .filter((uuid) => {
                if (usersToAssetRelations[userName] && usersToAssetRelations[userName][uuid]) {
                  return !['Applied', 'Approved'].includes(usersToAssetRelations[userName][uuid]);
                }
                return true;
              })
              .map((uuid) => ({
                areaUuid: uuid,
                apply: true,
              }));

            if (configUuid)
              canaryNetworkServiceContext.applyForExternalConnection(
                applicationMapping,
                configUuid,
              );
          }}
        />
      </div>
      <div>
        {activeTab === 'Community Members' && (
          <CanaryRegistryUsersList
            assetsData={filteredAssetsData}
            usersData={[...invitationEmails, ...scmMembersData]}
            // usersData={scmMembersData}
            usersToAssetRelations={usersToAssetRelations}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onUserItemClick}
            getParentUuid={getParentUuid}
            getOwners={getOwners}
            getAppliedUser={getAppliedUser}
          />
        )}
        {activeTab === 'Community Assets' && (
          <CanaryRegistryAssetsList
            assetsData={filteredAssetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
            selectedItems={selectedItems}
            searchValue={searchValue}
            onItemClick={onAssetItemClick}
            getParentUuid={getParentUuid}
            getOwners={getOwners}
            getAppliedUser={getAppliedUser}
            onAssetRemove={onAssetRemove}
          />
        )}
      </div>
    </div>
  );
};
