import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  TResponseBill,
  TScmTableBill,
  TTableSCMBillsProps,
  TTableSCMBillsState,
} from 'src/components/_charts/TableSCMBills/TableSCMBills.types';

import { KeysToSnakeCase } from 'src/typings/helpers';
import { TableSCMBillRow } from 'src/components/_charts/TableSCMBills/TableSCMBillRow';
import { TableSCMBillsHeader } from 'src/components/_charts/TableSCMBills/TableSCMBillsHeader';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classNames from 'classnames';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import s from './TableSCMBills.module.scss';
import { selectDataResolution } from 'src/redux/scm/scm.selectors';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useSelector } from 'react-redux';

export const TableSCMBills: React.FC<TTableSCMBillsProps> = ({ onBillClick }) => {
  const { mapService } = useContext(WorldMapContext);
  const { flyToAsset, assetFlyConfig } = useFlyTo({ mapService });
  const dataResolution = useSelector(selectDataResolution);
  const dataResolutionRef = useRef(dataResolution);
  const [bills, setBills] = useState<TTableSCMBillsState['bills']>([]);

  const {
    queries: { getAccumulatedBillsDifferencesCommunity },
    billsDifferencesCommunityData,
    calledInfo: { billsDifferencesCommunityResponseCalled },
  } = useAccumulatedResults({
    initialRunQueries: [],
    mode: 'community',
  });

  useEffect(() => {
    if (!billsDifferencesCommunityResponseCalled || dataResolutionRef.current !== dataResolution) {
      dataResolutionRef.current = dataResolution;
      getAccumulatedBillsDifferencesCommunity();
    }
  }, [
    billsDifferencesCommunityResponseCalled,
    dataResolution,
    getAccumulatedBillsDifferencesCommunity,
  ]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    if (billsDifferencesCommunityData) {
      const responseObj: Record<
        string,
        { name: string; bills: KeysToSnakeCase<TResponseBill> }
      > = JSON.parse(billsDifferencesCommunityData);

      const tempBills: TTableSCMBillsState['bills'] = [];

      for (const key in responseObj) {
        const partialBill = objectCamelCase<TResponseBill>(responseObj[key].bills);
        tempBills.push({
          ...partialBill,
          assetName: responseObj[key].name,
          uuid: key,
        });
      }

      setBills(tempBills);
    }
  }, [billsDifferencesCommunityData]);

  const handleBillClick = (uuid: string) => {
    onBillClick(uuid);
    flyToAsset(uuid, assetFlyConfig);
  };

  return (
    <div className={s.scrollableTable}>
      <table className={s.tableBills}>
        <TableSCMBillsHeader />
        <tbody>
          {bills.map((bill, index) => (
            <TableSCMBillRow bill={bill} key={index} onBillClick={handleBillClick} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
