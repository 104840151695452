type TSortValue = 'asc' | 'desc';

export enum ESortOptions {
  asc = 'asc',
  desc = 'desc',
}

export const sortByCreateDate = (data, value: TSortValue) => {
  let tempData;
  if (value === ESortOptions.asc) {
    tempData = data.sort(
      (a, b) => new Date(a.joinedDate).valueOf() - new Date(b.joinedDate).valueOf(),
    );
  } else {
    tempData = data.sort(
      (a, b) => new Date(b.joinedDate).valueOf() - new Date(a.joinedDate).valueOf(),
    );
  }
  return tempData;
};

export const sortByProjects = (data, value: TSortValue) => {
  let tempData;
  if (value === ESortOptions.asc) {
    tempData = data.sort((a, b) => a.numberOfSimulations - b.numberOfSimulations);
  } else {
    tempData = data.sort((a, b) => b.numberOfSimulations - a.numberOfSimulations);
  }
  return tempData;
};

export const sortByString = (data, value: TSortValue, sortKey: string) => {
  let tempData;
  if (value === ESortOptions.asc) {
    tempData = data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
  } else {
    tempData = data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
  }
  return tempData;
};
