import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  DataAggregationResolution,
  ScmAccumulatedBillsDifferencesCommunityLazyQueryHookResult,
  ScmAccumulatedBillsDifferencesCommunityQuery,
  ScmAccumulatedBillsDifferencesLazyQueryHookResult,
  ScmAccumulatedBillsDifferencesQuery,
  ScmAccumulatedKpiDifferencesLazyQueryHookResult,
  ScmAccumulatedKpiDifferencesQuery,
  ScmAccumulatedResultsLazyQueryHookResult,
  ScmAccumulatedResultsQuery,
  useScmAccumulatedBillsDifferencesCommunityLazyQuery,
  useScmAccumulatedBillsDifferencesLazyQuery,
  useScmAccumulatedKpiDifferencesLazyQuery,
  useScmAccumulatedResultsLazyQuery,
} from 'src/graphql';
import {
  selectActiveConfigurationJobUuid,
  selectCommunityAsset,
  selectRawSelectedAssetUuid,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSelectedAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import { useCallback, useEffect, useMemo } from 'react';

import { TResolutionButtonState } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';
import { selectDataResolution } from 'src/redux/scm/scm.selectors';
import { setDataResolution } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export type TUseAccumulatedResultsResponse = {
  queries: {
    getAccumulatedResults: ScmAccumulatedResultsLazyQueryHookResult[0];
    getAccumulatedBillsDifferences: ScmAccumulatedBillsDifferencesLazyQueryHookResult[0];
    getAccumulatedKpiDifferences: ScmAccumulatedKpiDifferencesLazyQueryHookResult[0];
    getAccumulatedResultsDaily: ScmAccumulatedResultsLazyQueryHookResult[0];
    getAccumulatedResultsMonthly: ScmAccumulatedResultsLazyQueryHookResult[0];
    getAccumulatedBillsDifferencesCommunity: ScmAccumulatedBillsDifferencesCommunityLazyQueryHookResult[0];
  };
  calledInfo: {
    billsDifferencesCommunityResponseCalled: boolean;
  };
  resultsStartTime: string | null;
  resultsEndTime: string | null;
  resultsData: ScmAccumulatedResultsQuery['scmAccumulatedResults'];
  resultsDataMonthly: ScmAccumulatedResultsQuery['scmAccumulatedResults'];
  resultsDataDaily: ScmAccumulatedResultsQuery['scmAccumulatedResults'];
  billsDifferenceData: ScmAccumulatedBillsDifferencesQuery['scmAccumulatedBillsDifferences'];
  kpiDifferenceData: ScmAccumulatedKpiDifferencesQuery['scmAccumulatedKpiDifferences'];
  billsDifferencesCommunityData: ScmAccumulatedBillsDifferencesCommunityQuery['scmAccumulatedBillsDifferencesCommunity'];
  handleResolutionButton: (value: TResolutionButtonState) => void;
};

export type TUseAccumulatedResultsArgs = {
  initialRunQueries: Array<keyof TUseAccumulatedResultsResponse['queries']>;
  mode: 'home' | 'community';
};

export const useAccumulatedResults = ({
  initialRunQueries,
  mode,
}: TUseAccumulatedResultsArgs): TUseAccumulatedResultsResponse => {
  const dispatch = useAppDispatch();

  const dataResolution = useSelector(selectDataResolution);
  //const resultsStartTimeState = useSelector(selectResultsStartTime);
  const resultsEndTimeState = useSelector(selectResultsEndTime);
  const rawSelectedAssetUuid = useSelector(selectRawSelectedAssetUuid);
  const activeJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityUuid = useSelector(selectCommunityAsset)?.uuid;

  const uuidForQueries = useMemo(() => {
    if (mode === 'home') {
      return rawSelectedAssetUuid;
    }
    return communityUuid;
  }, [mode, communityUuid, rawSelectedAssetUuid]);

  const resultsEndTime = useMemo(() => {
    if (!resultsEndTimeState) return null;
    return UTCMoment.fromBackend(resultsEndTimeState)
      .subtract(1, 'day')
      .endOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [resultsEndTimeState]);

  const calculateResultsStartTime = useCallback(
    (resolution: DataAggregationResolution) => {
      return UTCMoment.utc(resultsEndTime)
        .startOf(resolution === DataAggregationResolution.Daily ? 'day' : 'month')
        .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
    },
    [resultsEndTime],
  );

  const resultsStartTime = useMemo(() => {
    if (!resultsEndTime) return null;
    return UTCMoment.utc(resultsEndTime)
      .startOf(dataResolution === DataAggregationResolution.Daily ? 'day' : 'month')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [resultsEndTime, dataResolution]);

  const isQueryParametersOK =
    activeJobUuid && rawSelectedAssetUuid && resultsStartTime && resultsEndTime;

  //ACCUMULATED RESULTS

  const [
    getAccumulatedResults,
    { data: resultsResponse, called: resultsCalled },
  ] = useScmAccumulatedResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  const [
    getAccumulatedResultsMonthly,
    { data: resultsResponseMonthly, called: resultsCalledMonthly },
  ] = useScmAccumulatedResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: calculateResultsStartTime(DataAggregationResolution.Monthly),
      endTime: resultsEndTime,
    },
  });

  const [
    getAccumulatedResultsDaily,
    { data: resultsResponseDaily, called: resultsCalledDaily },
  ] = useScmAccumulatedResultsLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: calculateResultsStartTime(DataAggregationResolution.Daily),
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      isQueryParametersOK &&
      initialRunQueries.includes('getAccumulatedResults') &&
      !resultsResponse &&
      !resultsCalled
    ) {
      getAccumulatedResults();
    }
  }, [
    isQueryParametersOK,
    initialRunQueries,
    getAccumulatedResults,
    resultsResponse,
    resultsCalled,
  ]);

  // ACCUMULATED RESULTS FOR WHOLE COMMUNITY

  const [
    getAccumulatedBillsDifferencesCommunity,
    { data: billsDifferencesCommunityResponse, called: billsDifferencesCommunityResponseCalled },
  ] = useScmAccumulatedBillsDifferencesCommunityLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  //BILLS DIFFERENCES

  const [
    getAccumulatedBillsDifferences,
    { data: billsDifferenceResponse, called: billsDifferenceCalled },
  ] = useScmAccumulatedBillsDifferencesLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      isQueryParametersOK &&
      initialRunQueries.includes('getAccumulatedBillsDifferences') &&
      !billsDifferenceResponse &&
      !billsDifferenceCalled
    ) {
      getAccumulatedBillsDifferences();
    }
  }, [
    isQueryParametersOK,
    initialRunQueries,
    getAccumulatedBillsDifferences,
    billsDifferenceResponse,
    billsDifferenceCalled,
  ]);

  //KPI DIFFERENCES

  const [
    getAccumulatedKpiDifferences,
    { data: kpiDifferenceResponse, called: isKpiDifferenceCalled },
  ] = useScmAccumulatedKpiDifferencesLazyQuery({
    variables: {
      jobId: activeJobUuid || '',
      uuid: uuidForQueries || '',
      startTime: resultsStartTime,
      endTime: resultsEndTime,
    },
  });

  useEffect(() => {
    if (
      activeJobUuid &&
      rawSelectedAssetUuid &&
      initialRunQueries.includes('getAccumulatedKpiDifferences') &&
      !kpiDifferenceResponse &&
      !isKpiDifferenceCalled
    ) {
      getAccumulatedKpiDifferences();
    }
    //
  }, [
    activeJobUuid,
    rawSelectedAssetUuid,
    initialRunQueries,
    getAccumulatedKpiDifferences,
    kpiDifferenceResponse,
    isKpiDifferenceCalled,
  ]);

  const handleResolutionButton = useCallback<(value: TResolutionButtonState) => void>(
    (value) => {
      dispatch(setDataResolution(value));
    },
    [dispatch],
  );

  return {
    queries: {
      getAccumulatedResults,
      getAccumulatedBillsDifferences,
      getAccumulatedKpiDifferences,
      getAccumulatedResultsDaily,
      getAccumulatedResultsMonthly,
      getAccumulatedBillsDifferencesCommunity,
    },
    calledInfo: {
      billsDifferencesCommunityResponseCalled,
    },
    resultsStartTime,
    resultsEndTime,
    resultsData: resultsResponse?.scmAccumulatedResults,
    resultsDataMonthly: resultsResponseMonthly?.scmAccumulatedResults,
    resultsDataDaily: resultsResponseDaily?.scmAccumulatedResults,
    billsDifferenceData: billsDifferenceResponse?.scmAccumulatedBillsDifferences,
    kpiDifferenceData: kpiDifferenceResponse?.scmAccumulatedKpiDifferences,
    billsDifferencesCommunityData:
      billsDifferencesCommunityResponse?.scmAccumulatedBillsDifferencesCommunity,
    handleResolutionButton,
  };
};
