import {
  EEventStatusType,
  TDataType,
} from 'src/components/SimulationProgressModal/components/EventsTooltip/EventsTooltip.types';

import { EEventType } from './TimelineEventChart.types';
// svgs
import create_home from 'src/assets/images/EventChart/icon-home-add.svg';
import create_home_hover from 'src/assets/images/EventChart/icon-home-add-hover.svg';
import delete_home from 'src/assets/images/EventChart/icon-home-delete.svg';
import delete_home_hover from 'src/assets/images/EventChart/icon-home-delete-hover.svg';
import update_home from 'src/assets/images/EventChart/icon-home-update.svg';
import update_home_hover from 'src/assets/images/EventChart/icon-home-update-hover.svg';

const getEventType = (eventType: EEventType) => {
  switch (eventType) {
    case EEventType.added:
      return EEventStatusType.added;
    case EEventType.edited:
      return EEventStatusType.edited;
    case EEventType.removed:
      return EEventStatusType.removed;
    default:
      return '';
  }
};

export const prepareDataForTooltip = (data): TDataType[] => {
  return data.map((_event) => {
    const { eventType, parameters } = _event;
    const eventStatusType = getEventType(eventType).toLowerCase();
    const params = JSON.parse(parameters);
    return { title: params?.area_representation?.name || 'Asset', status: eventStatusType };
  });
};

export const assetTypeStaticIcons = {
  create_area_PV: create_home,
  update_area_PV: update_home,
  delete_area_PV: delete_home,
  create_area_Load: create_home,
  update_area_Load: update_home,
  delete_area_Load: delete_home,
  create_area_FiniteDieselGenerator: create_home,
  update_area_FiniteDieselGenerator: update_home,
  delete_area_FiniteDieselGenerator: delete_home,
  create_area_Storage: create_home,
  update_area_Storage: update_home,
  delete_area_Storage: delete_home,
  create_area_MarketMaker: create_home,
  update_area_MarketMaker: update_home,
  delete_area_MarketMaker: delete_home,
  create_area_InfiniteBus: create_home,
  update_area_InfiniteBus: update_home,
  delete_area_InfiniteBus: delete_home,
  create_area_Area: create_home,
  update_area_Area: update_home,
  delete_area_Area: delete_home,
};

export const assetTypeStaticIconsHover = {
  create_area_PV: create_home_hover,
  update_area_PV: update_home_hover,
  delete_area_PV: delete_home_hover,
  create_area_Load: create_home_hover,
  update_area_Load: update_home_hover,
  delete_area_Load: delete_home_hover,
  create_area_FiniteDieselGenerator: create_home_hover,
  update_area_FiniteDieselGenerator: update_home_hover,
  delete_area_FiniteDieselGenerator: delete_home_hover,
  create_area_Storage: create_home_hover,
  update_area_Storage: update_home_hover,
  delete_area_Storage: delete_home_hover,
  create_area_MarketMaker: create_home_hover,
  update_area_MarketMaker: update_home_hover,
  delete_area_MarketMaker: delete_home_hover,
  create_area_InfiniteBus: create_home_hover,
  update_area_InfiniteBus: update_home_hover,
  delete_area_InfiniteBus: delete_home_hover,
  create_area_Area: create_home_hover,
  update_area_Area: update_home_hover,
  delete_area_Area: delete_home_hover,
};
