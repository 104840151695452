import { TAssets, TAssetsValues } from 'src/redux/configuration/configuration.slice';

import { TValuesByFieldName } from 'src/utils/assetsFields/valuesByFieldName.types';

export const transformAssetValuesToArray = (
  assets: TAssetsValues,
  keys?: Array<string>,
): Array<TValuesByFieldName & { uuid: string }> => {
  if (keys) {
    return keys.map((key) => ({
      ...assets[key],
      uuid: key,
    }));
  }
  return Object.entries(assets).map(([uuid, asset]) => ({
    ...asset,
    uuid,
  }));
};

export const getTheAvarageOfTheProperty = (
  assets: Array<TValuesByFieldName & { uuid: string }>,
  property: keyof TValuesByFieldName,
): number => {
  const tmpAssets = assets.filter(
    (asset) => asset[property] !== undefined && asset[property] !== null,
  );
  const sum = tmpAssets.reduce((acc, asset) => acc + asset[property], 0);
  const result = sum / tmpAssets.length;
  return Math.round(result * 100) / 100;
};

export const getHomeKeys = (assets: TAssets, filterKeys: Array<string>): Array<string> => {
  return Object.keys(assets).filter((key) => {
    const asset = assets[key];
    return asset.type === 'Area' && !filterKeys.includes(key);
  });
};
