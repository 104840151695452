import {
  TLibrary,
  TSelectedLibraryOptions,
} from 'src/components/LibraryChooser/LibraryChooser.types';
import { formatSubtitle } from 'src/components/LibraryChooser/LibraryChooserHelpers';
import { LibraryTypesGraphql } from 'src/graphql';
import { v4 } from 'uuid';

const customHouseUuid = v4();
export const customHouse: TLibrary = {
  libraryUuid: customHouseUuid,
  uuid: customHouseUuid,
  icon: 'house-3d',
  title: 'Custom Home',
  subtitle: formatSubtitle(0, 0),
  type: 'Area',
  key: customHouseUuid,
  isCustomHouse: true,
  description: 'A home with no assets attached - configure and add custom assets',
};

const customPVUuid = v4();
export const customPV: TLibrary = {
  libraryUuid: customPVUuid,
  uuid: customPVUuid,
  icon: 'solar-3d',
  title: 'Custom PV',
  subtitle: formatSubtitle(0, 1),
  type: 'PV',
  key: customPVUuid,
  isCustomHouse: false,
  isCustomPV: true,
  description: `Add Custom PV \n By default the generation is calculated according to the location and capacity, powered by energydatamap.com`,
};

export const selectLibraryOptions: TSelectedLibraryOptions = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'GS_y Library',
    value: LibraryTypesGraphql.GsyLibrary,
  },
  {
    label: 'Public Library',
    value: LibraryTypesGraphql.PublicLibrary,
  },
  {
    label: 'My Library',
    value: LibraryTypesGraphql.MyLibrary,
  },
];
