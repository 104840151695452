import React, { useCallback, useMemo } from 'react';
import { TAssetInside, TAssetsInsideProps, TState } from './AssetsInside.types';
import { TAssetType, TLibrariesTags } from 'src/typings/base-types';
import {
  selectAssets,
  selectAssetsTreeRelations,
  selectAssetsValues,
} from 'src/redux/configuration/configuration.selectors';

import { AssetsListWithSearch } from 'src/components/AssetsListWithSearch';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TAsset } from 'src/typings/configuration.types';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useMergedState } from 'src/hooks/useMergedState';
import { useSelector } from 'react-redux';

export const AssetsInside: React.FC<TAssetsInsideProps> = ({
  parentUuid,
  theme = 'dark',
  showEnergyStatus = false,
  showAssetsCount = false,
  ...rest
}: TAssetsInsideProps) => {
  const dispatch = useAppDispatch();
  const assets = useSelector(selectAssets);
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
  const assetsValues = useSelector(selectAssetsValues);
  const [{ searchQuery }, setState] = useMergedState<TState>({
    searchQuery: '',
  });
  const activeFilterDeviceType = useMemo(() => {
    const type =
      (Object.entries(LIBRARY_FILTERS_MAPPING).find((item) => item[1] === rest.activeFilter) ||
        [])[0] || '';
    const translations: Record<Exclude<keyof typeof LIBRARY_FILTERS_MAPPING, 'All'>, string> = {
      Area: 'Areas',
      PV: 'PVs',
      Load: 'Loads',
      Storage: 'Batteries',
    };

    return translations[type];
  }, [rest.activeFilter]);

  const count = useCallback(
    (
      parentAssetChildrenUuids,
      searchQueryLC = '',
      filter: TLibrariesTags[] = LIBRARY_FILTERS_MAPPING.All,
    ) => {
      const output: TAssetInside[] = [];

      const gatherTheChildren = (childrenUuids: TAsset['uuid'][]) => {
        childrenUuids.forEach((uuid) => {
          const asset = assets[uuid];
          const values = assetsValues[uuid];
          const assetNameLC = (values.name || '').toLowerCase();
          let include = true;

          if (
            filter !== LIBRARY_FILTERS_MAPPING.All &&
            LIBRARY_FILTERS_MAPPING[asset.type] !== filter
          ) {
            include = false;
          }

          if (searchQueryLC && !assetNameLC.includes(searchQueryLC)) {
            include = false;
          }

          if (include) {
            output.push({
              title: values.name || '',
              subtitle: '',
              type: asset.type as TAssetType,
              uuid: asset.uuid,
              icon: 'mkt-maker-3d' as const,
              key: asset.uuid,
            });
          }

          if (assetsTreeRelations[asset.uuid].length) {
            gatherTheChildren(assetsTreeRelations[asset.uuid]);
          }
        });
      };

      gatherTheChildren(parentAssetChildrenUuids);

      return output;
    },
    [assets, assetsTreeRelations, assetsValues],
  );

  const queriedAssets = useMemo(() => {
    const parentAssetChildrenUuids = assetsTreeRelations[parentUuid];
    const searchQueryLC = searchQuery.toLowerCase().trim();
    return count(parentAssetChildrenUuids, searchQueryLC, rest.activeFilter);
  }, [assetsTreeRelations, count, parentUuid, rest.activeFilter, searchQuery]);

  return (
    <AssetsListWithSearch
      showAssetsCount={showAssetsCount}
      theme={theme}
      assets={queriedAssets}
      searchQuery={searchQuery}
      onAssetChoose={(data) => {
        if (data) {
          dispatch(setSelectedAssetUuid(data.uuid));
        }
      }}
      onSearchQueryChange={(v) => setState({ searchQuery: v })}
      noResultsMessage={`No ${activeFilterDeviceType} in this home`}
      showEnergyStatus={showEnergyStatus}
      {...rest}
    />
  );
};
