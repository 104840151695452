import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { TBaseProgressProps } from './BaseProgress.types';
import classNames from 'classnames';
import s from './BaseProgress.module.scss';

export const BaseProgress: React.FC<TBaseProgressProps> = ({
  className = '',
  progress,
  showProgress = false,
  barClassName = '',
  expanded,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [progressWidth, setProgressWidth] = useState<number>(0);
  useLayoutEffect(() => {
    setTimeout(() => {
      if (ref.current && showProgress) {
        const rect = ref.current.getBoundingClientRect();
        setProgressWidth(rect.width);
      }
    }, 0);
  }, [ref, progress, showProgress, expanded]);

  return (
    <div className={classNames(s.progressWrapper, className)}>
      <div
        ref={(e) => {
          ref.current = e;
        }}
        style={{ width: `${progress}%` }}
        className={classNames(s.bar, barClassName)}>
        {showProgress && (
          <div className={s.progressPointWrapper}>
            <div className={s.progressPoint}></div>
            <span className={s.progressCountWrapper}>{progress}%</span>
          </div>
        )}
      </div>
    </div>
  );
};
