import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import { BaseTags, TBaseTagsProps } from 'src/components/BaseTags';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TBidsAndOffersDataPoint, TChartBidsAndOffersProps } from './ChartBidsAndOffers.types';

import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSlider } from 'src/components/BaseSlider';
import { Chart } from 'chart.js';
import { ChartBidsAndOffersColors } from 'src/constants/chart';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { TBidsOffersTradesItem } from 'src/typings/simulation.types';
import { debounce } from 'lodash';
import { formatter } from 'src/utils/formatter';
import { parseSimulationResults } from 'src/utils/parseSimulationResults';
import s from './ChartBidsAndOffers.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useChartJS } from 'src/hooks/useChartJS';
import { useCurrentRefs } from 'src/hooks/useCurrentRefs';
import { useReadSimulationResultsBidTradeLazyQuery } from 'src/graphql';
import { useSelector } from 'react-redux';

const INTERVAL = 15; // interval in minutes

export const ChartBidsAndOffers: React.FC<TChartBidsAndOffersProps> = ({
  jobId,
  assetUuid,
  currentMarket,
}) => {
  const [visibleBidsAndOffersTags, _setVisibleBidsAndOffersTags] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const setVisibleBidsAndOffersTags: TBaseTagsProps['onChange'] = ({ value }) => {
    _setVisibleBidsAndOffersTags(value);
  };
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const timeoutId = useRef<NodeJS.Timeout>();
  const { currency } = useSelector(selectSettingsData);
  const [readBidTrade, { data }] = useReadSimulationResultsBidTradeLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const settingsData = useSelector(selectSettingsData);

  const { bidsOffersTrades } = useMemo(() => {
    return parseSimulationResults(data?.simulationResults);
  }, [data]);

  const bidsAndOffersTags = [
    { name: 'Bids', color: ChartBidsAndOffersColors.bidsColor },
    { name: 'Offers', color: ChartBidsAndOffersColors.offersColor },
    { name: 'Trades', color: ChartBidsAndOffersColors.tradesColor },
  ];

  const mapItemsAndDeduplicate = (items: TBidsOffersTradesItem[] | undefined) =>
    items &&
    items.reduce((acc: TBidsAndOffersDataPoint[], item) => {
      const time = item.creation_time || item.time;
      const x = UTCMoment.fromBackend(time, BACKEND_DATE_FORMATS.BIDS_OFFERS_TRADES).valueOf();
      const y = item.energy_rate / 100; // convert cents to euros
      const isDulicate = acc.findIndex((p) => p.x === x && p.y === y) > -1;

      const point = (isDulicate ? item : { ...item, x, y, r: 2 }) as TBidsAndOffersDataPoint;

      acc.push(point);

      return acc;
    }, []);

  const tradesData = useMemo(() => mapItemsAndDeduplicate(bidsOffersTrades?.trades) || [], [
    bidsOffersTrades?.trades,
  ]);

  const offersData = useMemo(() => {
    const mappedItems = mapItemsAndDeduplicate(bidsOffersTrades?.offers) || [];

    return mappedItems.map(({ x, y, ...item }) => {
      const samePointInTrades = tradesData.findIndex((p) => p.x === x && p.y === y) > -1;

      // This is hack. Data point will not be displayed if we omit x,y. We do that to correcly format tooltip content.
      // Chart.js calls multiple times tooltip callbacks if mouse is over multiple datapoints with the same x,y.
      // As a result tooltip content gets duplicated. That is why we use this hack.
      return samePointInTrades ? item : { x, y, ...item };
    });
  }, [bidsOffersTrades?.offers, tradesData]) as TBidsAndOffersDataPoint[];

  const bidsData = useMemo(() => {
    const mappedItems = mapItemsAndDeduplicate(bidsOffersTrades?.bids) || [];

    return mappedItems.map(({ x, y, ...item }) => {
      const samePointInTrades = tradesData.findIndex((p) => p.x === x && p.y === y) > -1;
      const samePointInOffers = offersData.findIndex((p) => p.x === x && p.y === y) > -1;

      return samePointInTrades || samePointInOffers ? item : { x, y, ...item };
    });
  }, [bidsOffersTrades?.bids, offersData, tradesData]) as TBidsAndOffersDataPoint[];

  const datasets = useMemo(() => {
    // Ordering trades, bids, and offers for tooltip
    return [
      {
        data: loading ? [] : tradesData,
        backgroundColor: ChartBidsAndOffersColors.tradesColor,
        hidden: visibleBidsAndOffersTags.includes(2),
      },
      {
        data: loading ? [] : bidsData,
        backgroundColor: ChartBidsAndOffersColors.bidsColor,
        hidden: visibleBidsAndOffersTags.includes(0),
      },
      {
        data: loading ? [] : offersData,
        backgroundColor: ChartBidsAndOffersColors.offersColor,
        hidden: visibleBidsAndOffersTags.includes(1),
      },
    ];
  }, [bidsData, loading, offersData, tradesData, visibleBidsAndOffersTags]);

  useChartJS(EChartName.BidsAndOffers, canvasRef, chartRef, {
    datasets,
  });

  const selectOptions = useMemo(() => {
    const output: Array<{ label: string; value: number }> = [];
    const { startDate, endDate } = settingsData;
    const startMoment = UTCMoment.fromBackend(startDate, BACKEND_DATE_FORMATS.SETTINGS_DATA);
    const endMoment = UTCMoment.fromBackend(endDate, BACKEND_DATE_FORMATS.SETTINGS_DATA);
    const diff = endMoment.diff(startMoment, 'days');

    for (let i = 0; i < diff; i++) {
      const nextDay = startMoment.clone().add(i, 'days');

      output.push({
        label: nextDay.format('MMM DD YYYY'),
        value: +nextDay.format(UTCMoment.UNIX_FORMAT),
      });
    }

    return output;
  }, [settingsData]);

  const [selectedDate, setSelectedDate] = useState<number>(selectOptions[0].value);
  const [selectedStep, setSelectedStep] = useState(0);

  useEffect(() => {
    setSelectedDate(selectOptions[0].value);
  }, [selectOptions]);

  const variables = useMemo(() => {
    const startUnix = selectedDate + INTERVAL * 60 * selectedStep;
    const startMoment = UTCMoment.fromUnix(startUnix);
    const endMoment = startMoment.clone().add(14, 'minutes').add(59, 'seconds');

    return {
      assetUuid: assetUuid,
      jobId: jobId,
      startTime: startMoment.format(BACKEND_DATE_FORMATS.BIDS_OFFERS_TRADES),
      endTime: endMoment.format(BACKEND_DATE_FORMATS.BIDS_OFFERS_TRADES),
      endUnix: endMoment.unix(),
    };
  }, [assetUuid, jobId, selectedDate, selectedStep]);

  const lastFetchVariables = useRef<typeof variables | undefined>();

  const currentRefs = useCurrentRefs({
    variables,
  });

  useEffect(() => {
    lastFetchVariables.current = undefined;
  }, [jobId, assetUuid]);

  useEffect(() => {
    if (!chartRef.current) return;

    chartRef.current.options.scales!.x!.min = UTCMoment.fromBackend(
      variables.startTime,
      BACKEND_DATE_FORMATS.BIDS_OFFERS_TRADES,
    )
      // Prevents cutting off bubbles
      .add(-30, 'seconds')
      .valueOf();
    chartRef.current.options.scales!.x!.max = UTCMoment.fromBackend(
      variables.endTime,
      BACKEND_DATE_FORMATS.BIDS_OFFERS_TRADES,
    )
      .add(30, 'seconds')
      .valueOf();

    chartRef.current.update();
  }, [variables.endTime, variables.startTime]);

  useEffect(() => {
    if (!chartRef.current) return;
    chartRef.current.options.scales!.y!.ticks!.color = loading ? 'transparent' : undefined;
    chartRef.current.update();
  }, [loading]);

  const fetchResults = useMemo(
    () =>
      debounce(async () => {
        await readBidTrade({
          variables: currentRefs.current.variables,
        });

        timeoutId.current = setTimeout(() => {
          setLoading(false);
        }, 300);
      }, 1000),
    [currentRefs, readBidTrade],
  );

  useEffect(() => {
    if (!currentMarket || currentMarket < currentRefs.current.variables.endUnix) {
      return;
    }

    if (
      lastFetchVariables.current &&
      lastFetchVariables.current.endUnix === currentRefs.current.variables.endUnix
    ) {
      return;
    }

    lastFetchVariables.current = currentRefs.current.variables;
    setLoading(true);
    fetchResults();
  }, [assetUuid, fetchResults, jobId, selectedDate, selectedStep, currentMarket, currentRefs]);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  return (
    <>
      <ChartWrapper
        title={`Bids & Offers (${formatter.getCurrencySymbol(currency)}/kWh)`}
        className={s.container}
        headerComponent={
          <>
            <BaseSelect
              name="selectedDay"
              value={selectedDate}
              options={selectOptions}
              onChange={({ value }) => setSelectedDate(value as number)}
              theme="line-dark"
              className={s.select}
            />
          </>
        }
        footerComponent={
          <>
            <BaseTags
              className={s.bidsAndOfferTags}
              items={bidsAndOffersTags}
              name="housesTags"
              value={visibleBidsAndOffersTags}
              onChange={(value) => setVisibleBidsAndOffersTags(value)}
            />
            <BaseSlider
              min={0}
              max={(24 * 60) / INTERVAL}
              step={1}
              theme="black"
              name="selectedStep"
              value={selectedStep}
              showButtons
              onChange={({ value: v }) => {
                if (!Array.isArray(v)) {
                  setSelectedStep(v);
                }
              }}
            />
          </>
        }>
        <ChartDataLoadingWrapper loading={loading}>
          <canvas ref={canvasRef} />
        </ChartDataLoadingWrapper>
      </ChartWrapper>
    </>
  );
};
