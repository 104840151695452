import React from 'react';
import { TEnergyFlowRowProps } from './EnergyFlowRow.types';
import classNames from 'classnames';
import s from './EnergyFlowRow.module.scss';

export const EnergyFlowRow: React.FC<TEnergyFlowRowProps> = ({ title, value, flowType }) => {
  const borderCSS = `titleBorder${flowType}`;

  return (
    <div className={s.miniItem}>
      <span className={classNames(s.title, s[borderCSS])}>{title}</span>
      <span className={s.energy}>{value} kWh</span>
    </div>
  );
};
