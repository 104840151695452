import { RootState } from 'src/redux/store';
import { TSCMState } from 'src/redux/scm/scm.slice';

export const selectSCMDataSheetResponse = (state: RootState): TSCMState['dataSheetResponse'] =>
  state.scm.dataSheetResponse;

export const selectActiveSCMStep = (state: RootState): TSCMState['activeSCMStep'] =>
  state.scm.activeSCMStep;

export const selectSCMFlow = (state: RootState): TSCMState['isSCMFlow'] => state.scm.isSCMFlow;
export const selectSCMCommunityMember = (state: RootState): TSCMState['scmCommunityManager'] =>
  state.scm.scmCommunityManager;
export const selectScmSidebarTab = (state: RootState): TSCMState['scmSidebarTab'] =>
  state.scm.scmSidebarTab;
export const selectSCMHomeDetails = (state: RootState): TSCMState['scmHomeDetails'] =>
  state.scm.scmHomeDetails;

export const selectHomeBillsDifferences = (state: RootState): TSCMState['homeBillsDifferences'] =>
  state.scm.homeBillsDifferences;

export const selectHomeKpi = (state: RootState): TSCMState['homeKpi'] => state.scm.homeKpi;
export const selectKpiDifference = (state: RootState): TSCMState['kpiDifference'] =>
  state.scm.kpiDifference;

export const selectDataResolution = (state: RootState): TSCMState['dataResolution'] =>
  state.scm.dataResolution;

export const selectCommunityKpi = (state: RootState): TSCMState['communityKpi'] =>
  state.scm.communityKpi;

export const selectCommunityBillsDifference = (
  state: RootState,
): TSCMState['communityBillsDifferences'] => state.scm.communityBillsDifferences;

export const selectCommunityKpiDifference = (
  state: RootState,
): TSCMState['communityKpiDifference'] => state.scm.communityKpiDifference;

export const selectActiveTabResults = (state: RootState): TSCMState['activeTabResults'] =>
  state.scm.activeTabResults;
