import { ArrayElement, EUserRoles } from 'src/typings/base-types';
import {
  AuthToken,
  ConfigType,
  ListPersonalCommunityInfoQuery,
  ListScmQuery,
  Maybe,
  TokenAuthMutation,
  useListPersonalCommunityInfoLazyQuery,
  useListScmLazyQuery,
} from 'src/graphql';
import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import { FacebookAuth, GoogleAuth } from 'src/components/SocialLogins';
import { FormLogin, FormLoginSCM } from 'src/components/FormLogin';
import { FormSignup, FormSignupSCM, useSignUpFormData } from 'src/components/FormSignup';
import React, { useCallback, useEffect, useState } from 'react';
import {
  TAuthRegisterFormsProps,
  TConfigurationInPersonalCommunityProject,
  TProjectInPersonalCommunity,
} from './AuthRegisterForms.types';

import { BaseButton } from 'src/components/BaseButton';
import { BaseClose } from 'src/components/BaseClose';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { DeepNonNullable } from 'utility-types';
import { FormForgotPassword } from 'src/components/FormForgotPassword';
import classNames from 'classnames';
import { closeModal } from 'src/redux/modals/modals.slice';
import { routesConfig } from 'src/routes/routes.config';
import s from './AuthRegisterForms.module.scss';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { termsAndConditions } from 'src/components/ModalAuth/termsAndConditions.data';
import { useAppDispatch } from 'src/redux/store';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

export const AuthRegisterForms: React.FC<TAuthRegisterFormsProps> = ({
  showCloseButton,
  onCloseButtonClick,
  onSuccess,
}) => {
  const isSCMFlow = useSelector(selectSCMFlow);
  const dispatch = useAppDispatch();
  const { formData, handleFieldChange } = useSignUpFormData();
  const history = useHistory();
  const pathname = history.location.pathname;

  const [fetchSCMCommunities, { data: personalCommunities }] = useListScmLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const isLoginModalOpened =
    pathname.includes(routesConfig.login()) ||
    pathname.includes(routesConfig.singularityMap()) ||
    isSCMFlow;

  // PH-985-show default login view on mobile screens
  const [showLoginBlock, setShowLoginBlock] = useState<boolean>(isLoginModalOpened);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const showJoinCommunity = true;

  const onLoginSuccess = useCallback<(data?: Maybe<AuthToken>) => void>(
    (data) => {
      // TODO: remove this after when get the user role Information
      //if (isSCMFlow && data?.userRole === EUserRoles.Researcher) {
      //  fetchSCMCommunities();
      //  return;
      //}

      onSuccess();
    },
    [
      onSuccess,
      //isSCMFlow,
      //fetchSCMCommunities
    ],
  );

  //const findCollabConfig = useCallback<
  //  (item: TProjectInPersonalCommunity) => TConfigurationInPersonalCommunityProject | false
  //>((item) => {
  //  const collabConfigs = item?.configurations?.find(
  //    (subItem) => subItem?.configType === ConfigType.Collaboration,
  //  );
  //  if (collabConfigs) return collabConfigs;
  //  return false;
  //}, []);

  /*  useEffect(() => {
    if (
      !personalCommunities?.listScmCommunities ||
      !personalCommunities.listScmCommunities.configurations ||
      personalCommunities?.listScmCommunities?.totalCount === 0
    ) {
      return history.push(routesConfig.scmMap());
    }

    const firstCommunity = personalCommunities.listScmCommunities.configurations[0];
    if (!firstCommunity || !firstCommunity.uuid) {
      return history.push(routesConfig.scmMap());
    }

    return history.push(routesConfig.singularityMapResults(firstCommunity.uuid, ''));
  }, [personalCommunities, history]); */

  return (
    <>
      <div>
        {(showCloseButton || forgotPassword) && !isSCMFlow && (
          <BaseClose
            className={s.closeBtn}
            onClick={() => {
              setForgotPassword(false);
              if (!forgotPassword && onCloseButtonClick) {
                onCloseButtonClick();
              }
            }}
          />
        )}

        {!forgotPassword ? (
          <>
            <header className={s.formSwitches}>
              <BaseLink
                className={s.formSwitch}
                onClick={() => setShowLoginBlock(true)}
                isActive={showLoginBlock}
                theme="2">
                Login
              </BaseLink>
              {!isSCMFlow && (
                <BaseLink
                  className={s.formSwitch}
                  onClick={() => setShowLoginBlock(false)}
                  isActive={!showLoginBlock}
                  theme="2">
                  Register
                </BaseLink>
              )}
            </header>

            {showLoginBlock ? (
              <>
                {isSCMFlow ? (
                  <FormLoginSCM
                    onSuccess={onLoginSuccess}
                    handleForgotPassword={() => setForgotPassword(true)}
                  />
                ) : (
                  <FormLogin className={s.formLogin} onSuccess={onSuccess} />
                )}
                {!isSCMFlow && (
                  <p className={s.forgotPassword}>
                    Forgot password?{'  '}
                    <a onClick={() => setForgotPassword(true)} className={s.forgotPasswordCTA}>
                      Reset
                    </a>
                  </p>
                )}
              </>
            ) : isSCMFlow ? (
              <FormSignupSCM
                className={s.formSignup}
                onSuccess={onSuccess}
                {...{ formData, handleFieldChange }}
              />
            ) : (
              <FormSignup
                className={s.formSignup}
                onSuccess={onSuccess}
                {...{ formData, handleFieldChange }}
              />
            )}
          </>
        ) : (
          <>
            <header>
              <h1 className={s.heading}>Password recovery</h1>
            </header>

            <FormForgotPassword />
          </>
        )}
        {showJoinCommunity && isSCMFlow && !showLoginBlock && (
          <>
            <div className={s.orDivider}>OR</div>
            <div className={s.socialBtnsContainer}>
              {/* <FacebookAuth /> */}
              <GoogleAuth onSuccess={onSuccess} />
            </div>
          </>
        )}
      </div>

      <BaseGenericModal
        className={s.modal}
        modalId={EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS}
        size={EModalSize.XL650}>
        <BaseClose
          className={s.closeBtn}
          onClick={() => {
            dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
          }}
        />

        <div className={s.termsWrapper}>
          <header className={s.termsHeader}>
            {/* <BaseIcon className={s.gsLogo} icon="gs-logo-white" size={74} /> */}
            <BaseIcon className={s.gsLogo} icon="landing-logo-dark" size={74} />
            <h1 className={s.heading2}>Terms of Service</h1>
          </header>

          <CustomScrollbar className={s.termsContent}>
            <main dangerouslySetInnerHTML={{ __html: termsAndConditions }}></main>
          </CustomScrollbar>

          <footer className={s.termsFooter}>
            <BaseButton
              className={s.termsBtn}
              theme="grey3"
              onClick={() => {
                handleFieldChange({ name: 'termsAccepted', value: false });
                dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
              }}>
              Decline
            </BaseButton>
            <BaseButton
              className={s.termsBtn}
              onClick={() => {
                handleFieldChange({ name: 'termsAccepted', value: true });
                dispatch(closeModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
              }}>
              I accept the Terms of Service
            </BaseButton>
          </footer>
        </div>
      </BaseGenericModal>
    </>
  );
};
