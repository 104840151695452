import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useEffect, useRef, useState } from 'react';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import s from './ModalNetworkCanary.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { TModalAuthProps } from './ModalNetworkCanary.types';
import { closeModal } from 'src/redux/modals/modals.slice';
import classnames from 'classnames';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseButton } from 'src/components/BaseButton';
import { selectSimulationStatus } from 'src/redux/configuration/configuration.selectors';

export const ModalNetworkCanary: React.FC<TModalAuthProps> = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const isModalNetworkCanaryOpen = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_NETWORK_CANARY),
  );
  const simulationStatus = useSelector(selectSimulationStatus);

  useEffect(() => {
    if (simulationStatus !== 'finished' && isModalNetworkCanaryOpen) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_NETWORK_CANARY));
    }
  }, [dispatch, simulationStatus, isModalNetworkCanaryOpen]);

  function communitySlack() {
    window.open(
      'https://join.slack.com/t/gsycommunity/shared_invite/zt-dpfxe2gk-hKFccsrr68g982odCJlrIg',
      '_blank',
    );
  }

  function back() {
    dispatch(closeModal(EPredefinedModalIds.MODAL_NETWORK_CANARY));
    dispatch(closeModal(EPredefinedModalIds.MODAL_MAP_ALERT));
  }

  if (!isModalNetworkCanaryOpen) return null;

  return (
    <>
      <div className={s.background} />
      <BaseGenericModal
        className={s.modal}
        modalId={EPredefinedModalIds.MODAL_NETWORK_CANARY}
        size={EModalSize.XL820}
        modalRef={modalRef}>
        <div className={s.header}>
          <div className={s.left}>
            <BaseIcon onClick={() => back()} icon="gs-logo-white" size={64} />
            <div className={s.closeTop} onClick={() => back()}>
              <div>
                <BaseIcon className={s.arrowIcon} icon="arrow-left-full" size={20} />
              </div>
              <span>Back to My Simulation</span>
            </div>
          </div>

          <div className={s.buttonClose}>
            <BaseButtonSquare
              theme="flat-gray-dark"
              icon="close"
              size="3"
              svgSize="2"
              onClick={() => back()}
            />
          </div>
        </div>
        <div className={s.container}>
          <BaseIcon icon="effect-canary" size={100} />
          <h1>Bring your simulation closer to reality</h1>

          <div className={s.content}>
            <div className={s.box}>
              <div className={s.box_title}>
                <span>1 Simulation</span>
                <BaseIcon icon="check-mark" className={s.iconCheck} size={16.25} />
              </div>
              <p>
                Simulate an energy community using the free web-based Grid Singularity tool that
                creates a digital representation of household and community assets
              </p>
            </div>
            <div className={classnames(s.box, s.box_active)}>
              <div className={s.box_title}>
                <span>2 Connect</span>
              </div>
              <p>
                Connect your <span>Digital Energy Community</span> (live data stream) to Grid
                Singularity’s Canary Test Network for a technical proof-of-concept through a third
                party, like an aggregator collaborating with Grid Singularity
              </p>
              <BaseButton className={s.takeMeToTheFuture} onClick={() => communitySlack()}>
                Contact to begin
              </BaseButton>
            </div>
            <div className={s.box}>
              <div className={s.box_title}>
                <span>3 Launch your energy community!</span>
              </div>
              <p>
                This step may require regulatory reform in some markets, with Belgium’s Wallonia
                region and Austria ready for implementation as early as Autumn 2021
              </p>
            </div>
          </div>

          <div className={s.info}>
            <p>
              {`For more information, please see Grid Singularity's`}
              <a target="_blank" rel="noreferrer" href="https://gridsingularity.medium.com/">
                {' '}
                Medium
              </a>{' '}
              and
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gridsingularity.github.io/gsy-e/documentation/">
                {' '}
                Wiki pages
              </a>{' '}
              and contact us for support via our
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gsycommunity.slack.com/join/shared_invite/zt-dpfxe2gk-hKFccsrr68g982odCJlrIg#/shared-invite/email">
                {' '}
                Slack community channel
              </a>
              .
            </p>
            <p>
              Checkout also the
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.compile-project.eu/products/coolkit/">
                {' '}
                COMPILE COOLKIT
              </a>{' '}
              for guides to build an energy community, such as
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.rescoop.eu/toolbox/compile-toolkit-financing-guide">
                {' '}
                financing guide
              </a>
              ,
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.rescoop.eu/toolbox/compile-toolkit-best-practice-guide">
                {' '}
                best practice guide
              </a>
              ,
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.rescoop.eu/toolbox/compile-toolkit-stakeholder-engagement-guide">
                {' '}
                stakeholder engagement guide{' '}
              </a>
              and
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.compile-project.eu/products/coolkit/technical-tools/">
                {' '}
                technical tools guide
              </a>
            </p>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
