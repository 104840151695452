import {
  AdminInviteFlag,
  AreaUuidApplyMapping,
  ExternalConnectionApprovalStatus,
  UserRequestsStatus,
  UserRequestsStatusOutput,
  UserRoleEnum,
  useBatchRegisterExternalConnectionMutation,
  useInviteUserToWebsiteMutation,
  useListAdminRegistryUsersLazyQuery,
  useListAggregatorRegistryUsersLazyQuery,
  useListCanaryNetworksQuery,
  useListGridOperatorRegistryMarketsLazyQuery,
  useListGsyUserRegistryAssetsLazyQuery,
  useListUserToAdminRequestsLazyQuery,
  useMoveCollaborationToCanaryNetworkMutation,
  useRegExternalConnectionMutation,
  useRequestSimulationToCnConversionMutation,
  useRespondExternalConnectionRequestMutation,
  useSetExternalConnectionMutation,
  useSetUserInviteMutation,
} from 'src/graphql';
import React, { useMemo } from 'react';
import {
  createCNName,
  parseAdminRegistryUsersData,
  parseAggregatorRegistryUsersData,
  parseGridOperatorRegistryMarkets,
  parseGsyUserRegistryUsersData,
  parseUserToAdminRequestsData,
} from './CanaryNetworkServiceHelper';
import {
  selectProfilePicture,
  selectUserRole,
  selectUsername,
} from 'src/redux/auth/auth.selectors';

import { EUserRoles } from 'src/typings/base-types';
import { addGlobalNotification } from 'src/redux/notifications/notificaitons.slice';
import { isJson } from 'src/utils/isJson';
import { openToast } from 'src/redux/toast/toast.slice';
import { setUserToAdminRequests } from 'src/redux/communities/communities.slice';
import { updateCnUsers } from 'src/redux/canaryNetwork/canaryNetwork.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

type TCanaryNetworkServiceContext = {
  fetchCanaryNetworkRegistry: (configUuid: string) => void;
  approveExternalConnection: (
    configUuid: string,
    areaUuid: string,
    email: string,
    username: string,
  ) => Promise<void>;
  removeExternalConnection: (
    configUuid: string,
    areaUuid: string,
    email: string,
    username: string,
  ) => Promise<void>;
  registerExternalConnection: (
    apply: boolean,
    configUuid: string,
    areaUuid: string,
  ) => Promise<void>;
  inviteUserToCn: (configUuid: string, email: string) => Promise<void>;
  inviteUserToWebsite: (configUuid: string, email: string, userRole: UserRoleEnum) => Promise<void>;
  applyForExternalConnection: (
    applicationMapping: AreaUuidApplyMapping[],
    configUuid: string,
  ) => Promise<void>;
  fetchGlobalListUserToAdminRequests: () => void;
  fetcAllUserToAdminRequests: (username: string) => void;
  respondExternalConnectionRequest: (
    accept: boolean,
    areaUuid: string,
    configUuid: string,
    username: string,
  ) => Promise<void>;
  unregisterUser: (
    configurationUuid: string,
    assetUuid: string,
    userName: string,
    targetIsAggregator?: boolean,
    targetIsMyself?: boolean,
  ) => Promise<void>;
  launchToCanary(
    configUuid: string,
    payload: { timezone?: string; name?: string },
  ): Promise<string | null | undefined>;
};

// @ts-ignore
export const CanaryNetworkServiceContext = React.createContext<TCanaryNetworkServiceContext>({});

export const CanaryNetworkServiceProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const userRole = useSelector(selectUserRole);
  const userName = useSelector(selectUsername);
  const profilePicture = useSelector(selectProfilePicture);

  const user = {
    email: userName,
    userRole: userRole!,
    requestStatus: UserRequestsStatusOutput.NotRequested,
    id: '',
    name: userName,
    profilePicture: profilePicture,
    canaryInvitations: [],
    assetsConfigured: [],
  };

  const { data: canaryNetworksList } = useListCanaryNetworksQuery();

  const [listAdminRegistryUsers] = useListAdminRegistryUsersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(updateCnUsers(parseAdminRegistryUsersData(data)));
    },
  });

  const [listGridOperatorRegistryMarkets] = useListGridOperatorRegistryMarketsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(updateCnUsers(parseGridOperatorRegistryMarkets(data, user)));
    },
  });

  const [listGsyUserRegistryAssets] = useListGsyUserRegistryAssetsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(updateCnUsers(parseGsyUserRegistryUsersData(data, user)));
    },
  });

  const [listAggregatorRegistryUsers] = useListAggregatorRegistryUsersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(updateCnUsers(parseAggregatorRegistryUsersData(data)));
    },
  });

  const [listUserToAdminRequests] = useListUserToAdminRequestsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(setUserToAdminRequests(parseUserToAdminRequestsData(data)));
    },
  });

  const [listGlobalUserToAdminRequests] = useListUserToAdminRequestsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data?.listUserToAdminRequests?.forEach(
        // @ts-ignore
        (item) => dispatch(addGlobalNotification(item)),
      );
    },
  });

  const [moveCollabToCN] = useMoveCollaborationToCanaryNetworkMutation();
  const [requestSimulationToCnConversion] = useRequestSimulationToCnConversionMutation();
  const [respondExternalConnectionRequest] = useRespondExternalConnectionRequestMutation();
  const [setExternalConnection] = useSetExternalConnectionMutation();
  const [setUserInviteMutation] = useSetUserInviteMutation();
  const [batchRegisterExternalConnection] = useBatchRegisterExternalConnectionMutation();
  const [regExternalConnection] = useRegExternalConnectionMutation();
  const [inviteUserToWebsite] = useInviteUserToWebsiteMutation();

  const canaryNetworkService = useMemo<TCanaryNetworkServiceContext>(
    () => ({
      fetchCanaryNetworkRegistry: (configUuid) => {
        switch (userRole) {
          case EUserRoles.Admin:
            listAdminRegistryUsers({
              variables: {
                configUuid,
              },
            });
            break;
          case EUserRoles.Aggregator:
            listAggregatorRegistryUsers({
              variables: {
                configUuid,
              },
            });
            break;
          case EUserRoles.DSO:
            listGridOperatorRegistryMarkets({
              variables: {
                configUuid,
              },
            });
            break;
          case EUserRoles.Researcher:
            listGsyUserRegistryAssets({
              variables: {
                configUuid,
              },
            });
            break;
          case EUserRoles.ExchangeOperator:
            // Nothing here for now
            break;
        }
      },
      approveExternalConnection: async (configUuid, areaUuid, email, username) => {
        try {
          await setExternalConnection({
            variables: {
              configUuid,
              areaUuid,
              email,
              username,
              status: ExternalConnectionApprovalStatus.Approved,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },
      removeExternalConnection: async (configUuid, areaUuid, email, username) => {
        try {
          await setExternalConnection({
            variables: {
              configUuid,
              areaUuid,
              email,
              username,
              status: ExternalConnectionApprovalStatus.Blocked,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },
      registerExternalConnection: async (apply, configUuid, areaUuid) => {
        try {
          await regExternalConnection({
            variables: {
              areaUuid,
              configUuid,
              apply,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },
      inviteUserToCn: async (configUuid, email) => {
        try {
          await setUserInviteMutation({
            variables: {
              configUuid,
              username: email,
              inviteFlag: AdminInviteFlag.Invited,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          const msg = JSON.parse(err.message).unauthorized_access;
          dispatch(
            openToast({
              message: msg || 'Something went wrong',
              type: 'error',
            }),
          );
          throw false;
        }
      },
      inviteUserToWebsite: async (configUuid, email, userRole) => {
        try {
          await inviteUserToWebsite({
            variables: {
              email,
              name: email,
              userRole: userRole,
            },
          });
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },

      applyForExternalConnection: async (applicationMapping, configUuid) => {
        try {
          await batchRegisterExternalConnection({
            variables: {
              applicationMapping,
              configUuid,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },
      fetchGlobalListUserToAdminRequests: async () => {
        switch (userRole) {
          case EUserRoles.Admin:
            listGlobalUserToAdminRequests({
              variables: {
                status: UserRequestsStatus.Received,
              },
            });
        }
      },
      fetcAllUserToAdminRequests: async (username) => {
        listUserToAdminRequests({
          variables: {
            username: username,
          },
        });
      },
      respondExternalConnectionRequest: async (accept, areaUuid, configUuid, username) => {
        try {
          await respondExternalConnectionRequest({
            variables: {
              accept,
              areaUuid,
              configUuid,
              username,
            },
          });
          canaryNetworkService.fetchCanaryNetworkRegistry(configUuid);
        } catch (err) {
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
        }
      },
      unregisterUser: async (
        configurationUuid,
        assetUuid,
        userName,
        targetIsAggregator,
        targetIsMyself,
      ) => {
        if (targetIsAggregator) {
          if (targetIsMyself) {
            canaryNetworkService.registerExternalConnection(false, configurationUuid!, assetUuid);
          } else {
            canaryNetworkService.respondExternalConnectionRequest(
              false,
              assetUuid,
              configurationUuid!,
              userName,
            );
          }
        } else {
          canaryNetworkService.removeExternalConnection(
            configurationUuid!,
            assetUuid,
            userName,
            userName,
          );
        }
      },
      launchToCanary: async (configUuid, payload) => {
        try {
          if (userRole === EUserRoles.Admin) {
            const cnNames =
              canaryNetworksList?.listCanaryNetworks?.configurations?.map((item) => item!.name!) ||
              [];
            const communityName = createCNName(payload.name!, cnNames);

            const { data } = await moveCollabToCN({
              variables: {
                uuid: configUuid,
                name: communityName,
                private: true,
              },
            });
            return data?.moveCollaborationToCanaryNetwork?.uuid;
          } else {
            if (!payload.timezone) throw new Error('Timezone is required');
            const { data } = await requestSimulationToCnConversion({
              variables: {
                configUuid,
                timezone: payload.timezone,
              },
            });

            return data?.requestSimulationToCnConversion;
          }
        } catch (err: any) {
          if (err?.graphQLErrors[0].message) {
            const isParsableJSON = isJson(err.graphQLErrors[0].message);
            let errMessage = '';
            if (isParsableJSON) {
              const errorObject = JSON.parse(err.graphQLErrors[0].message);
              if (errorObject.request_simulation_to_cn_conversion) {
                errMessage = errorObject.request_simulation_to_cn_conversion;
              } else {
                errMessage = Object.keys(errorObject)
                  .map((errorKey) => errorObject[errorKey])
                  .join(' ');
              }
            } else {
              errMessage = err.graphQLErrors[0].message;
            }

            dispatch(
              openToast({
                message: errMessage,
                type: 'error',
              }),
            );
            return null;
          }
          dispatch(
            openToast({
              message: 'Something went wrong',
              type: 'error',
            }),
          );
          return null;
        }
      },
    }),
    [
      userRole,
      listAdminRegistryUsers,
      listAggregatorRegistryUsers,
      listGridOperatorRegistryMarkets,
      listGsyUserRegistryAssets,
      setExternalConnection,
      dispatch,
      regExternalConnection,
      setUserInviteMutation,
      batchRegisterExternalConnection,
      listGlobalUserToAdminRequests,
      listUserToAdminRequests,
      respondExternalConnectionRequest,
      canaryNetworksList?.listCanaryNetworks?.configurations,
      moveCollabToCN,
      requestSimulationToCnConversion,
      inviteUserToWebsite,
    ],
  );

  return (
    // @ts-ignore
    <CanaryNetworkServiceContext.Provider value={canaryNetworkService}>
      {children}
    </CanaryNetworkServiceContext.Provider>
  );
};
