import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useRef } from 'react';

import { BaseGenericModal } from '../BaseGenericModal';
import s from './ModalMobileWarning.module.scss';

export const ModalMobileWarning: React.FC = () => {
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <BaseGenericModal
        modalId={EPredefinedModalIds.MODAL_MOBILE_WARNING}
        size={EModalSize.M280}
        relativeElement={modalRef}
        position={{
          side: 'right',
          anchorPosition: 50,
        }}
        className={s.modal}
        initialOpen>
        <div className={s.modalWrapper}>
          <h2>This is a web based application and do not support tablet and mobile view yet</h2>
        </div>
      </BaseGenericModal>
    </>
  );
};
