import React, { useMemo } from 'react';
import { LibraryOutput } from 'src/graphql';

import { BaseIcon } from 'src/components/BaseIcon';
import classNames from 'classnames';
import s from './DashboardAssets.module.scss';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export const DashboardAssetsGridItem: React.FC<{
  className: string;
  data: LibraryOutput;
  assetIcon: TIconNames;
  updateGridData(): void;
  onClick(uuid: string): void;
  onThreeDotsClick?;
}> = ({ data, className, assetIcon, updateGridData, onClick, onThreeDotsClick }) => {
  const representation = useMemo(() => {
    return data?.scenarioData?.representation?.serialized
      ? JSON.parse(data?.scenarioData?.representation?.serialized)
      : null;
  }, [data?.scenarioData?.representation?.serialized]);

  const subtitle = useMemo(() => {
    switch (data.scenarioData?.representation?.type) {
      case 'Area':
        return data.scenarioData.deviceCountNumber + ' Assets';
      case 'PV':
      case 'Storage':
        return (
          'Capacity ' + (representation.capacity_kW || representation.battery_capacity_kWh) + ' kWh'
        );
      case 'Load':
        return representation.avg_power_W ? 'Avg power ' + representation.avg_power_W + 'W' : '';
      default:
    }
  }, [data, representation]);

  return (
    <div className={classNames(className, s.gridItem)} onClick={() => onClick(data.uuid!)}>
      <button
        className="moreMenu"
        type="button"
        title="More"
        onClick={(e) => onThreeDotsClick(e, { target: e.target as HTMLElement, lib: data })}>
        <BaseIcon icon="more-horizontal" size={10} />
      </button>
      <div className="content">
        <div className="icon">
          <BaseIcon icon={assetIcon} size={100} />
        </div>
        <h6>{data.name}</h6>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {representation?.type === 'Area' && (
        <div className="numbers">
          <div>
            <BaseIcon icon="pv" size={12} />
            <span>{representation?.children?.filter((item) => item.type === 'PV').length}</span>
          </div>
          <div>
            <BaseIcon icon="battery" size={12} />
            <span>
              {representation?.children?.filter((item) => item.type === 'Storage').length}
            </span>
          </div>
          <div>
            <BaseIcon icon="plug" size={12} />
            <span>{representation?.children?.filter((item) => item.type === 'Load').length}</span>
          </div>
        </div>
      )}
    </div>
  );
};
