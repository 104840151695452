import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { InfoHelper } from 'src/components/InfoHelper';
import { TBaseInputProps } from './BaseInput.types';
import classnames from 'classnames';
import s from './BaseInput.module.scss';

const ButtonOrDiv: React.FC<{
  className: string;
  onClick: React.DOMAttributes<HTMLButtonElement>['onClick'];
}> = ({ children, className, onClick }) => {
  if (onClick) {
    return (
      <button className={className} type="button" onClick={onClick}>
        {children}
      </button>
    );
  }

  return <div className={classnames(className, s.PEN)}>{children}</div>;
};

export const BaseInput = React.forwardRef<HTMLInputElement & HTMLTextAreaElement, TBaseInputProps>(
  function BaseInput(
    {
      className,
      name = '',
      title,
      theme = 'line-light',
      style = {},
      value,
      unit,
      error,
      disabled,
      label,
      placeholder,
      type,
      onChange,
      onBlur,
      onClick,
      iconLeft,
      iconLeftSize,
      iconLeftColor,
      iconRight,
      iconRightSize,
      iconRightColor,
      iconRightOnClick,
      inputHeight = '1',
      autoComplete,
      elevateLabel = true,
      errorWidth,
      containerRef,
      accept,
      filename,
      showValidationIcon = true,
      showErrorMessage = true,
      required = false,
      showTooltip = false,
      tooltipText = '',
      fieldForm,
    }: TBaseInputProps,
    forwardedRef,
  ) {
    const [focused, setFocused] = useState(false);
    const filled = value || value === 0 || filename;
    const [cursor, setCursor] = useState<null | number>(null);
    const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const files = 'files' in e.target ? e.target.files : undefined;
      const { value: targetValue } = e.target;

      let newValue: TBaseInputProps['value'] | File;

      switch (type) {
        case 'number': {
          newValue = targetValue ? parseFloat(targetValue) : targetValue;
          break;
        }

        case 'file': {
          newValue = files?.[0];
          if (!newValue) return;
          break;
        }

        default: {
          newValue = targetValue;
          break;
        }
      }

      //The commented logic can be useful in a possible problem
      //const changedPos = newValue.split('').findIndex((item, index) => item !== value[index]);
      //const direction = newValue.length > value.length ? +1 : -1;
      //setCursor(changedPos + direction);
      setCursor(e.target.selectionStart);

      onChange?.({ name, value: newValue });
    };

    useEffect(() => {
      const input = inputRef.current;
      if (input && input.setSelectionRange && cursor) input.setSelectionRange(cursor, cursor);
    }, [inputRef, cursor, value]);

    const getRightIconSize = () => {
      if (iconRightSize) return iconRightSize;
      if (theme.includes('line-')) return 13;
      return 16;
    };

    let generatedRightIcon;

    if (showValidationIcon) {
      if (error) {
        generatedRightIcon = (
          <div className={classnames(s.iconWrapper, s.iconWrapperRight)}>
            <BaseIcon
              className={classnames(s.icon, s.iconError)}
              icon="alert-circle"
              style={{
                ['--icon-color' as string]: iconRightColor,
              }}
              size={getRightIconSize()}
            />
          </div>
        );
      }
    } else {
      generatedRightIcon = iconRight && (
        <ButtonOrDiv
          className={classnames(s.iconWrapper, s.iconWrapperRight)}
          onClick={iconRightOnClick}>
          <BaseIcon
            className={s.icon}
            icon={iconRight}
            style={{
              ['--icon-color' as string]: iconRightColor,
            }}
            size={getRightIconSize()}
          />
        </ButtonOrDiv>
      );
    }

    const inputCommonProps = {
      ref: forwardedRef ?? inputRef,
      name,
      value,
      placeholder,
      disabled,
      onChange: handleChange,
      onBlur: (e) => {
        onBlur?.(e);
        setFocused(false);
      },
      onFocus: () => setFocused(true),
      required,
    };

    const textAreaRowsCount = useMemo(() => {
      if (value && type === 'textarea') {
        return `${value}`.split('\n').length + 1;
      }
      return 0;
    }, [type, value]);

    return (
      <div
        ref={containerRef}
        className={classnames(className, s.root, s[`input-height-${inputHeight}`], {
          [s.themeLine]: theme.includes('line-'),
          [s.themeFilled]: theme.includes('filled-'),
          [s.themeLineLight]: theme === 'line-light',
          [s.themeLineDark]: theme === 'line-dark',
          [s.themeWhite]: theme === 'filled-white',
          [s.themeGray]: theme === 'filled-gray',
          [s.themeDark]: theme === 'filled-dark',
          [s.themeDarkGray]: theme === 'filled-dark-gray',
          [s.themeOutlineGray]: theme === 'outline-gray',
          [s.hasPlaceholder]: placeholder,
          [s.elevateLabel]: elevateLabel,
          [s.ghostLabel]: !elevateLabel,
          [s.fakeInput]: type === 'file',
          [s.hasError]: !!error,
          [s.filled]: filled,
          [s.focused]: focused,
          [s.disabled]: disabled,
        })}
        style={{
          ['--right-icon-size' as string]: `${getRightIconSize()}px`,
          ...style,
        }}>
        <div className={s.container} onClick={onClick}>
          {iconLeft && (
            <div className={classnames(s.iconWrapper, s.iconWrapperLeft)}>
              <BaseIcon
                className={s.icon}
                icon={iconLeft}
                style={{
                  ['--icon-color' as string]: iconLeftColor,
                }}
                size={iconLeftSize || 10}
              />
            </div>
          )}

          <div className={s.innerContainer}>
            {type === 'textarea' ? (
              <div
                className={classnames(s.textareaWrapper, {
                  [s.textareaWrapperBig]: inputHeight === '10',
                })}>
                <textarea
                  className={classnames(s.input, s.textarea)}
                  spellCheck={false}
                  rows={textAreaRowsCount}
                  form={fieldForm}
                  {...inputCommonProps}
                />
              </div>
            ) : (
              <input
                className={s.input}
                type={type}
                autoComplete={autoComplete}
                accept={accept}
                title={type === 'file' ? filename || 'No file chosen' : title}
                form={fieldForm}
                {...inputCommonProps}
              />
            )}
            {type === 'file' ? (
              <span className={classnames(s.filename, 'ellipsis')}>{filename}</span>
            ) : (
              ''
            )}
            {type === 'file' ? (
              <span className={classnames(s.chooseFile)}>
                <BaseIcon
                  className={s.icon}
                  icon="file-upload"
                  style={{
                    ['--icon-color' as string]: iconLeftColor,
                  }}
                  size={iconLeftSize || 10}
                />
                choose file
              </span>
            ) : (
              ''
            )}
            <label className={s.label}>
              {label}
              {showTooltip && tooltipText && (
                <InfoHelper className={s.lengthTooltip} info={tooltipText} />
              )}
            </label>
          </div>

          {(generatedRightIcon || unit) && (
            <div className={s.rightSlot}>
              {unit && (
                <span className={classnames(s.unit, { [s.unitAlwaysVisible]: !label })}>
                  {unit}
                </span>
              )}
              {generatedRightIcon}
            </div>
          )}
        </div>
        {showErrorMessage && <BaseErrorMessage width={errorWidth}>{error}</BaseErrorMessage>}
      </div>
    );
  },
);
