import { FRONTEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

import { formatter } from 'src/utils/formatter';
import { isJson } from 'src/utils/isJson';

export const tooltipCallbacks = {
  title: function (context) {
    const isValidDate = UTCMoment.utc(context?.[0]?.label).isValid();
    if (isValidDate) {
      const newDate = UTCMoment.utc(context?.[0]?.label).format(
        FRONTEND_DATE_FORMATS.TOOLTIP_CHART,
      );
      return newDate;
    }
    return context.label;
  },
  label: function (context) {
    const isJsonLabelData = isJson(context.dataset.label);
    if (isJsonLabelData) {
      // eslint-disable-next-line prefer-const
      let { unit, currency, title } = JSON.parse(context.dataset.label);

      // if (title === 'Community') {
      //   title = 'Grid Market';
      // }

      if (currency) {
        const currencySymbol = formatter.getCurrencySymbol(context.dataset.label.split('/')[0]);
        return `${title}: ` + context.formattedValue + ` ${currencySymbol} ${unit && `/ ${unit}`}`;
      }
      return `${title}: ` + context.formattedValue + ` ${unit}`;
    } else {
      return context.formattedValue;
    }
  },
};
