import React from 'react';
import { EMenuType } from 'src/components/LandingPage/MainMenu/MainMenu.types';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { Read } from 'src/pages/Read';
import { News } from 'src/pages/News';
import { Trending } from 'src/pages/Trending';
import { Watch } from 'src/pages/Watch';
import { TalkToUs } from 'src/pages/TalkToUs';
import {
  EParams,
  TemplateLandingPage,
  TTemplateLandingPageProps,
} from 'src/templates/TemplateLandingPage';
import { useParams } from 'react-router-dom';

export const Resources: React.FC = () => {
  const [headerColorMode, setHeaderColorMode] = React.useState('white');
  const initialRoute = useParams<{
    page: string;
  }>()?.page;
  const slug = useParams<{ slug: string }>()?.slug;

  const pages = {
    [EParams.READ]: Read,
    [EParams.TRENDING]: Trending,
    [EParams.NEWS]: News,
    [EParams.WATCH]: Watch,
    [EParams.TALK_TO_US]: TalkToUs,
  };

  function applyProps() {
    const props: TTemplateLandingPageProps = {
      headerColorMode,
      menuType: EMenuType.RESOURCES,
    };

    switch (initialRoute) {
      default:
        props['rightBgColor'] = '#1641f7';
        break;
    }

    return props;
  }

  const Component = pages?.[
    initialRoute && initialRoute.length > 0 ? initialRoute : Object.keys(pages)[0]
  ] || <></>;

  return (
    <TemplateLandingPage {...applyProps()}>
      <Component slug={slug} />
    </TemplateLandingPage>
  );
};
