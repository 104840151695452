import {
  TAreaDeviceInput,
  TAreaInput,
  TPrepareAreaInputArgs,
} from 'src/utils/mutationsGates/prepareAreaInput/prepareAreaInput.types';

import { TAssetsTreeRelations } from 'src/typings/configuration.types';
import { prepareDeviceInputFunctions } from 'src/utils/mutationsGates/prepareAreaInput/prepareDeviceInputFunctions';

export function prepareAreaInput({
  rootAssetUuid, // Doesn't have to be a root of the whole tree. It can be a root of some branch beneath.
  assets,
  assetsTreeRelations,
  assetsValues,
  settingsData,
  configurationCharacteristic,
}: TPrepareAreaInputArgs): TAreaInput | null {
  if (!rootAssetUuid) return null;

  const rootAsset = assets[rootAssetUuid];

  if (!rootAsset) return null;

  const output = {} as TAreaInput;

  function fillTreeRecursively(assetsUuids: TAssetsTreeRelations['uuid'], container: TAreaInput) {
    if (!container) return;
    if (!assetsUuids.length) return;

    assetsUuids.forEach((singleUuid) => {
      const isRoot = singleUuid === rootAssetUuid;
      const { type, uuid } = assets[singleUuid];

      const singleAsset = prepareDeviceInputFunctions[type]({
        type,
        uuid,
        assetsValues,
        settingsData,
        configurationCharacteristic,
      });

      if (isRoot) {
        Object.assign(container, singleAsset);
      } else {
        const areaContainer = container as TAreaDeviceInput;

        if (!areaContainer.children) {
          areaContainer.children = [];
        }
        areaContainer.children.push(singleAsset);
      }

      fillTreeRecursively(assetsTreeRelations[singleAsset.uuid], singleAsset as TAreaInput);
    });
  }

  fillTreeRecursively([rootAssetUuid], output);

  return output;
}
