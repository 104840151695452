import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Header } from 'src/components/Header';
import s from './PrivacyPolicy.module.scss';
import { BaseButton } from 'src/components/BaseButton';
import { FadeAnimationHelper } from 'src/components/AnimationHelpers/FadeAnimationHelper/FadeAnimationHelper';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { ContentfulContextProvider } from 'src/contexts/ContentfulContext';

export const PrivacyPolicy: React.FC = () => {
  const headerColorMode = 'white';
  const history = useHistory();

  const RenderPrivacyPolicy = () => {
    const { privacyPolicy } = useContext(ContentfulContext);
    return <div className={s.text}>{privacyPolicy?.text}</div>;
  };

  return (
    <ContentfulContextProvider>
      <Header isLandingPage headerColorMode={headerColorMode} />
      <div className={s.content}>
        <BaseButton
          className={s.buttonBack}
          theme="quaternary"
          icon="arrow-left-full"
          onClick={() => history.goBack()}>
          BACK
        </BaseButton>
        <FadeAnimationHelper key="privacy-policy" className={s.container} delay={0.5}>
          <div className={s.heading}>Grid Singularity Privacy Policy</div>
          <RenderPrivacyPolicy />
        </FadeAnimationHelper>
      </div>
    </ContentfulContextProvider>
  );
};
