import { BaseIcon } from 'src/components/BaseIcon';
import { DataAggregationResolution } from 'src/graphql';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/DownloadResultsButton';
import { EActiveTabState } from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import { HeaderSimulationFlag } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/HeaderSimulationFlag';
import { HomeCommunityTab } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/HomeCommunityTab';
import React from 'react';
import { TCustomResultHeaderProps } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/CustomResultHeader.types';
import classNames from 'classnames';
import s from './CustomResultHeader.module.scss';
import { selectAssetsValues } from 'src/redux/configuration/configuration.selectors';
import { setSCMsidebarTab } from 'src/redux/scm/scm.slice';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useSelector } from 'react-redux';

export const CustomResultHeader: React.FC<TCustomResultHeaderProps> = ({
  className,
  handleResolutionButton,
  selectedResolution,
  isHomeNameActive,
  isCommunityLabelActive,
  flag = 'Operation',
  homeName,
}) => {
  const dispatch = useAppDispatch();
  const { zoomIntoConfiguration } = useConfigurationUtils();
  const assetsValues = useSelector(selectAssetsValues);

  const changeTheScmSidebarTab = () => {
    dispatch(setSidebarExpanded(true));
    dispatch(setSelectedAssetUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
  };

  return (
    <div className={s.customResultHeaderWrapper}>
      {isHomeNameActive && (
        <div className={s.homeName} onClick={changeTheScmSidebarTab}>
          <div className={s.iconContainer}>
            <div className={s.borderIcon} />
            <BaseIcon icon="arrow-left-full" size={15} />
          </div>
          <span>{homeName}</span>
        </div>
      )}
      {isCommunityLabelActive && (
        <div className={s.communityName}>
          <span>Community Results</span>
        </div>
      )}
      {!isHomeNameActive && !isCommunityLabelActive && <HomeCommunityTab />}
      <div className={s.topRightArea}>
        <div className={s.dailyMonthlyContainer}>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedResolution === DataAggregationResolution.Monthly,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Monthly)}>
            Monthly
          </button>
          <button
            type="button"
            className={classNames(s.text, {
              [s.selected]: selectedResolution === DataAggregationResolution.Daily,
            })}
            onClick={() => handleResolutionButton(DataAggregationResolution.Daily)}>
            Daily
          </button>
        </div>
        {flag === 'Operation' && (
          <div className={s.liveResultsContainer}>
            <span className={s.text}>Operation Results</span>
          </div>
        )}
        {flag === 'Simulation' && (
          <HeaderSimulationFlag
            className={s.simulationResults}
            isIconActive={true}
            title={'Simulation Results'}
          />
        )}
      </div>
    </div>
  );
};
