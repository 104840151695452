import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import s from '../ModalAssetsManager.module.scss';
import { BaseIcon } from 'src/components/BaseIcon';
import { useAppDispatch } from 'src/redux/store';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';

type GridMarketModalHeaderProps = {
  formTitle: string;
  onClick?: () => void;
  onClose?: () => void;
};

export const GridMarketModalHeader: React.FC<GridMarketModalHeaderProps> = ({
  formTitle,
  onClick,
  onClose,
}) => {
  return (
    <div className={classnames(s.paddingLR, s.paddingT)} onClick={onClick}>
      <header className={s.header}>
        <h3 className={s.title}>{formTitle}</h3>
        {onClose && (
          <button
            type="button"
            className={classnames(s.mlaClose, {
              [s.autoLeft]: true,
            })}
            onClick={() => {
              if (onClose) onClose();
            }}>
            <BaseIcon icon="close" size={12} />
          </button>
        )}
      </header>
    </div>
  );
};
