import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TChartEnergyProfileProps,
  TChartProfileInfoList,
} from 'src/components/ChartEnergyProfile/ChartEnergyProfile.types';

import React from 'react';
import { UTCMoment } from 'src/utils/UTCMoment';

export const ChartEnergyProfile: React.FC<TChartEnergyProfileProps> = ({ data }) => {
  const calculateTicks = (data: TChartProfileInfoList) => {
    const ticks: Array<{ value: string; label: string }> = [];
    const firstDate = new Date(data[0].time);
    const lastDate = new Date(data[data.length - 1].time);
    const firstDay = firstDate.getDate();
    const lastDay = lastDate.getDate();

    // if the first and last date are in the same day, then we want to show the ticks for every hour
    if (firstDay === lastDay) {
      data.forEach((item) => {
        const date = new Date(item.time);
        // check the minutes of the date, if it's 0, then we want to show the tick
        if (date.getMinutes() === 0) {
          ticks.push({ value: item.time, label: `${date.getHours()}h` });
        }
      });

      return ticks;
    }

    // check the difference between the first and last date, if it's less than 7 days,
    // then we want to show the ticks for every day
    if (Math.abs(firstDate.getTime() - lastDate.getTime()) < 1000 * 3600 * 24 * 7) {
      let dayIterator = 0;
      data.forEach((item) => {
        const date = new Date(item.time);
        // check the minutes of the date, if it's 0,
        // and check the hours of the date, if it's 0, then we want to show the tick
        if (date.getMinutes() === 0 && date.getHours() === 0) {
          ticks.push({ value: item.time, label: `Day ${dayIterator}` });
          dayIterator += 1;
        }
      });
      return ticks;
    }

    let weekIterator = 0;
    data.forEach((item) => {
      const date = new Date(item.time);
      // check the minutes of the date, if it's 0,
      // and check the hours of the date, if it's 0,
      // and check the difference with the last element of the ticks array, if it's more than 7 day,
      // then we want to show the tick
      if (
        date.getMinutes() === 0 &&
        date.getHours() === 0 &&
        (ticks.length === 0 ||
          Math.abs(new Date(ticks[ticks.length - 1].value).getTime() - date.getTime()) >=
            1000 * 3600 * 24 * 7)
      ) {
        ticks.push({ value: item.time, label: `Week ${weekIterator}` });
        weekIterator += 1;
      }
    });

    return ticks;
  };

  const calculateYAxisTicks = (data: TChartProfileInfoList) => {
    const consumptionValues = data.map((item) => item.consumption);
    const generationValues = data.map((item) => item.generation);
    const maxValue = Math.max(...consumptionValues, ...generationValues);
    const minValue = Math.min(...consumptionValues, ...generationValues);
    //const difference = maxValue - minValue;

    const minValueRounded = Math.floor(minValue / 100) * 100;
    const ticks: Array<number> = [];
    for (let i = minValueRounded; i < maxValue; i += 100) {
      ticks.push(minValueRounded + i);
    }

    ticks.push(ticks[ticks.length - 1] + 100);
    return ticks;
  };

  const ticksYAxis = calculateYAxisTicks(data);

  const ticks = React.useMemo(() => calculateTicks(data), [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={730}
        height={500}
        data={data}
        margin={{ top: 5, right: 20, left: 25, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          stroke="#A0A0AC"
          ticks={ticks.map((item) => item.value)}
          tickFormatter={(value, index) => ticks[index].label}
        />
        <YAxis
          axisLine={false}
          ticks={ticksYAxis.length < 25 ? ticksYAxis : undefined}
          tickCount={ticksYAxis.length < 25 ? ticksYAxis.length : 25}
          minTickGap={-10}
          tick={(props) => {
            const displayText = `${(props.payload.value / 1000).toFixed(2)} kWh`;

            return (
              <g>
                <text x={props.x} textAnchor={'end'} color={'#A0A0AC'} y={props.y + 5}>
                  {displayText}
                </text>
              </g>
            );
          }}
        />
        <Tooltip
          labelFormatter={(label, payload) => {
            return UTCMoment.fromBackend(label).format('DD/MM/YYYY HH:mm');
          }}
          formatter={(value, name, props) => {
            let tempName = name;
            if (typeof tempName === 'string') {
              tempName = `${tempName.charAt(0).toUpperCase()}${tempName.slice(1)} Wh`;
            }
            return [value, tempName];
          }}
        />
        <Line type="monotone" dot={false} dataKey="generation" stroke="#5DE273" />
        <Line type="monotone" dot={false} dataKey="consumption" stroke="#FC1355" />
      </LineChart>
    </ResponsiveContainer>
  );
};
