import { MakeOptional, Maybe } from 'src/graphql';

import { $Keys } from 'utility-types';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TNotification = {
  uuid: string;
  title: string;
  description: string;
  descriptionLink?: {
    label: string;
    url: string;
  };
};

export enum ENotificationPlace {
  HEADER = 'HEADER',
  JUSTPLACEHOLDER = 'JUSTPLACEHOLDER',
}

// Add the notificationPlace property if you want to show the notification in somewhere else
// like: 'HEADER' | 'FOOTER' | 'SIDEBAR'
// It is a global state to manage Notifiation from different components
// to see how to use it, look at the useSingleNotification hook
export type TSingleNotification = {
  description: string;
  title?: string;
  icon?: TIconNames;
  characterIcon?: string;
  notificationPlace: ENotificationPlace;
};

export type TNotificationsState = {
  localNotificationsList: TNotification[];
  globalNotificationsList: TNotification[];
  singleNotification: TSingleNotification | null;
};
