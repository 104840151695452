import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { closeModal, openModal, updateModalContent } from 'src/redux/modals/modals.slice';
import { setActiveSCMStep, setDataSheetResponse, setSCMsidebarTab } from 'src/redux/scm/scm.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { EActiveTabState } from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import { FileUploadDrop } from './components/FileUploadDrop';
import { TFileType } from './components/FileUploadDrop/FileUploadDrop.types';
import classnames from 'classnames';
import fileUpload from 'src/assets/images/Scm/icon-file-plus.png';
import { matchPath } from 'react-router';
import { routesConfig } from 'src/routes/routes.config';
import s from './ModalUploadDataSheet.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { selectSelectedLocation } from 'src/redux/map/map.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useMapLoadAfterBeResponse } from 'src/hooks/useMapLoadAfterBeResponse';
import { useUploadCommunityDatasheetMutation } from '../../graphql';

export const ModalUploadDataSheet: React.FC = () => {
  // This hook will take care of saving BE response data in store
  useMapLoadAfterBeResponse();

  const loadingModalData = useMemo(
    () => ({
      title: 'Processing Sheet',
      subTitle: 'Scanning Data',
      icon: 'file',
    }),
    [],
  );

  const [uploadCommunityDataSheetMutation, { loading }] = useUploadCommunityDatasheetMutation();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<TFileType>();
  const scmLocation = useSelector(selectSelectedLocation);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  //TODO: It Should be deleted, it has been added for development purposes
  useEffect(() => {
    if (
      activeConfigurationUuid &&
      scmLocation &&
      !!matchPath(location.pathname, {
        path: routesConfig.singularityMapCreate(),
      })
    ) {
      dispatch(setActiveSCMStep(1));
      dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
    }
  }, [activeConfigurationUuid, dispatch, scmLocation]);

  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET),
  );

  const closeUploadDataSheetModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
  };

  const handleDownloadModelSheet = (e) => {
    downloadLinkRef.current?.click();
    e.stopPropagation();
  };

  const handleUploadDataSheet = async () => {
    try {
      const { data } = await uploadCommunityDataSheetMutation({
        variables: {
          configUuid: activeConfigurationUuid as string,
          file,
        },
      });
      if (data && data?.uploadCommunityDatasheet) {
        batch(() => {
          dispatch(setDataSheetResponse(data.uploadCommunityDatasheet));
          dispatch(closeModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
          dispatch(setActiveSCMStep(1));
          dispatch(setSCMsidebarTab(EActiveTabState.Registry));
        });
      }
    } catch (error: any) {
      const msg = error && error?.message ? error.message : 'Something went wrong';
      const modalData = {
        ...loadingModalData,
        title: 'Upload Failed',
        subTitle: msg,
        icon: 'close',
      };
      dispatch(updateModalContent(modalData));
    }
  };

  useEffect(() => {
    // loading case
    if (loading) {
      dispatch(closeModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
      const modalData = {
        ...loadingModalData,
        title: 'Processing Sheet',
      };
      batch(() => {
        dispatch(updateModalContent(modalData));
        dispatch(openModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
      });
    }
  }, [dispatch, loading, loadingModalData]);

  if (!isModalDeployOpened) return null;

  return (
    <>
      <a ref={downloadLinkRef} href="/dataSheet/CommunityDataSheet.xlsx" target="_blank" download />
      <div className={s.background} onClick={closeUploadDataSheetModal} />
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalUploadDataSheet)}>
          <header className={s.header}>
            <h2 className={s.modalTitle}>Upload your community data sheet</h2>
            <p>Add multiple homes fast, customise it later.</p>
          </header>
          <div className={s.body}>
            <FileUploadDrop handleChange={(file) => setFile(file[0])}>
              <div className={s.uploadSection}>
                <img src={fileUpload} />
                <div className={s.title}>{file ? file?.name : 'Drop your file here'}</div>
                <div className={s.subTitle}>{file ? file?.type : 'Format: Xcel/CSV'}</div>
              </div>
            </FileUploadDrop>
            <BaseLink onClick={handleDownloadModelSheet} className={s.downloadLink}>
              Click here to download our model sheet
            </BaseLink>
            <BaseButton
              disabled={!Boolean(file)}
              className={s.uploadBtn}
              onClick={handleUploadDataSheet}>
              Upload
            </BaseButton>
            <BaseLink to={'https://youtu.be/83aoZ6RHDck'} target="_blank" className={s.plainLink}>
              Watch a tutorial to create a community data sheet
            </BaseLink>
          </div>
        </div>
      </BaseGenericModal>
    </>
  );
};
