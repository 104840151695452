import React, { useCallback, useMemo } from 'react';
import {
  TCanaryAssetsTree,
  TCanaryRegistryUserTileProps,
  TCanaryRegistryUsersListProps,
} from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  selectActiveConfigurationUuid,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';

import { CanaryAggregatorInfo } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryAggregatorInfo';
import { CanaryRegistryAssetTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryAssetTile';
import { CanaryRegistryUserTile } from 'src/components/MapSidebar/components/MapSidebarCanary/components/CanaryRegistryList/CanaryRegistryUserTile';
import { EUserRoles } from 'src/typings/base-types';
import { InviteStatusOutputEnum } from 'src/graphql';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import s from './CanaryRegistryList.module.scss';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { useSelector } from 'react-redux';

export const CanaryRegistryUsersList: React.FC<TCanaryRegistryUsersListProps> = ({
  assetsData,
  usersData,
  usersToAssetRelations,
  selectedItems,
  searchValue,
  onItemClick,
  getParentUuid,
  getOwners,
}) => {
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const communityAsset = useSelector(selectCommunityAsset);
  const assetsUnderSelectedUuid = useSelector(selectAssetsUnderUuid(communityAsset?.uuid));
  const assetValues = useSelector(selectAssetsValues);
  const isResearcher = userRole === EUserRoles.Researcher;

  const list = useMemo<TCanaryRegistryUserTileProps['data'][]>(() => {
    return usersData.map((user) => {
      const assignedAssets = assetsData.reduce<TCanaryAssetsTree>((acc, asset) => {
        if (typeof usersToAssetRelations[user.name]?.[asset.uuid] === 'undefined') return acc;
        if (usersToAssetRelations[user.name][asset.uuid] === 'Rejected') return acc;

        const parentUuid = getParentUuid(asset.uuid);
        const owners = getOwners(asset.uuid);

        const data = {
          uuid: asset.uuid,
          name: asset.name,
          type: asset.type,
          icon: 'house' as TIconNames,
          owners: owners,
          status: usersToAssetRelations[user.name][asset.uuid],
          appliedUserName: user.name,
          appliedUserAvatar: user.avatarUrl,
          parentUuid: asset.type !== 'Area' && parentUuid ? parentUuid : null,
        };

        if (asset.type === 'Area' || asset.type === 'InfiniteBus') {
          acc[asset.uuid] = { data, children: [] };
        } else {
          if (parentUuid && acc[parentUuid]) {
            acc[parentUuid].children.push(data);
          } else {
            acc[asset.uuid] = { data, children: [] };
          }
        }

        return acc;
      }, {});

      return {
        name: user.name,
        isAggregator: user.isAggregator,
        isGridOperator: user.isGridOperator,
        assignedAssets: assignedAssets,
        joined: ![InviteStatusOutputEnum.Blocked, InviteStatusOutputEnum.Invited].includes(
          user.canaryInvitations.find((item) => item.configUuid === configUuid)
            ?.status as InviteStatusOutputEnum,
        ),
        avatarUrl: user.avatarUrl,
      };
    });
  }, [assetsData, configUuid, getOwners, getParentUuid, usersData, usersToAssetRelations]);

  const selectboxUsersList = useMemo(() => usersData.map(({ name, uuid }) => ({ name, uuid })), [
    usersData,
  ]);

  const getHomesList = useCallback(
    (userName: string) =>
      assetsUnderSelectedUuid.reduce<TCanaryRegistryUserTileProps['homesList']>((acc, item) => {
        const owners = getOwners(item.uuid).filter((owner) => !owner.isAggregator);
        const name = assetValues[item.uuid].name as string;
        const isUserOwner = !!owners.find((item) => item.name === userName);
        if (isUserOwner || owners.length === 0) {
          acc.push({
            uuid: item.uuid,
            name,
          });
        }
        return acc;
      }, []),
    [assetValues, assetsUnderSelectedUuid, getOwners],
  );

  const getAggregatorInfo = (item: TCanaryRegistryUserTileProps['data']) => {
    const user = usersData.find((usr) => usr.name === item.name);
    const aggregator = user?.aggregatorInformation?.userDetails;
    const data = aggregator
      ? {
          name: aggregator.name!,
          avatarUrl: aggregator.profilePicture!,
        }
      : undefined;
    return <CanaryAggregatorInfo aggregator={data} />;
  };

  return (
    <div className={s.usersList}>
      {list.map((item) => {
        const isSelected = userRole === EUserRoles.DSO ? true : selectedItems.includes(item.name);
        const homesList = getHomesList(item.name);
        return (
          <React.Fragment key={item.name}>
            <CanaryRegistryUserTile
              data={item}
              selected={isSelected}
              onClick={onItemClick}
              homesList={homesList}
              withoutSelectedUIEffect={userRole === EUserRoles.DSO}
            />
            {isSelected &&
              Object.keys(item.assignedAssets).map((key, i) => {
                const asset = item.assignedAssets[key];
                return (
                  <React.Fragment key={i}>
                    <CanaryRegistryAssetTile
                      data={asset.data}
                      childrenCount={asset.children.length}
                      usersList={selectboxUsersList}
                      selected={false}
                    />
                    {asset.children.map((childAsset) => {
                      return (
                        <CanaryRegistryAssetTile
                          key={childAsset.uuid}
                          data={childAsset}
                          isChildren
                          usersList={selectboxUsersList}
                          selected={false}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}

            {isResearcher && getAggregatorInfo(item)}
          </React.Fragment>
        );
      })}
    </div>
  );
};
