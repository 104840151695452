import { LibraryChooser, TLibrary, TLibraryChooserProps } from 'src/components/LibraryChooser';
import React, { useEffect, useState } from 'react';
import { TAssetCreateNewProps, TTabsInfo } from './AssetCreateNew.types';

import { AssetCreateNewTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNewTabs';
import { AssetSettings } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetSettings';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { LibraryOutput } from 'src/graphql';
import { TFilterItem } from 'src/components/AssetsListWithSearch';
import { TLibrariesTags } from 'src/typings/base-types';
import classNames from 'classnames';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import s from './AssetCreateNew.module.scss';
import { useAssetCreateTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/useAssetCreateTabs';

export const AssetCreateNew: React.FC<TAssetCreateNewProps> = ({
  data,
  selectedAsset,
  setMode,
  onUpdate,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
}) =>
  //
  {
    const [activeFilter, setActiveFilter] = useState<TLibrariesTags[]>(LIBRARY_FILTERS_MAPPING.All);
    const [selectedNewAsset, setSelectedNewAsset] = useState<TLibrary>();

    const onAssetSelect = (data: TLibrary) => setSelectedNewAsset(data);

    const onFilterChange = (filter: TFilterItem) => setActiveFilter(filter.value);
    const onCancelClick = () => {
      //setSelectedNewAsset(undefined);
      setMode('assetPreview');
      // if (!selectedNewAsset) setMode('assetPreview');
    };
    const { onTabSelect, activeSettingsTab, setActiveSettingsTab } = useAssetCreateTabs({
      data: selectedNewAsset,
    });

    const onTreeUpdate = () => onUpdate();

    // const disabledLibraryChooserFilters: TLibraryChooserProps['disabledFilters'] = selectedAsset
    //   ? [LIBRARY_FILTERS_MAPPING.Area]
    //   : undefined;

    return (
      <div className={s.container}>
        <header className={s.header}>
          <p>Add Asset</p>
          <BaseButton size="mini" theme="grey3" onClick={onCancelClick}>
            Cancel
          </BaseButton>
        </header>
        <div className={s.content}>
          {!selectedNewAsset && (
            <>
              <div className={s.typeSelector}>
                <h5>What asset you want to add?</h5>
                <p>You can add based on any asset we have or create a custom one, your choice.</p>
              </div>
              <LibraryChooser
                onLibraryChoose={onAssetSelect}
                isAllButtonRequiredInFilter={false}
                activeFilter={activeFilter}
                onFilterChange={onFilterChange}
                theme="light"
                listViewProp="3grid"
              />
            </>
          )}
          {selectedNewAsset && (
            <div className={s.createAsset}>
              <div className={s.hero}>
                <div className={s.icon}>
                  <BaseIcon icon={get3dAssetIcon(selectedNewAsset.type)} />
                </div>
                <h5>{selectedNewAsset.title}</h5>
                <p>{selectedNewAsset.subtitle}</p>
              </div>
              <nav className={s.navigation}>
                <AssetCreateNewTabs
                  onTabSelect={onTabSelect}
                  activeSettingsTab={activeSettingsTab}
                  mode={selectedNewAsset.type}
                />
              </nav>
              <AssetSettings
                onAddNewAsset={onAddNewAsset}
                onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
                mainParentData={data}
                selectedChildUuid={selectedAsset}
                newAssetData={selectedNewAsset}
                mode={activeSettingsTab}
                setMode={setMode}
                onUpdate={onTreeUpdate}
                onSaveAction="add"
              />
            </div>
          )}
        </div>
      </div>
    );
  };
