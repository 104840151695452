import {
  AssetsListActiveFilter,
  TAssetsListWithSearchProps,
  TFilterItem,
  TListView,
} from './AssetsListWithSearch.types';
import { BaseSwitch, TBaseSwitchProps } from 'src/components/BaseSwitch';
import { BaseTooltip, BaseTooltipContainer, BaseTooltipTrigger } from 'src/components/BaseTooltip';
import {
  MAX_ASSETS_GUEST,
  MAX_ASSETS_LOGGED_IN,
} from 'src/components/AssetsListWithSearch/constants';
import React, { useState } from 'react';

import { AssetBlock } from 'src/components/AssetBlock';
import { AssetListItem } from 'src/components/AssetListItem';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseList } from 'src/components/BaseList';
import { BaseSearch } from 'src/components/BaseSearch';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { EExportStatus } from 'src/components/AssetsEnergyExportIndicator';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import classnames from 'classnames';
import { getLibraryBadgeColor } from 'src/utils/getLibraryBadgeColor';
import s from './AssetsListWithSearch.module.scss';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectSimulationResults } from 'src/redux/configuration/configuration.selectors';
import { setModalAssetManagerShowGuide } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';

const filterButtons: Array<TFilterItem> = [
  {
    label: 'All',
    value: LIBRARY_FILTERS_MAPPING.All,
  },
  {
    icon: 'house',
    label: 'Home',
    value: LIBRARY_FILTERS_MAPPING.Area,
  },
  {
    icon: 'pv',
    label: 'Solar Panels (PVs)',
    value: LIBRARY_FILTERS_MAPPING.PV,
  },
  {
    icon: 'plug',
    label: 'Consumption',
    value: LIBRARY_FILTERS_MAPPING.Load,
  },
  {
    icon: 'battery',
    label: 'Battery',
    value: LIBRARY_FILTERS_MAPPING.Storage,
  },
];

export const AssetsListWithSearch: React.FC<TAssetsListWithSearchProps> = ({
  assets,
  searchQuery,
  activeFilter,
  disabledFilters,
  isAllButtonRequiredInFilter,
  onSearchQueryChange,
  onFilterChange,
  onAssetChoose,
  loading,
  error,
  showGuide,
  noResultsMessage = 'No results.',
  paddingClassName,
  onThreeDotsClick,
  theme = 'dark',
  showEnergyStatus = false,
  showAssetsCount = false,
  listViewProp = 'grid',
}) => {
  const dispatch = useAppDispatch();
  const [listView, setListView] = useState<TListView>(listViewProp);
  const simulationResults = useSelector(selectSimulationResults);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleViewChange: TBaseSwitchProps['onChange'] = ({ value }) => {
    setListView(value as TListView);
    dispatch(setModalAssetManagerShowGuide(false));
  };

  const selectDescription = () => {
    switch (activeFilter?.[0]) {
      case AssetsListActiveFilter.HOME:
        return { title: 'Home', description: 'The main building you consume energy in' };
      case AssetsListActiveFilter.PV:
        return {
          title: 'Solar Panels (PVs)',
          description: 'Source of renewable energy production',
        };
      case AssetsListActiveFilter.LOAD:
        return { title: 'Consumption', description: 'Source of energy consumption or load' };
      case AssetsListActiveFilter.STORAGE:
        return {
          title: 'Battery',
          description:
            'Energy storage device that saves excess energy produced for use at a later time',
        };
      default:
        return { title: 'All', description: 'Choose your home/assets' };
    }
  };

  const getEnergyStatus = (key: string): EExportStatus | undefined => {
    if (!simulationResults) {
      return undefined;
    }
    if (simulationResults.priceEnergyAreaBalance && simulationResults.priceEnergyAreaBalance[key]) {
      const { total_energy } = simulationResults.priceEnergyAreaBalance[key];
      return total_energy < 0 ? EExportStatus.Exporter : EExportStatus.Importer;
    }
    return undefined;
  };

  const { title, description } = selectDescription();

  return (
    <>
      <div>
        {showAssetsCount && assets.length > 0 && (
          <p className={s.totalAssets}>
            {assets.length}/{isLoggedIn ? MAX_ASSETS_LOGGED_IN : MAX_ASSETS_GUEST}
          </p>
        )}
        <div className={classnames(s.filtersWrapper, paddingClassName)}>
          {filterButtons.map((item, index) => {
            if (isAllButtonRequiredInFilter && item.label === 'All') {
              return;
            } else {
              return (
                <BaseTooltipTrigger
                  key={index}
                  triggerClassName={classnames(s.filterBtnWrapper, {
                    [s.filterBtnLarge]: !index && item.label === 'All',
                    [s.filterBtnActive]: activeFilter === item.value,
                    [s['theme-' + theme]]: true,
                  })}
                  position="top"
                  disabled={!item.icon}
                  tooltipChildren={item.label}
                  wordWrap={true}>
                  <button
                    type="button"
                    disabled={disabledFilters?.includes(item.value)}
                    onClick={() => onFilterChange(item)}>
                    {item.icon ? <BaseIcon icon={item.icon} size={11} /> : item.label}
                  </button>
                </BaseTooltipTrigger>
              );
            }
          })}
        </div>

        <div className={classnames(s.searchWrapper, paddingClassName)}>
          <BaseSearch
            name="assetsSearch"
            value={searchQuery}
            onChange={({ value }) => onSearchQueryChange(value)}
            theme={theme === 'dark' ? 'filled-dark' : 'filled-gray'}
            label=""
            iconRight="search"
            inputHeight="2"
            iconRightSize={12}
            className={s.search}
            autoComplete={undefined}
            iconRightColor={vars['color-black']}
          />
          <BaseSwitch
            name="assets-view"
            options={[
              {
                icon: 'table',
                value: 'list' as const,
              },
              {
                icon: 'grid',
                value: listViewProp ? listViewProp : ('grid' as const),
              },
            ]}
            value={listView}
            onChange={handleViewChange}
            theme={theme}
            variant="horizontal-small"
          />
        </div>
      </div>

      <CustomScrollbar className={classnames(s.assetsListWrapper, paddingClassName)}>
        {!loading && (
          <div
            className={classnames(
              s.boxDescription,
              theme === 'light' ? s.boxDescriptionLight : s.boxDescriptionDark,
            )}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        )}

        {loading ? (
          <p className={s.statusMessage}>Loading...</p>
        ) : error ? (
          <p className={s.statusMessage}>Error: {error}</p>
        ) : assets.length ? (
          <BaseList className={s[`assets-ul-${listView}`]}>
            {assets.map((item, index) => {
              if (!item) return null;

              const Block =
                listView === 'grid' || listView === '3grid' ? (
                  <AssetBlock
                    theme={theme}
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    onClick={onAssetChoose ? () => onAssetChoose(item) : undefined}
                    onThreeDotsClick={
                      onThreeDotsClick
                        ? (e) => onThreeDotsClick({ target: e.target as HTMLElement, asset: item })
                        : undefined
                    }
                    isCustomPV={item.isCustomPV}
                  />
                ) : (
                  <AssetListItem
                    energyExportStatus={showEnergyStatus ? getEnergyStatus(item.title) : undefined}
                    theme={theme}
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    badgeColor={getLibraryBadgeColor(item)}
                    onClick={onAssetChoose ? () => onAssetChoose(item) : undefined}
                    onThreeDotsClick={
                      onThreeDotsClick
                        ? (e) => onThreeDotsClick({ target: e.target as HTMLElement, asset: item })
                        : undefined
                    }
                  />
                );

              if (index === 0) {
                return (
                  <div
                    key={item.key}
                    className={classnames(s.blockWrapper, {
                      [s.blockHintActive]: showGuide || (item.type === 'PV' && item.isCustomPV),
                    })}>
                    {Block}
                  </div>
                );
              }

              return <React.Fragment key={item.key}>{Block}</React.Fragment>;
            })}
          </BaseList>
        ) : (
          <p className={s.statusMessage}>{noResultsMessage}</p>
        )}
      </CustomScrollbar>
    </>
  );
};
