import {
  CanaryRegistryList,
  TCanaryRegistryTabs,
  TCanaryRegistryUserTileProps,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import {
  LibraryChooser,
  TLibraryChooserProps,
} from 'src/components/MapSidebarSCM/components/LibraryChooser';
import {
  LibraryOutput,
  UserRequestsStatusOutput,
  useInviteUserToWebsiteMutation,
} from 'src/graphql';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TCanaryMode, TInvitationUserItem, TMapSidebarCanaryProps } from './MapSidebarCanary.types';
import {
  TCanaryUserData,
  TCanaryUsersAssetsRelations,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import {
  selectActiveConfigurationUuid,
  selectAssetByUuid,
  selectAssetValuesForUuid,
  selectAssetsValues,
} from 'src/redux/configuration/configuration.selectors';

import { AssetCreateNew } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/';
import { AssetEdit } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetEdit';
import { AssetsView } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { CanaryUserInvite } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryUserInvite';
import { EUserRoles } from 'src/typings/base-types';
import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TAssetsListWithSearchProps } from 'src/components/AssetsListWithSearch';
import { UserAssetPreview } from 'src/components/MapSidebarSCM/components/UserAssetPreview';
import s from './MapSidebarCanary.module.scss';
import { selectAvailableUserRoles } from 'src/redux/application/application.selectors';
import { selectCanaryNetworkUsers } from 'src/redux/canaryNetwork/canaryNetwork.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { selectUserRole } from 'src/redux/auth/auth.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useAssetsData } from 'src/hooks/useAssetsData';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useSCMMemberEvents } from 'src/hooks/useSCMMemberEvents';
import { useSelector } from 'react-redux';

const SCM_USER_ROLES = ['Exchange Operator', 'GSy User'];
type TSelectedItem = {
  data: LibraryOutput;
  children: LibraryOutput[];
};

export const MapSidebarCanary: React.FC<TMapSidebarCanaryProps> = ({
  onAssetValuesSave,
  onAssetValuesSavePromise,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  onAssetRemove,
}) => {
  // Ctx

  const CanaryNetworkContext = useContext(CanaryNetworkServiceContext);
  const dispatch = useAppDispatch();
  const [inviteUserToWebsite] = useInviteUserToWebsiteMutation();

  // Selectors
  const userRole = useSelector(selectUserRole);
  const configUuid = useSelector(selectActiveConfigurationUuid);
  const users = useSelector(selectCanaryNetworkUsers);
  const userRoles = useSelector(selectAvailableUserRoles).filter((x) =>
    SCM_USER_ROLES.includes(x.roleName),
  );
  const scmMembers = useSelector(selectSCMHomeDetails);
  const assetsValues = useSelector(selectAssetsValues);
  // State
  const [activeFilter, setActiveFilter] = useState<TAssetsListWithSearchProps['activeFilter']>(
    LIBRARY_FILTERS_MAPPING.Area,
  );
  const [invitationEmails, setInvitationEmails] = useState<TInvitationUserItem[]>([]);
  const [assetView, setAssetView] = useState<boolean>(false);
  const [mode, setMode] = useState<TCanaryMode>('assetPreview');
  const [addAssetView, setAddAssetView] = useState<boolean>(false);
  const [createAssetView, setCreateAssetView] = useState<boolean>(false);
  const [assetsList, setAssetsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState<TSelectedItem | undefined>(undefined);
  const [selectedMember, setSelectedMember] = useState<
    TCanaryRegistryUserTileProps['data'] | undefined
  >(undefined);

  const [activeTab, setActiveTab] = useState<TCanaryRegistryTabs>('Community Members');

  const { assetsData } = useAssetsData();
  const [selectedAsset, setSelectedAsset] = useState<string>();

  const selectedAssetDataByUuid = useSelector(selectAssetValuesForUuid(selectedAsset || ''));
  const selectedAssetByUuid = useSelector(selectAssetByUuid(selectedAsset || ''));

  const combinedEditData = useRef<null | Record<string, unknown>>(null);
  const registryWrapperRef = useRef<HTMLDivElement>(null);

  const [addWrapperTop, setAddWrapperTop] = useState(0);

  const { zoomIntoConfiguration } = useConfigurationUtils();
  // Added because of the Asset Edit component.
  // It was working with destructive data like {...bla, ...blabla}
  // because of the enum selector which is in the formfieldgenerator, It caused many time re-render
  // so I added combined useRef and I handle the render cycle with this state
  const [, setForceRender] = useState<boolean>(false);

  const { handleReadSCMConfiguration } = useSCMMemberEvents({});

  const usersData = useMemo<TCanaryUserData[]>(() => {
    return users.map((user) => ({
      uuid: `${user.id}`,
      name: user.email,
      subtitle: user.email,
      avatarUrl: user.profilePicture,
      requestStatus: user.requestStatus,
      canaryInvitations: user.canaryInvitations,
      isAggregator: user.userRole === 'Aggregator',
      isGridOperator: user.userRole === 'DSO',
      aggregatorInformation: user.aggregatorInformation,
    }));
  }, [users]);

  const objChangeCheck = useCallback<
    (obj: Record<string, unknown>, compareObj: Record<string, unknown>) => boolean
  >((obj, compareObj) => {
    const compareKeys = Object.keys(compareObj);
    for (let i = 0; i < compareKeys.length; i++) {
      if (obj[compareKeys[i]] === undefined || obj[compareKeys[i]] !== compareObj[compareKeys[i]])
        return true;
    }
    return false;
  }, []);

  useEffect(() => {
    let tempCombined = {
      ...selectedAssetByUuid,
      ...selectedAssetDataByUuid,
    };
    if (!Object.values(tempCombined).length) return;

    if (
      combinedEditData.current === null ||
      objChangeCheck(combinedEditData.current, tempCombined)
    ) {
      combinedEditData.current = tempCombined;
      setForceRender((prev) => !prev);
    }
  }, [selectedAssetDataByUuid, selectedAssetByUuid, combinedEditData, objChangeCheck]);

  const onUpdate = () => {
    setSelectedItem(undefined);
  };
  const invitationEmailsData = useMemo(() => {
    return invitationEmails?.length
      ? invitationEmails.map((item) => ({
          uuid: item.email,
          name: item.name || 'New User',
          subtitle: item.email,
          email: item.email,
          zipCode: item.zipCode,
          address: item.address,
          avatarUrl: undefined,
          requestStatus: UserRequestsStatusOutput.NotRequested,
          canaryInvitations: [],
          isAggregator: false,
          isGridOperator: false,
          aggregatorInformation: undefined,
          isNewUser: true,
        }))
      : [];
  }, [invitationEmails]);

  const usersToAssetRelations = useMemo<TCanaryUsersAssetsRelations>(() => {
    const relations = {};
    users.forEach((user) => {
      relations[user.email] = {};
      user.assetsConfigured.forEach((asset) => {
        relations[user.email][asset.uuid] = asset.registrationStatus;
      });
    });
    return relations;
  }, [users]);

  // Effects
  useEffect(() => {
    if (configUuid) {
      CanaryNetworkContext.fetchCanaryNetworkRegistry(configUuid);
    }
  }, [CanaryNetworkContext, configUuid]);

  useEffect(() => {
    if (mode == 'addNewAsset') setAssetView(false);
  }, [mode]);

  const clickOnAllMembers = useCallback(() => {
    toggleAssetView();
    setAssetsList([]);
    setSelectedMember(undefined);
    setAddAssetView(false);
    dispatch(setSelectedAssetUuid(undefined));
    zoomIntoConfiguration({ assetsValues });
    if (configUuid)
      handleReadSCMConfiguration({
        variables: {
          uuid: configUuid,
        },
      });
  }, [
    setAssetsList,
    setSelectedMember,
    setAddAssetView,
    dispatch,
    zoomIntoConfiguration,
    configUuid,
    handleReadSCMConfiguration,
    assetsValues,
  ]);

  const toggleAssetView = () => {
    setAssetView((prevState) => !prevState);
  };

  const toggleAddAssetView = () => {
    setAddAssetView((prevState) => !prevState);
  };

  const handleLibraryChoose: TLibraryChooserProps['onLibraryChoose'] = (library) => {
    const libraryRep = library?.scenarioData?.representation?.serialized
      ? JSON.parse(library?.scenarioData?.representation?.serialized)
      : null;
    const selectedItemData = {
      data: { ...library, name: library.title } as LibraryOutput,
      children: libraryRep?.children,
    };
    setSelectedItem(selectedItemData);
    setCreateAssetView(true);
  };

  const HeaderWithTitleAndClose = ({ title, onClose }) => (
    <div className={s.headerContainer}>
      <div className={s.innerHeader}>
        <h1>{title}</h1>
        <BaseButtonSquare
          size="2"
          svgSize="3"
          icon="close"
          theme="flat-gray-dark"
          onClick={onClose}
        />
      </div>
    </div>
  );

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setAddWrapperTop(scrollTop);
  };

  useEffect(() => {
    if (!registryWrapperRef || !registryWrapperRef.current) return;
    registryWrapperRef.current.removeEventListener('scroll', handleScroll);
    const relatedListener = registryWrapperRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (!registryWrapperRef || !registryWrapperRef.current) return;
      // remove the event listener with the same reference
      // eslint-disable-next-line react-hooks/exhaustive-deps
      registryWrapperRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [registryWrapperRef]);

  const renderSidebarContent = () => {
    if (mode == 'addNewAsset') {
      return (
        <AssetCreateNew
          data={selectedItem}
          selectedAsset={selectedAsset}
          setMode={setMode}
          onAddNewAsset={onAddNewAsset}
          onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
          setSelectedAsset={setSelectedAsset}
          onUpdate={onUpdate}
        />
      );
    } else if (mode == 'editAsset') {
      if (!combinedEditData.current) return null;
      return (
        <AssetEdit
          key={(() => Object.values(combinedEditData.current).join('-'))()}
          data={combinedEditData.current}
          setMode={setMode}
          onAssetValuesSave={onAssetValuesSave}
          onUpdate={onUpdate}
        />
      );
    } else if (selectedItem) {
      return (
        <>
          {createAssetView && (
            <div className={s.createAssetContainer}>
              <HeaderWithTitleAndClose
                title={'Create Assets'}
                onClose={() => setSelectedItem(undefined)}
              />
            </div>
          )}
          <UserAssetPreview
            selectedItem={selectedItem}
            backToDashboard={() => setSelectedItem(undefined)}
            onRemove={() => {
              setSelectedItem(undefined);
              setSelectedMember(undefined);
              setAddAssetView(false);
              setAssetView(false);
              dispatch(setSelectedAssetUuid(undefined));
              zoomIntoConfiguration({ assetsValues });
            }}
            selectedAsset={undefined}
            handleSetMode={(mode: TCanaryMode) => {
              setAssetView(false);
              setMode(mode);
              //setSelectedItem(undefined);
            }}
            setSelectedAsset={setSelectedAsset}
            onAssetValuesSave={onAssetValuesSave}
            onAssetValuesSavePromise={onAssetValuesSavePromise}
            isCreateAssetView={createAssetView}
            setAssetsList={setAssetsList}
          />
        </>
      );
    } else if (assetView) {
      return (
        <>
          <AssetsView
            selectedMember={selectedMember}
            assetsList={assetsList}
            onAddAssetClick={() => {
              toggleAssetView();
              toggleAddAssetView();
            }}
            onAssetClick={(data) => {
              setSelectedItem(data);
            }}
            toggleAssetView={toggleAssetView}
            clickOnAllMembers={clickOnAllMembers}
          />
        </>
      );
    } else if (addAssetView) {
      return (
        <div className={s.addAssetContainer}>
          <HeaderWithTitleAndClose title={'Add Assets'} onClose={toggleAddAssetView} />
          <div className={s.heading}>What asset you want to add?</div>
          <div className={s.description}>
            You can add based on any asset we have or create a custom one, your choice.
          </div>
          <LibraryChooser
            paddingClassName={s.paddingLR}
            disabledFilters={undefined}
            isAllButtonRequiredInFilter={false}
            onLibraryChoose={handleLibraryChoose}
            activeFilter={activeFilter}
            onFilterChange={(v) => setActiveFilter(v.value)}
            theme="light"
          />
        </div>
      );
    } else {
      return (
        <>
          {(userRole === EUserRoles.Admin || userRole === EUserRoles.ExchangeOperator) && (
            <CanaryUserInvite
              setSelectedMember={setSelectedMember}
              setAssetView={setAssetView}
              wrapperTop={addWrapperTop}
            />
          )}
          <CanaryRegistryList
            assetsData={assetsData}
            usersData={usersData}
            usersToAssetRelations={usersToAssetRelations}
            setAssetView={setAssetView}
            setAddAssetView={setAddAssetView}
            setAssetsList={setAssetsList}
            onAssetRemove={onAssetRemove}
            setSelectedMember={setSelectedMember}
            invitationEmails={invitationEmailsData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            wrapperTop={addWrapperTop}
          />
          {/* <div className={s.formButtonsWrapper}>
            <BaseButton type="submit" className={s.formButton} onClick={inviteUsers}>
              Save
            </BaseButton>
          </div> */}
        </>
      );
    }
  };

  return (
    <div className={s.registry} ref={registryWrapperRef}>
      <div className={s.container}>
        {/* {invitationEmails.length ? (
        <CanaryInvitationPanel emails={invitationEmails} onCancel={() => setInvitationEmails([])} />
      ) : ( */}
        {renderSidebarContent()}
        {/* )} */}
      </div>
    </div>
  );
};
