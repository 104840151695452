import { EAppMode, getAppMode } from 'src/utils/appMode';
import { EDashboard, EFormVariant, EGridMarketTab } from 'src/typings/base-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EModalAssetsManagerView } from 'src/components/ModalAssetsManager';

export type TApplicationState = {
  modalAssetManagerActiveView: EModalAssetsManagerView;
  modalAssetManagerShowGuide: boolean;
  modalCommunitySummaryView: EModalAssetsManagerView;
  modalHouseSummaryView: EModalAssetsManagerView;
  modalHouseSummaryFormVariant: EFormVariant;
  modalMapHeroDismissed: boolean;
  isCustomHouseAddingContinuation: boolean;
  canaryWaitlistDismissed: boolean;
  modalMapAlertDismissed: boolean;
  userClickedBuildOnWelcomeScreen: boolean;
  sidebarExpanded: boolean;
  isEmbed: boolean;
  appMode: EAppMode;
  newCommunity: boolean;
  step: number;
  guideStep: number;
  tutorialStep: number;
  communityNotFound: boolean;
  userCreatedCommunityBefore: boolean;
  availableUserRoles: { roleName: string; value: number }[];
  activeDashboard: EDashboard | undefined;
  activeGridMarketTab: EGridMarketTab;
  activeGridMarketForm: EFormVariant;
  addNewLibrary: boolean;
  collaborationStep: number;
  isBottomRightCopyrightVisible: boolean;
};

export const initialApplicationState = {
  modalAssetManagerActiveView: EModalAssetsManagerView.AddCommunity as const,
  modalAssetManagerShowGuide: true,
  modalCommunitySummaryView: EModalAssetsManagerView.SaveOrSettings as const,
  modalHouseSummaryView: EModalAssetsManagerView.Summary as const,
  modalHouseSummaryFormVariant: EFormVariant.Express as const,
  modalMapHeroDismissed: false,
  isCustomHouseAddingContinuation: false,
  canaryWaitlistDismissed: false,
  modalMapAlertDismissed: false,
  userClickedBuildOnWelcomeScreen: false,
  sidebarExpanded: false,
  isEmbed: false,
  appMode: getAppMode(),
  communityWizardModeStatus: false,
  newCommunity: false,
  step: 0,
  guideStep: 0,
  tutorialStep: 0,
  communityNotFound: false,
  userCreatedCommunityBefore: false,
  availableUserRoles: [],
  activeDashboard: undefined,
  activeGridMarketTab: EGridMarketTab.Market,
  activeGridMarketForm: EFormVariant.GridMarket,
  addNewLibrary: false,
  collaborationStep: 0,
  isBottomRightCopyrightVisible: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState: initialApplicationState as TApplicationState,
  reducers: {
    setModalAssetManagerActiveView(
      state,
      action: PayloadAction<TApplicationState['modalAssetManagerActiveView']>,
    ) {
      state.modalAssetManagerActiveView = action.payload;
    },
    setModalAssetManagerShowGuide(
      state,
      action: PayloadAction<TApplicationState['modalAssetManagerShowGuide']>,
    ) {
      state.modalAssetManagerShowGuide = action.payload;
    },
    setModalCommunitySummaryView(
      state,
      action: PayloadAction<TApplicationState['modalCommunitySummaryView']>,
    ) {
      state.modalCommunitySummaryView = action.payload;
    },
    setModalHouseSummaryView(
      state,
      action: PayloadAction<TApplicationState['modalHouseSummaryView']>,
    ) {
      state.modalHouseSummaryView = action.payload;
    },
    setModalHouseSummaryFormVariant(
      state,
      action: PayloadAction<TApplicationState['modalHouseSummaryFormVariant']>,
    ) {
      state.modalHouseSummaryFormVariant = action.payload;
    },
    setIsCustomHouseAddingContinuation(
      state,
      action: PayloadAction<TApplicationState['isCustomHouseAddingContinuation']>,
    ) {
      state.isCustomHouseAddingContinuation = action.payload;
    },
    setModalMapHeroDismissed(
      state,
      action: PayloadAction<TApplicationState['modalMapHeroDismissed']>,
    ) {
      state.modalMapHeroDismissed = action.payload;
    },
    setCanaryWaitlistDismissed(
      state,
      action: PayloadAction<TApplicationState['canaryWaitlistDismissed']>,
    ) {
      state.canaryWaitlistDismissed = action.payload;
    },
    setModalMapAlertDismissed(
      state,
      action: PayloadAction<TApplicationState['modalMapAlertDismissed']>,
    ) {
      state.modalMapAlertDismissed = action.payload;
    },
    setUserClickedBuildOnWelcomeScreen(
      state,
      action: PayloadAction<TApplicationState['userClickedBuildOnWelcomeScreen']>,
    ) {
      state.userClickedBuildOnWelcomeScreen = action.payload;
    },
    setSidebarExpanded(state, action: PayloadAction<TApplicationState['sidebarExpanded']>) {
      state.sidebarExpanded = action.payload;
    },
    setIsEmbed(state, action: PayloadAction<TApplicationState['isEmbed']>) {
      state.isEmbed = action.payload;
    },
    setAppMode(state, action: PayloadAction<TApplicationState['appMode']>) {
      state.appMode = action.payload;
    },
    setNewCommunity(state, action: PayloadAction<TApplicationState['newCommunity']>) {
      state.newCommunity = action.payload;
    },
    setOnBoardingStep(state, action: PayloadAction<TApplicationState['step']>) {
      state.step = action.payload;
    },
    setGuideStep(state, action: PayloadAction<TApplicationState['guideStep']>) {
      state.guideStep = action.payload;
    },
    setTutorialStep(state, action: PayloadAction<TApplicationState['tutorialStep']>) {
      state.tutorialStep = action.payload;
    },
    setCommunityNotFound(state, action: PayloadAction<TApplicationState['communityNotFound']>) {
      state.communityNotFound = action.payload;
    },
    setUserCreatedCommunityBefore(
      state,
      action: PayloadAction<TApplicationState['userCreatedCommunityBefore']>,
    ) {
      state.userCreatedCommunityBefore = action.payload;
    },
    setAvailableUserRoles(state, action: PayloadAction<TApplicationState['availableUserRoles']>) {
      state.availableUserRoles = action.payload;
    },
    setActiveDashboard(state, action: PayloadAction<TApplicationState['activeDashboard']>) {
      state.activeDashboard = action.payload;
    },
    setActiveGridMarketTab(state, action: PayloadAction<TApplicationState['activeGridMarketTab']>) {
      state.activeGridMarketTab = action.payload;
    },
    setActiveGridMarketForm(
      state,
      action: PayloadAction<TApplicationState['activeGridMarketForm']>,
    ) {
      state.activeGridMarketForm = action.payload;
    },
    setAddNewLibrary(state, action: PayloadAction<TApplicationState['addNewLibrary']>) {
      state.addNewLibrary = action.payload;
    },
    setCollaborationStep(state, action: PayloadAction<TApplicationState['collaborationStep']>) {
      state.collaborationStep = action.payload;
    },
    setIsBottomRightCopyrightVisible(
      state,
      action: PayloadAction<TApplicationState['isBottomRightCopyrightVisible']>,
    ) {
      state.isBottomRightCopyrightVisible = action.payload;
    },
  },
});

// Actions
export const {
  setCanaryWaitlistDismissed,
  setModalMapAlertDismissed,
  setIsCustomHouseAddingContinuation,
  setModalAssetManagerActiveView,
  setModalAssetManagerShowGuide,
  setModalCommunitySummaryView,
  setModalHouseSummaryFormVariant,
  setModalHouseSummaryView,
  setModalMapHeroDismissed,
  setSidebarExpanded,
  setUserClickedBuildOnWelcomeScreen,
  setIsEmbed,
  setAppMode,
  setNewCommunity,
  setOnBoardingStep,
  setGuideStep,
  setTutorialStep,
  setCommunityNotFound,
  setUserCreatedCommunityBefore,
  setAvailableUserRoles,
  setActiveDashboard,
  setActiveGridMarketTab,
  setActiveGridMarketForm,
  setAddNewLibrary,
  setCollaborationStep,
  setIsBottomRightCopyrightVisible,
} = applicationSlice.actions;

// Reducer
export const applicationReducer = applicationSlice.reducer;
