import { TConfigurationState } from 'src/redux/configuration/configuration.slice';

export enum EChartSelfType {
  Sufficiency = 'Sufficiency',
  Consumption = 'Consumption',
}

export type TChartSelfData = {
  energyDemanded: number;
  energyProduced: number;
  selfConsumed: number;
};

export type TChartSelfProps = {
  kpi: NonNullable<TConfigurationState['simulationResults']>['kpi'] | undefined;
  type: EChartSelfType;
  className?: string;
  isDecimalPercentage?: boolean;
  isItemInfoActive?: boolean;
  isTitleInfoActive?: boolean;
  titleInfo?: string;
};
