import { TSCMSimulationGraphData } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';

export const pickKpiData = function (obj: Array<Record<string, any>>): TSCMSimulationGraphData {
  return obj
    .filter((item) => item.simulation_time)
    .map((item) => {
      return {
        time: item.simulation_time,
        consumption: item.energy_demanded_wh,
        generation: item.energy_produced_wh,
      };
    });
};
