import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React, { useContext } from 'react';

import { BaseLink } from 'src/components/BaseLink';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import logoOne from '../../assets/images/LandingPage/logo-economicForum.svg';
import logoThree from '../../assets/images/LandingPage/logo-cleantech.png';
import logoTwo from '../../assets/images/LandingPage/logo-beaworld.png';
import s from './OurApproach.module.scss';

export const OurApproach: React.FC = () => {
  const { approachEntry } = useContext(ContentfulContext);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const referencedEntry = getEntryWithId(node.data.target.sys.id);

        return <a href={`/pages/${referencedEntry.fields.slug}`}>{children}</a>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={`${node.data.uri}`} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      },
    },
  };

  function getEntryWithId(entryId) {
    const mockEntry = {
      fields: {
        slug: entryId,
      },
    };

    return mockEntry;
  }

  return (
    <div className={s.content}>
      <div className={s.boxText}>
        {documentToReactComponents(approachEntry?.[0]?.description as any, options)}
      </div>
      <div className={s.boxImage}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://widgets.weforum.org/techpioneers-2018/index.html">
          <img className={s.imgEconomic} src={logoOne} alt="World Economic Forum" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://beaworldfestival.com/eubea_events/event-horizon-2018/">
          <img className={s.imgBeworld} src={logoTwo} alt="be world" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.cleantech.com/">
          <img className={s.imgCleantech} src={logoThree} alt="Cleantech Group" />
        </a>
      </div>
    </div>
  );
};
