import React, { useContext } from 'react';
import { BaseButton } from 'src/components/BaseButton';
import ContentfulContext from 'src/contexts/ContentfulContext';
import s from './JoinUs.module.scss';

export function JoinUs() {
  const { joinUsEntry } = useContext(ContentfulContext);

  function redirect(link: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  return (
    <div className={s.container}>
      {joinUsEntry?.map((joinUs, key) => (
        <div key={key.toString()} className={s.box}>
          <div className={s.boxText}>
            <h2>{joinUs.title}</h2>
            <span className={s.text}>{joinUs.locale}</span>
            <span className={s.text_uppercase}>{joinUs.jobType}</span>
          </div>
          <div className={s.buttonPosition}>
            <button className={s.buttonApply} onClick={() => redirect(joinUs.link)}>
              <span>Apply Now</span>
            </button>
          </div>
        </div>
      ))}
      {joinUsEntry?.length === 0 && (
        <div className={s.boxNoOpportunitie}>
          <p>
            All our open positions are advertised here. At the moment there are no open positions
            but as we are growing expect to see an ad soon!
          </p>
          <BaseButton onClick={() => redirect('mailto:contact@gridsingularity.com')}>
            SEND US AN EMAIL
          </BaseButton>
        </div>
      )}
    </div>
  );
}
