import React, { useEffect } from 'react';

import { TInformationBoxProps } from './InformationBox.types';
import s from './InformationBox.module.scss';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const InformationBox: React.FC<TInformationBoxProps> = ({ onClickAction }) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Fixed::PH-1160-Admin dashboard is visible to not logged in user
  useEffect(() => {
    if (!isLoggedIn) {
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div className={s.container}>
      <div className={s.InfoContainer}>
        <div>☝️</div>
        <div>
          <p>This is an energy setup of your home based on available information. </p>
          <p>Please notify us of any changes.</p>
          <h3>
            <a onClick={onClickAction}>
              CLICK ON THE RESULTS TAB TO SEE THE PERFORMANCE OF YOUR HOME AND COMMUNITY
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};
