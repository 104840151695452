import React, { useCallback, useEffect, useState } from 'react';
import {
  TAssetsViewUserTileProps,
  TFormDataState,
  TFormErrorAreas,
} from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView/AssetsView.types';
import { useCreateCommunityMemberMutation, useUpdateCommunityMemberMutation } from 'src/graphql';

import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { UserTileForm } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView/UserTileForm';
import { UserTileShowData } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView/UserTileShowData';
import classNames from 'classnames';
import s from './AssetsView.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useSCMMemberEvents } from 'src/hooks/useSCMMemberEvents';
import { useSelector } from 'react-redux';

export const AssetsViewUserTile: React.FC<TAssetsViewUserTileProps> = ({
  uuid: uuidProp,
  name,
  avatarUrl,
  email,
  isAdmin,
  address,
  isNewUser: isNewUserProp,
  zipCode,
  toggleAssetView,
  onChangeNewUser,
}) => {
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  // updateCommunityMember
  const [updateCommunityMemberMutation, { loading, error }] = useUpdateCommunityMemberMutation();
  // createCommunityMember
  const [
    createCommunityMemberMutation,
    {
      //loading: createLoading,
      //error: createError,
      data: createData,
    },
  ] = useCreateCommunityMemberMutation();

  const [formData, setFormData] = useState<TFormDataState>({ name, email, address, zipCode });
  const [formStatus, setFormStatus] = useState<boolean>(isNewUserProp);
  const [isImageLoaded, setisImageLoaded] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(isNewUserProp);
  const [uuid, setUuid] = useState<string>(uuidProp);
  const [formErrorAreas, setFormErrorAreas] = useState<TFormErrorAreas>({});

  const { handleRemoveCommunityMember } = useSCMMemberEvents({
    toggleAssetView,
  });

  useEffect(() => {
    if (createData?.createCommunityMember?.uuid) {
      setIsNewUser(false);
      setUuid(createData.createCommunityMember.uuid);
    }
  }, [createData]);

  const onSubmit = useCallback(() => {
    if (!activeConfigurationUuid) return;

    const formKeys = ['name', 'email', 'address'];

    const isFormValid = formKeys.every((key) => formData[key]);

    if (!activeConfigurationUuid) return;

    if (!isFormValid) {
      // find the first empty field and focus on it
      const emptyFields = formKeys.filter((key) => !formData[key]);
      if (emptyFields.length > 0) {
        const errorAreas: TFormErrorAreas = {};
        emptyFields.forEach((field) => {
          errorAreas[field] = 'This field is required';
        });
        setFormErrorAreas(errorAreas);
      }
      return;
    }

    if (!isNewUser && uuid) {
      updateCommunityMemberMutation({
        variables: {
          name: formData.name,
          email: formData.email,
          zipcode: formData.zipCode,
          address: formData.address,
          memberUuid: uuid,
          configUuid: activeConfigurationUuid || '',
        },
      });
    }

    if (isNewUser && onChangeNewUser) {
      createCommunityMemberMutation({
        variables: {
          name: formData.name,
          email: formData.email,
          zipcode: formData.zipCode || '',
          address: formData.address || '',
          configUuid: activeConfigurationUuid || '',
          marketMakerRate: 0,
          feedInTariff: 0,
          gridFee: 0,
          taxesSurcharges: 0,
          fixedMonthlyFee: 0,
          marketplaceMonthlyFee: 0,
        },
      });

      onChangeNewUser(formData);
    }

    setFormStatus(false);
  }, [
    formData,
    updateCommunityMemberMutation,
    createCommunityMemberMutation,
    activeConfigurationUuid,
    uuid,
    isNewUser,
    onChangeNewUser,
  ]);

  const getInitials = () => {
    return name
      ?.match(/(^\S\S?|\b\S)?/g)
      ?.join('')
      ?.match(/(^\S|\S$)?/g)
      ?.join('')
      ?.toUpperCase();
  };
  return (
    <div
      className={classNames(s.userTileContainer, {
        [s.newUser]: isNewUser,
      })}>
      {false && (
        <div className={s.imgContainer}>
          {avatarUrl && isImageLoaded && (
            <img
              src={avatarUrl}
              onError={(e) => {
                e.currentTarget.src = 'your_image_not_found_defalt_picture_here';
                setisImageLoaded(false);
              }}
            />
          )}
          <div className={s.initialContainer}>
            {!isImageLoaded && <h3 className={s.initialssss}>{getInitials()}</h3>}
          </div>
        </div>
      )}
      <div className={s.infoContainer}>
        <div className={s.actionsContainer}>
          {isAdmin && !formStatus && (
            <BaseButtonSquare
              onClick={() => {
                setFormStatus(true);
              }}
              className={s.button}
              icon="pencil-edit"
              size="2"
            />
          )}
          {!formStatus && (isAdmin || isNewUser) && (
            <BaseButtonSquare
              onClick={() => handleRemoveCommunityMember(uuid)}
              className={s.button}
              icon="trash"
              size="2"
            />
          )}
          {formStatus && !isNewUser && (
            <BaseButtonSquare
              onClick={() => setFormStatus(false)}
              className={s.button}
              icon="close"
              size="2"
              svgSize="3"
            />
          )}
        </div>
        {formStatus ? (
          <>
            <UserTileForm
              formErrorAreas={formErrorAreas}
              setFormData={setFormData}
              isNewUser={isNewUser}
              {...formData}
            />
            <BaseButton type="button" onClick={onSubmit} className={s.formButton}>
              Save
            </BaseButton>
          </>
        ) : (
          <UserTileShowData {...formData} setFormData={setFormData} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};
