import { ArrayElement, EUserRoles } from 'src/typings/base-types';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { selectUserRole, selectUsername } from 'src/redux/auth/auth.selectors';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { ProfilePictureIcon } from 'src/components/UserAvatar';
import { TCanaryRegistryAssetTileProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/CanaryRegistryList';
import classNames from 'classnames';
import s from './CanaryRegistryList.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { useModal } from 'src/hooks/useModal';
import { useSelector } from 'react-redux';

type TOwnerItem = ArrayElement<TCanaryRegistryAssetTileProps['data']['owners']>;

export const CanaryRegistryAssetTile: React.FC<TCanaryRegistryAssetTileProps> = ({
  asset,
  data,
  usersList,
  onClick,
  selected,
  childrenCount,
  isChildren,
  onMenuClick,
  //homeNumber,
}) => {
  const [respondSent, setRespondSent] = useState(false);
  const userName = useSelector(selectUsername);
  const userRole = useSelector(selectUserRole);
  const configurationUuid = useSelector(selectActiveConfigurationUuid);
  const scmMembers = useSelector(selectSCMHomeDetails);
  const canaryNetworkServiceContext = useContext(CanaryNetworkServiceContext);
  const modalRefObject = useRef<HTMLDivElement>(null);

  const isAdmin = userRole === EUserRoles.Admin;
  const isAggregator = userRole === EUserRoles.Aggregator;

  const assignUserToAsset = ({ value }) => {
    const user = usersList.find((item) => item.uuid === value);
    if (user) {
      canaryNetworkServiceContext.approveExternalConnection(
        configurationUuid!,
        data.uuid,
        user.name,
        user.name,
      );
    }
  };

  const unregisterUser = (e, { name, isAggregator }: TOwnerItem) => {
    e.stopPropagation();
    canaryNetworkServiceContext.unregisterUser(
      configurationUuid!,
      data.uuid,
      name,
      isAggregator,
      name === userName,
    );
  };

  const onTileClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClick?.(asset);
  };

  const respondToApplyRequest = async (apply: boolean) => {
    if (!data.appliedUserName || !configurationUuid) return;
    setRespondSent(true);
    await canaryNetworkServiceContext.respondExternalConnectionRequest(
      apply,
      data.uuid,
      configurationUuid!,
      data.appliedUserName,
    );
    setRespondSent(false);
  };

  const owners = useMemo(() => {
    return data.owners.sort((a, b) => (a.isAggregator !== b.isAggregator ? 1 : 0));
  }, [data.owners]);

  const isTriggerForAggregatorOrAdmin = (
    item: ArrayElement<TCanaryRegistryAssetTileProps['data']['owners']>,
  ) => !item.isGridOperator && (isAdmin || (isAggregator && item.name === userName));

  const currentItem = useMemo(() => scmMembers.find((item) => item.uuid === data?.uuid), [
    data?.uuid,
    scmMembers,
  ]);

  const showSelectBox =
    currentItem &&
    !currentItem?.email &&
    ['Community', 'Grid Market'].includes(currentItem?.name) &&
    isAdmin;

  //const displayName = useMemo(() => {
  //  if (homeNumber === undefined || data.name === 'Grid Market') return data.name;
  //  return `Home ${homeNumber}`;
  //}, [data.name, homeNumber]);

  return (
    <div
      className={classNames(s.assetTile, {
        [s.isChildren]: isChildren,
        [s.selected]: selected,
      })}>
      <div
        className={classNames(s.leftContainer, {
          [s.clickable]: !!onClick,
        })}
        onClick={onTileClick}>
        <span>
          <BaseIcon className={s.icon} icon={data.icon} size={12} />
        </span>

        <div className={s.nameContainer}>
          <p className={s.name}>{data.name}</p>
          {
            //<div className={s.assetUser}>{data.name}</div>
          }
        </div>
      </div>

      {/* {!!childrenCount && userRole === EUserRoles.Admin && data.status !== 'Applied' && ( */}
      {!!childrenCount && data.status !== 'Applied' && (
        <div className={s.childAssetsCount}>
          <span>{childrenCount} Assets</span>
          <BaseIcon icon="eye" size={12} />
        </div>
      )}
      {owners && (
        <div
          className={classNames(s.owners, {
            [s.hoverable]: isAdmin || isAggregator,
          })}>
          {owners.map((item) =>
            isTriggerForAggregatorOrAdmin(item) ? (
              <BaseTooltipTrigger
                key={item.id}
                tooltipChildren={`Unregister ${item.name}`}
                position="right">
                <div
                  className={classNames(s.avatar, {
                    [s.hasAvatar]: !!item.avatarUrl,
                  })}>
                  <ProfilePictureIcon avatarUrl={item.avatarUrl} name={item.name} />
                  <button type="button" title="Unregister" onClick={(e) => unregisterUser(e, item)}>
                    <BaseIcon icon="close" size={10} />
                  </button>
                </div>
              </BaseTooltipTrigger>
            ) : (
              <ProfilePictureIcon
                avatarUrl={item.avatarUrl}
                name={item.name}
                className={{ nameInitials: s.tileAvatar, picture: s.tileAvatar }}
              />
            ),
          )}
        </div>
      )}
      {data.status === 'Applied' &&
        (userRole === EUserRoles.Aggregator ? (
          <BaseTooltipTrigger tooltipChildren={`Pending Approval`} position="right">
            <div className={s.owners}>
              <div className={s.appliedState}>
                <BaseIcon icon="time" size={12} />
              </div>
            </div>
          </BaseTooltipTrigger>
        ) : (
          <div className={s.actions}>
            <div className={s.appliedUserAvatarWrapper}>
              <ProfilePictureIcon
                avatarUrl={data.appliedUserAvatar}
                name={data.appliedUserName}
                className={{ nameInitials: s.tileAvatar, picture: s.tileAvatar }}
              />
            </div>
            {respondSent ? (
              <BaseIcon icon="spinner" size={12} />
            ) : (
              <>
                <BaseTooltipTrigger
                  tooltipChildren={`Approve ${data.appliedUserName}`}
                  position="right">
                  <button
                    className={s.actionButton}
                    type="button"
                    onClick={() => respondToApplyRequest(true)}>
                    <BaseIcon icon="check-mark" size={12} />
                  </button>
                </BaseTooltipTrigger>
                <BaseTooltipTrigger
                  tooltipChildren={`Decline ${data.appliedUserName}`}
                  position="right">
                  <button
                    className={classNames(s.actionButton, s.actionButtonDecline)}
                    type="button"
                    onClick={() => respondToApplyRequest(false)}>
                    <BaseIcon icon="close" size={8} />
                  </button>
                </BaseTooltipTrigger>
              </>
            )}
          </div>
        ))}
      {showSelectBox && (
        <BaseSelect
          inputHeight="2"
          theme="filled-white"
          name="userRole"
          value={undefined}
          options={usersList.map(({ name, uuid }) => ({ label: name.split('@')[0], value: uuid }))}
          className={s.selectbox}
          defaultLabel="Assign User"
          onChange={assignUserToAsset}
        />
      )}
      <div>
        {onMenuClick && (
          <div
            ref={modalRefObject}
            onClick={() => {
              if (data && data.uuid) {
                onMenuClick(modalRefObject.current, { itemUuid: data.uuid });
              }
            }}>
            <BaseIcon icon="more-horizontal" size={15} />
          </div>
        )}
      </div>
    </div>
  );
};
