import { BaseTags, TBaseTagsProps } from 'src/components/BaseTags';
import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import { NET_ENERGY_KEY, SWITCHER_ICON_SIZES } from 'src/constants/application';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  TBarDataset,
  TChartEnergyTradeProfileProps,
  TLegendExtraProps,
  TLineDataset,
} from './ChartEnergyTradeProfile.types';
import {
  selectCommunityAsset,
  selectSettingsData,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';

import { AxisXCustom } from 'src/components/_charts/AxisXCustom/AxisXCustom';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { Chart } from 'chart.js';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EChartName } from 'src/components/_charts/chartsData';
import { UTCMoment } from 'src/utils/UTCMoment';
import { formatter } from 'src/utils/formatter';
import { getColor } from 'src/components/_charts/ChartEnergyTradeProfile/helpers';
import { useChartJS } from 'src/hooks/useChartJS';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';

export const peakAnalysisConfig = [
  {
    property: 'baseline_peak_energy_kWh',
    borderColor: '#573AFF',
  },
  {
    property: 'peak_energy_net_kWh',
    borderColor: '#9B6BFD',
  },
  {
    property: 'capacity_kWh',
    borderColor: '#46F4FF',
    borderDash: [10, 10] as [number, number],
  },
];

export const ChartEnergyTradeProfile: React.FC<TChartEnergyTradeProfileProps> = ({
  showPeakAnalysis,
  showNetEnergy,
  legend,
  netEnergyData,
  importData,
  exportData,
  soldEnergy,
  boughtEnergy,
  togglePeakAnalysis,
  toggleIsolated,
  toggleNetEnergy,
  hasExternalTrades,

  startUnix,
  endUnix,
  assetUuid,
  hasData,
}) => {
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { currency } = useSelector(selectSettingsData);
  const simulationResults = useSelector(selectSimulationResults);
  const communityAsset = useSelector(selectCommunityAsset);

  const updateNames = (
    data: {
      name: string;
      color: string;
      disabled?: boolean | undefined;
      inactive?: boolean | undefined;
      value: string;
    }[],
  ) => {
    const isCommunity = simulationResults && simulationResults?.assetUuid === communityAsset?.uuid;

    return data?.map((e) => {
      let nameUpdated = e.name;

      nameUpdated = nameUpdated.split('IAA ').join('');

      if (!isCommunity) {
        if (nameUpdated.includes('Grid Market')) {
          nameUpdated = 'Community';
        }
        if (nameUpdated.includes('Home')) {
          nameUpdated = 'Community';
        }
      }
      return { ...e, name: nameUpdated };
    });
  };

  const legendsUpdated = updateNames(legend);

  const handleLegendItemClick: TBaseTagsProps<TLegendExtraProps>['onTagClick'] = (item) => {
    if (item.value === NET_ENERGY_KEY) {
      toggleNetEnergy();
    } else {
      toggleIsolated(item.value);
    }
  };

  const peakAnalysisDatasets = useMemo(() => {
    const datasets: TLineDataset[] = [];
    const capacityDatasets: TLineDataset[] = [];

    if (!startUnix || !endUnix) return [];

    [importData, exportData].forEach((data, index) => {
      peakAnalysisConfig.forEach(({ property, borderColor, borderDash }) => {
        const value = data[property];

        if (!value || Number.isNaN(value)) return;

        const container = property.includes('capacity') ? capacityDatasets : datasets;
        const isImport = index === 0;
        const finalValue = isImport ? value : -value;
        const datasetData: TLineDataset['data'] = [];

        for (let unix = startUnix; unix <= endUnix; unix += 15 * 60) {
          datasetData.push({ x: UTCMoment.fromUnix(unix).valueOf(), y: finalValue });
        }

        const dataset: TLineDataset = {
          type: 'line',
          data: datasetData,
          label: JSON.stringify({
            title: `Current peak ${isImport ? 'imports' : 'exports'}`,
            unit: 'kWh',
          }),
          borderColor,
          borderDash,
          pointHoverRadius: 0,
          hidden: !showPeakAnalysis,
        };

        container.push(dataset);
      });
    });

    return [...datasets, ...capacityDatasets];
  }, [endUnix, exportData, importData, showPeakAnalysis, startUnix]);

  const tradesDatasets: TBarDataset[] = useMemo(() => {
    const output: TBarDataset[] = [];

    if (!soldEnergy || !boughtEnergy || !assetUuid) return output;

    legendsUpdated.forEach(({ value: assetName, name: labelTitle }) => {
      [soldEnergy[assetName], boughtEnergy[assetName]].forEach((data) => {
        const color = getColor(assetName, assetUuid);

        if (!data) return;

        output.push({
          type: 'bar',
          data: data,
          label: JSON.stringify({
            title: labelTitle,
            unit: 'kWh',
          }),
          borderColor: color,
          backgroundColor: color,
          hoverBackgroundColor: color,
        });
      });
    });

    return output;
  }, [legendsUpdated, soldEnergy, boughtEnergy, assetUuid]);

  const datasets: Array<TLineDataset | TBarDataset> = useMemo(
    () => [
      {
        type: 'bar' as const,
        data: [...netEnergyData], // Create a new array reference because below we push data to it
        backgroundColor: vars['color-net-energy'],
        hoverBackgroundColor: vars['color-net-energy'],
        hidden: !showNetEnergy,
        label: JSON.stringify({
          title: 'Net Energy',
          unit: 'kWh',
        }),
      },
      ...tradesDatasets.map((item) => ({
        ...item,
        data: [...item.data],
        hidden: showNetEnergy,
      })),
      ...peakAnalysisDatasets.map((item) => ({
        ...item,
        data: [...item.data],
        hidden: !showPeakAnalysis,
      })),
    ],
    [netEnergyData, showNetEnergy, tradesDatasets, peakAnalysisDatasets, showPeakAnalysis],
  );

  useChartJS(
    EChartName.EnergyTradeProfile,
    canvasRef,
    chartRef,
    {
      datasets,
    },
    {
      chartOptions: {
        scales: {
          x: {
            max: endUnix * 1000,
            min: startUnix * 1000,
          },
          y: {
            ticks: {
              display: true,
              font: {
                size: 8,
              },
            },
          },
        },
      },
      datasetsLengthWillChange: true,
      preventReRender: true,
    },
  );

  // useEffect(() => {
  //   if (chartRef.current && endUnix) {
  //     chartRef.current.options.scales!.x!.max = endUnix * 1000;
  //     chartRef.current.update();
  //   }
  // }, [endUnix]);

  return (
    <ChartWrapper
      title="Trade Profile"
      info={getChartTooltip(
        EChartTooltips.TOOLTIP_ENERGY_TRADE_PROFILE,
        formatter.getCurrencySymbol(currency),
      )}
      titleRightSlotComponent={
        <BaseSwitch
          name="peak-analysis"
          label="Peak Analysis"
          options={[
            { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
            { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
          ]}
          theme="gray-custom"
          variant="horizontal-small"
          disabled={!hasExternalTrades}
          value={showPeakAnalysis}
          onChange={togglePeakAnalysis}
        />
      }
      footerComponent={
        <BaseTags items={legendsUpdated} name="legend" onTagClick={handleLegendItemClick} />
      }>
      <ChartDataLoadingWrapper loading={!hasData}>
        <canvas ref={canvasRef} />

        <AxisXCustom startUnix={startUnix} endUnix={endUnix} style={{ marginLeft: 15 }} />
      </ChartDataLoadingWrapper>
    </ChartWrapper>
  );
};
