import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  ReadConfigurationQueryResult,
  ReadProjectLocationVisibilityDocument,
  ReadProjectLocationVisibilityQuery,
  ReadProjectLocationVisibilityQueryVariables,
} from 'src/graphql';
import { batch, useSelector } from 'react-redux';
import {
  configurationReset,
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
  setConfiguration,
  setResultsEndTime,
  setResultsStartTime,
  updateConfigType,
  updateReadOnly,
} from 'src/redux/configuration/configuration.slice';
import { matchPath, useHistory, useRouteMatch } from 'react-router';
import {
  selectActiveConfigurationUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import { useCallback, useEffect, useMemo } from 'react';

import { NN } from 'src/typings/helpers';
import { TAsset } from 'src/typings/configuration.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { getCommunityAsset } from 'src/utils/configuration/getCommunityAsset';
import { openToast } from 'src/redux/toast/toast.slice';
import { parseSimulationResults } from 'src/utils/parseSimulationResults';
import { routesConfig } from 'src/routes/routes.config';
import { selectIsEmbed } from 'src/redux/application/application.selectors';
import { selectSCMDataSheetResponse } from 'src/redux/scm/scm.selectors';
import { setCommunityNotFound } from 'src/redux/application/application.slice';
import { setSCMhomeDetails } from 'src/redux/scm/scm.slice';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';

export type TSimulationResultData = ReturnType<typeof parseSimulationResults> & {
  assetUuid: TAsset['uuid'];
};

/**
 *
 *  __useConfigurationEffects__
 *
 *  This hook is important part of app flow. It's used for:
 *  - Fetching configuration on activeConfigurationUuid change
 *  - Parsing configuration and storing in redux
 *  - Fetching simulation results
 *  - Simulation subscription handling
 *  - Parsing and passing configuration results to redux
 *  - Catching route change, matching and setting activeConfigurationUuid
 *
 */
export function useMapLoadAfterBeResponse(): void {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const history = useHistory();
  const location = useAppLocation();
  const isEmbed = useSelector(selectIsEmbed);
  const scmDataSheetResponse = useSelector(selectSCMDataSheetResponse);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const settingsData = useSelector(selectSettingsData);

  const { zoomIntoConfiguration, discardCurrentConfiguration } = useConfigurationUtils();

  const settingsStartTime = useMemo(() => {
    return UTCMoment.fromBackend(settingsData.startDate)
      .startOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [settingsData]);

  /**
   *
   * Match route with map results path. It helps us to get configuration and asset uuids
   *
   */
  const paramsMatch = useRouteMatch<{ configurationUuid: string; assetUuid: string }>({
    path: isEmbed
      ? routesConfig.embed('')
      : [
          routesConfig.singularityMapResults(undefined, undefined),
          routesConfig.singularityMapResults(undefined, ''),
        ],
  });
  /**
   *
   *  __updateConfigurationStoreData__
   *
   *  Update configuration in redux store
   *  @param locationVisible Location visible param
   *  @param configurationData Parsed configuration data object
   *
   */
  const updateConfigurationStoreData = useCallback(
    (
      locationVisible,
      {
        jobUuid,
        configType,
        readOnly,
        name,
        user,
        description,
        timezone,
        project,
        assets,
        assetsValues,
        assetsTreeRelations,
        rootAssetUuid,
        selectedAssetUuid,
        settingsData,
      }: ReturnType<typeof parseConfigurationData>,
      scmHomeDetails,
    ) => {
      batch(() => {
        dispatch(setSCMhomeDetails(scmHomeDetails));
        dispatch(setActiveConfigurationJobUuid(jobUuid || undefined));
        dispatch(updateConfigType(configType || undefined));
        dispatch(updateReadOnly(!!readOnly));
        dispatch(
          setConfiguration({
            name: name || '',
            user: user || '',
            description: description || '',
            timezone: timezone || '',
            projectUuid: project?.uuid || '',
            locationVisible,
            assets,
            assetsTreeRelations,
            assetsValues,
            rootAssetUuid,
            selectedAssetUuid,
            // TODO: Type has to be fixed
            settingsData: settingsData || (undefined as any),
          }),
        );
      });
    },
    [dispatch],
  );

  /**
   *
   *  __parseConfigurationData__
   *
   *  Parse configuration object
   *  @param configuration Configuration object from backend
   *  @returns Parsed configuration data with destructured tree and communityAssetUuid
   *
   */
  function parseConfigurationData(
    configuration: NN<NN<ReadConfigurationQueryResult['data']>['readConfiguration']>,
    assetUuidFromUrl?: string,
  ) {
    const { scenarioData, simulationResults } = configuration;
    const jobUuid = simulationResults;
    const configTree = destructureConfigTree(scenarioData?.latest?.serialized);
    const { assets, assetsTreeRelations, rootAssetUuid, assetsValues } = configTree;
    const communityAssetUuid = getCommunityAsset({
      assets,
      rootAssetUuid,
      assetsTreeRelations,
      assetsValues,
    })?.uuid;
    return {
      ...configuration,
      ...configTree,
      jobUuid,
      selectedAssetUuid: assetUuidFromUrl || communityAssetUuid,
    };
  }

  /**
   *
   *  __callForProjectLocationVisibility__
   *
   *  Read project visibility param from backend
   *  @param projectUuid Project uuid
   *  @returns Boolean value of locationVisible param
   *
   */
  const callForProjectLocationVisibility = useCallback(
    async (projectUuid: string) => {
      const projectResult = await client.query<
        ReadProjectLocationVisibilityQuery,
        ReadProjectLocationVisibilityQueryVariables
      >({
        query: ReadProjectLocationVisibilityDocument,
        variables: { projectUuid },
        fetchPolicy: 'no-cache',
      });

      return projectResult?.data?.readProject?.locationVisible ?? true;
    },
    [client],
  );

  /**
   *
   *  __getConfiguration__
   *
   *  Main function for configuration reading.
   *  @param configurationUuid Uuid of configuration
   *
   */
  const getConfiguration = useCallback(
    async (configuration) => {
      if (configuration) {
        let startTime, endTime;
        const scmMembers = configuration?.members || [];
        const scmHomeDetails = configuration?.scenarioData?.homeInfo?.scmHomeDetails || [];

        const configurationData = parseConfigurationData(
          configuration,
          paramsMatch?.params.assetUuid,
        );
        const isCN = configuration.configType === 'CANARY_NETWORK';
        const isShorterThanWeek =
          UTCMoment.fromBackend(configuration.settingsData?.endDate).diff(
            UTCMoment.fromBackend(configuration.settingsData?.startDate),
            'days',
          ) < 7;

        if (isShorterThanWeek) {
          startTime = UTCMoment.fromBackend(configuration.settingsData?.startDate)
            .startOf('day')
            .toISOString();
          endTime = UTCMoment.fromBackend(configuration.settingsData?.endDate)
            .endOf('day')
            .toISOString();
        } else if (isCN) {
          startTime = UTCMoment.utc().subtract(6, 'days').startOf('day').toISOString();
          endTime = UTCMoment.utc().endOf('day').toISOString();
        } else {
          startTime = UTCMoment.utc(settingsStartTime).startOf('day').toISOString();
          endTime = UTCMoment.utc(settingsStartTime).add(6, 'day').endOf('day').toISOString();
        }

        batch(() => {
          dispatch(setResultsStartTime(startTime));
          dispatch(setResultsEndTime(endTime));
        });

        const { project, selectedAssetUuid, assetsValues } = configurationData;

        if (project?.uuid && selectedAssetUuid) {
          const locationVisible = await callForProjectLocationVisibility(project.uuid);

          updateConfigurationStoreData(locationVisible, configurationData, scmHomeDetails);

          const routeMatch = matchPath(location.pathname, {
            path: isEmbed
              ? routesConfig.embed(activeConfigurationUuid)
              : routesConfig.singularityMapResults(activeConfigurationUuid, selectedAssetUuid),
          });

          if (!routeMatch?.isExact) {
            history.push(
              isEmbed
                ? routesConfig.embed(activeConfigurationUuid)
                : routesConfig.singularityMapResults(activeConfigurationUuid, selectedAssetUuid),
            );
          }

          try {
            zoomIntoConfiguration({ assetsValues });
          } catch (err) {
            discardCurrentConfiguration();
            dispatch(
              openToast({
                message: 'Something went wrong',
                type: 'error',
              }),
            );
          }
        }
      }
    },
    [
      activeConfigurationUuid,
      callForProjectLocationVisibility,
      discardCurrentConfiguration,
      dispatch,
      history,
      isEmbed,
      location.pathname,
      paramsMatch?.params.assetUuid,
      settingsStartTime,
      updateConfigurationStoreData,
      zoomIntoConfiguration,
    ],
  );

  /**
   *
   *  Fetch configuration and results after uuid change. Reset if configuration fetching fails
   *
   */
  useEffect(() => {
    async function makeRequest() {
      if (scmDataSheetResponse) {
        try {
          await getConfiguration(scmDataSheetResponse);
        } catch (error) {
          if (!isEmbed) {
            discardCurrentConfiguration();
          } else {
            dispatch(setCommunityNotFound(true));
          }
        }
      }
    }

    makeRequest();
  }, [
    activeConfigurationUuid,
    discardCurrentConfiguration,
    dispatch,
    getConfiguration,
    isEmbed,
    scmDataSheetResponse,
  ]);

  /**
   *
   *  Get and set configuration uuid from URL
   *
   *  Notes:
   *  - setActiveConfigurationUuid dispatch will trigger readConfigurationQuery
   *
   */
  useEffect(() => {
    if (paramsMatch?.params.configurationUuid) {
      dispatch(setActiveConfigurationUuid(paramsMatch?.params.configurationUuid));
    }
  }, [dispatch, history, paramsMatch]);

  /**
   *
   *  On configuration quit
   *
   */
  useEffect(() => {
    if (!activeConfigurationUuid && scmDataSheetResponse === null) {
      dispatch(configurationReset());
    }
  }, [activeConfigurationUuid, scmDataSheetResponse, dispatch]);
}
