/* eslint-disable default-case */
import { TAssetType } from 'src/typings/base-types';
import { TFieldNamesMappingUnion } from 'src/utils/assetsFields/assetsFields.types';

interface IProps {
  assetType: TAssetType;
}

// field name mappings should be in format { BACKEND_FIELD_NAME: UI_FIELD_NAME }
export function fieldNamesMapping({ assetType }: IProps): TFieldNamesMappingUnion {
  let output = {
    name: 'name',
    libraryUUID: 'libraryUUID',
    allow_external_connection: 'allowExternalConnection',
  } as TFieldNamesMappingUnion;

  switch (assetType) {
    case 'Area':
      output = {
        ...output,
        __typename: 'Area',
        grid_fee_constant: 'gridFeeConstant',
        grid_fee_percentage: 'gridFeePercentage',
        target_market_kpi: 'targetMarketKpi',
        fit_area_boundary: 'fitAreaBoundary',
        geo_tag_location: 'geoTagLocation',
        import_capacity_kVA: 'importCapacityKva',
        export_capacity_kVA: 'exportCapacityKva',
        coefficient_percentage: 'coefficientPercentage',
        baseline_peak_energy_import_kWh: 'baselinePeakEnergyImportKwh',
        baseline_peak_energy_export_kWh: 'baselinePeakEnergyExportKwh',
        fixed_monthly_fee: 'fixedMonthlyFee',
        market_maker_rate: 'marketMakerRate',
        feed_in_tariff: 'feedInTariff',
        taxes_surcharges: 'taxesSurcharges',
        marketplace_monthly_fee: 'marketplaceMonthlyFee',
      };
      break;

    case 'FiniteDieselGenerator':
      output = {
        ...output,
        __typename: 'FiniteDieselGenerator',
        energy_rate: 'energyRate',
        max_available_power_kW: 'maxAvailablePowerKw',
        geo_tag_location: 'geoTagLocation',
      };
      break;

    case 'Load':
      output = {
        ...output,
        __typename: 'Load',
        daily_load_profile: 'dailyLoadProfile',
        daily_load_profile_uuid: 'dailyLoadProfileUuid',
        avg_power_W: 'avgPowerW',
        hrs_per_day: 'hrsPerDay',
        hrs_of_day: 'hrsOfDay',
        initial_buying_rate: 'initialBuyingRate',
        use_market_maker_rate: 'useMarketMakerRate',
        final_buying_rate: 'finalBuyingRate',
        energy_rate_increase_per_update: 'energyRateIncreasePerUpdate',
        fit_to_limit: 'fitToLimit',
        update_interval: 'updateInterval',
        target_device_kpi: 'targetDeviceKpi',
        geo_tag_location: 'geoTagLocation',
        forecast_stream_enabled: 'forecastStreamEnabled',
      };
      break;

    case 'PV':
      output = {
        ...output,
        __typename: 'PV',
        capacity_kW: 'capacityKw',
        cloud_coverage: 'cloudCoverage',
        power_profile: 'powerProfile',
        power_profile_uuid: 'powerProfileUuid',
        initial_selling_rate: 'initialSellingRate',
        final_selling_rate: 'finalSellingRate',
        energy_rate_decrease_per_update: 'energyRateDecreasePerUpdate',
        fit_to_limit: 'fitToLimit',
        update_interval: 'updateInterval',
        target_device_kpi: 'targetDeviceKpi',
        use_market_maker_rate: 'useMarketMakerRate',
        geo_tag_location: 'geoTagLocation',
        forecast_stream_enabled: 'forecastStreamEnabled',
        azimuth: 'azimuth',
        tilt: 'tilt',
      };
      break;

    case 'Storage':
      output = {
        ...output,
        __typename: 'Storage',
        battery_capacity_kWh: 'batteryCapacityKwh',
        initial_soc: 'initialSoc',
        min_allowed_soc: 'minAllowedSoc',
        max_abs_battery_power_kW: 'maxAbsBatteryPowerKw',
        initial_selling_rate: 'initialSellingRate',
        final_selling_rate: 'finalSellingRate',
        initial_buying_rate: 'initialBuyingRate',
        final_buying_rate: 'finalBuyingRate',
        energy_rate_decrease_per_update: 'energyRateDecreasePerUpdate',
        energy_rate_increase_per_update: 'energyRateIncreasePerUpdate',
        fit_to_limit: 'fitToLimit',
        update_interval: 'updateInterval',
        cap_price_strategy: 'capPriceStrategy',
        target_device_kpi: 'targetDeviceKpi',
        geo_tag_location: 'geoTagLocation',
        forecast_stream_enabled: 'forecastStreamEnabled',
      };
      break;

    case 'MarketMaker':
    case 'InfiniteBus':
      output = {
        ...output,
        __typename: 'MarketMaker',
        grid_connected: 'gridConnected',
        energy_rate: 'energyRate',
        energy_rate_profile: 'energyRateProfile',
        energy_rate_profile_uuid: 'energyRateProfileUuid',
        energy_buy_rate: 'energyBuyRate',
        buying_rate_profile: 'buyingRateProfile',
        buying_rate_profile_uuid: 'buyingRateProfileUuid',
      };
      break;

    default:
      console.error(`assetType: ${assetType} was not found.`);
      break;
  }

  return output;
}
