import {
  ENotificationPlace,
  TNotificationsState,
} from 'src/redux/notifications/notifications.types';

import { RootState } from 'src/redux/store';

export const selectLocalNotificationsList = (
  state: RootState,
): TNotificationsState['localNotificationsList'] => state.notifications.localNotificationsList;

export const selectGlobalNotificationsList = (
  state: RootState,
): TNotificationsState['globalNotificationsList'] => state.notifications.globalNotificationsList;

export const selectSingleNotification = (
  state: RootState,
): TNotificationsState['singleNotification'] => state.notifications.singleNotification;

export const selectSingleNotificationByNotificationPlace = (
  notificationPlace: ENotificationPlace,
) => (state: RootState): TNotificationsState['singleNotification'] => {
  if (state.notifications.singleNotification?.notificationPlace == notificationPlace) {
    return state.notifications.singleNotification;
  }

  return null;
};
