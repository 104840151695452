import {
  ENotificationPlace,
  TSingleNotification,
} from 'src/redux/notifications/notifications.types';

import React from 'react';

export const EnergyBillInfoContent: React.FC = () => (
  <>
    The energy bill is divided into four different components: <br />
    <br />
    <b>Energy cost:</b> Refers to the cost of energy bought from the utility; measured in €/kWh.
    <br />
    <br />
    <b>Service fee:</b> Represents a monthly fixed amount in € to cover the energy community
    administrative costs <br />
    <br />
    <b>Grid tariff:</b> Refers to the cost to cover the maintenance costs of the electrical grid of
    the energy community, measured in €/kWh, which is proportional to the energy consumed by each
    community member
    <br />
    <br />
    <b>Taxes and surcharges:</b> Includes all the taxes and levies to be paid pursuant to the local
    regulation
  </>
);

export const SelfSufficiencyInfoText =
  'This is the share of your electricity demand that is provided by your home renewable assets reducing the reliance on the external (community or utility) supply.';

export const SelfConsumptionInfoText =
  'This is the share of your electricity production from your renewable assets that you consumed internally.';

export const SavingsBenchmarkInfoText =
  'This shows how you compare with other members of your energy community in terms of energy savings. The higher the number the more you have saved compared to others.';

export const MyEnergyFlowsInfoText =
  'This diagram shows the energy flows of your assets within your home over a set time period (daily or monthly, depending on the selected setting). Red represents assets which are consuming (importing) energy, and green assets which are generating (exporting) energy.';

export const MyEnergyProfileInfoText =
  'This graph shows the amount of energy that is generated and consumed in your home over the selected time period.';

export const SCMMyHomeNotification: TSingleNotification = {
  notificationPlace: ENotificationPlace.HEADER,
  characterIcon: '☝',
  description:
    'You can also rerun the same simulation or go back to change member or market settings and run a new simulation to optimise configuration ahead of moving to operation.',
};
