import {
  EViewType,
  TKeyResultInfoDescriptions,
} from 'src/components/KeyResultsSCM/KeyResultsSCM.types';

export const getDetails = (viewType: EViewType): TKeyResultInfoDescriptions => {
  switch (viewType) {
    case EViewType.Savings:
      return {
        title: 'Home Savings',
        description:
          'This is the monetary savings in € derived by participating in the local energy market, compared to buying from a single energy supplier.',
      };
    case EViewType.SelfSufficiency:
      return {
        title: 'Home Self-Sufficiency',
        description:
          'This is the share of community electricity consumption that is supplied by its own renewable energy assets, reducing reliance on the electricity grid.',
      };
    case EViewType.EnergyBill:
      return {
        title: 'Energy Bill',
        description:
          'This corresponds to the total energy bill in Euros (€) for this home for the selected period of time. Apart from the energy cost, it also includes any revenue generated, energy community service fee, applicable grid tariffs and taxes.',
      };
    case EViewType.CommunitySavings:
      return {
        title: 'Community Savings',
        description:
          'By activating your local energy trading, compared to buying from a single energy supplier.',
      };
    case EViewType.CommunitySelfSufficiency:
      return {
        title: 'Community Self-Sufficiency',
        description:
          'This is the share of community electricity demand that is provided by the community. This rate also represents the minimum share of renewable energy in your community energy mix.',
      };
    default:
      return {
        title: '',
        description: '',
      };
  }
};
