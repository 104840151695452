import { AppLocationContextProvider, TAppLocationContextValue } from 'src/hooks/useAppLocation';
import React, { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Route, useLocation } from 'react-router-dom';

import { ModalAuth } from 'src/components/ModalAuth';
import { ModalNetworkCanary } from 'src/components/ModalNetworkCanary';
import { TAppRouteProps } from 'src/routes/routes.config';

export const AppRoute: React.FC<TAppRouteProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    const favicon = document.getElementById('js-favicon') as HTMLLinkElement;

    if (props.routeName !== 'home') {
      if (favicon) {
        // favicon.href = FaviconGreen;
        favicon.href = process.env.PUBLIC_URL + '/favicon-green.png';
      }
    } else {
      if (favicon) {
        favicon.href = process.env.PUBLIC_URL + '/favicon-red.png';
      }
    }
  }, [props.routeName]);

  const value: TAppLocationContextValue = useMemo(() => {
    return {
      ...location,
      routeName: props.routeName,
    };
  }, [location, props.routeName]);

  return (
    <AppLocationContextProvider value={value}>
      <Route {...props} />

      {/* ModalAuth */}
      <ModalAuth />

      {/* ModalNetworkCanary */}
      <ModalNetworkCanary />
    </AppLocationContextProvider>
  );
};
