import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { closeModal, openModal, updateModalContent } from 'src/redux/modals/modals.slice';

import { BaseButton } from 'src/components/BaseButton';
import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import { BaseLink } from 'src/components/BaseLink';
import { CollaborationArrowButons } from 'src/components/ModalCollaborationDeploy/components/CollaborationArrowButton';
import { CollaborationSteps } from 'src/components/ModalCollaborationDeploy/components/CollaborationSteps';
import s from './ModalCollaborationDeploy.module.scss';
import { selectIsModalOpened } from 'src/redux/modals/modals.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const ModalCollaborationDeploy: React.FC = () => {
  const dispatch = useAppDispatch();
  const [apiKey, setApiKey] = useState<string>();
  const [isApiKeyValid, setIsApiKeyValid] = useState<boolean>(false);

  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_COLLABORATION_DEPLOY),
  );

  //TODO: It Should be deleted, it has been added for development purposes
  /* useEffect(() => {
    dispatch(openModal(EPredefinedModalIds.MODAL_COLLABORATION_DEPLOY));
  }, [dispatch]);
  */
  const modalRef = useRef<HTMLDivElement>(null);

  const closeDeployModal = useCallback<() => void>(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_COLLABORATION_DEPLOY));
  }, [dispatch]);

  const validateAPIKey = useCallback<(apiKey: string) => void>(
    (apiKey) => {
      //TODO: We Need a BE service for the validation

      if (apiKey.length > 12) {
        setIsApiKeyValid(true);
        return;
      }
      setIsApiKeyValid(false);
    },
    [setIsApiKeyValid],
  );

  const onChange = useCallback<(apiKey: string) => void>(
    (apiKey) => {
      setApiKey(apiKey);
      validateAPIKey(apiKey);
    },
    [setApiKey, validateAPIKey],
  );

  const handleConnectButton = useCallback<() => void>(() => {
    closeDeployModal();
    const modalData = {
      icon: 'canary',
    };
    dispatch(updateModalContent(modalData));
    dispatch(openModal(EPredefinedModalIds.MODAL_DEPLOY_PROCESS));
  }, [closeDeployModal, dispatch]);

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} onClick={closeDeployModal} />
      <BaseGenericModal
        size={EModalSize.XL820}
        modalId={EPredefinedModalIds.MODAL_COLLABORATION_DEPLOY}
        className={s.modal}
        modalRef={modalRef}>
        <div className={s.modalWrapper}>
          <h2 className={s.modalTitle}>Deploy your community</h2>
          <div className={s.stepsWrapper}>
            <CollaborationSteps />
            <CollaborationArrowButons />
          </div>
        </div>
        <div className={s.modalContentWrapper}>
          <p>Please insert API key numbers to enable connection</p>
          <div className={s.inputArea}>
            <BaseInput
              type="text"
              name="apiKey"
              value={apiKey}
              onChange={(e) => onChange(e.value as string)}
              label="API Key"
              theme="line-light"
              className={s.apiInput}
              onBlur={(e) => validateAPIKey(e.target.value)}
            />
            {isApiKeyValid && (
              <div className={s.checkmarkBox}>
                <BaseIcon icon="check-mark" size={30} />
              </div>
            )}
          </div>
          <BaseLink className={s.plainLink}>What is this?</BaseLink>
          <BaseButton
            size="normal"
            className={s.buttonConnect}
            onClick={handleConnectButton}
            disabled>
            Connect
          </BaseButton>
        </div>
      </BaseGenericModal>
    </>
  );
};
