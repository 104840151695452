import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import {
  ApiLayer,
  BlueBoxLayer,
  DeploymentLayer,
  ELayersGroup,
  EMenus,
  EcosystemLayer,
  ExchangeLayer,
  InitialLayer,
  MarketLayer,
  SimulationLayer,
  TLayers,
  layerPositions,
  menus,
  totalPages,
} from 'src/pages/MarketDesign';
import { EMenuColor, Header } from 'src/components/Header';
import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { BaseIcon } from 'src/components/BaseIcon';
import { Fade } from 'react-reveal';
import { FullBar } from 'src/components/LandingPage/MarketDesign/StatusBar';
import bgLayerDeployment from '../../assets/images/LandingPage/marketDesign/layer_deployment/bg.png';
import bgLayerEcosystem from '../../assets/images/LandingPage/marketDesign/layer_ecosystem/bg.png';
import bgLayerOneExchanges from '../../assets/images/LandingPage/marketDesign/layerOne_exchanges/bg.png';
import s from './MarketDesign.module.scss';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export function detectiOS() {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const MarketDesign: React.FC = () => {
  const [headerColorMode, setHeaderColorMode] = React.useState('white');
  const dispatch = useAppDispatch();
  const isSCMFlow = useSelector(selectSCMFlow);
  const parallaxRef = useRef<IParallax>(null);
  const [currentLayer, setCurrentLayer] = useState(0);
  const [scrolling, setScrolling] = useState<boolean>(false);
  // const [scrollDirection, setScrollDirection] = useState<null | string>(null);
  const [scaleScrolling, setScallingScrolling] = useState(0);
  // const scrollDirectionMemo = useMemo(() => scrollDirection, [scrollDirection]);
  const scrollingMemo = useMemo(() => scrolling, [scrolling]);
  const scaleScrollingMemo = useMemo(() => scaleScrolling, [scaleScrolling]);
  const { updateAppFlow, resetSCMSteps } = useAppFlow();

  // Reset the SCM flow on home page
  useEffect(() => {
    resetSCMSteps();
    updateAppFlow(APP_FLOW.PHOENIX);
    if (isSCMFlow) {
      dispatch(setSCMFlow(false));
    }
  }, [dispatch, isSCMFlow, updateAppFlow, resetSCMSteps]);

  // const currentLayerMemo = useMemo(() => currentLayer, [currentLayer]);

  // ====================================================================================
  // Get page height based in the viewport
  // ====================================================================================
  const vh = useMemo(
    () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    [],
  );

  // ====================================================================================
  // Get the layer of parallax and save in the state
  // ====================================================================================
  const scrollAnalyzer = useCallback(() => {
    if (parallaxRef.current) {
      setScallingScrolling((parallaxRef.current.current / vh) % 1);
      if (
        ((parallaxRef.current.current / vh) % 1 >= 0 ||
          (parallaxRef.current.current / vh) % 1 <= 1) &&
        !scrolling
      ) {
        setCurrentLayer(Math.round(parallaxRef.current.current / vh));
      }
    }
  }, [setCurrentLayer, setScallingScrolling, vh, scrolling]);

  // ====================================================================================
  // Verify scroll direction and save in the state
  // ====================================================================================
  // useEffect(() => {
  //   const scrollableElement = document.body;

  //   scrollableElement.addEventListener('wheel', checkScrollDirection);
  //   function checkScrollDirection(event) {
  //     if (checkScrollDirectionIsUp(event)) {
  //       setScrollDirection('up');
  //     } else {
  //       setScrollDirection('down');
  //     }
  //   }

  //   function checkScrollDirectionIsUp(event) {
  //     if (event.wheelDelta) {
  //       return event.wheelDelta > 0;
  //     }
  //     return event.deltaY < 0;
  //   }
  // }, [setScrollDirection, setCurrentLayer]);

  // ====================================================================================
  // Useeffect for listening scroll events
  // ====================================================================================
  useEffect(() => {
    const container = document.querySelector('.parallax-wrapper');
    container?.addEventListener('scroll', scrollAnalyzer);

    return () => {
      container?.removeEventListener('scroll', scrollAnalyzer);
    };
  }, [scrollAnalyzer]);

  // ====================================================================================
  // Function to select background (case needed)
  // ====================================================================================
  function selectBg(page) {
    let bg;

    switch (page) {
      case layerPositions[ELayersGroup.INITIAL]:
      case layerPositions[ELayersGroup.EXCHANGES]:
        bg = bgLayerOneExchanges;
        break;
      case layerPositions[ELayersGroup.MARKETS]:
      case layerPositions[ELayersGroup.MARKETS] + 1:
      case layerPositions[ELayersGroup.MARKETS] + 2:
      case layerPositions[ELayersGroup.MARKETS] + 3:
        bg = null;
        break;
      case layerPositions[ELayersGroup.APIS]:
        bg = null;
        break;
      case layerPositions[ELayersGroup.DEPLOYMENT]:
        bg = bgLayerDeployment;
        break;
      case layerPositions[ELayersGroup.ECOSYSTEM]:
        bg = bgLayerEcosystem;
        break;
      default:
        bg = bgLayerOneExchanges;
        break;
    }

    return bg ? `url(${bg})` : 'none';
  }

  // ====================================================================================
  // Select the page to scroll
  // ====================================================================================
  const scrollTo = useCallback(
    (pageNumber) => {
      if (pageNumber <= layerPositions[ELayersGroup.ECOSYSTEM] && !scrolling) {
        setScrolling(true);
        parallaxRef?.current?.scrollTo(pageNumber);
        setCurrentLayer(pageNumber);
        // setScrollDirection(null);
      }
    },
    [setScrolling, setCurrentLayer, scrolling],
  );

  // ====================================================================================
  // Render all layers from configuration file
  // ====================================================================================
  function renderLayers(layers: TLayers[], paralax = false) {
    const layersWithClassesGrouped = layers
      .filter((e) => e.customClass)
      .reduce((acc, value: TLayers) => {
        if (value?.customClass) {
          if (!acc[value.customClass]) {
            acc[value.customClass] = [];
          }

          acc[value.customClass].push(value);
        }
        return acc;
      }, {});
    const layersWithNoClasses = layers.filter((e) => !e.customClass);

    function render(layer, key) {
      const { Component } = layer;
      const withLayers: number[] = [];

      if (layer.config?.offset) {
        withLayers.push(layer.config?.offset);
      }
      if (!layer.config?.offset && layer.config?.sticky) {
        for (let i = layer.config?.sticky?.start; i <= layer.config?.sticky?.end; i++) {
          withLayers.push(i);
        }
      }

      if (!paralax) {
        let valid = false;
        if (!withLayers?.includes(currentLayer) && scrollingMemo) {
          valid = false;
        }

        if (withLayers?.includes(currentLayer)) {
          valid = true;
        }

        return (
          <Component
            {...layer.props}
            scrolling={scrollingMemo}
            show={valid}
            withLayers={withLayers}
            currentLayer={currentLayer}
            scrollTo={scrollTo}
          />
        );
      }

      return (
        <ParallaxLayer key={key.toString()} {...layer.config} speed={0.8}>
          <Component
            {...layer.props}
            withLayers={withLayers}
            scrolling={scrollingMemo}
            currentLayer={currentLayer}
            scrollTo={scrollTo}
          />
        </ParallaxLayer>
      );
    }

    const renderDivClasses = Object.keys(layersWithClassesGrouped)?.map((key) => (
      <div key={key} className={s[key]}>
        {layersWithClassesGrouped[key]?.map((layer, key) => render(layer, key))}
      </div>
    ));

    const renderDefault = layersWithNoClasses?.map((layer, key) => render(layer, key));

    return [...renderDivClasses, ...renderDefault].map((component) => component);
  }

  // ====================================================================================
  // Control scroll status
  // ====================================================================================
  useEffect(() => {
    if (scaleScrollingMemo % 1 === 0 && scaleScrollingMemo !== 1) {
      setScrolling(false);
    }
  }, [scaleScrollingMemo, setScrolling]);

  // =================================================================================================
  // Check the scroll direction and force that scroll ** don't remove scrollAnalyzer from dependencies
  // =================================================================================================
  // useEffect(() => {
  //   if (scrollDirectionMemo === 'up' && !scrollingMemo) {
  //     if (currentLayerMemo > 0) {
  //       setScrolling(true);
  //       setTimeout(() => {
  //         scrollTo(currentLayerMemo - 1);
  //       }, 1000);
  //     }
  //   }
  //   if (scrollDirectionMemo === 'down' && !scrollingMemo) {
  //     if (currentLayerMemo < totalPages) {
  //       setScrolling(true);
  //       setTimeout(() => {
  //         scrollTo(currentLayerMemo + 1);
  //       }, 1000);
  //     }
  //   }
  // }, [
  //   scrollDirectionMemo,
  //   setScrolling,
  //   currentLayerMemo,
  //   scrollTo,
  //   scrollingMemo,
  //   scrollAnalyzer,
  // ]);

  function isMenuActive(cLayer: number, itemLayer: number, key: number, menuName: EMenus) {
    const menuSelected = menus?.[menuName];
    const nextKey = key + 1;
    const layerMenuSelected = menuSelected?.[nextKey]?.layer;

    if (nextKey < menuSelected.length) {
      if (layerMenuSelected) {
        return cLayer >= itemLayer && cLayer < menuSelected?.[nextKey]?.layer;
      }
    }

    return cLayer >= itemLayer;
  }

  const selectColorItemsMenu = (layer: number) => {
    if (layer === layerPositions[ELayersGroup.ECOSYSTEM]) {
      return EMenuColor.AlternativeOne;
    }
    if (layer === layerPositions[ELayersGroup.DEPLOYMENT]) {
      return EMenuColor.AlternativeTwo;
    }
    return null;
  };

  return (
    <>
      <Header
        isLandingPage
        headerColorMode={headerColorMode}
        textColorLandingpage={selectColorItemsMenu(currentLayer)}
      />

      <div className={s.wrapper}>
        <div
          className={s.backgroundLayer}
          style={{
            backgroundImage: selectBg(currentLayer),
          }}
        />

        {currentLayer > layerPositions[ELayersGroup.INITIAL] && <FullBar page={currentLayer} />}

        <div className={s.layersContainer}>
          {renderLayers([
            ...ExchangeLayer,
            ...BlueBoxLayer,
            ...MarketLayer,
            ...ApiLayer,
            ...SimulationLayer,
            ...DeploymentLayer,
          ])}
        </div>

        {currentLayer < layerPositions[ELayersGroup.ECOSYSTEM] && (
          <div className={s.bottomToTopFade}>
            <BaseButton
              theme="tertiary"
              className={`${s.scrollBtn} ${scrolling ? s.scrollBtnDisabled : {}}`}
              onClick={() => {
                scrollTo(currentLayer + 1);
                setScallingScrolling(0);
              }}>
              <span>Scroll</span>
              <BaseIcon className={s.buttonScroll} icon="arrow-down" size={24} />
            </BaseButton>
          </div>
        )}

        {currentLayer >= layerPositions[ELayersGroup.EXCHANGES] &&
          currentLayer < layerPositions[ELayersGroup.SIMULATION] && (
            <div className={s.boxActions}>
              {menus?.[EMenus.FIRST]?.map((item, key) => (
                <Fade top delay={500 * (key + 1)} key={key.toString()}>
                  <div
                    className={`${s.action} ${
                      isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                        ? s.activeAction
                        : {}
                    } 
                    ${
                      scrolling && !isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                        ? s.disabledAction
                        : {}
                    }
                    `}
                    onClick={() => (item?.layer ? scrollTo(item?.layer) : {})}>
                    <BaseButtonSquare
                      size="3"
                      theme={
                        isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                          ? 'flat-red'
                          : 'flat-dark-soft'
                      }
                      icon={item?.icon}
                      svgSize="1"
                    />
                    <span>{item?.title}</span>
                  </div>
                </Fade>
              ))}
            </div>
          )}

        {currentLayer >= layerPositions[ELayersGroup.SIMULATION] &&
          currentLayer < layerPositions[ELayersGroup.ECOSYSTEM] && (
            <div className={`${s.boxActions} ${s.boxCustomSize}`}>
              {menus?.[EMenus.SECOND]?.map((item, key) => (
                <Fade top delay={500 * (key + 1)} key={key.toString()}>
                  <div
                    className={`${s.action} ${
                      isMenuActive(currentLayer, item?.layer, key, EMenus.SECOND)
                        ? s.activeAction
                        : {}
                    }
                    ${
                      scrolling && !isMenuActive(currentLayer, item?.layer, key, EMenus.FIRST)
                        ? s.disabledAction
                        : {}
                    }
                    `}
                    onClick={() => (item?.layer ? scrollTo(item?.layer) : {})}>
                    <BaseButtonSquare
                      size="3"
                      theme={
                        isMenuActive(currentLayer, item?.layer, key, EMenus.SECOND)
                          ? 'flat-red'
                          : 'flat-dark-soft'
                      }
                      icon={item?.icon}
                      svgSize="1"
                    />
                    <span
                      className={
                        !isMenuActive(currentLayer, item?.layer, key, EMenus.SECOND) &&
                        currentLayer === layerPositions[ELayersGroup.DEPLOYMENT]
                          ? s.colorOposite
                          : ''
                      }>
                      {item?.title}
                    </span>
                  </div>
                </Fade>
              ))}
            </div>
          )}

        <Parallax
          ref={parallaxRef}
          pages={totalPages + 1}
          style={{ top: '0', left: '0', zIndex: 20 }}
          className="parallax-wrapper">
          {/* Render the first layer inside of parallax */}
          {renderLayers(InitialLayer, true)}
          {renderLayers(EcosystemLayer)}
        </Parallax>
      </div>
    </>
  );
};
