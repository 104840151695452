import 'react-tooltip/dist/react-tooltip.css';

import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import React, { useRef, useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import ReactDOMServer from 'react-dom/server';
import { TCanaryUserInviteProps } from './CanaryUserInvite.types';
import { Tooltip } from 'react-tooltip';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './CanaryUserInvite.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const CanaryUserInvite: React.FC<TCanaryUserInviteProps> = ({
  setSelectedMember,
  setAssetView,
  wrapperTop,
}) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const onValueChange: TBaseInputProps['onChange'] = ({ value }) => {
    if (typeof value === 'string') {
      value = value.replaceAll(' ', ';');
      setInputValue(value);
    }
  };

  const addClick = () => {
    if (validateEmail(inputValue)) {
      setSelectedMember({
        uuid: inputValue,
        name: inputValue,
        assignedAssets: {},
        isAggregator: false,
        isGridOperator: false,
        joined: false,
        isNewUser: true,
      });
      setAssetView(true);
      return;
    }
    setInputError('Email is not valid');
  };

  const openUploadDataSheetModal = () => {
    dispatch(openModal(EPredefinedModalIds.MODAL_SCM_UPLOAD_DATA_SHEET));
  };

  return (
    <div className={s.container}>
      {/* PH-1272-Disable user invite for SCM */}
      <div className={s.emailInvitation}>
        <div>
          <h3>Add Members</h3>
        </div>
        <div>
          <BaseInput
            inputHeight="1"
            className={s.input}
            theme="filled-gray"
            type="text"
            name="email"
            placeholder="Email"
            error={inputError}
            value={inputValue}
            onChange={onValueChange}
          />
          <div
            id="my-anchor-element"
            data-tooltip-html={ReactDOMServer.renderToString(
              <div className={s.tooltipContent}>
                <h3> 👈 Add member here</h3>
                <p>
                  This is to manually add a member. You can also upload an updated data sheet below.
                </p>
              </div>,
            )}>
            <BaseButton size="medium" theme="primary" onClick={addClick}>
              Add <BaseIcon icon="plus-circle" size={12} />
            </BaseButton>
          </div>
          <Tooltip
            anchorId="my-anchor-element"
            isOpen={true}
            place="right"
            position={{ x: 350, y: 255 - wrapperTop }}
          />
        </div>
      </div>
      <div className={s.uploadSheetLink} onClick={openUploadDataSheetModal}>
        <BaseIcon icon="file-upload" size={16} />
        Upload an updated community data sheet
      </div>
      <div className={s.line}></div>
    </div>
  );
};
