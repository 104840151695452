import { ArrayElement, EDashboard, EUserRoles } from 'src/typings/base-types';
import React, { useEffect } from 'react';
import { selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';

import { TCopyrightProps } from './Copyright.types';
import s from './Copyright.module.scss';
import { selectCommunityUser } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const Copyright: React.FC<TCopyrightProps> = () => {
  const dispatch = useAppDispatch();
  const userRole = useSelector(selectUserRole);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const communityUser = useSelector(selectCommunityUser);

  // Fixed::PH-1160-Admin dashboard is visible to not logged in user
  useEffect(() => {
    if (!isLoggedIn) {
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div className={s.container}>
      <footer>
        <span>
          Energy community managed by {communityUser} with digital tools powered by © Grid
          Singularity Pte Ltd.
        </span>
      </footer>
    </div>
  );
};
