import * as THREE from 'three';
import ThreeGlobe from 'three-globe';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import CanvasStats from 'src/utils/canvasStats';
import { getCanvasRelativePosition, toScreenPosition, calcCrow } from 'src/utils/canvasUtils';
import { compareOrdinaryJSONObjects } from 'src/utils/validation';
import { TTGScreenPos, TTGConnection } from './TeamGlobeController.types';
import { TTGCountry } from '../../TeamGlobe.types';
import { COUNTRY_FEATURE_COLLECTION } from 'src/constants/countries';
import hotspotTexture from 'src/assets/textures/hotspot.png';

class TeamGlobeController {
  public onHoveredObj: ((object: THREE.Mesh) => void) | null = null;
  public onUpdateHoveredObjPosition: ((position: TTGScreenPos) => void) | null = null;

  private container: HTMLDivElement; // Container of canvas
  private renderer: THREE.WebGLRenderer; // Renderer
  private scene: THREE.Scene; // Scene
  private camera: THREE.PerspectiveCamera; // Camera
  private controls: OrbitControls; // Orbit controller
  private width: number; // Container width
  private height: number; // Container height
  private aspect: number; // Camera aspect
  private ratio = window.devicePixelRatio; // Display ratio
  private raycaster = new THREE.Raycaster(); // Raycaster
  private mouse = new THREE.Vector2(); // Vector2 for mouse
  private currentHoveredObj: THREE.Object3D = null; // Previous hovered object by ray castering
  private disposed = false; // Flag of disposal status
  private requestID = 0; // Animation frame id
  private dragCounter = 0; // Drag counter for mouse action
  private model = new THREE.Object3D(); // Container of all meshes
  private hotspotGroup = new THREE.Group(); // Group of all hotspots
  private globe: THREE.Group | null = null; // Globe
  private lightColor = 'rgb(254, 255, 219)'; // Color for light
  private globeDiffuseColor = 'rgb(57, 57, 57)'; // Main diffuse color of globe
  private globeHexagonDiffuseColor = 'rgb(255, 255, 255)'; // Diffuse color of globe hexagon
  private globeAtmosColor = 'rgb(102, 238, 102)'; // Color of arc line
  private officeHotspotColor = 'rgb(102, 238, 102)'; // Color of hotspot
  private memberHotspotColor = 'rgb(66, 100, 243)'; // Color of hotspot
  private officeArcLineColor = 'rgb(102, 238, 102)'; // Color of arc line
  private memberArcLineColor = 'rgb(66, 100, 243)'; // Color of arc line
  private globeMaxSize = 0; // Max size of globe, it will be used for camera pos calculation

  private connections: TTGConnection[]; // Connection array defining arc lines on globe
  private countries: TTGCountry[]; // Country array defining hotspots on globe
  private headquarters: string[]; // Country array defining hotspots on globe
  private maxConnectionPerHotspot = 3; // Max connection count per hotspot

  // Dev mode
  private enableStats = false; // Enable stats view
  private stats: CanvasStats | null = null; // Stats

  constructor(container: HTMLDivElement, countries: TTGCountry[], headquarters: string[]) {
    this.container = container;
    this.countries = countries;
    this.headquarters = headquarters;
    this.width = container.clientWidth;
    this.height = container.clientHeight;
    this.aspect = this.width / this.height;

    this.connections = this.countries.flatMap((country, index) => {
      let connection: TTGConnection[] = [];

      for (let i = 0; i < this.maxConnectionPerHotspot; i++) {
        const randomId = Math.floor(Math.random() * this.countries.length);

        if (randomId !== index) {
          connection = [
            ...connection,
            {
              startCountry: country.code,
              endCountry: this.countries[randomId].code,
              startLat: country.lat,
              startLng: country.lng,
              endLat: this.countries[randomId].lat,
              endLng: this.countries[randomId].lng,
            },
          ];
        }
      }

      return connection;
    });

    // Initialize
    this.init();
  }

  /**
   * Initialize all setups
   */
  init = async (): Promise<void> => {
    this.rendererSetup();
    this.sceneSetup();
    await this.meshSetup();
    this.cameraSetup();
    this.lightSetup();
    this.eventSetup();

    // Init dev views
    if (this.enableStats) {
      this.stats = new CanvasStats(this.container);
    }

    this.tick();
  };

  /**
   * Setup renderer and append to dom
   */
  rendererSetup = (): void => {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(this.ratio);
    this.renderer.setSize(this.width, this.height);
    this.container.appendChild(this.renderer.domElement);
  };

  /**
   * Setup scene
   */
  sceneSetup = (): void => {
    this.scene = new THREE.Scene();
  };

  /**
   * Setup camera, add controls
   */
  cameraSetup = (): void => {
    this.camera = new THREE.PerspectiveCamera(35, this.aspect, 0.1, 1000);

    this.scene.add(this.camera);

    const camDist = this.globeMaxSize / this.aspect + 230;
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = -0.5;
    this.controls.enableDamping = true;
    this.controls.dynamicDampingFactor = 0.01;
    this.controls.enablePan = true;
    this.controls.minDistance = camDist;
    this.controls.maxDistance = camDist;
    this.controls.rotateSpeed = 0.8;
    this.controls.zoomSpeed = 1;
    this.controls.minPolarAngle = Math.PI / 3.5;
    this.controls.maxPolarAngle = Math.PI - Math.PI / 3;
  };

  /**
   * Setup all lights
   */
  lightSetup = (): void => {
    const spotLight1 = new THREE.SpotLight(this.lightColor, 1);
    spotLight1.position.set(-500, 250, -100);
    const spotLight2 = new THREE.SpotLight(this.lightColor, 0.4);
    spotLight2.position.set(500, -250, 100);

    this.camera.add(spotLight1);
    this.camera.add(spotLight2);
  };

  /**
   * Setup meshes
   */
  meshSetup = async (): Promise<void> => {
    // Add globe
    await this.addGlobe();

    // Add hotspots
    this.addHotspots();

    // Model transformation
    this.model.rotateZ(-Math.PI / 6);

    this.scene.add(this.model);
    // this.model.scale.set(1.0, 1.0, 1.0);
  };

  /**
   * Add globe
   */
  addGlobe = async (): Promise<void> => {
    await new Promise<void>((resolve, reject) => {
      this.globe = new ThreeGlobe({ animateIn: false })
        .atmosphereColor(this.globeAtmosColor)
        .atmosphereAltitude(0.1)
        .arcsData(this.connections) // arrow
        .arcColor((entity: any): string => {
          const isFromHeadquarter = this.headquarters.includes(entity.startCountry);

          return isFromHeadquarter ? this.officeArcLineColor : this.memberArcLineColor;
        })
        .arcAltitude((entity: any): number => {
          const alti =
            calcCrow(entity.startLat, entity.startLng, entity.endLat, entity.endLng) * 0.000025;

          return Math.max(alti, Math.random() * 0.1 + 0.1);
        })
        .arcStroke(() => 0.5)
        .arcDashLength(0.9)
        .arcDashGap(4)
        .arcDashAnimateTime(Math.random() * 1000 + 1000)
        .arcsTransitionDuration(Math.random() * 200 + 300)
        .arcDashInitialGap(() => Math.floor(Math.random() * 10))
        .hexPolygonsData(COUNTRY_FEATURE_COLLECTION.features) // points
        .hexPolygonResolution(3)
        .hexPolygonMargin(0.7)
        .hexPolygonAltitude(0.004)
        .hexPolygonColor(() => this.globeHexagonDiffuseColor)
        .onGlobeReady(() => {
          resolve();
        });
    });

    const globeMaterial = this.globe.globeMaterial();
    globeMaterial.color = new THREE.Color(this.globeDiffuseColor);
    globeMaterial.shininess = 0.7;

    // Calculate box3
    const bBox = new THREE.Box3().setFromObject(this.globe);
    const bBoxSize = new THREE.Vector3();
    bBox.getSize(bBoxSize);
    this.globeMaxSize = Math.max(...bBoxSize.toArray());

    this.model.add(this.globe);
  };

  /**
   * Add hotspots
   */
  addHotspots = (): void => {
    const hotspotGeo = new THREE.PlaneGeometry(5, 5);
    const hotspotMap = new THREE.TextureLoader().load(hotspotTexture);
    const memberHotspotMat = new THREE.MeshBasicMaterial({
      map: hotspotMap,
      color: new THREE.Color(this.memberHotspotColor),
      side: THREE.BackSide,
      transparent: true,
      depthWrite: false,
    });
    const officeHotspotMat = memberHotspotMat.clone();
    officeHotspotMat.color = new THREE.Color(this.officeHotspotColor);
    const memberHotspotMesh = new THREE.Mesh(hotspotGeo, memberHotspotMat);
    const officeHotspotMesh = new THREE.Mesh(hotspotGeo, officeHotspotMat);

    this.countries.forEach((country) => {
      const cartesianCoord = this.globe.getCoords(country.lat, country.lng, 0.005);

      const newHotSpot = this.headquarters.includes(country.code)
        ? officeHotspotMesh.clone()
        : memberHotspotMesh.clone();
      newHotSpot.position.set(cartesianCoord.x, cartesianCoord.y, cartesianCoord.z);
      newHotSpot.lookAt(0, 0, 0);
      newHotSpot.name = 'hotspot';
      newHotSpot.userData.pickable = true;
      newHotSpot.userData.countryCode = country.code;

      this.hotspotGroup.add(newHotSpot);
    });

    this.model.add(this.hotspotGroup);
  };

  /**
   * Setup event listener
   */
  eventSetup = (): void => {
    window.addEventListener('resize', this.onWindowResize, false);
    this.container.addEventListener('mousemove', this.onMouseMove, false);
    this.container.addEventListener('mousedown', this.onMouseDown, false);
    this.container.addEventListener('mouseup', this.onMouseUp, false);
  };

  /**
   * Dispose event listener
   */
  eventDispose = (): void => {
    window.removeEventListener('resize', this.onWindowResize, false);
    this.container.removeEventListener('mousemove', this.onMouseMove, false);
    this.container.removeEventListener('mousedown', this.onMouseDown, false);
    this.container.removeEventListener('mouseup', this.onMouseUp, false);
  };

  /**
   * Resize event listener
   */
  onWindowResize = (): void => {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.aspect = this.width / this.height;

    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.aspect;

    this.camera.updateProjectionMatrix();

    const camDist = this.globeMaxSize / this.aspect + 230;
    this.controls.minDistance = camDist;
    this.controls.maxDistance = camDist;
  };

  /**
   * Mousemove event listener
   */
  onMouseMove = (e: MouseEvent): void => {
    const pos = getCanvasRelativePosition(e, this.renderer.domElement, this.width, this.height); // Adjusted mouse position related to canvas view size
    this.mouse.x = (pos.x / this.width) * 2 - 1;
    this.mouse.y = -(pos.y / this.height) * 2 + 1;

    this.raycasterUpdate();

    // Update drag counter
    this.dragCounter++;
  };

  /**
   * Mousedown event listener
   */
  onMouseDown = (): void => {
    // Reset drag counter
    this.dragCounter = 0;
  };

  /**
   * Mouseup event listener
   */
  onMouseUp = (): void => {
    // If the mouse draging was short
    if (this.dragCounter < 5) {
      if (this.currentHoveredObj) {
        this.currentHoveredObj.scale.x = 1;
        this.currentHoveredObj.scale.y = 1;
        this.currentHoveredObj.scale.z = 1;
        this.currentHoveredObj = null;
      }
      if (this.onHoveredObj) this.onHoveredObj(null);
      this.controls.autoRotate = true;
    }
  };

  /**
   * Tick
   */
  tick = (): void => {
    // If disposed, remove all event listeners and frame update
    if (this.disposed) {
      window.cancelAnimationFrame(this.requestID);
      this.eventDispose();
      return;
    }

    if (this.currentHoveredObj) {
      const tooltipPos = toScreenPosition(
        this.currentHoveredObj,
        this.camera,
        this.width,
        this.height,
      );

      if (this.onUpdateHoveredObjPosition) this.onUpdateHoveredObjPosition(tooltipPos);
    }

    this.render();
    this.controls.update();
    this.stats?.tick(this.renderer);

    this.requestID = window.requestAnimationFrame(this.tick);
  };

  /**
   * Render per frame
   */
  render = (): void => {
    this.renderer.render(this.scene, this.camera);
  };

  /**
   * Update ray castering
   */
  raycasterUpdate = (): void => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    // Check intersecting obj
    const intersects = this.raycaster.intersectObjects(this.hotspotGroup.children, true);

    if (intersects.length === 0) {
      this.container.style.cursor = 'default';

      return;
    }

    this.container.style.cursor = 'pointer';
    const hoveredObj = intersects[0].object;

    if (!compareOrdinaryJSONObjects(this.currentHoveredObj, hoveredObj)) {
      if (this.currentHoveredObj) {
        this.currentHoveredObj.scale.x = 1;
        this.currentHoveredObj.scale.y = 1;
        this.currentHoveredObj.scale.z = 1;
        this.currentHoveredObj = null;
      }

      hoveredObj.scale.x = 1.5;
      hoveredObj.scale.y = 1.5;
      hoveredObj.scale.z = 1.5;
      this.currentHoveredObj = hoveredObj;
      this.controls.autoRotate = false;

      if (this.onHoveredObj) this.onHoveredObj(hoveredObj);
    }
  };

  /**
   * Dispose
   */
  dispose = (): void => {
    this.disposed = true;
  };
}

export { TeamGlobeController };
