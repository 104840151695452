import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';
import {
  selectReadOnly,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';

import { AdminDashboards } from 'src/components/AdminDashboards';
import { BottomRightCopyright } from 'src/components/BottomRightCopyright';
import { CanaryNetworkServiceProvider } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { ConfigurationManagementCentral } from 'src/components/ConfigurationManagementCentral';
import { Copyright } from 'src/components/Copyright';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EUserRoles } from 'src/typings/base-types';
import { InformationBox } from 'src/components/InformationBox';
import { MapLeftMenuBar } from 'src/components/MapLeftMenuBar';
import { MapRightSidebar } from 'src/components/MapRightSidebar';
import { MapSidebar } from 'src/components/MapSidebar';
import { MapSidebarSCM } from 'src/components/MapSidebarSCM';
import { ModalAddUser } from 'src/components/ModalAddUser';
import { ModalBuildCommunity } from 'src/components/ModalBuildCommunity';
import { ModalCanaryRequest } from 'src/components/ModalCanaryRequest';
import { ModalCollaborationDeploy } from 'src/components/ModalCollaborationDeploy';
import { ModalDeployProcess } from 'src/components/ModalDeployProcess';
import { ModalEmbedLegends } from 'src/components/ModalEmbedLegends/ModalEmbedLegends';
import { ModalMapHero } from 'src/components/ModalMapHero';
import { ModalMoveToCollabs } from 'src/components/ModalMoveToCollabs';
import { ModalOperateProcess } from 'src/components/ModalOperateProcess';
import { ModalRunSimCta } from 'src/components/ModalRunSimCta';
import { ModalSearchLocation } from 'src/components/ModalSearchLocation';
import { ModalUploadDataSheet } from 'src/components/ModalUploadDataSheet';
import PageEffectsContainer from '../../components/PageEffectsContainer/PageEffectsContainer';
import { SimulationProgressModal } from 'src/components/SimulationProgressModal';
import { TemplateDefault } from 'src/templates/TemplateDefault';
import { WorldMap } from 'src/components/WorldMap';
import { WorldMapContextProvider } from 'src/contexts/WorldMapContext';
import { WorldMapNavigation } from 'src/components/WorldMapNavigation';
import { openModal } from 'src/redux/modals/modals.slice';
import { routesConfig } from 'src/routes/routes.config';
import s from './SingularityMap.module.scss';
import { selectModalMapHeroDismissed } from 'src/redux/application/application.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setOnBoardingStep } from 'src/redux/application/application.slice';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationEffects } from 'src/hooks/useConfigurationEffects';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useInitialSCMCommunity } from 'src/hooks/useInitialSCMCommunity';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const SingularityMap: React.FC = () => {
  const { updateAppFlow } = useAppFlow();
  const dispatch = useAppDispatch();
  const phoenixMatch = useRouteMatch({
    path: routesConfig.singularityMap(),
    strict: true,
  });
  const scmMatch = useRouteMatch({
    path: routesConfig.scmMap(),
    strict: true,
  });

  useInitialSCMCommunity();

  useEffect(() => {
    const isSCMMap = scmMatch && scmMatch.isExact;
    const isPhoenixMap = phoenixMatch && phoenixMatch.isExact;
    if (isPhoenixMap || isSCMMap) {
      let appType = APP_FLOW.PHOENIX;
      if (isSCMMap) appType = APP_FLOW.SCM;
      updateAppFlow(appType);
      dispatch(setSCMFlow(appType === APP_FLOW.SCM));
    }
  }, [dispatch, updateAppFlow, scmMatch, phoenixMatch]);

  return (
    <WorldMapContextProvider>
      <SingularityMapContent />
    </WorldMapContextProvider>
  );
};

const SingularityMapContent: React.FC = () => {
  const dispatch = useAppDispatch();
  // This hook should be used only once per page
  useConfigurationEffects();
  const { graphSimulationProgressShow } = useConfigurationFlowState();
  const readOnly = useSelector(selectReadOnly);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isSCMFlow = useSelector(selectSCMFlow);
  const { isUserACommunityMember } = useIsUserACommunityMember();
  const isModalMapHeroDismissed = useSelector(selectModalMapHeroDismissed);
  const simulationStatus = useSelector(selectSimulationStatus);
  const showResultsTab = typeof simulationStatus !== 'undefined';
  const [showResultsTabOnClick, setShowResultsTabOnClick] = useState<boolean>(false);
  // state worldMapUuid is used to force re-render of WorldMap component
  // when user changes the map
  const [worldMapUuid, setWorldMapUuid] = useState<string>(v4());

  useEffect(() => {
    const seenOnboardingModal = localStorage.getItem('seenOnboardingModal');
    if (!seenOnboardingModal) {
      localStorage.setItem('seenOnboardingModal', 'true');
      dispatch(setOnBoardingStep(1));
    }
  }, [dispatch]);

  // const handleModalInfoClick = useCallback(() => {
  //   if (!showResultsTabOnClick) setShowResultsTabOnClick(true);
  // }, [showResultsTabOnClick]);

  // useEffect(() => {
  //   handleModalInfoClick();
  // }, [handleModalInfoClick]);

  // FIX::PH-1237-Login modal is missing when accessing the community builder and unlogged
  useEffect(() => {
    if (isSCMFlow && !isLoggedIn) {
      dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
    }
  }, [dispatch, isSCMFlow, isLoggedIn]);

  // useEffect for setting the worldMapUuid when the sidebarExpanded state changes

  return (
    <CanaryNetworkServiceProvider>
      <PageEffectsContainer>
        <TemplateDefault>
          {!isSCMFlow && <MapLeftMenuBar />}
          <AdminDashboards />
          <Switch>
            <Route>
              {isSCMFlow ? (
                <MapSidebarSCM
                  showResultsTabOnClick={showResultsTabOnClick}
                  hideResultsTab={(status) => {
                    setShowResultsTabOnClick(status);
                  }}
                />
              ) : (
                <MapSidebar />
              )}
              {!isSCMFlow && <WorldMapNavigation className={s.mapNavigation} />}
              <WorldMap className={s.worldMap} />
              {!isSCMFlow && <MapRightSidebar />}
              <ModalSearchLocation />
              {!isSCMFlow && <ModalEmbedLegends />}
              {!isModalMapHeroDismissed && <ModalMapHero />}

              {!readOnly && <ModalRunSimCta />}
              {/* <ModalCanaryWaitlist /> */}
              <ConfigurationManagementCentral>
                {({ handleSettingsDataSave }) => {
                  return <ModalCanaryRequest onSave={handleSettingsDataSave} />;
                }}
              </ConfigurationManagementCentral>
              <ModalCollaborationDeploy />
              {isSCMFlow && (
                <>
                  <ModalUploadDataSheet />
                  <ModalBuildCommunity />
                </>
              )}
              <ModalDeployProcess />
              <ModalAddUser />
              <ModalOperateProcess />

              <ModalMoveToCollabs />
              {graphSimulationProgressShow && !isUserACommunityMember && (
                <SimulationProgressModal />
              )}
            </Route>
          </Switch>
          {isSCMFlow && isUserACommunityMember && <Copyright />}
          {isSCMFlow && !isUserACommunityMember && <BottomRightCopyright />}
          {isSCMFlow && isUserACommunityMember && showResultsTab && !showResultsTabOnClick && (
            <InformationBox
              onClickAction={() => {
                setShowResultsTabOnClick(true);
              }}
            />
          )}
        </TemplateDefault>
      </PageEffectsContainer>
    </CanaryNetworkServiceProvider>
  );
};
