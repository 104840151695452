import { Header } from 'src/components/Header';
import React from 'react';
import { SCMHeaderSteps } from 'src/components/SCMHeaderSteps';
import { TTemplateDefaultProps } from './TemplateDefault.types';
import classnames from 'classnames';
import { matchPath } from 'react-router-dom';
import { routesConfig } from 'src/routes/routes.config';
import s from './TemplateDefault.module.scss';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSelector } from 'react-redux';

export const TemplateDefault: React.FC<TTemplateDefaultProps> = ({ className, children }) => {
  const isSCMFlow = useSelector(selectSCMFlow);
  const showSCMHeader =
    isSCMFlow &&
    !!matchPath(location.pathname, {
      path: [routesConfig.singularityMapCreate(), routesConfig.singularityMapResults()],
    });

  const { isUserACommunityMember } = useIsUserACommunityMember();

  return (
    <div className={classnames(s.container, className)}>
      <Header />
      {showSCMHeader && !isUserACommunityMember && <SCMHeaderSteps />}
      {children}
    </div>
  );
};
