import {
  ConfigType,
  useLaunchSimulationMutation,
  usePauseSimulationMutation,
  useResumeSimulationMutation,
  useStopSimulationMutation,
} from 'src/graphql';
import {
  EConfigurationFlowState,
  useConfigurationFlowState,
} from 'src/hooks/useConfigurationFlowState';
import {
  resetSimulationResults,
  setActiveConfigurationJobUuid,
  setFrontendSimulationStatus,
  setSimulationStatus,
} from 'src/redux/configuration/configuration.slice';
import {
  selectConfigType,
  selectFrontendSimulationStatus,
  selectHasUserAllowedResultsLoss,
  selectReadOnly,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { setActiveSCMStep, setSCMsidebarTab } from 'src/redux/scm/scm.slice';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { EActiveTabState } from 'src/components/MapSidebarSCM/components/MapSidebarResults';
import { EPredefinedModalIds } from 'src/constants/modals';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TSimResultsStatus } from 'src/typings/base-types';
import { openModal } from 'src/redux/modals/modals.slice';
import { selectIsLoggedIn } from 'src/redux/auth/auth.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export type TRunButtonOptions = {
  title: string;
  icon: TIconNames;
  disabled?: boolean;
  onClick?:
    | TuseSimulationButtons['simulationLaunch']
    | TuseSimulationButtons['simulationPause']
    | TuseSimulationButtons['simulationResume'];
};

export type TStopButtonOptions = {
  icon: TIconNames;
  disabled?: boolean;
  onClick?: TuseSimulationButtons['simulationStop'];
};

export type TuseSimulationButtonsProps = {
  jobUuid: string | undefined;
  configurationUuid: string | undefined;
  simulationStatus?: TSimResultsStatus;
};

export type TuseSimulationButtons = {
  runButtonState: TRunButtonState;
  runButtonOptions: TRunButtonOptions;
  stopButtonState: TStopButtonState;
  stopButtonOptions: TStopButtonOptions;
  simulationLaunch: (uuid?: string | undefined) => Promise<string | null>;
  simulationPause: (uuid?: string | undefined) => Promise<string | null>;
  simulationResume: (uuid?: string | undefined) => Promise<string | null>;
  simulationStop: (uuid?: string | undefined) => Promise<string | null>;
};
export type TRunButtonState =
  | 'run'
  | 'rerun'
  | 'resume'
  | 'pause'
  | 'processing'
  | 'loading'
  | 'stopping'
  | 'disabled';
export type TStopButtonState = 'stop' | 'disabled';

const failureStates = ['error', 'failed', 'timed-out'];
const startingStates = ['queued', 'initializing', 'started'];
const overridingStates = {
  loading: ['running', ...startingStates, ...failureStates],
  initializing: ['started', 'running'],
  queued: ['started', 'running', ...failureStates],
  started: ['paused', 'stopped', 'stopping', 'running', ...failureStates],
  paused: ['stopped', 'stopping', 'running', ...failureStates],
  stopped: ['running', ...startingStates, ...failureStates],
  stopping: ['stopped', 'paused', ...failureStates],
  running: ['paused', 'stopped', 'stopping', 'finished', ...failureStates],
  finished: [...startingStates, ...failureStates],
  error: startingStates,
  failed: startingStates,
  'timed-out': startingStates,
};

/**
 * TODO: Describe logic of this hook (especially overridingStates)
 */
export function useSimulationButtons(options: TuseSimulationButtonsProps): TuseSimulationButtons {
  const dispatch = useAppDispatch();
  const [runButtonState, setRunButtonState] = useState<TRunButtonState>('loading');
  const [stopButtonState, stopRunButtonState] = useState<TStopButtonState>('disabled');
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectedSimulationStatus = useSelector(selectSimulationStatus);
  const selectedFrontendSimulationStatus = useSelector(selectFrontendSimulationStatus);
  const [launchSimulation] = useLaunchSimulationMutation();
  const [pauseSimulation] = usePauseSimulationMutation();
  const [resumeSimulation] = useResumeSimulationMutation();
  const [stopSimulation] = useStopSimulationMutation();
  const { triggerResultsLossAlert } = useContext(ApplicationContext);
  const [simFontendStatus, setSimFrondtendStatus] = useState(options.simulationStatus);
  const isActiveSimulation = !options.simulationStatus;
  const { configurationFlowState } = useConfigurationFlowState();
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);
  const hasUserAllowedResultsLoss = useSelector(selectHasUserAllowedResultsLoss);
  const isCN = configType === ConfigType.CanaryNetwork;
  const isSCMFlow = useSelector(selectSCMFlow);

  const simulationStatus = useMemo(() => {
    if (options.simulationStatus) return options.simulationStatus;
    return selectedSimulationStatus;
  }, [options.simulationStatus, selectedSimulationStatus]);

  const frontendSimulationStatus = useMemo(() => {
    if (!isActiveSimulation) return simFontendStatus;
    return selectedFrontendSimulationStatus;
  }, [isActiveSimulation, simFontendStatus, selectedFrontendSimulationStatus]);

  const updateFrontendSimStatus = useCallback(
    (status: TSimResultsStatus) => {
      if (options.simulationStatus) {
        setSimFrondtendStatus(status);
      } else {
        dispatch(setFrontendSimulationStatus(status));
      }
    },
    [dispatch, options.simulationStatus],
  );

  useEffect(() => {
    if (simulationStatus && !frontendSimulationStatus) {
      updateFrontendSimStatus(simulationStatus);
    }
  }, [frontendSimulationStatus, simulationStatus, updateFrontendSimStatus]);

  useEffect(() => {
    const isOfflineBuildMode = configurationFlowState === EConfigurationFlowState.OfflineBuildMode;
    let state = frontendSimulationStatus;

    if (
      frontendSimulationStatus &&
      simulationStatus &&
      overridingStates[frontendSimulationStatus]?.includes(simulationStatus)
    ) {
      state = simulationStatus;
      updateFrontendSimStatus(simulationStatus);
    }

    switch (state) {
      case 'paused':
        setRunButtonState('resume');
        stopRunButtonState('stop');
        break;
      case 'stopped':
      case 'error':
      case 'failed':
      case 'timed-out':
      case 'finished':
        setRunButtonState('rerun');
        stopRunButtonState('disabled');
        break;
      case 'started':
      case 'running':
        setRunButtonState('disabled');
        stopRunButtonState('stop');
        break;
      case 'queued':

      case 'initializing':
        setRunButtonState('processing');
        stopRunButtonState('disabled');
        break;
      case 'loading':
        setRunButtonState('loading');
        stopRunButtonState('disabled');
        break;
      case 'stopping':
        setRunButtonState('stopping');
        stopRunButtonState('disabled');
        break;
      default:
        // New Configuration does not have jobUuid so we can allow to run
        if ((options.configurationUuid && !options.jobUuid) || !isLoggedIn || isOfflineBuildMode) {
          setRunButtonState('run');
          stopRunButtonState('disabled');
        } else {
          setRunButtonState('loading');
          stopRunButtonState('disabled');
        }
    }
  }, [
    options.configurationUuid,
    frontendSimulationStatus,
    isLoggedIn,
    options.jobUuid,
    simulationStatus,
    updateFrontendSimStatus,
    configurationFlowState,
  ]);

  const simulationLaunch = useCallback(
    async (uuid?: string) => {
      if (runButtonState === 'rerun' && !hasUserAllowedResultsLoss) {
        await triggerResultsLossAlert();
      }
      if (isSCMFlow) {
        dispatch(setActiveSCMStep(2));
        dispatch(setSCMsidebarTab(EActiveTabState.CollaborationSettings));
      }
      if (uuid || options.configurationUuid) {
        // Reset results only if hook is used to currently opened simulation
        if (isActiveSimulation && !isCN) {
          dispatch(resetSimulationResults());
        }
        updateFrontendSimStatus('initializing');
        const { data } = await launchSimulation({
          variables: {
            uuid: (uuid || options.configurationUuid)!,
          },
        });
        if (isActiveSimulation && data?.launchSimulation?.job) {
          dispatch(setActiveConfigurationJobUuid(data?.launchSimulation?.job));
          return data?.launchSimulation?.job;
        }
      } else if (!isLoggedIn) {
        dispatch(openModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
      }
      return null;
    },
    [
      isCN,
      isSCMFlow,
      runButtonState,
      options.configurationUuid,
      isLoggedIn,
      triggerResultsLossAlert,
      isActiveSimulation,
      updateFrontendSimStatus,
      launchSimulation,
      dispatch,
      hasUserAllowedResultsLoss,
    ],
  );

  const simulationPause = useCallback(
    async (uuid?: string) => {
      if (uuid || options.jobUuid) {
        updateFrontendSimStatus('stopping');
        const { data } = await pauseSimulation({
          variables: {
            jobId: (uuid || options.jobUuid)!,
          },
        });
        if (data?.pauseSimulation) {
          return data.pauseSimulation;
        }
      }
      return null;
    },
    [options.jobUuid, pauseSimulation, updateFrontendSimStatus],
  );

  const simulationResume = useCallback(
    async (uuid?: string) => {
      if (uuid || options.jobUuid) {
        updateFrontendSimStatus('initializing');
        const { data } = await resumeSimulation({
          variables: {
            jobId: (uuid || options.jobUuid)!,
          },
        });
        if (data?.resumeSimulation) {
          return data.resumeSimulation;
        }
      }
      return null;
    },
    [options.jobUuid, resumeSimulation, updateFrontendSimStatus],
  );

  const simulationStop = useCallback(
    async (uuid?: string) => {
      if (uuid || options.jobUuid) {
        updateFrontendSimStatus('stopping');
        dispatch(setSimulationStatus('stopping'));
        const { data } = await stopSimulation({
          variables: {
            jobId: (uuid || options.jobUuid)!,
          },
        });
        if (data?.stopSimulation) {
          return data.stopSimulation;
        }
      }
      return null;
    },
    [dispatch, options.jobUuid, stopSimulation, updateFrontendSimStatus],
  );

  const runButtonOptions = useMemo<TRunButtonOptions>(() => {
    switch (runButtonState) {
      case 'run':
        return {
          onClick: simulationLaunch,
          title: 'Run Simulation',
          icon: 'play',
          disabled: readOnly,
        };
      case 'rerun':
        return {
          onClick: simulationLaunch,
          title: 'Rerun Simulation',
          icon: 'refresh',
          disabled: readOnly,
        };
      case 'resume':
        return {
          onClick: simulationResume,
          title: 'Resume Simulation',
          icon: 'play',
          disabled: readOnly,
        };
      case 'pause':
        return {
          onClick: simulationPause,
          title: 'Pause Simulation',
          icon: 'pause',
          disabled: readOnly,
        };
      case 'processing':
        return {
          title: 'Initializing',
          icon: 'spinner',
          disabled: true,
        };
      case 'loading':
        return {
          title: 'Loading',
          icon: 'spinner',
          disabled: true,
        };
      case 'stopping':
        return {
          title: 'Stopping',
          icon: 'spinner',
          disabled: true,
        };
      case 'disabled':
        return {
          title: 'Simulation Running',
          icon: 'spinner',
          disabled: true,
        };
    }
  }, [readOnly, runButtonState, simulationLaunch, simulationPause, simulationResume]);

  const stopButtonOptions = useMemo<TStopButtonOptions>(() => {
    switch (stopButtonState) {
      case 'stop':
        return {
          icon: 'stop',
          onClick: simulationStop,
          disabled: readOnly,
        };
      case 'disabled':
        return {
          icon: 'stop',
          disabled: true,
        };
    }
  }, [readOnly, simulationStop, stopButtonState]);

  return {
    runButtonState,
    stopButtonState,
    runButtonOptions,
    stopButtonOptions,
    simulationLaunch,
    simulationPause,
    simulationResume,
    simulationStop,
  };
}
