import { AssetPreviewData, UserData } from './static';
import { EProfileTab, TUserProfileProps } from './UserProfile.types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  TConfigurationState,
  setSelectedAssetUuid,
} from 'src/redux/configuration/configuration.slice';
import {
  selectAssetValuesForUuid,
  selectAssets,
  selectAssetsValues,
  selectCommunityAsset,
} from 'src/redux/configuration/configuration.selectors';

import { AssetsView } from '../MapSidebarCanary/components/AssetsView';
import { LibraryOutput } from 'src/graphql';
import { TAssetType } from 'src/typings/base-types';
import { TabNav } from 'src/components/TabNav';
import { UserAssetPreview } from '../UserAssetPreview';
import WorldMapContext from 'src/contexts/WorldMapContext';
import s from './UserProfile.module.scss';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { selectTokenAuth } from 'src/redux/auth/auth.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const UserProfile: React.FC<TUserProfileProps> = ({
  onAssetValuesSave,
  onAssetValuesSavePromise,
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectTokenAuth);
  const [activeTab, setActiveTab] = useState<EProfileTab>(EProfileTab.Profile);
  const { mapService } = useContext(WorldMapContext);
  const userInfo = useSelector(selectTokenAuth);
  const scmMembers = useSelector(selectSCMHomeDetails);
  const currentItem = useMemo(() => scmMembers.find((item) => item.email === userInfo?.email), [
    userInfo?.email,
    scmMembers,
  ]);

  const usersAssetValues = useSelector(selectAssetValuesForUuid(currentItem?.uuid || ''));
  const assetsValues = useSelector(selectAssetsValues);
  const assets = useSelector(selectAssets);

  useEffect(() => {
    const assetCordinates = {
      lng: usersAssetValues?.geoTagLocation?.[0],
      lat: usersAssetValues?.geoTagLocation?.[1],
    };

    mapService?.flyTo(assetCordinates, { specificZoom: 18 });
  }, [usersAssetValues, mapService]);

  const getChildren = (
    parentUuid: string,
    assets: TConfigurationState['assets'],
    assetsValues: TConfigurationState['assetsValues'],
  ) => {
    const childrenUuids = Object.values(assets).filter((item) => item.parentUuid == parentUuid);
    return childrenUuids.map((item) => ({
      ...assetsValues[item.uuid],
      ...item,
    }));
  };

  const assetsData = useMemo(() => {
    if (currentItem) {
      return {
        ...usersAssetValues,
        children: getChildren(currentItem.uuid, assets, assetsValues),
        data: {
          type: 'Area',
          ...usersAssetValues,
          owners: [
            {
              avatarUrl: userInfo?.profilePicture,
              id: userInfo?.username,
              name: userInfo?.name,
              email: userInfo?.email,
              isAggregator: true,
              isGridOperator: false,
              isAdmin: userInfo?.isAdmin,
            },
          ],
        },
      };
    }
  }, [userInfo, usersAssetValues, currentItem, assetsValues, assets]);

  /*
  const assetValues = useSelector(selectAssetValuesForUuid(UserData.data?.parentUuid));

  useEffect(() => {
    const assetCordinates = {
      lng: assetValues?.geoTagLocation?.[0],
      lat: assetValues?.geoTagLocation?.[1],
    };
    console.log('assetCordinates', assetCordinates);

    mapService?.flyTo(assetCordinates, { specificZoom: 18 });
  }, [assetValues, mapService]);
*/
  if (!assetsData) return null;

  const renderTabsContent = (tab) => {
    switch (tab) {
      case EProfileTab.Profile:
        return <AssetsView assetsList={assetsData} />;
      case EProfileTab.Home:
        return (
          <UserAssetPreview
            selectedItem={assetsData}
            selectedAsset={undefined}
            handleSetMode={() => console.log('setMode')}
            setSelectedAsset={() => console.log('setSelectedAsset')}
            menuActive={false}
          />
        );
    }
  };

  return <div className={s.container}>{renderTabsContent(activeTab)}</div>;
};
