import React, { useCallback, useState } from 'react';
import { TOptionsType, TOrientationBasicProps } from './OrientationBasic.types';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseList } from 'src/components/BaseList';
import { EOrientationParam } from 'src/components/CustomPV/components/Orientation/Orientation.types';
import classnames from 'classnames';
import s from './OrientationBasic.module.scss';

const options: TOptionsType[] = [
  {
    icon: 'flat-pv',
    label: 'Flat',
    value: 0,
    description: 'No inclination',
  },
  {
    icon: 'inclinated-pv',
    label: 'Tilted',
    value: 45,
    description: '45 degrees',
  },
  {
    icon: 'facade-pv',
    label: 'Facade',
    value: 90,
    description: '90 degrees',
  },
];

export const OrientationBasic: React.FC<TOrientationBasicProps> = ({
  theme,
  className,
  orientationParam,
  setOrientationParam,
}) => {
  const [activeItem, setActiveItem] = useState(() => {
    return options.find((item) => item.value == orientationParam[EOrientationParam.TILT]);
  });

  const onItemClick = useCallback(
    (item: TOptionsType) => {
      setActiveItem(item);
      setOrientationParam({ ...orientationParam, [EOrientationParam.TILT]: item.value });
    },
    [orientationParam, setOrientationParam],
  );

  const isItemActive = ({ value }: TOptionsType) => activeItem?.value === value;

  return (
    <div
      className={classnames(s.orientationBasic, className, {
        [s.light]: theme === 'light',
      })}>
      <BaseList width="100%" className={s.list}>
        {options.map((item, index) => (
          <div
            key={index}
            className={classnames(s.item, {
              [s.itemActive]: isItemActive(item),
              [s.menuItemBottomBorder]: index !== options.length - 1,
            })}
            onClick={() => {
              onItemClick(item);
            }}>
            <div className={s.optionContainer}>
              <BaseIcon icon={item?.icon} size={42} />
              <label htmlFor={item.label}>
                <div className={s.label}>{item.label}</div>
                <div className={s.description}>{item.description}</div>
              </label>
            </div>
            <input
              type="radio"
              id={item.label}
              name="filter"
              defaultChecked={item?.value === activeItem?.value}
            />
          </div>
        ))}
      </BaseList>
    </div>
  );
};
