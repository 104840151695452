import { TStylesScrolling, detectiOS, layerPositions } from 'src/pages/MarketDesignMobile';

import { BaseIcon } from 'src/components/BaseIcon';
import { EPredefinedModalIds } from 'src/constants/modals';
import React from 'react';
import { TProps } from './InitialLayer.types';
import classnames from 'classnames';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './InitialLayer.module.scss';
import { useAppDispatch } from 'src/redux/store';
import useMarketDesignContext from 'src/hooks/useMarketDesignContext';

const VIDEO_URL = 'https://phoenix-dev-assets.s3.eu-central-1.amazonaws.com/low-bitrate.mp4';

const Logo = React.lazy(() => import('src/components/LandingPage/LazyImages/Logo'));
const IphoneVideo = React.lazy(() => import('src/components/LandingPage/LazyImages/IphoneVideo'));

export const SelectedTitle: React.FC<TProps> = ({ currentLayer, scaleScrolling = 0 }) => {
  const dispatch = useAppDispatch();
  const { isEcoSystemChildView } = useMarketDesignContext();
  const activedTitle = {
    minSize: 5.0,
  };

  const ItemOneValidation = currentLayer < 21;

  // const ItemTwoValidation =
  //   currentLayer >= layerPositions.simulation &&
  //   (!layerPositions?.ecosystem || currentLayer < layerPositions.ecosystem);

  const ItemTwoValidation = currentLayer >= 21 && (!layerPositions?.ecosystem || currentLayer < 31);

  const ItemThreeValidation = currentLayer >= 32;

  // const blackText = currentLayer === 16;
  const blackText = currentLayer === 32;

  return (
    <>
      <div
        className={classnames(s.selectedTextContainer, {
          [s.displayNone]: isEcoSystemChildView,
        })}>
        {ItemOneValidation && (
          <div
            className={`${s.headingText} ${ItemOneValidation && blackText && s.blackText}`}
            style={scrollingFontSize(
              scaleScrolling,
              currentLayer,
              ItemOneValidation
                ? activedTitle
                : {
                    darker: true,
                  },
            )}>
            <>
              We Build
              <br /> Grid-Aware
              <br /> Energy Markets
            </>
          </div>
        )}
        {ItemTwoValidation && (
          <div
            className={`${s.headingText} ${ItemTwoValidation && blackText && s.blackText}`}
            style={scrollingFontSize(
              scaleScrolling,
              currentLayer,
              ItemTwoValidation
                ? activedTitle
                : {
                    darker: true,
                  },
            )}>
            <>
              Enabling
              <br /> Continuous
              <br /> Innovation
            </>
          </div>
        )}
        {ItemThreeValidation && (
          <div
            className={`${s.headingText} ${ItemThreeValidation && blackText && s.blackText}`}
            style={scrollingFontSize(
              scaleScrolling,
              currentLayer,
              ItemThreeValidation
                ? activedTitle
                : {
                    darker: true,
                  },
            )}>
            <>
              For An Open
              <br /> Ecosystem
            </>
          </div>
        )}
      </div>
      {currentLayer > 0 && !isEcoSystemChildView && (
        <>
          <div className={s.arrowAction}>
            <BaseIcon
              className={s.arrowIcon}
              icon="chevron-down"
              size={24}
              onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_MARKET_DESIGN_OVERLAY))}
            />
          </div>
        </>
      )}
    </>
  );
};

export const MainTitle: React.FC<TProps> = ({ currentLayer, scaleScrolling = 0, scrollTo }) => {
  const dispatch = useAppDispatch();
  const activedTitle = {
    minSize: 6.0,
  };

  return (
    <>
      <div
        className={classnames(s.textContainer, {
          [s.textContainerClipped]: currentLayer > 0,
        })}>
        <div
          onClick={() => (layerPositions?.exchanges ? scrollTo(layerPositions.exchanges) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer < layerPositions.simulation
              ? activedTitle
              : {
                  darker: true,
                },
          )}>
          We Build
          <br /> Grid-Aware
          <br /> Energy Markets
        </div>
        <div
          onClick={() => (layerPositions?.simulation ? scrollTo(layerPositions.simulation) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer >= layerPositions.simulation &&
              (!layerPositions?.ecosystem || currentLayer < layerPositions.ecosystem)
              ? activedTitle
              : {
                  darker: true,
                },
          )}>
          Enabling
          <br /> Continuous
          <br /> Innovation
        </div>
        <div
          onClick={() => (layerPositions?.ecosystem ? scrollTo(layerPositions.ecosystem) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer >= layerPositions.ecosystem
              ? activedTitle
              : {
                  darker: true,
                  minBright: 0.3,
                },
          )}>
          For An Open
          <br /> Ecosystem
        </div>
      </div>
      {currentLayer > 0 && (
        <>
          <div className={s.arrowAction}>
            <BaseIcon
              className={s.arrowIcon}
              icon="chevron-down"
              size={24}
              onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_MARKET_DESIGN_OVERLAY))}
            />
          </div>
        </>
      )}
    </>
  );
};

export const InitialLayer: React.FC<TProps> = ({ scrollTo }) => {
  const isOS = detectiOS();
  const { isLandscape } = useMarketDesignContext();

  return (
    <>
      <div
        className={classnames(s.content, {
          [s.landscapeContent]: isLandscape,
        })}>
        <div className={s.bgFractuals}>
          {isOS ? (
            <React.Suspense fallback={<span>Loading...</span>}>
              <IphoneVideo />
            </React.Suspense>
          ) : (
            <video loop autoPlay muted playsInline>
              <source src={VIDEO_URL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <React.Suspense fallback={<span>Loading...</span>}>
          <Logo className={s.logoBadge} />
        </React.Suspense>
      </div>
      <div className={s.scrollButtonContainer}>
        <div className={s.quoteText}>
          <div className={s.text}>
            Look deep into nature, and then you will <br />
            understand everything better.
          </div>
          <div className={s.bold}>Albert Einstein</div>
        </div>
      </div>
    </>
  );
};

function scrollingFontSize(scrollUntilNextLayer: number, layer: number, styles?: TStylesScrolling) {
  const maxFontSize = styles?.maxSize || 5.75;
  const minFontSize = styles?.minSize || 3.75;
  const darkerBright = styles?.darker ? 1 - scrollUntilNextLayer : 1;
  const minBright = styles?.minBright || 0.4;
  const unit = 'vh';

  if (layer === 0) {
    const calcBetweenScrollAndFonSize = scrollUntilNextLayer * maxFontSize;
    const fontSize = maxFontSize - calcBetweenScrollAndFonSize;

    return {
      fontSize: `${fontSize <= minFontSize ? minFontSize : fontSize}${unit}`,
      filter: `brightness(${darkerBright <= minBright ? minBright : darkerBright})`,
    };
  }

  // if (layer === 1) {
  //   const calcBetweenScrollAndFonSize = scrollUntilNextLayer * minFontSize;
  //   const fontSize = minFontSize - calcBetweenScrollAndFonSize;

  //   return {
  //     fontSize: `${fontSize <= minFontSize ? minFontSize : fontSize}vh`,
  //     filter: `brightness(${darkerBright <= minBright ? minBright : darkerBright})`,
  //   };
  // }

  if (layer > 0) {
    return {
      fontSize: `${minFontSize - 2}${unit}`,
      filter: `brightness(${styles?.darker ? minBright : 1})`,
    };
  }

  return { fontSize: `${maxFontSize}${unit}` };
}

export const OverLayLayer: React.FC<TProps> = ({ currentLayer, scaleScrolling = 0, scrollTo }) => {
  const dispatch = useAppDispatch();
  const activedTitle = {
    minSize: 6.0,
  };

  const renderIcon = () => (
    <div className={s.arrowAction}>
      <BaseIcon
        className={s.arrowIcon}
        icon="chevron-down"
        size={24}
        onClick={() => dispatch(openModal(EPredefinedModalIds.MODAL_MARKET_DESIGN_OVERLAY))}
      />
    </div>
  );

  return (
    <div className={classnames(s.overlayLayer)}>
      <div className={classnames(s.textContainer)}>
        <div
          onClick={() => (layerPositions?.exchanges ? scrollTo(layerPositions.exchanges) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer < layerPositions.simulation
              ? activedTitle
              : {
                  darker: true,
                },
          )}>
          We Build
          <br /> Grid-Aware
          <br /> Energy Markets
          {currentLayer <= 5 && renderIcon()}
        </div>
        <div
          onClick={() => (layerPositions?.simulation ? scrollTo(layerPositions.simulation) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer >= layerPositions.simulation &&
              (!layerPositions?.ecosystem || currentLayer < layerPositions.ecosystem)
              ? activedTitle
              : {
                  darker: true,
                },
          )}>
          Enabling
          <br /> Continuous
          <br /> Innovation
          {currentLayer > 5 && currentLayer <= 21 && renderIcon()}
        </div>
        <div
          onClick={() => (layerPositions?.ecosystem ? scrollTo(layerPositions.ecosystem) : {})}
          className={s.headingText}
          style={scrollingFontSize(
            scaleScrolling,
            currentLayer,
            currentLayer >= layerPositions.ecosystem
              ? activedTitle
              : {
                  darker: true,
                  minBright: 0.3,
                },
          )}>
          For An Open
          <br /> Ecosystem
          {currentLayer >= 32 && renderIcon()}
        </div>
      </div>
    </div>
  );
};
