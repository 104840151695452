import * as d3 from 'd3';

import React, { useCallback, useEffect } from 'react';

import { TNetworkGraphProps } from './NetworkGraph.types';
import { data } from './NetworkGraph.config';
import s from './NetworkGraph.module.scss';

export const NetworkGraph: React.FC<TNetworkGraphProps> = ({ className }) => {
  const chart = useCallback(() => {
    const height = 500;
    const width = 800;

    const groupData = [
      { name: 'Manager', shape: 'circle', radius: 2 },
      { name: 'people', shape: 'circle', radius: 2 },
      { name: 'business', shape: 'rectangle', radius: 0.5 },
    ];
    const getRadius = (group) => {
      let res;
      switch (group) {
        case 'Manager':
          res = groupData[0].radius;
          break;
        case 'people':
          res = groupData[1].radius;
          break;
        case 'business':
          res = groupData[2].radius;
          break;
      }
      return res * forceVariables.nodeRadius;
    };

    function idCompress(id) {
      let compressed_id = id.split(' ').join('');
      return compressed_id;
    }

    const forceVariables = {
      nodeRadius: 15,
      nodeDistance: 100,
      chargeStr: -500,
      xDenom: 3,
      xStr: 0.0,
      yDenom: 0.9,
      yStr: 0.0,
    };
    const links = data.links.map((d) => Object.create(d));
    const nodes = data.nodes.map((d) => Object.create(d));

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .distance(forceVariables.nodeDistance)
          // @ts-ignore
          .id((d) => d.id),
      )
      .force('charge', d3.forceManyBody().strength(forceVariables.chargeStr))
      .force(
        'collide',
        // @ts-ignore
        d3.forceCollide((d) => getRadius(d.group)),
      ) //sets the collision points of the various nodes
      .force('center', d3.forceCenter(width / 2, height / 2)) //centers the diagram in the middle of the svg
      .force('x', d3.forceX(width / forceVariables.xDenom).strength(forceVariables.xStr))
      .force('y', d3.forceY(height * forceVariables.yDenom).strength(forceVariables.yStr));

    const svg = d3
      .select('.networkgraph')
      .append('svg')
      .attr('width', width)
      .attr('height', height); //creates the svg canvas that all groups, nodes and links will sit on

    const link = svg
      .append('g')
      .attr('stroke', '#fff')
      .attr('stroke-opacity', 0.6)
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke-width', 1);

    const g = svg.append('g');

    const defs = svg
      .append('defs')
      .selectAll('clipPath')
      .data(groupData)
      .join('clipPath')
      .attr('id', (d) => d.name + '-clip')
      .append('circle')
      .attr('r', (d) => forceVariables.nodeRadius * d.radius - 2);

    const node = g
      .append('g')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .attr('data-name', function (d) {
        let id = d.id;
        if (id == 'social community manager') {
          id = id.split('&').join('');
        }
        id = id.split(' ').join('');
        return id;
      })
      .attr('fill', 'none')

      .on('click', function (d) {
        d.fx = null;
        d.fy = null;
      });

    const cir = node
      .append('circle')
      .attr('id', (d) => idCompress(d.id))
      .attr('r', (d) => getRadius(d.group))
      .attr('stroke', '#fff')
      .attr('stroke-width', 5)
      .attr('fill', '#fff');

    const img = node
      .append('image')
      .attr('xlink:href', function (d) {
        let img;
        if (d.group == 'people') {
          img = d.image
            ? d.image
            : 'https://media.miamiherald.com/static/media/projects/2019/yang-interactive/GenderlessIcon.74c2fa61.jpg';
        }
        if (d.group == 'Manager') {
          img = d.image
            ? d.image
            : 'https://media.miamiherald.com/static/media/projects/2019/yang-interactive/GenderlessIcon.74c2fa61.jpg';
        }
        return img;
      })
      .attr('clip-path', (d) => `url(#${d.group}-clip)`)
      .attr('width', (d) => getRadius(d.group) * 2)
      .attr('height', (d) => getRadius(d.group) * 2)
      .attr('x', (d) => getRadius(d.group) * -1)
      .attr('y', (d) => getRadius(d.group) * -1);

    let firer = node.filter((d) => d.group == 'Manager').datum();

    simulation.on('tick', () => {
      node.attr('transform', function (d) {
        d.x = Math.max(getRadius(d.group), Math.min(width - getRadius(d.group), d.x));
        d.y = Math.max(getRadius(d.group), Math.min(height - getRadius(d.group), d.y));
        return `translate(${d.x},${d.y})`;
      });

      link
        .attr('x1', function (d) {
          return d.source.x;
        })
        .attr('y1', function (d) {
          return d.source.y;
        })
        .attr('x2', function (d) {
          return d.target.x;
        })
        .attr('y2', function (d) {
          return d.target.y;
        });

      firer.fy = height / 2;
      firer.fx = width / 2;
    });

    return svg.node();
  }, []);
  useEffect(() => {
    chart();
  }, [chart]);

  return (
    <div className={s.baseBanner}>
      <div className="networkgraph"></div>
    </div>
  );
};
