import {
  ConfigType,
  GridFeeType,
  ListCommunityInfoDocument,
  ListCommunityInfoQuery,
  SettingsDataFieldsFragment,
  SpotMarketType,
} from 'src/graphql';
import { EFormVariant, TFieldValue } from 'src/typings/base-types';
import { FormFieldsGenerator, TFormFieldsGeneratorProps } from 'src/components/FormFieldsGenerator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TCommunitySettingsFields,
  TFieldsUnionWithValue,
  TFileFieldWithValue,
  TSettingsData,
} from 'src/utils/assetsFields/assetsFields.types';
import {
  TFieldsVisibility,
  TFormSettingsDataProps,
  TGetVisibleFieldsArgs,
  TSettingsSaveProps,
} from './FormSettingsData.types';
import { fieldTemplates, relatedFormFields, validators } from './formFields';
import {
  selectCommunityAssetSettings,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectIsCommunityCreatedInDB,
  selectReadOnly,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';

import { TAllFieldNames } from 'src/utils/assetsFields/assetsFields.types';
import { WritableDraft } from 'immer/dist/internal';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';
import { findField } from 'src/utils/fieldUtils';
import { getAssetValues } from 'src/utils/assetsFields/fieldTemplatesWithValues';
import { isLibrary } from 'src/mocks/configurationSettings';
import produce from 'immer';
import { selectIsAdmin } from 'src/redux/auth/auth.selectors';
import { updateFields } from 'src/utils/assetsFields/updateFields';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import { validateFields } from 'src/utils/validation';

function getVisibleFields({
  fields,
  fieldsVisibility,
  formVariant,
  isAdmin,
  visibleFieldsInfoFromProp,
  visibleFieldsOrderFromProp,
}: TGetVisibleFieldsArgs) {
  if (!fields) return fields;
  const fieldsUnion = fields
    .filter((field) => {
      if (visibleFieldsInfoFromProp) {
        return field.name in fieldsVisibility ? fieldsVisibility[field.name] : false;
      }
      const conditionA =
        formVariant === EFormVariant.Express ? field.formView === EFormVariant.Express : true;
      const conditionB = field.name in fieldsVisibility ? fieldsVisibility[field.name] : true;

      return conditionA && conditionB;
    })
    .map((field) => {
      if (field.name === 'pvUserProfile') {
        const modifiedField = produce(field, (draftState) => {
          (draftState as WritableDraft<TFileFieldWithValue>).allowDownload = isAdmin;
        });
        return modifiedField;
      }
      return field;
    });

  if (visibleFieldsOrderFromProp) {
    const orderedResult: Array<TFieldsUnionWithValue> = visibleFieldsOrderFromProp
      .map((item) => {
        const fieldIndex = fieldsUnion.findIndex((field) => field.name === item);
        if (fieldIndex > -1) {
          return fieldsUnion[fieldIndex];
        }
        return undefined;
      })
      .filter(
        (item: TFieldsUnionWithValue | undefined): item is TFieldsUnionWithValue =>
          item !== undefined,
      );
    return orderedResult;
  }
  return fieldsUnion;
}

export const FormSettingsData: React.FC<TFormSettingsDataProps> = ({
  formVariant = EFormVariant.Advanced,
  hasErrorsRef,
  onSubmit,
  theme = 'light',
  visibleFieldsInfo,
  visibleFieldsOrder,
  ...formFieldsGeneratorProps
}) => {
  const client = useApolloClient();

  const isAdmin = useSelector(selectIsAdmin);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;
  const {
    gridFeePercentage,
    gridFeeConstant,
    bidOfferMatchAlgo,
    importCapacityKva,
    exportCapacityKva,
    coefficientPercentage,
    baselinePeakEnergyImportKwh,
    baselinePeakEnergyExportKwh,
  } = useSelector(selectCommunityAssetSettings) || {};
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const isCommunityCreatedInDB = useSelector(selectIsCommunityCreatedInDB);
  const readOnly = useSelector(selectReadOnly);
  const configType = useSelector(selectConfigType);

  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;

  const [allFields, setAllFields] = useState(
    fieldTemplates({
      values: {
        ...settingsData,
        name: configurationCharacteristic.name,
        description: configurationCharacteristic.description,
        timezone: configurationCharacteristic.timezone,
        locationVisible: configurationCharacteristic.locationVisible,
        gridFeeEnabled: Boolean(gridFeePercentage || gridFeeConstant),
        gridFeePercentage,
        gridFeeConstant,
        transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
        importCapacityKva,
        exportCapacityKva,
        coefficientPercentage,
        baselinePeakEnergyEnabled: Boolean(
          baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
        ),
        baselinePeakEnergyImportKwh,
        baselinePeakEnergyExportKwh,
      },
      configurationCharacteristic,
    }),
  );

  const [allFieldsStored, setAllFieldsStored] = useState<TFieldsUnionWithValue[] | undefined>(
    undefined,
  );

  const [errors, setErrors] = useState<TFormFieldsGeneratorProps['errors']>(null);
  const settingsDataMemo = useMemo(() => settingsData, [settingsData]);
  const configurationCharacteristicMemo = useMemo(() => configurationCharacteristic, [
    configurationCharacteristic,
  ]);
  const gridFeePercentageMemo = useMemo(() => gridFeePercentage, [gridFeePercentage]);
  const gridFeeConstantMemo = useMemo(() => gridFeeConstant, [gridFeeConstant]);

  const combineValues = useMemo(
    () =>
      fieldTemplates({
        values: {
          ...settingsDataMemo,
          name: configurationCharacteristicMemo.name,
          description: configurationCharacteristicMemo.description,
          timezone: configurationCharacteristicMemo.timezone,
          locationVisible: configurationCharacteristicMemo.locationVisible,
          gridFeeEnabled: Boolean(gridFeePercentageMemo || gridFeeConstantMemo),
          gridFeePercentage: gridFeePercentageMemo,
          gridFeeConstant: gridFeeConstantMemo,
          transformerCapacityEnabled: Boolean(importCapacityKva || exportCapacityKva), // setting as default true as data unavailble from BE
          importCapacityKva,
          exportCapacityKva,
          coefficientPercentage,
          baselinePeakEnergyEnabled: Boolean(
            baselinePeakEnergyImportKwh || baselinePeakEnergyExportKwh,
          ),
          baselinePeakEnergyImportKwh,
          baselinePeakEnergyExportKwh,
        },
        configurationCharacteristic: configurationCharacteristicMemo,
      }),
    [
      settingsDataMemo,
      configurationCharacteristicMemo,
      gridFeePercentageMemo,
      gridFeeConstantMemo,
      importCapacityKva,
      exportCapacityKva,
      coefficientPercentage,
      baselinePeakEnergyImportKwh,
      baselinePeakEnergyExportKwh,
    ],
  );

  useEffect(() => {
    if (allFieldsStored && JSON.stringify(combineValues) !== JSON.stringify(allFieldsStored)) {
      setAllFields(combineValues);
      setAllFieldsStored(combineValues);
    }
  }, [combineValues, allFieldsStored, setAllFields, setAllFieldsStored]);

  useEffect(() => {
    if (!allFieldsStored) {
      setAllFieldsStored(allFields);
    }
  }, [setAllFieldsStored, allFields, allFieldsStored]);

  // If a field is not present in the object it will be visible by default, if it is not from the visibleFieldsInfo prop
  const fieldsVisibility: TFieldsVisibility = visibleFieldsInfo
    ? {
        ...visibleFieldsInfo,
        gridFeeConstant:
          visibleFieldsInfo.gridFeeConstant &&
          Boolean(
            !isLibrary && findField(allFields, 'gridFeeEnabled')?.value,
            // &&
            // findField(allFields, 'gridFeeType')?.value === GridFeeType.Constant,
          ),
        // gridFeePercentage:
        //   visibleFieldsInfo.gridFeePercentage &&
        //   Boolean(
        //     !isLibrary && findField(allFields, 'gridFeeEnabled')?.value
        //     &&
        //     findField(allFields, 'gridFeeType')?.value === GridFeeType.Percentage,
        //   ),
      }
    : {
        currency: !isLibrary,
        startEndDate: !isCanaryNetwork && !isLibrary,
        pvUserProfile: findField(allFields, 'cloudCoverage')?.value === 4,
        tickLengthSeconds: !isLibrary,
        gridFeeType: !isLibrary,
        slotLengthRealtimeSeconds: !isCanaryNetwork && !isLibrary,
        gridFeeEnabled: !isLibrary,
        // gridFeePercentage: Boolean(
        // !isLibrary && findField(allFields, 'gridFeeEnabled')?.value,
        // &&
        // findField(allFields, 'gridFeeType')?.value === GridFeeType.Percentage,
        // ),
        gridFeeConstant: Boolean(
          !isLibrary && findField(allFields, 'gridFeeEnabled')?.value,
          // &&
          // findField(allFields, 'gridFeeType')?.value === GridFeeType.Constant,
        ),
        locationVisible: !isCanaryNetwork,
        timezone: isCanaryNetwork,
        importCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
        exportCapacityKva: Boolean(findField(allFields, 'transformerCapacityEnabled')?.value),
        coefficientPercentage: false,
        baselinePeakEnergyImportKwh: Boolean(
          findField(allFields, 'baselinePeakEnergyEnabled')?.value,
        ),
        baselinePeakEnergyExportKwh: Boolean(
          findField(allFields, 'baselinePeakEnergyEnabled')?.value,
        ),
        bidOfferMatchAlgo:
          findField(allFields, 'spotMarketType')?.value === SpotMarketType.TwoSided,
      };

  const visibleFields = getVisibleFields({
    fields: allFields,
    fieldsVisibility,
    formVariant,
    isAdmin,
    visibleFieldsInfoFromProp: visibleFieldsInfo,
    visibleFieldsOrderFromProp: visibleFieldsOrder,
  });

  const getUsedProjectNames = useCallback((): string[] => {
    const data: ListCommunityInfoQuery | null = client.readQuery({
      query: ListCommunityInfoDocument,
    });
    const projects = data?.listCommunityInfo?.projects || [];
    const output: string[] = [];

    projects.forEach((project) => {
      const name = project?.name;

      if (name) {
        output.push(name);
      }
    });

    return output;
  }, [client]);

  const validateFieldsWrapper = useCallback(
    (fields: TFieldsUnionWithValue[]) => {
      const output = validateFields({
        validators: validators({
          usedProjectNames: getUsedProjectNames(),
          currentProjectName: isCommunityCreatedInDB ? configurationCharacteristic.name : '',
        }),
        fields,
      });

      setErrors(output.errors);

      return output;
    },
    [setErrors, configurationCharacteristic.name, getUsedProjectNames, isCommunityCreatedInDB],
  );

  const handleChange = useCallback(
    ({
      name,
      value,
    }: {
      name: keyof TSettingsData | 'locationVisible' | 'name';
      value: TFieldValue;
    }) => {
      if (!allFields) return;

      const payload = {
        settingsData,
        isLibrary,
        configType,
        configurationCharacteristic,
      };

      let allFieldsNew = [...allFields];
      const fieldToUpdate = allFields.find((f) => f.name === name);
      /*if (
        // fieldToUpdate?.name === 'transformerCapacityEnabled' ||
        fieldToUpdate?.name === 'baselinePeakEnergyEnabled'
      ) {
        allFieldsNew = updateFields({
          type: 'Area',
          fields: allFields,
          updatedField: { name: name as TAllFieldNames, value },
          ...payload,
        });
      }*/

      // We use immer for immutability, cloneDeep is too expensive
      const newFields = produce(allFieldsNew, (draftState) => {
        const fieldToUpdate = draftState.find((f) => f.name === name);

        if (fieldToUpdate) {
          fieldToUpdate.value = value;

          // Update sibling fields
          switch (fieldToUpdate.name) {
            case 'startEndDate':
              const startEndDate = draftState.find((f) => f.name === 'startEndDate');

              if (startEndDate) {
                startEndDate.EXCLUDE = isCanaryNetwork;
              }
              break;
            case 'gridFeeEnabled':
            case 'gridFeeType':
              const gridFeeType = draftState.find((f) => f.name === 'gridFeeType')?.value as
                | GridFeeType
                | undefined;

              // if (gridFeeType) {
              const gridFeePercentageField = draftState.find((f) => f.name === 'gridFeePercentage');
              const gridFeeConstantField = draftState.find((f) => f.name === 'gridFeeConstant');
              // if (gridFeeType === GridFeeType.Constant) {
              if (gridFeePercentageField) {
                gridFeePercentageField.value = null;
                gridFeePercentageField.EXCLUDE = true;
              }
              if (gridFeeConstantField) {
                gridFeeConstantField.value = 0;
                gridFeeConstantField.EXCLUDE = false;
              }
              // }
              // else if (gridFeeType === GridFeeType.Percentage) {
              //   if (gridFeePercentageField) {
              //     gridFeePercentageField.value = 0;
              //     gridFeePercentageField.EXCLUDE = false;
              //   }
              //   if (gridFeeConstantField) {
              //     gridFeeConstantField.value = null;
              //     gridFeeConstantField.EXCLUDE = true;
              //   }
              // }
              // }

              break;
            case 'baselinePeakEnergyEnabled':
            case 'transformerCapacityEnabled':
              const fieldEnabled = draftState.find((f) => f.name === fieldToUpdate.name)?.value;
              const relatedFieldsItem = relatedFormFields.find(
                (item) => item.fieldName === fieldToUpdate.name,
              )?.relatedFields;

              if (relatedFieldsItem) {
                const importExport = relatedFieldsItem.map((subitem) =>
                  draftState.find((f) => f.name === subitem),
                );
                importExport.forEach((item) => {
                  if (item) {
                    item.EXCLUDE = !fieldEnabled;
                    item.value = 0;
                  }
                });
              }
              break;
            default:
              break;
          }
        }
      });
      validateFieldsWrapper(newFields);
      setAllFields(newFields);
    },
    [
      allFields,
      settingsData,
      configurationCharacteristic,
      validateFieldsWrapper,
      configType,
      isCanaryNetwork,
    ],
  );

  const renderedFields = useMemo(() => {
    switch (formVariant) {
      case EFormVariant.Advanced:
        return visibleFields?.filter(
          (f) => f.formView === EFormVariant.Advanced || f.formView === EFormVariant.Express,
        );

      default:
        return visibleFields?.filter((f) => f.formView === formVariant);
    }
  }, [visibleFields, formVariant]);

  return (
    <FormFieldsGenerator
      fields={renderedFields}
      errors={errors}
      onChange={handleChange}
      onSubmit={() => {
        const { errors } = validateFieldsWrapper(renderedFields);
        if (errors) return;

        const values = getAssetValues(
          allFields.filter((f) => (f.name in fieldsVisibility ? fieldsVisibility[f.name] : true)),
        ) as TCommunitySettingsFields & TSettingsSaveProps['communityAssetSettings'];

        if (values.startEndDate) {
          values.startDate = values.startEndDate.startDate as string;
          values.endDate = values.startEndDate.endDate as string;
        }

        const {
          name,
          description,
          locationVisible,
          timezone,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          startEndDate,
          gridFeeConstant,
          gridFeePercentage,
          ...newSettingsData
        } = values;

        onSubmit({
          name,
          description,
          locationVisible,
          timezone: timezone ?? '',
          settingsData: newSettingsData,
          communityAssetSettings: {
            gridFeeConstant: gridFeeConstant,
            gridFeePercentage: gridFeePercentage,
          },
        });
      }}
      focusField="name"
      theme={theme}
      readOnly={readOnly}
      {...formFieldsGeneratorProps}
    />
  );
};
