import { BaseIcon } from 'src/components/BaseIcon';
import React from 'react';
import { TEnergyFlowAssetType } from 'src/components/EnergyFlow/EnergyFlow.types';
import { TFlowAssetRendererProps } from './FlowAssetRenderer.types';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import s from './FlowAssetRenderer.module.scss';

export const FlowAssetRenderer: React.FC<TFlowAssetRendererProps> = ({
  polygonCoordinate,
  assetType,
}) => {
  const getIcon = (assetType: TEnergyFlowAssetType): TIconNames => {
    switch (assetType) {
      case 'Load':
        return 'plug';
      case 'Storage':
        return 'battery';
      case 'PV':
      default:
        return 'pv';
    }
  };
  return (
    <div className={s.testArea} style={{ top: polygonCoordinate.y, left: polygonCoordinate.x }}>
      <BaseIcon icon={getIcon(assetType)} size={16.25} />
    </div>
  );
};
