import * as d3 from 'd3';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  selectCommunityAsset,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';

import { BaseTags } from 'src/components/BaseTags';
import { ChartDataLoadingWrapper } from 'src/components/ChartDataLoadingWrapper';
import { TChartChordDiagramProps } from './ChartChordDiagram.types';
import s from './ChartChordDiagram.module.scss';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';

type TCreateChartPayload = {
  container: SVGSVGElement;
  data: { source: string; target: string; value: number }[];
  names: string[];
  colors: string[];
};

const renderChart = ({ container, names, colors, data }: TCreateChartPayload) => {
  const width = container.clientWidth;
  const height = container.clientHeight;
  const innerRadius = Math.min(width, height) * 0.5 - 20;
  const outerRadius = innerRadius + 10;
  const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
  const ribbon = d3
    .ribbonArrow()
    .radius(innerRadius - 1)
    .padAngle(1 / innerRadius);
  const chord = d3
    .chordDirected()
    .padAngle(10 / innerRadius)
    .sortSubgroups(d3.descending)
    .sortChords(d3.descending);
  const getMatrix = () => {
    const index: Map<string, number> = new Map(names.map((name, i) => [name, i]));
    const matrix: number[][] = Array.from(index, () => new Array(names.length).fill(0));
    for (const { source, target, value } of data) {
      matrix[index.get(source) as number][index.get(target) as number] += value;
    }
    return matrix;
  };

  const svg = d3.select(container);
  svg.attr('viewBox', [-width / 2, -height / 2, width, height] as any);

  const chords = chord(getMatrix());

  const group = svg
    .append('g')
    .attr('font-size', 10)
    .attr('font-family', 'sans-serif')
    .selectAll('g')
    .data(chords.groups)
    .join('g');

  group
    .append('path')
    .attr('fill', (d) => colors[d.index])
    .attr('d', arc as any);

  function createTooltipBg(elem) {
    const bounds = elem.getBBox();
    tooltipBg.attr('x', `${bounds.x - 20}`);
    tooltipBg.attr('y', `${bounds.y - 5}`);
    tooltipBg.attr('width', bounds.width + 40);
    tooltipBg.attr('height', bounds.height + 10);
  }

  svg
    .append('g')
    .attr('fill-opacity', 0.75)
    .selectAll('path')
    .data(chords)
    .join('path')
    .style('mix-blend-mode', 'multiply')
    .attr('fill', (d) => colors[d.source.index])
    // @ts-ignore
    .attr('d', ribbon)
    .on('mouseover', (d) => {
      const data = d.target['__data__'];

      tooltipText.style('opacity', 1).text(() => {
        if (names[data.source.index] === names[data.target.index]) {
          return names[data.source.index] + ' self-consumed ' + data.source.value + ' kWh';
        } else {
          return `${names[data.source.index]} sold ${data.source.value} kWh to ${
            names[data.target.index]
          } `;
        }
      });

      const textElement = tooltipText.nodes()[0];
      const bounds = textElement.getBBox();
      tooltipText.attr('x', -bounds.width / 2);
      createTooltipBg(textElement);
      tooltipBg.style('opacity', 1);
    })
    .on('mouseout', () => {
      tooltipText.style('opacity', 0).text('');
      tooltipBg.style('opacity', 0);
    });

  const tooltipBg = svg
    .append('rect')
    .attr('pointer-events', 'none')
    .attr('fill', 'rgba(0,0,0,0.9)')
    .attr('rx', 5);

  const tooltipText = svg
    .append('text')
    .attr('x', '0')
    .attr('y', '0')
    .attr('fill', '#fff')
    .attr('pointer-events', 'none');

  return svg.node();
};

function nameMapping(name, isCommunity = true) {
  name.split('IAA ').join('');
  if (isCommunity) {
    if (name === 'External Trades') return 'Grid Market';
    if (name === 'Community') return 'Grid Market';
  } else {
    if (name === 'External Trades') return 'Community';
    if (name === 'Community') return 'Community';

    if (name.includes('Grid Market')) {
      return 'Community';
    }
    if (name.includes('Home')) {
      return 'Community';
    }
  }
  return name;
}

export const ChartChordDiagram: React.FC<TChartChordDiagramProps> = ({ cumulativeGridTrades }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [activeTagsIds, setActiveTagsIds] = useState<number[]>([]);
  const simulationResults = useSelector(selectSimulationResults);
  const communityAsset = useSelector(selectCommunityAsset);

  const chordData = useMemo<TCreateChartPayload['data']>(() => {
    const isCommunity = simulationResults && simulationResults?.assetUuid === communityAsset?.uuid;

    return (
      cumulativeGridTrades?.flatMap(({ areaName, bars }) => {
        return bars
          .filter(({ energy }) => {
            return energy >= 0;
          })
          .map(({ targetArea, energy }) => ({
            target: nameMapping(areaName, isCommunity),
            source: nameMapping(targetArea, isCommunity),
            value: Math.abs(energy),
          }));
      }) || []
    );
  }, [cumulativeGridTrades, simulationResults, communityAsset]);

  const names = useMemo(() => uniq(chordData.flatMap((item) => [item.source, item.target])), [
    chordData,
  ]);

  const colors = useMemo(
    () =>
      names.map((item) =>
        // +1 because for 2 elements colors are same
        d3.scaleOrdinal(names, d3.quantize(d3.interpolateRainbow, names.length + 1))(item),
      ),
    [names],
  );

  const housesTags = useMemo(
    () =>
      names.map((item, index) => ({
        name: item,
        color: colors[index],
      })),
    [colors, names],
  );

  const excludedTagsIds = useMemo(() => {
    return housesTags.reduce((prev: number[], curr, index) => {
      if (activeTagsIds.length !== 0 && !activeTagsIds.includes(index)) {
        prev.push(index);
      }
      return prev;
    }, []);
  }, [housesTags, activeTagsIds]);

  const handleFilterClick = ({ name }) => {
    const clickedItemIndex = housesTags.findIndex((item) => item.name === name);

    if (activeTagsIds.includes(clickedItemIndex)) {
      setActiveTagsIds(activeTagsIds.filter((item) => item !== clickedItemIndex));
    } else {
      if (activeTagsIds.length === housesTags.length - 1) {
        setActiveTagsIds([]);
      } else {
        setActiveTagsIds([...activeTagsIds, clickedItemIndex]);
      }
    }
  };

  useEffect(() => {
    if (svgRef.current) {
      while (svgRef.current.lastElementChild) {
        svgRef.current.removeChild(svgRef.current.lastElementChild);
      }

      const data = chordData.filter((item) => {
        return (
          !excludedTagsIds.includes(housesTags.findIndex((tag) => tag.name === item.source)) ||
          !excludedTagsIds.includes(housesTags.findIndex((tag) => tag.name === item.target))
        );
      });

      renderChart({
        container: svgRef.current,
        data,
        names: names,
        colors,
      });
    }
  }, [chordData, names, colors, excludedTagsIds, housesTags]);

  return (
    <div className={s.container}>
      <div>
        <ChartDataLoadingWrapper loading={!cumulativeGridTrades}>
          <svg className={s.svgChart} ref={svgRef} />
        </ChartDataLoadingWrapper>
      </div>
      <BaseTags
        className={s.housesTags}
        items={housesTags}
        name="housesTags"
        value={excludedTagsIds}
        onTagClick={handleFilterClick}
      />
    </div>
  );
};
