import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';
import {
  EActiveTabState,
  ECollaborationTab,
  ECommunityTab,
  EResultsTab,
  TMapSidebarResultsProps,
  TPresentationNames,
} from './MapSidebarResults.types';
import { EFormVariant, EUserRoles } from 'src/typings/base-types';
import { FormSettingsData, TFormSettingsDataProps } from '../FormSettingsData';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  selectActiveConfigurationJobUuid,
  selectActiveConfigurationUuid,
  selectAssetByUuid,
  selectCommunityAsset,
  selectCommunityName,
  selectCommunitySimulationResults,
  selectConfigType,
  selectConfigurationCharacteristic,
  selectGridMarketAsset,
  selectIsCNLaunching,
  selectReadOnly,
  selectRootAssetUuid,
  selectSelectedAssetUuid,
  selectSettingsData,
  selectSimulationResults,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectActiveSCMStep,
  selectActiveTabResults,
  selectSCMFlow,
  selectScmSidebarTab,
} from 'src/redux/scm/scm.selectors';
import {
  selectCommunityNotFound,
  selectIsEmbed,
  selectSidebarExpanded,
} from 'src/redux/application/application.selectors';
import { selectIsLoggedIn, selectUserRole } from 'src/redux/auth/auth.selectors';
import { setActiveSCMStep, setSCMsidebarTab } from 'src/redux/scm/scm.slice';

import { ApplicationContext } from 'src/contexts/ApplicationContext';
import { BaseButton } from '../../../BaseButton';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import { CollaborationFormFields } from 'src/components/MapSidebar/components/MapSidebarResults/MapSidebarResultsConstants';
import { ConfigType } from 'src/graphql';
import { EDomIds } from 'src/constants/domSelectors';
import { EPredefinedModalIds } from 'src/constants/modals';
import { FormSettingsData as FormSettingsDataLegacy } from 'src/components/FormSettingsData';
import { GridMarketForm } from 'src/components/MapSidebarSCM/components/GridMarket/GridMarketForm';
import { InformationBox } from 'src/components/InformationBox';
import { MapSidebarCanary } from 'src/components/MapSidebarSCM/components/MapSidebarCanary';
import { RegularNoResults } from 'src/components/MapSidebarSCM/components/MapSidebarResults/components/RegularNoResults';
import { SCMMyCommunity } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity';
import { SCMMyHome } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';
import { SharingCoefficient } from 'src/components/MapSidebarSCM/components/SharingCoefficients';
import { SidebarSubTab } from 'src/components/MapSidebar/components/MapSidebarResults/components/SidebarSubTab';
import { SimulationResultsPresentation } from 'src/components/SimulationResultsPresentation';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';
import { TSidebarSubTabItemList } from 'src/components/MapSidebar/components/MapSidebarResults/components/SidebarSubTab/SidebarSubTab.types';
import { TabNav } from 'src/components/TabNav';
import { TextBrick } from 'src/components/TextBrick';
import { UserProfile } from 'src/components/MapSidebarSCM/components/UserProfile';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classNames from 'classnames';
import classnames from 'classnames';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './MapSidebarResults.module.scss';
import { selectUserToAdminRequests } from 'src/redux/communities/communities.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationUtils } from 'src/hooks/useConfigurationUtils';
import { useCurrentUserItemForSCM } from 'src/hooks/useCurrentUserItemForSCM';
import useDownloadAPI from 'src/hooks/useDownloadAPI';
import { useFlyTo } from 'src/hooks/useFlyTo';
import { useHouseSummaryResults } from 'src/hooks/useHouseSummaryResults';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useLaunchToCanary } from 'src/hooks/useLaunchToCanary';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const MapSidebarResults: React.FC<TMapSidebarResultsProps> = ({
  onSettingsDataSave,
  showResultsTabOnClick,
  hideResultsTab,
  onCommunityRemove,
  onAssetValuesSavePromise,
  onAssetValuesSave,
  onChangeSettingsTab,
  onAssetRemove,
  onAddNewAsset,
  onAddNewAssetUnderUuid,
  showHeaderTabs = true,
}) => {
  const dispatch = useAppDispatch();
  const sidebarTab = useSelector(selectScmSidebarTab);
  const isEmbed = useSelector(selectIsEmbed);
  const loggedIn = useSelector(selectIsLoggedIn);
  const communityName = useSelector(selectCommunityName);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const { currentItem } = useCurrentUserItemForSCM();

  const isSCM = useSelector(selectSCMFlow);
  const simulationResults = useSelector(selectSimulationResults);
  const communitySimulationResults = useSelector(selectCommunitySimulationResults);
  const configType = useSelector(selectConfigType);
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
  const activeConfigurationJobUuid = useSelector(selectActiveConfigurationJobUuid);
  const communityNotFound = useSelector(selectCommunityNotFound);
  const readOnly = useSelector(selectReadOnly);
  const formHasErrorsRef = useRef(false);
  const formId = useRef(v4());
  const { triggerResultsLossAlert, triggerCommunityDeleteAlert, triggerDownloadAlert } = useContext(
    ApplicationContext,
  );
  const simulationStatus = useSelector(selectSimulationStatus);
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const userToAdminRequests = useSelector(selectUserToAdminRequests);
  const communityAsset = useSelector(selectCommunityAsset);
  const asset = useSelector(selectAssetByUuid(simulationResults?.assetUuid || ''));
  const isCommunity = communityAsset?.uuid === simulationResults?.assetUuid;
  const gridAgentAsset = useSelector(selectGridMarketAsset);
  const rootAssetUuid = useSelector(selectRootAssetUuid);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);
  const activeSCMStep = useSelector(selectActiveSCMStep);
  const activeResultsTab = useSelector(selectActiveTabResults);
  const assetType =
    simulationResults?.assetUuid === rootAssetUuid ? 'MarketMaker' : asset?.type || 'Area';
  const isDisabledArea = ['running', 'paused', 'queued', 'started', 'initializing'].includes(
    simulationStatus as string,
  );

  const handleTabChange = (value: EActiveTabState) => {
    setActiveTab(value);
    dispatch(setSidebarExpanded(false));
    if (sidebarTab === EActiveTabState.Registry && value !== EActiveTabState.Registry) {
      dispatch(setSCMsidebarTab(undefined));
    }
    if (value == EActiveTabState.Registry) dispatch(setActiveSCMStep(1));
    if (value == EActiveTabState.CollaborationSettings) dispatch(setActiveSCMStep(2));
    if (value == EActiveTabState.Community) dispatch(setActiveSCMStep(3));
    if (
      sidebarTab === EActiveTabState.CollaborationSettings &&
      value !== EActiveTabState.CollaborationSettings
    ) {
      dispatch(setSCMsidebarTab(undefined));
    }
  };

  const { isUserACommunityMember } = useIsUserACommunityMember();

  const { mapService } = useContext(WorldMapContext);
  const { flyToAsset } = useFlyTo({ mapService });

  useEffect(() => {
    const isCommunityTab = sidebarTab === EActiveTabState.Community || activeSCMStep === 3;
    const isCollaborationTab =
      sidebarTab === EActiveTabState.CollaborationSettings || activeSCMStep === 2;
    const isBasicCollaborationTab = activeSCMStep === 0 && !isUserACommunityMember;
    const isRegistryTab = sidebarTab === EActiveTabState.Registry || activeSCMStep === 1;
    const isProfileTab = sidebarTab === EActiveTabState.Profile;
    const isSidebarExpanded = activeSCMStep !== 3;

    if (isCommunityTab) {
      setActiveTab(EActiveTabState.Community);
      dispatch(setSidebarExpanded(true));
      dispatch(setSelectedAssetUuid(currentItem?.uuid));
    }
    if (isCollaborationTab) {
      setActiveTab(EActiveTabState.CollaborationSettings);
      setActiveCollaborationTab(ECollaborationTab.Basic);
    }
    if (isBasicCollaborationTab) {
      setActiveTab(EActiveTabState.CollaborationSettings);
      setActiveCollaborationTab(ECollaborationTab.Basic);
    }
    if (isRegistryTab) {
      setActiveTab(EActiveTabState.Registry);
    }
    if (isProfileTab) {
      setActiveTab(EActiveTabState.Profile);
    }

    if (isSidebarExpanded) {
      dispatch(setSidebarExpanded(false));
    }
  }, [sidebarTab, currentItem, activeSCMStep, isUserACommunityMember, dispatch]);

  const resultsDownloadAPI = useDownloadAPI({
    type: 'simulation-results',
    resultsUuid: activeConfigurationJobUuid,
    startDate: UTCMoment.fromBackend(
      settingsData.startDate,
      BACKEND_DATE_FORMATS.SETTINGS_DATA,
    ).format(BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS),
    endDate: UTCMoment.fromBackend(settingsData.endDate, BACKEND_DATE_FORMATS.SETTINGS_DATA).format(
      BACKEND_DATE_FORMATS.DOWNLOAD_SIM_RESULTS,
    ),
  });

  // this check will be updated and used when the user is a community member
  // invited through the invitation link
  const [formVariant, setFormVariant] = useState(EFormVariant.Express);

  const [activeTab, setActiveTab] = useState<EActiveTabState>(
    isUserACommunityMember ? EActiveTabState.Profile : EActiveTabState.CollaborationSettings,
  );

  const [activeCommunityTab, setActiveCommunityTab] = useState<ECommunityTab>(
    ECommunityTab.GridMarket,
  );
  const [activeCollaborationTab, setActiveCollaborationTab] = useState<ECollaborationTab>(
    ECollaborationTab.Basic,
  );
  const showGridMarketTab = isCommunity || ['MarketMaker', 'InfiniteBus'].includes(assetType);
  const showResultsTab = typeof simulationStatus !== 'undefined';
  const GridMarketAgentSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Grid Market Results',
        onClick: () => {
          setActiveCommunityTab(ECommunityTab.GridMarket);
          dispatch(setSelectedAssetUuid(rootAssetUuid));
        },
        label: 'Grid Market',
        isActive: () => activeCommunityTab === ECommunityTab.GridMarket,
      },
      {
        liTitle: 'Grid Agent Results',
        onClick: () => {
          setActiveCommunityTab(ECommunityTab.GridAgent);
          if (gridAgentAsset) dispatch(setSelectedAssetUuid(gridAgentAsset.uuid));
        },
        label: 'Grid Agent',
        isActive: () => activeCommunityTab === ECommunityTab.GridAgent,
      },
    ],
    [setActiveCommunityTab, dispatch, activeCommunityTab, gridAgentAsset, rootAssetUuid],
  );

  const CollaborationSubtabs: TSidebarSubTabItemList = useMemo(
    () => [
      {
        liTitle: 'Collaboration Basic Settings',
        onClick: () => setActiveCollaborationTab(ECollaborationTab.Basic),
        label: 'Basic',
        isActive: () => activeCollaborationTab === ECollaborationTab.Basic,
      },
      {
        liTitle: 'Collaboration Advanced Settings',
        onClick: () => setActiveCollaborationTab(ECollaborationTab.Advanced),
        label: 'Advanced',
        isActive: () => activeCollaborationTab === ECollaborationTab.Advanced,
      },
      {
        liTitle: 'Grid Market Settings',
        onClick: () => {
          setActiveCollaborationTab(ECollaborationTab.Grid);
          dispatch(setActiveSCMStep(2));
        },
        label: 'Grid Market',
        isActive: () => activeCollaborationTab === ECollaborationTab.Grid,
      },
    ],
    [setActiveCollaborationTab, activeCollaborationTab, dispatch],
  );

  const showPresentationAreas: Array<TPresentationNames> = useMemo(() => {
    if (assetType === 'Area' && isCommunity) {
      return [
        'KeyResultsSCM',
        'AssetCount',
        'TableEnergyBillsAndNetTraded',
        'SS-SC',
        'ChartEnergyTradeProfile',
        /*'ChartDayProfile',*/
        'ChartPrices',
        'TradesBidsAndOffers',
      ];
    } else if (assetType === 'MarketMaker') {
      return [
        'SS-SC',
        'ChartEnergyTradeProfile',
        /*'ChartDayProfile',*/
        'ChartPrices',
        'TradesBidsAndOffers',
      ];
    } else if (assetType === 'InfiniteBus') {
      return ['ChartAssetAgent', 'ChartDeviceProfile'];
    } else if (assetType === 'Area') {
      return [
        'KeyResultsSCM',
        'ChartSavings',
        'TableEnergyBillsAndNetTraded',
        'SS-SC',
        'ChartEnergyTradeProfile',
        'EnergyFlow',
      ];
    } else if (assetType === 'Load' || assetType === 'PV' || assetType === 'Storage') {
      return ['ChartAsset', 'ChartDeviceProfile'];
    }
    return [];
  }, [assetType, isCommunity]);

  const toggleSidebarExpand = () => {
    dispatch(setSidebarExpanded(!sidebarExpanded));
  };

  const handleSettingsDataSave: TFormSettingsDataProps['onSubmit'] = async (payload) => {
    await triggerResultsLossAlert();
    onSettingsDataSave?.(payload);
  };

  const deleteCommunity = async () => {
    await triggerCommunityDeleteAlert();

    onCommunityRemove?.({ projectUuid: configurationCharacteristic.projectUuid });
  };

  const isRequestedForCn = useMemo(() => {
    return (
      activeConfigurationUuid &&
      userToAdminRequests[activeConfigurationUuid]?.find(
        (item) => item.type === 'CONVERT_SIM_TO_CN' && item.status === 'RECEIVED',
      )
    );
  }, [activeConfigurationUuid, userToAdminRequests]);

  const showDownloadMessage = async (message) => {
    await triggerDownloadAlert(message);
  };
  const { launchToCanary } = useLaunchToCanary();

  const handleAssetValuesSavePromise: TFormAssetsParamsProps['onSubmitPromise'] = async (
    payload,
  ): Promise<boolean> => {
    await triggerResultsLossAlert();

    return onAssetValuesSavePromise
      ? onAssetValuesSavePromise(payload)
      : new Promise((resolve) => resolve(false));
  };

  const handleSwitchToGridMarketView = () => {
    handleTabChange(EActiveTabState.CollaborationSettings);
    setActiveCollaborationTab(ECollaborationTab.Grid);
  };
  const handleSwitchToMembersView = () => {
    handleTabChange(EActiveTabState.Registry);
  };

  const renderSettingsTabsContent = (tab) => {
    switch (tab) {
      case EFormVariant.Express:
        return (
          <FormSettingsData
            className={s.formMaxHeight}
            id={formId.current}
            hasErrorsRef={formHasErrorsRef}
            onSubmit={handleSettingsDataSave}
            disableLocationField={false}
            theme="dark"
            isBasicConfiguration={false}
          />
        );
      case EFormVariant.Advanced:
        return (
          <>
            <SharingCoefficient onSubmit={handleSettingsDataSave} />
          </>
        );
      case EFormVariant.GridMarket:
        return (
          <GridMarketForm
            handleAssetValuesSavePromise={handleAssetValuesSavePromise}
            hostAssetUuid={rootAssetUuid ?? ''}
            theme="dark"
            formId={formId}
            readOnly={readOnly}
          />
        );
    }
  };

  const handleResultsTabClick = () => {
    if (!isSCM && !selectedAssetUuid) {
      dispatch(setSelectedAssetUuid(currentItem?.uuid));
    }
    handleTabChange(EActiveTabState.Community);
    if (isSCM) {
      dispatch(setSidebarExpanded(true));
    }
    if (hideResultsTab) hideResultsTab(true);
  };

  useEffect(() => {
    if (isSCM && activeTab == EActiveTabState.Profile)
      dispatch(setSelectedAssetUuid(currentItem?.uuid));
    onChangeSettingsTab?.(activeTab);
  }, [activeTab, onChangeSettingsTab, currentItem, dispatch, isSCM]);

  useEffect(() => {
    if (showResultsTabOnClick) {
      setActiveTab(EActiveTabState.Community);
      dispatch(setSidebarExpanded(true));
      if (hideResultsTab) hideResultsTab(true);
    }
  }, [showResultsTabOnClick, dispatch, isSCM, hideResultsTab]);

  useEffect(() => {
    dispatch(
      setSCMsidebarTab(
        isUserACommunityMember ? EActiveTabState.Profile : EActiveTabState.CollaborationSettings,
      ),
    );
  }, [isUserACommunityMember, dispatch]);

  useEffect(() => {
    if (selectedAssetUuid) flyToAsset(selectedAssetUuid, { specificZoom: 18 });
  }, [flyToAsset, selectedAssetUuid]);

  // TODO::this check needs to be updated once we have live results flag from BE
  const showLiveResultsIndidcator = useMemo(() => configType !== ConfigType.Collaboration, [
    configType,
  ]);

  // is the expand button hidden
  const hideExpandButton = useMemo(() => {
    if (isSCM) {
      return [EActiveTabState.Registry, EActiveTabState.Community].includes(activeTab);
    }
    return false;
  }, [activeTab, isSCM]);

  return (
    <>
      <div
        className={s.container}
        id={EDomIds.MODAL_MAP_SIDEBAR}
        style={{
          pointerEvents: isDisabledArea ? 'none' : 'auto',
        }}>
        <>
          {showHeaderTabs && (
            <>
              {!loggedIn && (
                <div className={s.header}>
                  <h3>{communityName}</h3>
                </div>
              )}
              {loggedIn && (
                <>
                  <div className={s.header}>
                    <ul className={s.headerNav}>
                      {isUserACommunityMember ? (
                        <li
                          className={classnames({
                            [s.active]: activeTab === EActiveTabState.Profile,
                          })}>
                          <button
                            type="button"
                            onClick={() => {
                              if (isSCM) {
                                dispatch(setSidebarExpanded(false));
                              }
                              handleTabChange(EActiveTabState.Profile);
                              if (hideResultsTab) hideResultsTab(false);
                            }}
                            title="Profile">
                            My Profile
                          </button>
                        </li>
                      ) : (
                        <li
                          className={classnames({
                            [s.active]: [
                              EActiveTabState.Settings,
                              EActiveTabState.CollaborationSettings,
                            ].includes(activeTab),
                          })}>
                          <button
                            type="button"
                            onClick={() =>
                              handleTabChange(
                                configType === ConfigType.Collaboration
                                  ? EActiveTabState.CollaborationSettings
                                  : EActiveTabState.Settings,
                              )
                            }
                            title="Settings">
                            Settings
                          </button>
                        </li>
                      )}

                      {!isEmbed && !isUserACommunityMember && (
                        <>
                          {configType &&
                            [ConfigType.CanaryNetwork, ConfigType.Collaboration].includes(
                              configType,
                            ) && (
                              <li
                                className={classnames({
                                  [s.active]: activeTab === EActiveTabState.Registry,
                                })}>
                                <button
                                  type="button"
                                  onClick={() => handleTabChange(EActiveTabState.Registry)}
                                  title="Members">
                                  Members
                                </button>
                              </li>
                            )}
                        </>
                      )}
                      {showResultsTab && (
                        <li
                          className={classnames(s.communityName, {
                            [s.active]: activeTab === EActiveTabState.Community,
                          })}
                          title="Results">
                          <button
                            type="button"
                            onClick={() => {
                              handleResultsTabClick();
                            }}>
                            Results
                          </button>
                        </li>
                      )}
                      {/* showGridMarketTab && (
                        <li
                          className={classnames(s.communityName, {
                            [s.active]: activeTab === EActiveTabState.GridMarketResults,
                          })}
                          title="Results">
                          <button
                            type="button"
                            onClick={() => {
                              dispatch(setSelectedAssetUuid(rootAssetUuid));
                              handleTabChange(EActiveTabState.GridMarketResults);
                            }}>
                            Grid Market Results
                          </button>
                        </li>
                          ) */}
                    </ul>
                    {/* {hideExpandButton ? null : (
                      <BaseButtonSquare
                        theme="flat-gray"
                        icon={sidebarExpanded ? 'collapse' : 'expand-2'}
                        size="2"
                        svgSize="3"
                        onClick={toggleSidebarExpand}
                        className={s.sidebarIconButton}
                      />
                    )} */}
                  </div>
                  {activeTab === EActiveTabState.GridMarketResults && (
                    <SidebarSubTab tabs={GridMarketAgentSubtabs} />
                  )}
                  {activeTab === EActiveTabState.CollaborationSettings && (
                    <SidebarSubTab tabs={CollaborationSubtabs} />
                  )}
                </>
              )}
            </>
          )}
          {/*
            Used conditional rendering instead of switching "display: none",
            because there is some issues with d3 charts when they are not visible.
            https://gridsingularity.atlassian.net/browse/PH-464
        */}
          {(() => {
            switch (activeTab) {
              case EActiveTabState.Community:
              case EActiveTabState.GridMarketResults: {
                if (simulationStatus === undefined) {
                  return (
                    <div className={s.graphsList}>
                      <RegularNoResults communityNotFound={communityNotFound} />
                    </div>
                  );
                }
                if (isSCM) {
                  return (
                    <div className={classNames(s.graphsList, s.scmResultsContainer)}>
                      {((isUserACommunityMember && activeResultsTab === EResultsTab.Home) ||
                        (!isUserACommunityMember &&
                          selectedAssetUuid !== communityAsset?.uuid)) && <SCMMyHome />}
                      {((isUserACommunityMember && activeResultsTab === EResultsTab.Community) ||
                        (!isUserACommunityMember &&
                          selectedAssetUuid === communityAsset?.uuid)) && <SCMMyCommunity />}
                    </div>
                  );
                }
                return (
                  <div className={s.graphsList}>
                    {typeof simulationStatus === 'undefined' ? (
                      <RegularNoResults communityNotFound={communityNotFound} />
                    ) : (
                      <>
                        {showLiveResultsIndidcator && (
                          <div className={s.liveResultsContainer}>
                            <span className={s.text}>Real-time results</span>
                          </div>
                        )}
                        <SimulationResultsPresentation
                          simulationResults={simulationResults}
                          // communitySimulationResults={communitySimulationResults}
                          jobId={activeConfigurationJobUuid}
                          settingsData={settingsData}
                          assetType={assetType}
                          showPresentationAreas={showPresentationAreas}
                          switchToGridMarketView={handleSwitchToGridMarketView}
                          switchToMembersView={handleSwitchToMembersView}
                          isUserACommunityMember={isUserACommunityMember}
                        />
                      </>
                    )}
                  </div>
                );
              }
              case EActiveTabState.Registry:
                return (
                  <MapSidebarCanary
                    onAddNewAsset={onAddNewAsset}
                    onAddNewAssetUnderUuid={onAddNewAssetUnderUuid}
                    onAssetValuesSave={onAssetValuesSave}
                    onAssetRemove={onAssetRemove}
                    onAssetValuesSavePromise={onAssetValuesSavePromise}
                  />
                );
              case EActiveTabState.Profile:
                return (
                  <div className={s.profile}>
                    <UserProfile
                      onAssetValuesSave={onAssetValuesSave}
                      onAssetValuesSavePromise={onAssetValuesSavePromise}
                    />
                  </div>
                );
              case EActiveTabState.CollaborationSettings:
                if (activeCollaborationTab === ECollaborationTab.Basic) {
                  return (
                    <div className={s.settingsList}>
                      <div className={s.formWrapper}>
                        {renderSettingsTabsContent(EFormVariant.Express)}
                        <div className={s.formButtonsWrapper}>
                          <BaseButton
                            type="submit"
                            className={s.formButton}
                            form={formId.current}
                            disabled={readOnly}>
                            Save
                          </BaseButton>
                        </div>
                      </div>
                    </div>
                    // <div className={classNames(s.settingsList, s.formSettingsLegacyContainer)}>
                    //   <FormSettingsDataLegacy
                    //     className={s.formMaxHeight}
                    //     id={formId.current}
                    //     hasErrorsRef={formHasErrorsRef}
                    //     onSubmit={handleSettingsDataSave}
                    //     disableLocationField={false}
                    //     theme="filled-gray"
                    //     visibleFieldsOrder={
                    //       CollaborationFormFields[activeCollaborationTab].visibleFieldsOrder
                    //     }
                    //     visibleFieldsInfo={
                    //       CollaborationFormFields[activeCollaborationTab].visibleFieldsInfo
                    //     }
                    //   />

                    // </div>
                  );
                } else if (activeCollaborationTab === ECollaborationTab.Advanced) {
                  return (
                    <div className={s.settingsList}>
                      <div className={s.formWrapper}>
                        {renderSettingsTabsContent(EFormVariant.Advanced)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className={classNames(s.settingsList, s.overflowYAuto)}>
                      {renderSettingsTabsContent(EFormVariant.GridMarket)}
                    </div>
                  );
                }

              case EActiveTabState.Settings:
                return (
                  <div className={s.settingsList}>
                    <TabNav
                      theme="1"
                      nav={[
                        {
                          label: 'Basic',
                          onClick() {
                            setFormVariant(EFormVariant.Express);
                          },
                          isActive: formVariant === EFormVariant.Express,
                        },
                        {
                          label: 'Advanced',
                          onClick() {
                            setFormVariant(EFormVariant.Advanced);
                          },
                          isActive: formVariant === EFormVariant.Advanced,
                        },
                        {
                          label: 'Grid Market',
                          onClick() {
                            setFormVariant(EFormVariant.GridMarket);
                          },
                          isActive: formVariant === EFormVariant.GridMarket,
                        },
                      ]}
                    />
                    <div className={s.formWrapper}>{renderSettingsTabsContent(formVariant)}</div>
                    <div className={s.formButtonsWrapper}>
                      <BaseButton
                        type="submit"
                        className={s.formButton}
                        form={formId.current}
                        disabled={readOnly}>
                        Save
                      </BaseButton>
                    </div>
                    {/* <div>
                      <BaseButton
                        className={classnames(s.settingsActionButton, s.settingsActionButtonBorder)}
                        onClick={async () => {
                          resultsDownloadAPI.handleDownload(showDownloadMessage);
                        }}
                        isLoading={resultsDownloadAPI.loading}
                        disabled={simulationStatus !== 'finished'}
                        icon={'download'}>
                        Download
                      </BaseButton>
                      <BaseButton
                        className={s.settingsActionButton}
                        onClick={deleteCommunity}
                        icon={'custom-trash'}
                        disabled={readOnly}>
                        Delete Community
                      </BaseButton>
                    </div> */}
                  </div>
                );
            }
          })()}
        </>
      </div>
    </>
  );
};
