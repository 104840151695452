import { Fade, Zoom } from 'react-reveal';

import React from 'react';
import { TProps } from './Market.types';
import { ToolTip } from 'src/pages/MarketDesign/components/ToolTip';
import basePlaqueOneMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/base-plaque-one.png';
import basePlaqueTwoMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/base-plaque-two.png';
import classnames from 'classnames';
import linesPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/lines-plaque.png';
import linksPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/links-plaque.png';
import marketIconMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/market.png';
import middlePlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/middle-plaque.png';
import s from './Market.module.scss';
import topPlaqueMarket from 'src/assets/images/LandingPage/marketDesign/layerTwo_markets/top-plaque.png';
import useMarketDesignContext from 'src/hooks/useMarketDesignContext';

export const BlueBoxOne: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Bottom-up Market Design</h3>
          <p>
            Decentralised, asset-based market design enables trading at local levels, scaling up to
            higher municipal and national levels by connecting markets into a transactive grid.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Local First</h3>
          <p>
            Locally generated energy is prioritised in this decentralised market structure,
            increasing resilience and sustainability for the grid and the communities.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxThree: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>Grid-aware, advanced system</h3>
          <p>
            Markets incorporate grid topology to enable granular, dynamic grid accounting and
            integrated auxiliary services.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const BlueBoxFour: React.FC<TProps> = ({ show }) => {
  return (
    <Fade bottom collapse={!show} when={show} duration={2000} delay={3000}>
      <div className={s.boxContent}>
        <div className={s.boxText}>
          <h3>New Degrees of Freedom</h3>
          <p>
            Trades take place across local, municipal and national markets, creating new
            possibilities and expanding your choices of energy source, type, trading partner....
          </p>
        </div>
      </div>
    </Fade>
  );
};

export const MarketOne: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Zoom opposite collapse={!show} when={show} duration={1000}>
        <img src={basePlaqueOneMarket} className={classnames(s.imageFloating, s.basePlaqueOne)} />
      </Zoom>
      <Fade opposite collapse={!show} when={show} delay={1000}>
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesPlaque)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1500} duration={1000}>
        <img src={middlePlaqueMarket} className={classnames(s.imageFloating, s.middlePlaque)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={2000}>
        <img src={linksPlaqueMarket} className={classnames(s.imageFloating, s.linksPlaque)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={2500} duration={1000}>
        <img src={topPlaqueMarket} className={classnames(s.imageFloating, s.topPlaque)} />
      </Fade>
    </>
  );
};

export const Voltage: React.FC<TProps> = ({ show, scrollTo, currentLayer }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={2000}>
        <div className={s.boxVoltage}>
          <span>Voltage Level</span>
          <div className={s.voltage}>
            <button
              className={`${currentLayer === 6 ? s.actived : ''}`}
              onClick={() => scrollTo(6)}>
              LOW VOLTAGE
            </button>
            <button
              className={`${currentLayer === 7 ? s.actived : ''}`}
              onClick={() => scrollTo(7)}>
              MEDIUM VOLTAGE
            </button>
            <button
              className={`${currentLayer === 8 ? s.actived : ''}`}
              onClick={() => scrollTo(8)}>
              HIGH VOLTAGE
            </button>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const TooltipMarketTwo: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={1000}>
      <div className={s.marketTwoTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <div>
                <img src={marketIconMarket} />
                <span>Second Level Market</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>Neighbourhood / Town</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketTwo: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade bottom opposite collapse={!show} when={show} duration={1000}>
        <img src={basePlaqueTwoMarket} className={classnames(s.imageFloating, s.plaqueMarketTwo)} />
      </Fade>
    </>
  );
};

export const GrayBox: React.FC<TProps> = ({ show }) => {
  const { isDocVisible } = useMarketDesignContext();

  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000}>
        <div
          className={classnames(s.grayBox, {
            [s.grayBoxForDoc]: isDocVisible,
          })}>
          <div className={s.grayBoxText}>
            <p>
              Markets may contain multiple exchanges like spot or balancing. See {''}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://gridsingularity.com/resources/trending">
                Resources
              </a>{' '}
              to learn more.
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const GrayBoxSecond: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <div className={s.grayBox}>
          <div className={s.grayBoxText}>
            <h3>Second Level Market</h3>
            <p>
              Your home starts trading with other houses and buildings in the neighbourhood and the
              town where you live
            </p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const TooltipMarketThree: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={2000}>
      <div className={s.marketThreeTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <div>
                <img src={marketIconMarket} />
                <span>Third Level Market</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>City and Intercity Markets</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketThree: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade top opposite collapse={!show} when={show} duration={1000}>
        <img
          src={basePlaqueTwoMarket}
          className={classnames(s.imageFloating, s.basePlaqueMarket)}
        />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={1000}>
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesMarketThree)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1000}>
        <img
          src={middlePlaqueMarket}
          className={classnames(s.imageFloating, s.topPlaqueMarketThree)}
        />
        <div className={s.marketPopper}>
          <div>Medium Voltage</div>
        </div>
      </Fade>
    </>
  );
};

export const GrayBoxThird: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={1000} delay={1000}>
        <div className={s.grayBoxThird}>
          <div className={s.grayBoxText}>
            <h3>Third Level Market</h3>
            <p>Your town/city trades with neighbouring towns</p>
          </div>
        </div>
      </Fade>
    </>
  );
};

export const TooltipNextTooltip: React.FC<TProps> = ({ show }) => {
  return (
    <Fade top opposite collapse={!show} when={show} duration={1000} delay={3000}>
      <div className={s.marketNextTooltip}>
        <ToolTip
          inline={true}
          position="top"
          title={
            <>
              <div>
                <img src={marketIconMarket} />
                <span>Next Levels</span>
              </div>
              <br />
              <div className={s.descriptionTooltip}>Region, Country, Global Markets</div>
            </>
          }
        />
      </div>
    </Fade>
  );
};

export const MarketNextLevel: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade top opposite collapse={!show} when={show} duration={1000}>
        <img src={basePlaqueTwoMarket} className={classnames(s.imageFloating, s.basePlaqueNext)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={1000}>
        <img src={linesPlaqueMarket} className={classnames(s.imageFloating, s.linesPlaqueNext)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={1500}>
        <img src={middlePlaqueMarket} className={classnames(s.imageFloating, s.middlePlaqueNext)} />
      </Fade>
      <Fade opposite collapse={!show} when={show} delay={2000}>
        <img src={linksPlaqueMarket} className={classnames(s.imageFloating, s.linksPlaqueNext)} />
      </Fade>
      <Fade top opposite collapse={!show} when={show} delay={2500}>
        <img src={topPlaqueMarket} className={classnames(s.imageFloating, s.topPlaqueNext)} />
      </Fade>
    </>
  );
};

export const GrayBoxNext: React.FC<TProps> = ({ show }) => {
  return (
    <>
      <Fade opposite collapse={!show} when={show} duration={2000}>
        <div className={s.grayBoxNext}>
          <div className={s.grayBoxText}>
            <h3>Next Levels</h3>
            <p>Bottom up energy trading becomes the global standard, optimizing use of energy</p>
          </div>
        </div>
      </Fade>
    </>
  );
};
