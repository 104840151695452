import { ChartOptions, TooltipOptions } from 'chart.js';
import {
  commonChartOptions,
  commonInteraction,
} from 'src/components/_charts/chartsData/commonOptions';

import { TooltipDefault } from 'src/components/_charts/TooltipFactory/tooltips/TooltipDefault/TooltipDefault';
import { TooltipFactory } from 'src/components/_charts/TooltipFactory/TooltipFactory';
import { tooltipCallbacks } from 'src/components/_charts/chartsData/tooltipCallbacks';

const scaleX = {
  type: 'time' as const,
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};

const scaleY = {
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
    drawTicks: false,
  },
};

const scaleYTitle = {
  display: true,
  font: {
    size: 8,
  },
};

const tooltip: TooltipOptions<any> = {
  enabled: false,
  external: (context) =>
    TooltipFactory({ context, component: TooltipDefault, position: 'next-to-cursor' }),
  // @ts-ignore
  callbacks: {
    title: tooltipCallbacks.title,
    label: tooltipCallbacks.label,
  },
};

export const DeviceProfileOneConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  layout: {
    padding: {
      top: 5,
      bottom: 5,
    },
  },
  interaction: commonInteraction,
  scales: {
    x: scaleX,
    y: {
      ...scaleY,
      title: {
        ...scaleYTitle,
      },
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 1,
    },
  },
  plugins: {
    tooltip,
  },
};

export const DeviceProfileTwoConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: { ...scaleX, stacked: true },
    y: {
      ...scaleY,
      stacked: false,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip,
  },
};

export const DeviceProfileThreeConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: scaleX,
    y: {
      ...scaleY,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip,
  },
};

export const DeviceProfileFourConfig: ChartOptions = {
  ...commonChartOptions,
  parsing: undefined,
  interaction: commonInteraction,
  scales: {
    x: { ...scaleX, stacked: true },
    y: {
      ...scaleY,
      stacked: false,
      title: {
        ...scaleYTitle,
      },
    },
  },
  plugins: {
    tooltip,
  },
};
