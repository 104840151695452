import ContentfulContext, { ICountriesEntry } from 'src/contexts/ContentfulContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Avatar } from 'src/components/LandingPage/TeamGlobe/components/Avatar/Avatar';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { COUNTRY_GEO_COORDS } from 'src/constants/countries';
import { EAppMode } from 'src/utils/appMode';
import { FadeAnimationHelper } from 'src/components/AnimationHelpers/FadeAnimationHelper/FadeAnimationHelper';
import Flag from 'react-world-flags';
import { TTGMember } from 'src/components/LandingPage/TeamGlobe/TeamGlobe.types';
import { TeamGlobe } from 'src/components/LandingPage/TeamGlobe/TeamGlobe';
import classNames from 'classnames';
import { routesConfig } from 'src/routes/routes.config';
import s from './OurTeam.module.scss';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export enum ESectionsTeam {
  INTRO = 'intro',
  GLOBE = 'globe',
  PEOPLE = 'people',
  MEMBER = 'member',
  EQUALITY = 'equality',
}

export function OurTeam() {
  const [section, setSection] = useState(ESectionsTeam.INTRO);
  const { teamMembersEntry, countriesEntry, officesEntry } = useContext(ContentfulContext);
  const [selectedMember, setSelectedMember] = useState<TTGMember | null>(null);
  const appMode = useSelector(selectAppMode);
  const history = useHistory();

  const selectMember = (member: TTGMember) => {
    setSelectedMember(member);
    setSection(ESectionsTeam.MEMBER);
  };

  const memberId = useParams<{
    memberId: string;
  }>()?.memberId;

  //if url contains specific memberId
  useEffect(() => {
    if (memberId) {
      //prevent multiple re-render
      if (!selectedMember) {
        if (teamMembersEntry.length) {
          const member = teamMembersEntry.find((member) => member.id === memberId);
          if (member) {
            selectMember(member);
          }
          //if memberId in the url does not match any team member
          else {
            history.push(routesConfig.company() + '/our-team');

            setSection(ESectionsTeam.PEOPLE);
          }
        }
      }
    }
  }, [teamMembersEntry, memberId, selectedMember, history]);

  const topFunction = useCallback(() => {
    if (appMode === EAppMode.Mobile) {
      document?.getElementById('menuLandingPage')?.scrollIntoView();
    } else {
      const scrollToTopBtn = document.getElementById('content');
      if (scrollToTopBtn) scrollToTopBtn.scrollTop = 0;
    }
  }, [appMode]);

  useEffect(() => {
    if (selectedMember) {
      topFunction();
    }
  }, [selectedMember, topFunction]);

  const headquarters = useMemo(
    () =>
      countriesEntry
        ?.filter((country) => country.headquarter)
        .reduce((acc: string[], country: ICountriesEntry) => {
          acc.push(country.countryCode);

          return acc;
        }, []),
    [countriesEntry],
  );

  const orderMembers = () => {
    const membersOrdered = teamMembersEntry.sort((a, b) =>
      a?.order ? (b?.order && a.order - b.order) || 0 : 0,
    );

    const membersWithOrderField = membersOrdered.filter((e) => e?.order);
    const membersWithNoOrderField = membersOrdered.filter((e) => !e?.order);

    const lastNameAlphabetic = membersWithNoOrderField.sort((a, b) => {
      const lastNameA = a.name.trim().split(' ').reverse()[0];
      const lastNameB = b.name.trim().split(' ').reverse()[0];

      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });

    return [...membersWithOrderField, ...lastNameAlphabetic];
  };

  const membersOrdered = orderMembers();

  const countriesMembers = useMemo(
    () =>
      COUNTRY_GEO_COORDS.filter(
        (country) =>
          teamMembersEntry.some((member) => member.countryCode === country.code) ||
          headquarters.some((headquarter) => headquarter === country.code),
      ),
    [headquarters, teamMembersEntry],
  );

  function renderIntro() {
    return (
      <>
        <div className={s.boxInfo}>
          <div className={s.bg} onClick={() => setSection(ESectionsTeam.PEOPLE)}></div>
          <div className={s.info}>
            <p>
              {appMode === EAppMode.Mobile
                ? 'Our cofounders are seasoned energy market professionals and blockchain pioneers.'
                : 'Our cofounders are seasoned energy market professionals and blockchain pioneers, who engaged a talented and diverse team of energy design thinkers and developers.'}
              <div className={s.controls}>
                {appMode !== EAppMode.Mobile && (
                  <div
                    className={s.buttonIntro}
                    onClick={() => sectionsControl(ESectionsTeam.GLOBE)}>
                    <BaseIcon icon="world-view" className={s.iconIntro} size={24} />
                    <span>Team map</span>
                  </div>
                )}
                <div
                  className={s.buttonIntro}
                  onClick={() => history.push(routesConfig.company() + '/bios')}>
                  <BaseIcon icon="members" className={s.iconIntro} size={24} />
                  <span>Bios</span>
                </div>
                <div
                  className={s.buttonIntro}
                  onClick={() => history.push(routesConfig.company() + '/gender-equality-plan')}>
                  <BaseIcon icon="equality" className={s.iconIntro} size={24} />
                  <span>Gender equality</span>
                </div>
              </div>
            </p>
          </div>
          <div className={s.mobileBg} onClick={() => setSection(ESectionsTeam.PEOPLE)} />
        </div>
      </>
    );
  }

  function renderPeople() {
    return (
      <div className={s.wrapperPeople}>
        <div className={s.boxPeople}>
          {membersOrdered?.map((member, key) => (
            <div
              key={key.toString()}
              className={s.photo}
              onClick={() => {
                history.push(routesConfig.company() + `/our-team/${member.id}`);
                selectMember(member);
              }}>
              <div
                className={s.boxPhoto}
                style={{ backgroundImage: `url(${member.avatar})` }}></div>
              <span>{member.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderMember() {
    return (
      <FadeAnimationHelper
        key="bio-section"
        className={s.bioSection}
        fadeIn={!!selectedMember}
        delay={!!selectedMember ? 0.5 : 0}>
        <div className={s.header}>
          <BaseButton
            className={s.bioBackBtn}
            theme="quaternary"
            icon="arrow-left-full"
            onClick={() => {
              //delete memberId from url
              history.push(routesConfig.company() + '/our-team');
              setSelectedMember(null);
              setSection(ESectionsTeam.PEOPLE);
            }}>
            BACK
          </BaseButton>
          <div className={s.bioAvatarContainer}>
            <Avatar className={s.bioAvatar} url={selectedMember?.avatar} />
            <div>
              <p className={s.bioName}>{selectedMember?.name}</p>
              <Flag code={selectedMember?.countryCode} height="10" />
              <span className={s.bioArea}>{selectedMember?.area}</span>
            </div>
          </div>
        </div>
        <div className={s.bioDescriptionContainer}>
          <p className={s.bioDescriptionName}>{selectedMember?.name}</p>
          <p className={s.bioDescription}>{selectedMember?.bio}</p>
        </div>
      </FadeAnimationHelper>
    );
  }

  function renderGlobe() {
    return (
      <>
        <AnimatePresence>
          <FadeAnimationHelper
            key="team-globe-section"
            className={s.teamGlobeSection}
            fadeIn={!selectedMember}
            delay={!selectedMember ? 0.5 : 0}>
            <TeamGlobe
              className={s.teamGlobe}
              members={teamMembersEntry}
              countries={countriesMembers}
              headquarters={headquarters}
              onSelectMember={setSelectedMember}
            />
            <div className={s.teamGlobeInfo}>
              <p className={s.teamGlobeTitle}>Energy is everywhere, so are we.</p>
              <div className={s.teamGlobeInfoDetail}>
                <div className={s.teamGlobeInfoDetailItem}>
                  <p className={s.teamGlobeNumber}>{countriesMembers.length}+</p>
                  <p className={s.teamGlobeNumberLabel}>Nationalities</p>
                </div>
                <div className={s.teamGlobeInfoDetailItem}>
                  <p className={s.teamGlobeNumber}>{teamMembersEntry.length}+</p>
                  <p className={s.teamGlobeNumberLabel}>Members</p>
                </div>
              </div>
              <div className={s.teamGlobeContainer}>
                <div className={s.teamGlobeHeadquarters}>
                  {officesEntry?.map((office, key) => (
                    <p className={s.teamGlobeHeadquarterItem} key={key}>
                      <span className={s.teamGlobeHeadquarterItemTitle}>{office.title}</span> <br />
                      {office.address}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </FadeAnimationHelper>
        </AnimatePresence>
      </>
    );
  }

  function renderTeamsPageForMobile() {
    return (
      <div className={s.wrapperPeopleMobile}>
        <p className={s.heading}>
          Energy is everywhere, <br /> so are we.
        </p>
        <div className={s.descriptionContainer}>
          <div>
            <span className={s.count}>{teamMembersEntry?.length}</span>
            <span className={s.label}>Members</span>
          </div>
          <div>
            <span className={s.count}>{countriesMembers.length}+</span>
            <span className={s.label}>Nationalities</span>
          </div>
        </div>
        <div className={s.boxPeople}>
          {membersOrdered?.map((member, key) => (
            <div
              key={key.toString()}
              className={s.tile}
              onClick={() => {
                history.push(routesConfig.company + `/our-team/${member.id}`);
                selectMember(member);
              }}>
              <div className={s.photoContainerBorder}>
                <div
                  className={s.photoContainer}
                  style={{ backgroundImage: `url(${member.avatar})` }}
                />
              </div>
              <div className={s.textContainer}>
                <div className={s.name}>{member.name}</div>
                <div className={s.bioLink}>Read Bio</div>
              </div>
            </div>
          ))}
          <div className={s.teamHeadquarters}>
            {officesEntry?.map((office, key) => (
              <div className={s.box} key={key}>
                <p className={s.teamGlobeHeadquarterItem}>
                  <span className={s.teamGlobeHeadquarterItemTitle}>{office.title}</span> <br />
                  {office.address}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function sectionsControl(section, back?: boolean) {
    switch (section) {
      default:
      case ESectionsTeam.INTRO:
        setSection(ESectionsTeam.PEOPLE);
        break;
      case ESectionsTeam.PEOPLE:
        setSection(back ? ESectionsTeam.INTRO : ESectionsTeam.GLOBE);
        break;
      case ESectionsTeam.GLOBE:
        setSection(ESectionsTeam.GLOBE);
        break;
      case ESectionsTeam.MEMBER:
        setSection(ESectionsTeam.PEOPLE);
        break;
      case ESectionsTeam.EQUALITY:
        setSection(ESectionsTeam.EQUALITY);
        break;
    }
  }

  const renderControl = {
    [ESectionsTeam.INTRO]: renderIntro(),
    [ESectionsTeam.GLOBE]: appMode === EAppMode.Mobile ? renderTeamsPageForMobile() : renderGlobe(),
    [ESectionsTeam.PEOPLE]: renderPeople(),
    [ESectionsTeam.MEMBER]: renderMember(),
  };

  return (
    <div
      id="content"
      className={s.content}
      style={section === ESectionsTeam.GLOBE ? { overflowY: 'visible' } : {}}>
      {renderControl?.[section]}
      {(section === ESectionsTeam.PEOPLE || section === ESectionsTeam.EQUALITY) && (
        <BaseButton
          className={s.backSection}
          theme="quaternary"
          icon="arrow-left-full"
          onClick={() => {
            setSelectedMember(null);
            setSection(ESectionsTeam.INTRO);
          }}>
          BACK
        </BaseButton>
      )}
      {section === ESectionsTeam.GLOBE && (
        <div className={s.nonPadding}>
          <div
            className={classNames(s.buttonIntro, s.backSec)}
            onClick={() => sectionsControl(ESectionsTeam.MEMBER)}>
            <BaseIcon icon="members" className={s.iconIntro} size={24} />
            <span>Bios</span>
          </div>
          <div
            className={classNames(s.buttonIntro, s.backSec)}
            onClick={() => history.push(routesConfig.company() + '/gender-equality-plan')}>
            <BaseIcon icon="equality" className={s.iconIntro} size={24} />
            <span>Gender equality</span>
          </div>
        </div>
      )}
    </div>
  );
}
