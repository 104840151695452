import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { selectIsLoggedIn, selectTokenAuth, selectUsername } from 'src/redux/auth/auth.selectors';
import {
  setActiveConfigurationJobUuid,
  setActiveConfigurationUuid,
} from 'src/redux/configuration/configuration.slice';

import { EAppMode } from 'src/utils/appMode';
import { ListItemBlock } from 'src/components/CommunityListItem/components/ListItemBlock';
import { ListItemDefault } from 'src/components/CommunityListItem/components/ListItemDefault';
import { TCommunityListItemProps } from './CommunityListItem.types';
import { getPlaceNameFromCoords } from 'src/utils/worldMap/helpers';
import { routesConfig } from 'src/routes/routes.config';
import { selectAppMode } from 'src/redux/application/application.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useHistory } from 'react-router';
import { useLookForCommunityUuidLazyQuery } from 'src/graphql';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { useSimulationStatus } from 'src/hooks/useSimulationStatus';

export const CommunityListItem: React.FC<TCommunityListItemProps> = function CommunityListItem({
  className,
  style,
  configurationUuid,
  user,
  title,
  description,
  configType,
  // actionNumber,
  locationCoords,
  onMenuClick,
}) {
  const dispatch = useAppDispatch();
  const modalRefObject = useRef<HTMLButtonElement>(null);
  const [place, setPlace] = useState('');
  const [jobId, setJobId] = useState<string>();
  const [communityId, setCommunityId] = useState<string>();
  const token = useSelector(selectTokenAuth);
  const history = useHistory();
  const belongsToLoggedUser = user === useSelector(selectUsername);
  const { sidebarOpen } = useConfigurationFlowState();
  const { simulationStatus } = useSimulationStatus(jobId, !sidebarOpen, configurationUuid);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const appMode = useSelector(selectAppMode);
  const [lookForCommunityUuid, { loading, data }] = useLookForCommunityUuidLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.readConfiguration?.simulationResults) {
        const children = data.readConfiguration.scenarioData?.latest?.children || [];
        if (children.length) {
          const community = children.find((item: any) => item?.name?.toLowerCase() === 'community');
          if (community) setCommunityId((community as any).uuid);
        }
        setJobId(data.readConfiguration.simulationResults);
      }
    },
  });

  const { runButtonState, runButtonOptions, stopButtonOptions } = useSimulationButtons({
    jobUuid: jobId,
    configurationUuid,
    simulationStatus,
  });

  const runSimulation = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (runButtonOptions.onClick) {
        const jobUuid = await runButtonOptions.onClick();
        if (runButtonState === 'run') {
          if (jobUuid) setJobId(jobUuid);
        }
      }
    },
    [runButtonOptions, runButtonState],
  );

  const stopSimulation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (stopButtonOptions.onClick) stopButtonOptions.onClick(jobId);
      setJobId(undefined);
    },
    [jobId, stopButtonOptions],
  );

  const goToResults = useCallback(() => {
    batch(() => {
      dispatch(setActiveConfigurationUuid(configurationUuid));
      if (jobId) {
        dispatch(setActiveConfigurationJobUuid(jobId));
      }
      history.push(routesConfig.singularityMapResults(configurationUuid, ''));
    });
  }, [configurationUuid, dispatch, history, jobId]);

  const handleCommunityClick = useCallback(() => {
    goToResults();
  }, [goToResults]);

  const handleMenuClick = useCallback(
    (buttonRef: HTMLButtonElement) => {
      onMenuClick(buttonRef, {
        communityUuid: configurationUuid,
      });
    },
    [configurationUuid, onMenuClick],
  );

  const commonProps = {
    onCommunityClick: handleCommunityClick,
    onMenuClick: handleMenuClick,
    communityId: communityId || '',
    jobId: jobId || '',
    className,
    style,
    place,
    title,
    isLoggedIn,
    modalRefObject,
    belongsToLoggedUser,
    configType,
  };

  // useLookForCommunityUuidQuery({
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     uuid: configurationUuid,
  //   },
  //   skip: !!jobId || token?.username !== user || !sidebarOpen,
  //   onCompleted: (data) => {
  //     if (data.readConfiguration?.simulationResults) {
  //       const children = data.readConfiguration.scenarioData?.latest?.children || [];
  //       if (children.length) {
  //         const community = children.find(
  //           (item: any) => item?.name?.toLowerCase() === 'community',
  //         );
  //         if (community) setCommunityId((community as any).uuid);
  //       }
  //       setJobId(data.readConfiguration.simulationResults);
  //     }
  //   },
  // });

  useEffect(() => {
    let isComponentMounted = true;
    const asyncAction = async () => {
      if (locationCoords) {
        const v = await getPlaceNameFromCoords(locationCoords);
        if (isComponentMounted) {
          setPlace(v?.place_name || '');
        }
      }
    };

    asyncAction();
    if (!data) {
      lookForCommunityUuid({
        variables: {
          uuid: configurationUuid,
        },
      });
    }

    return () => {
      isComponentMounted = false;
    };
  }, [configurationUuid, data, locationCoords, lookForCommunityUuid]);

  /**
   * ListItemDefault => Default view for desktop mode
   * ListItemBlock => Default view for mobile mode
   */
  return appMode === EAppMode.Desktop ? (
    <ListItemDefault
      configurationUuid={configurationUuid}
      description={description}
      actionNumber={1}
      onResultsClick={goToResults}
      onRunButtonClick={runSimulation}
      onStopButtonClick={stopSimulation}
      {...commonProps}
    />
  ) : (
    <ListItemBlock loading={loading} {...commonProps} />
  );
};
