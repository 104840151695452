import { BaseInput, TBaseInputProps } from 'src/components/BaseInput';
import { EUserRoles, TRenamedUserRoles } from 'src/typings/base-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReadUserRoleDocument, UserRoleEnum, useSetUserRoleMutation } from 'src/graphql';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseSelect } from 'src/components/BaseSelect';
import { CanaryNetworkServiceContext } from 'src/components/CanaryNetworkServiceProvider/CanaryNetworkServiceProvider';
import { TCanaryInvitationPanelProps } from './CanaryInvitationPanel.types';
import { getFrontendUserRoleName } from 'src/utils/getFrontendUserRoleName';
import { openToast } from 'src/redux/toast/toast.slice';
import s from './CanaryInvitationPanel.module.scss';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { selectAvailableUserRoles } from 'src/redux/application/application.selectors';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

const validateEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const CanaryInvitationPanel: React.FC<TCanaryInvitationPanelProps> = ({
  emails,
  onCancel,
}) => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const canaryNetworkService = useContext(CanaryNetworkServiceContext);

  const userRoles = useSelector(selectAvailableUserRoles);
  const configUuid = useSelector(selectActiveConfigurationUuid);

  const [usersEmails, setUsersEmails] = useState(emails);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [dbUserRoles, setDbUserRoles] = useState({});
  const [invitationInProgress, setInvitationInProgress] = useState(false);
  const [selectedUsersRoles, setSelectedUsersRoles] = useState<{ [key: string]: EUserRoles }>({});

  const [setUserRole] = useSetUserRoleMutation({
    onCompleted: () => {
      dispatch(
        openToast({
          message: 'User Role Updated',
          type: 'success',
        }),
      );
    },
    onError: (err) => {
      const msg = JSON.parse(err.message).set_user_role;
      dispatch(
        openToast({
          message: msg || 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const isAggregatorSelected = useMemo(() => {
    let aggregatorSelected = false;

    for (const key in selectedUsersRoles) {
      if (Object.prototype.hasOwnProperty.call(selectedUsersRoles, key)) {
        const element = selectedUsersRoles[key];
        if (element === EUserRoles.Aggregator) {
          aggregatorSelected = true;
          break;
        }
      }
    }
    return aggregatorSelected;
  }, [selectedUsersRoles]);

  const onInputValueChange: TBaseInputProps['onChange'] = ({ value }) => {
    if (typeof value === 'string') {
      value = value.replaceAll(' ', ';');
      setInputValue(value);
    }
  };

  const inviteClick = () => {
    const emails = inputValue.split(';').filter((item) => item.length > 0);

    const inInvalid = emails.map(validateEmail).some((item) => item === null);
    if (inInvalid) {
      if (emails.length > 1) {
        setInputError('Some of given emails is not valid');
      } else {
        setInputError('Email is not valid');
      }
    } else {
      if (emails.length === 0) {
        setInputError('Input is empty');
      } else {
        setUsersEmails([...usersEmails, ...emails]);
        setSelectedUsersRoles({
          ...selectedUsersRoles,
          ...emails.reduce((acc, item) => {
            acc[item] = EUserRoles.Researcher;
            return acc;
          }, {}),
        });
        setInputError('');
        setInputValue('');
      }
    }
  };

  const getAvailableUserOptions = (email: string) =>
    userRoles
      .reduce<typeof userRoles>((acc, item) => {
        const isUserAggregaor = selectedUsersRoles[email] === EUserRoles.Aggregator;
        const isAggregator = item.roleName === EUserRoles.Aggregator;
        const isAdmin = item.roleName === EUserRoles.Admin;
        // Remove admin and aggregator if already selected for another user

        if (
          (isAggregatorSelected && !isUserAggregaor && isAggregator) ||
          isAdmin ||
          item.roleName === EUserRoles.ExchangeOperator
        )
          return acc;

        acc.push(item);

        return acc;
      }, [])
      .map((item) => ({
        label: getFrontendUserRoleName(item.roleName),
        value: getFrontendUserRoleName(item.roleName),
      }));

  const getUserRole = useCallback<(email: string) => Promise<EUserRoles | null>>(
    async (email: string) => {
      try {
        const { data } = await client.query({
          query: ReadUserRoleDocument,
          fetchPolicy: 'no-cache',
          variables: {
            email,
          },
        });
        return data.readUserRole.roleName;
      } catch {
        return null;
      }
    },
    [client],
  );

  const assignUserRole = (email: string, value: EUserRoles) => {
    setSelectedUsersRoles({
      ...selectedUsersRoles,
      [email]: value,
    });
  };

  const inviteUsers = async () => {
    if (configUuid) {
      setInvitationInProgress(true);
      const promises: Promise<any>[] = [];
      usersEmails.forEach((email) =>
        promises.push(
          new Promise(async (resolve) => {
            let isAdmin = false;
            if (dbUserRoles[email]) {
              const userRole = userRoles.find(({ roleName }) => {
                return roleName === selectedUsersRoles[email] || roleName === dbUserRoles[email];
              });
              isAdmin = userRole?.roleName === EUserRoles.Admin;
              if (userRole && !isAdmin) {
                await setUserRole({
                  variables: {
                    email,
                    username: email,
                    role: userRole?.value,
                  },
                });
              }
            } else {
              // setting default role for new user
              let userRole = UserRoleEnum[selectedUsersRoles[email]]
                ? UserRoleEnum[selectedUsersRoles[email]]
                : UserRoleEnum['Researcher'];
              await canaryNetworkService.inviteUserToWebsite(configUuid, email, userRole);
            }

            if (!isAdmin) {
              await canaryNetworkService.inviteUserToCn(configUuid, email);
              resolve(true);
            } else {
              dispatch(
                openToast({
                  message: "Can't invite the admin user - " + email,
                  type: 'error',
                }),
              );
              resolve(false);
            }
          }),
        ),
      );
      const allpromises = await Promise.all(promises);
      if (allpromises.some((val) => val == true)) {
        dispatch(
          openToast({
            message: 'Invitations sent',
            type: 'success',
          }),
        );
      }

      setInvitationInProgress(false);
      onCancel();
    }
  };

  const removeUserInvitation = (email: string) => {
    const usersRoles = { ...selectedUsersRoles };
    delete usersRoles[email];
    setSelectedUsersRoles(usersRoles);
    setUsersEmails(usersEmails.filter((em) => em !== email));
  };

  useEffect(() => {
    const promises: Promise<{ email: string; role: EUserRoles } | null>[] = [];
    usersEmails.forEach((email) => {
      promises.push(
        new Promise<{ email: string; role: EUserRoles } | null>(async (resolve) => {
          const role = await getUserRole(email);
          if (role) {
            resolve({ email, role });
          }
          resolve(null);
        }),
      );
    });

    Promise.all(promises).then((data) => {
      const usersRoles = {};
      data.forEach((item) => {
        if (item) {
          usersRoles[item.email] = item.role;
        }
      });
      setDbUserRoles({
        ...usersRoles,
      });
    });
  }, [getUserRole, usersEmails]);

  return (
    <div className={s.container}>
      <h3>Set the new users roles</h3>
      <div className={s.invitationsList}>
        {usersEmails.length === 0 && <span>Add at least one email address.</span>}
        {usersEmails.map((email, i) => {
          return (
            <div key={i} className={s.invitation}>
              <div className={s.avatar}></div>
              <p>{email}</p>
              {!dbUserRoles[email] ? (
                <span className={s.tag}>Invited</span>
              ) : dbUserRoles[email] === EUserRoles.Admin ? (
                <span className={s.tag}>Admin</span>
              ) : (
                <BaseSelect
                  className={s.roleSelector}
                  theme="filled-white"
                  name="userRole"
                  value={getFrontendUserRoleName(
                    selectedUsersRoles[email] || dbUserRoles[email] || '',
                  )}
                  options={getAvailableUserOptions(email)}
                  onChange={({ value }) => assignUserRole(email, value as EUserRoles)}
                  inputHeight="2"
                />
              )}
              <button type="button" onClick={() => removeUserInvitation(email)}>
                <BaseIcon icon="close" size={13} />
              </button>
            </div>
          );
        })}
      </div>
      <div className={s.addMore}>
        <h3>Add {usersEmails.length > 0 ? 'more' : ''} people</h3>
        <div>
          <BaseInput
            inputHeight="1"
            className={s.input}
            theme="filled-gray"
            type="text"
            name="email"
            placeholder="Email"
            error={inputError}
            value={inputValue}
            onChange={onInputValueChange}
          />
          <BaseButton size="medium" theme="primary" onClick={inviteClick}>
            Add
          </BaseButton>
        </div>
      </div>
      <footer>
        {invitationInProgress ? (
          <BaseIcon icon="spinner" size={40} />
        ) : (
          <>
            <button type="button" className={s.cancelBtn} onClick={onCancel}>
              Cancel
            </button>
            <BaseButton disabled={usersEmails.length === 0} onClick={inviteUsers}>
              Send invites
            </BaseButton>
          </>
        )}
      </footer>
    </div>
  );
};
