import {
  Exact,
  ReadConfigurationQuery,
  useReadConfigurationLazyQuery,
  useRemoveCommunityMemberMutation,
} from 'src/graphql';
import { TSCMHome, setSCMhomeDetails } from 'src/redux/scm/scm.slice';

import { QueryLazyOptions } from '@apollo/client';
import { openToast } from 'src/redux/toast/toast.slice';
import { selectActiveConfigurationUuid } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export type TUseSCMMemberEventsProps = {
  toggleAssetView?: () => void;
};

export type TUseSCMMemberEventsReturn = {
  handleReadSCMConfiguration: (
    options?:
      | QueryLazyOptions<
          Exact<{
            uuid: string;
          }>
        >
      | undefined,
  ) => void;
  handleRemoveCommunityMember: (uuid: string) => void;
};

export const useSCMMemberEvents = ({
  toggleAssetView,
}: TUseSCMMemberEventsProps): TUseSCMMemberEventsReturn => {
  const dispatch = useAppDispatch();
  const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);

  const setHomesToStore = useCallback(
    (data: ReadConfigurationQuery) => {
      const scmHomeDetails = data.readConfiguration?.scenarioData?.homeInfo?.scmHomeDetails;
      if (scmHomeDetails) dispatch(setSCMhomeDetails(scmHomeDetails as TSCMHome[]));
    },
    [dispatch],
  );

  const [handleReadSCMConfiguration] = useReadConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setHomesToStore(data);
    },
  });

  const [handleReadSCMConfigurationAfterRemoveMember] = useReadConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setHomesToStore(data);
      if (toggleAssetView) toggleAssetView();
    },
  });

  const [removeCommunityMemberMutation] = useRemoveCommunityMemberMutation({
    onCompleted: (result) => {
      if (activeConfigurationUuid)
        handleReadSCMConfigurationAfterRemoveMember({
          variables: { uuid: activeConfigurationUuid },
        });
    },
    onError: (error) => {
      const message = JSON.parse(error.graphQLErrors[0].message);
      const messageKeys = Object.keys(message);
      if (messageKeys.length === 0) return;

      dispatch(
        openToast({
          message: message[messageKeys[0]],
          type: 'error',
        }),
      );
    },
  });

  const handleRemoveCommunityMember = useCallback<(uuid: string) => void>(
    (uuid) => {
      if (!activeConfigurationUuid) return;

      removeCommunityMemberMutation({
        variables: {
          memberUuid: uuid,
          configUuid: activeConfigurationUuid,
        },
      });
    },
    [activeConfigurationUuid, removeCommunityMemberMutation],
  );

  return {
    handleReadSCMConfiguration,
    handleRemoveCommunityMember,
  };
};
