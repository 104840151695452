import {
  CommunitySummaryResultsDocument,
  CommunitySummaryResultsQuery,
  CommunitySummaryResultsQueryHookResult,
  CommunitySummaryResultsQueryVariables,
} from 'src/graphql';

import { CommunitySummaryResultsPartial } from 'src/graphql/subscriptions/communitySummaryResultsPartial';
import { TAsset } from 'src/typings/configuration.types';
import { parseSimulationResults } from 'src/utils/parseSimulationResults';
import { useMemo } from 'react';
import { useSubscribeToQuery } from 'src/hooks/useSubscribeToQuery';
import { useSelector } from 'react-redux';
import {
  selectConfigType,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSimulationStatus,
} from 'src/redux/configuration/configuration.selectors';
import { BACKEND_DATE_FORMATS, UTCMoment } from 'src/utils/UTCMoment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCommunitySummaryResults({
  communityUuid,
  jobId,
}: {
  communityUuid: TAsset['uuid'] | undefined;
  jobId?: string;
}) {
  const resultsEndTimeState = useSelector(selectResultsEndTime);
  const configType = useSelector(selectConfigType);
  const resultsStartTimeState = useSelector(selectResultsStartTime);
  const simulationStatus = useSelector(selectSimulationStatus);

  const resultsStartTime = useMemo(() => {
    return UTCMoment.utc(resultsStartTimeState)
      .startOf('day')
      .format(BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME);
  }, [resultsStartTimeState]);

  const avoidLiveSubscriptionData = useMemo(() => {
    const noSimStatus = typeof simulationStatus === 'undefined';
    const simIsFinishedOrFailed = ['finished', 'failed'].includes(simulationStatus || '');
    const isCN = configType === 'CANARY_NETWORK';
    const indicatorMatchedWithEnd =
      UTCMoment.fromBackend(resultsEndTimeState).format('MMMM D') ===
      UTCMoment.utc().format('MMMM D');
    return noSimStatus ? true : isCN ? !indicatorMatchedWithEnd : simIsFinishedOrFailed;
  }, [configType, resultsEndTimeState, simulationStatus]);

  const { data, loading, error } = useSubscribeToQuery<
    CommunitySummaryResultsQuery,
    CommunitySummaryResultsQueryVariables
  >({
    queryDoc: CommunitySummaryResultsDocument,
    subscriptionDoc: CommunitySummaryResultsPartial,
    avoidLiveSubscriptionData: avoidLiveSubscriptionData,
    variables: {
      jobId: jobId || '',
      communityUuid: communityUuid || '',
      // @ts-ignore
      startTime: resultsStartTime,
    },
    updateQuery(prev, { subscriptionData }) {
      const resultsPartial: NonNullable<
        CommunitySummaryResultsQueryHookResult['data']
      >['simulationResults'] = (subscriptionData?.data as any).simulationAreaResultsPartial[0];
      if (!resultsPartial) return prev;

      const next = Object.assign({}, prev, {
        simulationResults: {
          ...prev.simulationResults,
          ...resultsPartial,
        },
      });

      return next;
    },
  });

  return {
    data: useMemo(() => parseSimulationResults(data?.simulationResults), [data]),
    loading,
    error,
  };
}
