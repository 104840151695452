import React, { useState } from 'react';

import { BaseSwitch } from 'src/components/BaseSwitch';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { EnergyFlowContent } from 'src/components/EnergyFlow/components/EnergyFlowContent';
import { EnergyFlowList } from 'src/components/EnergyFlow/components/EnergyFlowList';
import { SWITCHER_ICON_SIZES } from 'src/constants/application';
import { TEnergyFlowProps } from './EnergyFlow.types';
import s from './EnergyFlow.module.scss';

export const EnergyFlow: React.FC<TEnergyFlowProps> = ({
  data,
  title = 'Energy Flow',
  info = '',
  showSwitcher = true,
  wrapperClassNames,
  netTotalEnergy,
}) => {
  const [showList, setShowList] = useState<boolean>(true);

  const listSwitchOnChange = ({ value }) => setShowList(value);

  return (
    <ChartWrapper
      title={title}
      info={info}
      {...wrapperClassNames}
      titleRightSlotComponent={
        showSwitcher && (
          <BaseSwitch
            name="show-list"
            label="Show List"
            options={[
              { icon: 'close', iconSize: SWITCHER_ICON_SIZES.close, value: false },
              { icon: 'check-mark', iconSize: SWITCHER_ICON_SIZES.tick, value: true },
            ]}
            theme="gray-custom"
            variant="horizontal-small"
            value={showList}
            onChange={listSwitchOnChange}
          />
        )
      }>
      <div className={s.container}>
        <EnergyFlowContent netTotalEnergy={netTotalEnergy} data={data} isSidebarOpen={showList} />
        {showList && <EnergyFlowList data={data} />}
      </div>
    </ChartWrapper>
  );
};
