import * as d3 from 'd3';

import { TPolygonCoord, getPolygonData } from 'src/utils/polygonHelper';

import { TEnergyFlowType } from 'src/components/EnergyFlow/EnergyFlow.types';

export type TAppendTriangleArgs = {
  d3selection: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  center: TPolygonCoord;
  target: TPolygonCoord;
  strokeWidth: number;
  selector: string;
  flowType: TEnergyFlowType;
};

const getRotation = (xDiff: number, yDiff: number) => {
  if (xDiff === 0 && yDiff < 0) return 180;
  if (xDiff < 0 && yDiff === 0) return -30;
  if (xDiff > 0 && yDiff === 0) return 30;
  if (xDiff < 0 && yDiff < 0) return 10;
  if (xDiff > 0 && yDiff < 0) return -10;
  if (xDiff > 0 && yDiff > 0) return 70;
  if (xDiff < 0 && yDiff > 0) return 50;
  return 0;
};

const fixNumber = (num: number) => Number(num.toFixed(1));

export const appendTriangles = ({
  d3selection,
  center,
  target,
  strokeWidth,
  selector,
  flowType,
}: TAppendTriangleArgs): void => {
  const xDiff = fixNumber((center.x - target.x) / 4);
  const yDiff = fixNumber((center.y - target.y) / 4);
  const triangleInfos: Array<{ mainX: number; mainY: number }> = [
    {
      mainX: center.x - xDiff * 2,
      mainY: center.y - yDiff * 2,
    },
    {
      mainX: center.x - xDiff * 3,
      mainY: center.y - yDiff * 3,
    },
  ];
  const thickness = strokeWidth / 3;

  triangleInfos.forEach((triangleItem) => {
    const { mainX: tX, mainY: tY } = triangleItem;

    const exportRotation = getRotation(xDiff, yDiff);
    const triangle = getPolygonData({
      radius: thickness,
      center: { x: tX, y: tY },
      edgeCount: 3,
      rotation: flowType === 'Import' ? exportRotation + 180 : exportRotation,
    });
    const points = triangle.map((item) => `${item.x},${item.y}`).join(' ');

    d3selection
      .append('polygon')
      .attr('class', selector)
      .attr('points', points)
      .attr('fill', '#fff');
  });
};
