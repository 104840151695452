import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import {
  CommunityEnergyProfileInfoText,
  SCMMyCommunityNotification,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity/SCMMyCommunityConstants';
import React, { useEffect, useState } from 'react';
import {
  selectCommunityBillsDifference,
  selectCommunityKpi,
  selectCommunityKpiDifference,
  selectDataResolution,
} from 'src/redux/scm/scm.selectors';
import {
  setCommunityBillsDifferences,
  setCommunityKpi,
  setCommunityKpiDifference,
  setDataResolution,
  setHomeKpi,
} from 'src/redux/scm/scm.slice';

import { CustomResultHeader } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader';
import { DataAggregationResolution } from 'src/graphql';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/DownloadResultsButton';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { LiteWorldMap } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyCommunity/LiteWorldMap';
import { TKpi } from 'src/typings/simulation.types';
import { TResolutionButtonState } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';
import { TSCMMyCommunityProps } from './SCMMyCommunity.types';
import { TableSCMBills } from 'src/components/_charts/TableSCMBills';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './SCMMyCommunity.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { setSidebarExpanded } from 'src/redux/application/application.slice';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAppDispatch } from 'src/redux/store';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSelector } from 'react-redux';
import { useSingleNotification } from 'src/hooks/useSingleNotification';

export const SCMMyCommunity: React.FC<TSCMMyCommunityProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const settingsData = useSelector(selectSettingsData);
  const communityKpi = useSelector(selectCommunityKpi);
  const communityKpiDifference = useSelector(selectCommunityKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const communityBillsDifferences = useSelector(selectCommunityBillsDifference);
  const sidebarExpanded = useSelector(selectSidebarExpanded);

  const { isUserACommunityMember } = useIsUserACommunityMember();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
    handleResolutionButton,
  } = useAccumulatedResults({
    initialRunQueries: [
      'getAccumulatedResults',
      'getAccumulatedBillsDifferences',
      'getAccumulatedKpiDifferences',
    ],
    mode: 'community',
  });

  useSingleNotification({
    singleNotification: SCMMyCommunityNotification,
    checkStatement: !isUserACommunityMember,
  });

  usePositionBGM({
    modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR,
    top: isUserACommunityMember ? 73 : 118,
  });

  useEffect(() => {
    if (resultsData?.kpi) dispatch(setCommunityKpi(pickKpiData(JSON.parse(resultsData.kpi))));
  }, [resultsData, dispatch]);

  //create a useEffect to dispatch the setCommunityBillsDifferences
  useEffect(() => {
    if (billsDifferenceData) {
      dispatch(setCommunityBillsDifferences(objectCamelCase(JSON.parse(billsDifferenceData))));
    }
  }, [billsDifferenceData, dispatch]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    if (kpiDifferenceData) {
      dispatch(setCommunityKpiDifference(objectCamelCase(JSON.parse(kpiDifferenceData))));
    }
  }, [kpiDifferenceData, dispatch]);

  const onBillClick = (uuid: string) => {
    dispatch(setSidebarExpanded(true));
    dispatch(setSelectedAssetUuid(uuid));
  };

  if (!sidebarExpanded) return null;

  return (
    <>
      <div className={s.rowWrapper}>
        <CustomResultHeader
          handleResolutionButton={handleResolutionButton}
          selectedResolution={dataResolution}
          isHomeNameActive={false}
          isCommunityLabelActive={!isUserACommunityMember}
          flag={'Simulation'}
        />
        {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
          <div className={s.downloadResultContainer}>
            <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
          </div>
        )}
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          <KeyResultsSCM
            horizontal={false}
            mode={'Community'}
            currency={settingsData.currency}
            title=""
            communitySavings={communityBillsDifferences?.savings}
            communitySelfSufficiency={(communityKpiDifference?.selfSufficiency || 0) * 100}
          />
        </div>
        <div className={s.rightColumn}>
          {isUserACommunityMember && <LiteWorldMap className={s.worldMap} />}
          {!isUserACommunityMember && <TableSCMBills onBillClick={onBillClick} />}
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          {communityKpiDifference && (
            <div className={s.selfSufficiencyWrapper}>
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(communityKpiDifference)}
                type={EChartSelfType.Sufficiency}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={SelfSufficiencyInfoText}
              />
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(communityKpiDifference)}
                type={EChartSelfType.Consumption}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={SelfConsumptionInfoText}
              />
            </div>
          )}
        </div>
        <div className={s.rightColumn}>
          <HomeEnergyInfo
            generatedValue={`${
              communityKpiDifference?.totalEnergyProducedWh
                ? (communityKpiDifference?.totalEnergyProducedWh / 1000).toFixed(0)
                : 0
            } kWh`}
            //consumedValue={`${homeBillsDifferences?.homeBalanceKWh.toFixed(0)} kWh`}
            consumedValue={`${
              communityKpiDifference?.totalEnergyDemandedWh
                ? (communityKpiDifference?.totalEnergyDemandedWh / 1000).toFixed(0)
                : 0
            } kWh`}
          />
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.fullColumn}>
          {communityKpi && (
            <EnergyProfileComponent
              title="Community Energy Profile"
              mode={dataResolution}
              field="community"
              initialData={communityKpi}
              info={CommunityEnergyProfileInfoText}
            />
          )}
        </div>
      </div>
    </>
  );
};
