import { BaseErrorMessage, TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import React, { RefObject, useRef, useState } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/validation';

import { BaseButton } from 'src/components/BaseButton';
import { BaseCheckbox } from '../BaseCheckbox';
import { BaseInput } from '../BaseInput';
import { EPredefinedModalIds } from 'src/constants/modals';
import { PASSWORD_MIN_LENGTH } from 'src/constants/application';
import ReCAPTCHA from 'react-google-recaptcha';
import { TFormSignupProps } from './FormSignup.types';
import { initFormData } from 'src/components/FormSignup/useSignupFormData';
import { openModal } from 'src/redux/modals/modals.slice';
import s from './FormSignup.module.scss';
import { setTokenAuth } from 'src/redux/auth/auth.slice';
import { useAppDispatch } from 'src/redux/store';
import { useGraphQLMessage } from 'src/hooks/useGraphQLMessage';
import { useSignupMutation } from 'src/graphql';

const validators = () => ({
  name: [...((n) => [rules.required(n)])('Name')],
  email: [...((n) => [rules.required(n), rules.email(n)])('Email')],
  password: [...((n) => [rules.required(n), rules.minLength(n, PASSWORD_MIN_LENGTH)])('Password')],
  other: [...((n) => [rules.required(n)])('Other')] || {},
});

export const FormSignupSCM: React.FC<TFormSignupProps> = ({
  className,
  onSuccess,
  formData,
  handleFieldChange,
}) => {
  const dispatch = useAppDispatch();
  const [termsError, setTermsError] = useState(false);
  const [interestError, setInterestError] = useState(false);
  const [signupMutation, { loading, error }] = useSignupMutation();
  const graphqlMessage = useGraphQLMessage({ error });
  const recaptchaRef = useRef() as RefObject<ReCAPTCHA>;
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!formData.termsAccepted) {
      setTermsError(true);
      return;
    }

    setTermsError(false);

    const { errors } = validateFields({
      validators: validators(),
      formData,
    });

    setValidationErrors(errors || {});

    if (errors) {
      const keys = Object.keys(errors),
        key = keys[0];

      if (formData.interest === 'Other' || keys.length !== 1 || key !== 'other') return;
    }

    try {
      const { data } = await signupMutation({
        variables: {
          email: formData.email,
          name: formData.name,
          organisation: '',
          password: formData.password,
          interest: formData.interest === 'Other' ? formData.other : formData.interest,
          profilePicture: formData.picture,
        },
      });

      if (data && recaptchaRef.current?.getValue()) {
        const token = data.registerUser?.authToken;
        if (token) {
          dispatch(setTokenAuth(token));
          onSuccess?.();
        }
      }
    } catch (err) {}
  }

  const generatedErrorMessage: string = termsError
    ? 'You must agree with the terms and conditions.'
    : ``;

  return (
    <form className={className} onSubmit={handleSubmit}>
      <p className={s.startText}>Let’s start simple</p>
      <div className={s.inputsWrapper}>
        <BaseInput
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFieldChange}
          label="Your Email"
          theme="line-light"
          className={s.input}
          error={validationErrors.email}
          onBlur={(e) => handleFieldBlur(e, validators().email)}
        />
        <BaseInput
          type="password"
          name="password"
          value={formData.password}
          onChange={handleFieldChange}
          label="Password"
          theme="line-light"
          className={s.input}
          error={validationErrors.password}
          onBlur={(e) => handleFieldBlur(e, validators().password)}
        />
      </div>

      <BaseButton type="submit" isLoading={loading} className={s.buttonRegister}>
        Get Started
      </BaseButton>

      <div className={s.checkboxWrapper}>
        <BaseCheckbox
          value={formData.termsAccepted}
          onChange={handleFieldChange}
          theme="light"
          label={
            <div>
              I accept the{' '}
              <button
                type="button"
                className={s.termsBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(openModal(EPredefinedModalIds.MODAL_TERMS_AND_CONDITIONS));
                }}>
                Terms of Service
              </button>
            </div>
          }
          className={s.checkbox}
          name="termsAccepted"
          error={generatedErrorMessage}
        />
      </div>
      {graphqlMessage && <BaseErrorMessage>{graphqlMessage}</BaseErrorMessage>}
    </form>
  );
};
