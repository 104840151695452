import { ArrayElement, EUserRoles, TAssetType } from 'src/typings/base-types';
import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import {
  EnergyBillComponents,
  EnergyBillSubtitles,
  TListEnergyBillInfo,
} from 'src/components/EnergyBillComponents';
import {
  EnergyBillInfoContent,
  MyEnergyFlowsInfoText,
  MyEnergyProfileInfoText,
  SCMMyHomeNotification,
  SavingsBenchmarkInfoText,
  SelfConsumptionInfoText,
  SelfSufficiencyInfoText,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome/SCMMyHomeConstants';
import { EnergyFlow, TEnergyFlowAssetType, TEnergyFlowProps } from 'src/components/EnergyFlow';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  TBillsDifference,
  TSCMHomeKpi,
  TSCMMyHomeProps,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome/SCMMyHome.types';
import {
  selectAssets,
  selectAssetsValues,
  selectSelectedAssetUuid,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectDataResolution,
  selectHomeBillsDifferences,
  selectHomeKpi,
  selectKpiDifference,
} from 'src/redux/scm/scm.selectors';
import {
  setDataResolution,
  setHomeBillsDifferences,
  setHomeKpi,
  setKpiDifference,
} from 'src/redux/scm/scm.slice';

import { CustomResultHeader } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader';
import { DownloadResultsButton } from 'src/components/SimulationResultsPresentation/CustomResultPresentation/CustomResultHeader/DownloadResultsButton';
import { EPredefinedModalIds } from 'src/constants/modals';
import { EnergyProfileComponent } from 'src/components/EnergyProfileComponent';
import { HomeEnergyInfo } from 'src/components/HomeEnergyInfo';
import { KeyResultProgress } from 'src/components/KeyResultsSCM/components/KeyResultProgress';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { TKpi } from 'src/typings/simulation.types';
import classNames from 'classnames';
import { formatter } from 'src/utils/formatter';
import { objectCamelCase } from 'src/utils/objectCamelCase';
import { objectSnakeCase } from 'src/utils/objectSnakeCase';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './SCMMyHome.module.scss';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { setPositionBGM } from 'src/redux/modals/modals.slice';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAppDispatch } from 'src/redux/store';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { usePositionBGM } from 'src/hooks/usePositionBGM';
import { useSelector } from 'react-redux';
import { useSingleNotification } from 'src/hooks/useSingleNotification';
import { whToKwh } from 'src/utils/whToKwh';

export const SCMMyHome: React.FC<TSCMMyHomeProps> = () => {
  const dispatch = useAppDispatch();

  const homeBillsDifferences = useSelector(selectHomeBillsDifferences);
  const settingsData = useSelector(selectSettingsData);
  const homeKpi = useSelector(selectHomeKpi);
  const kpiDifference = useSelector(selectKpiDifference);
  const dataResolution = useSelector(selectDataResolution);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const assets = useSelector(selectAssets);
  const assetsValues = useSelector(selectAssetsValues);
  const selectedAssetUuid = useSelector(selectSelectedAssetUuid);

  const { isUserACommunityMember } = useIsUserACommunityMember();

  const {
    resultsData,
    resultsStartTime,
    resultsEndTime,
    billsDifferenceData,
    kpiDifferenceData,
    handleResolutionButton,
  } = useAccumulatedResults({
    initialRunQueries: [
      'getAccumulatedResults',
      'getAccumulatedBillsDifferences',
      'getAccumulatedKpiDifferences',
    ],
    mode: 'home',
  });

  useSingleNotification({
    singleNotification: SCMMyHomeNotification,
  });

  const energyFlowProps = useMemo<TEnergyFlowProps | null>(() => {
    if (!kpiDifference || !kpiDifference.assetEnergyRequirementsKWh || !assets || !assetsValues)
      return null;

    // get keys of the assetEnergyRequirementsKWh object
    const keys = Object.keys(kpiDifference.assetEnergyRequirementsKWh);

    // check the keys are exists in the assets and assetsValues
    if (!keys.every((key) => assets[key] && assetsValues[key])) return null;

    // iterate over the keys and create an array of objects with the data
    const data = keys.map((key) => {
      const value = kpiDifference.assetEnergyRequirementsKWh[key];
      return {
        title: assetsValues[key].name || '',
        // keep 2 decimals
        value: Math.abs(Math.abs(value) < 10 ? Math.floor(value * 100) / 100 : Math.floor(value)),
        flowType: (value < 0 ? 'Import' : 'Export') as ArrayElement<
          TEnergyFlowProps['data']
        >['flowType'],
        assetType: assets[key].type as TEnergyFlowAssetType,
      };
    });

    return {
      data,
      netTotalEnergy: Math.floor(kpiDifference.areaEnergyRequirementsKWh * 100) / 100,
    };
  }, [kpiDifference, assets, assetsValues]);

  const currencySymbol = formatter.getCurrencySymbol(settingsData.currency);
  const withCurrency = useCallback<(value: number) => string>(
    (value: number) => {
      return `${currencySymbol}${value > 10 ? Math.floor(value) : value.toFixed(2)}`;
    },
    [currencySymbol],
  );

  const listEnergyBillInfo = useMemo<TListEnergyBillInfo | null>(() => {
    if (!homeBillsDifferences) return null;
    const {
      taxSurcharges,
      gridFees,
      fixedFee,
      gsyEnergyBillExclRevenueWithoutFees,
      gsyEnergyBillExclRevenueWithoutFeesPercent,
      gsyEnergyBillExclRevenue,
      gridFeesPercent,
      marketplaceFee,
      marketplaceFeePercent,
      taxSurchargesPercent,
    } = homeBillsDifferences;

    // get human readable percantage from 0.XXX format to from 0 to 100
    const getPercentage = function (value: number) {
      return Math.floor(value * 100);
    };

    return [
      {
        labelColor: '#66EE66',
        label: 'Energy Cost',
        percentage: getPercentage(gsyEnergyBillExclRevenueWithoutFeesPercent),
        cost: withCurrency(gsyEnergyBillExclRevenueWithoutFees),
      },
      {
        labelColor: '#1641F7',
        label: 'Service Fee Grid',
        percentage: getPercentage(marketplaceFeePercent),
        cost: withCurrency(marketplaceFee),
      },
      {
        labelColor: '#FC1355',
        label: 'Grid Tariff',
        percentage: getPercentage(gridFeesPercent),
        cost: withCurrency(gridFees),
      },
      {
        labelColor: '#80B0F8',
        label: 'Taxes & Surcharges',
        percentage: getPercentage(taxSurchargesPercent),
        cost: withCurrency(taxSurcharges),
      },
    ];
  }, [homeBillsDifferences, withCurrency]);

  //useAccumulatedResultsWithSubscription({ initialRunQueries: [], mode: 'home' });

  usePositionBGM({ modalId: EPredefinedModalIds.MODAL_MAP_SIDEBAR, top: 73 });

  // unset the homebillsdifferences, homekpi, kpidifference when unmounting the component
  useEffect(() => {
    return () => {
      dispatch(setHomeBillsDifferences(undefined));
      dispatch(setHomeKpi(undefined));
      dispatch(setKpiDifference(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (billsDifferenceData)
      dispatch(
        setHomeBillsDifferences(objectCamelCase<TBillsDifference>(JSON.parse(billsDifferenceData))),
      );
  }, [billsDifferenceData, dispatch]);

  useEffect(() => {
    if (resultsData?.kpi) dispatch(setHomeKpi(pickKpiData(JSON.parse(resultsData.kpi))));
  }, [resultsData, dispatch]);

  useEffect(() => {
    if (kpiDifferenceData)
      dispatch(setKpiDifference(objectCamelCase<TSCMHomeKpi>(JSON.parse(kpiDifferenceData))));
  }, [kpiDifferenceData, dispatch]);

  if (!sidebarExpanded) return null;

  return (
    <>
      <div className={s.rowWrapper}>
        <CustomResultHeader
          handleResolutionButton={handleResolutionButton}
          selectedResolution={dataResolution}
          isHomeNameActive={!isUserACommunityMember}
          isCommunityLabelActive={false}
          flag={isUserACommunityMember ? 'Operation' : 'Simulation'}
          homeName={selectedAssetUuid ? assetsValues[selectedAssetUuid]?.name : ''}
        />
        {resultsStartTime && resultsEndTime && !isUserACommunityMember && (
          <div className={s.downloadResultContainer}>
            <DownloadResultsButton startDate={resultsStartTime} endDate={resultsEndTime} />
          </div>
        )}
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          <KeyResultsSCM
            horizontal={true}
            mode={'Home'}
            currency={settingsData.currency}
            homeBillValue={homeBillsDifferences?.homeBalance}
            homeSavings={homeBillsDifferences?.savings}
            title="⚡ Energy Bill"
          />
        </div>
        <div className={s.rightColumn}>
          <HomeEnergyInfo
            generatedValue={`${
              kpiDifference?.totalEnergyProducedWh
                ? whToKwh(kpiDifference?.totalEnergyProducedWh)
                : 0
            } kWh`}
            //consumedValue={`${homeBillsDifferences?.homeBalanceKWh.toFixed(0)} kWh`}
            consumedValue={`${
              kpiDifference?.totalEnergyDemandedWh
                ? whToKwh(kpiDifference?.totalEnergyDemandedWh)
                : 0
            } kWh`}
          />
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.leftColumn}>
          {listEnergyBillInfo && (
            <EnergyBillComponents
              key={listEnergyBillInfo.map((item) => item.cost).join('')}
              list={listEnergyBillInfo}
              total={
                homeBillsDifferences?.gsyEnergyBillExclRevenue
                  ? withCurrency(homeBillsDifferences.gsyEnergyBillExclRevenue)
                  : ''
              }
              info={<EnergyBillInfoContent />}
              title="Energy Bill Components"
              subTitle={EnergyBillSubtitles[dataResolution]}
            />
          )}
        </div>
        <div className={classNames(s.rightColumn, s.selfandprogress)}>
          {kpiDifference && (
            <div className={s.selfSufficiencyWrapper}>
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Sufficiency}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={SelfSufficiencyInfoText}
              />
              <ChartSelf
                kpi={objectSnakeCase<TKpi>(kpiDifference)}
                type={EChartSelfType.Consumption}
                className={s.selfItem}
                isDecimalPercentage={true}
                isTitleInfoActive={true}
                isItemInfoActive={false}
                titleInfo={SelfConsumptionInfoText}
              />
            </div>
          )}
          {homeBillsDifferences && (
            <KeyResultProgress
              title="My Energy Savings Ranking in Community"
              info={SavingsBenchmarkInfoText}
              percentage={parseInt((homeBillsDifferences.energyBenchmark * 100).toFixed(0))}
              badges={[]}
              className={s.savingsRanking}
            />
          )}
        </div>
      </div>
      <div className={s.rowWrapper}>
        <div className={s.rightColumn}>
          {homeKpi && (
            <EnergyProfileComponent
              title="My Energy Profile"
              info={MyEnergyProfileInfoText}
              mode={dataResolution}
              initialData={homeKpi}
              field="home"
            />
          )}
        </div>
        <div className={s.leftColumn}>
          {energyFlowProps && (
            <EnergyFlow
              showSwitcher={false}
              title={'My Energy Flows'}
              info={MyEnergyFlowsInfoText}
              wrapperClassNames={{
                className: s.energyFlowWrapper,
                headerClassName: s.energyFlowContent,
                contentClassName: s.energyFlowContent,
              }}
              {...energyFlowProps}
            />
          )}
        </div>
      </div>
    </>
  );
};
