import {
  ListAdminRegistryUsersQuery,
  ListAggregatorRegistryUsersQuery,
  ListGridOperatorRegistryMarketsQuery,
  ListGsyUserRegistryAssetsQuery,
  ListUserToAdminRequestsQuery,
} from 'src/graphql';

import { ArrayElement } from 'src/typings/base-types';
import { TCanaryNetworkState } from 'src/redux/canaryNetwork/canaryNetwork.slice';
import { TCommunitiesState } from 'src/redux/communities/communities.slice';

// todo - consider cleaning up name and username
const parser = (item) => {
  return {
    email: item.userDetails.email,
    userRole: item.userDetails.userRole,
    requestStatus: item.userDetails.requestStatus,
    id: `${item?.userDetails?.id}` || undefined,
    name: item?.userDetails?.name || undefined,
    username: item?.userDetails?.username || undefined,
    profilePicture: item?.userDetails?.profilePicture || undefined,
    canaryInvitations: item?.userDetails?.canaryInvitations || [],
    assetsConfigured:
      item.assetsConfigured?.reduce((acc, item) => {
        if (item?.assetUuid && item?.registrationStatus) {
          acc.push({
            uuid: item.assetUuid,
            registrationStatus: item.registrationStatus,
          });
        }
        return acc;
      }, []) || [],
  };
};

export const parseAdminRegistryUsersData = (
  data: ListAdminRegistryUsersQuery,
): TCanaryNetworkState['users'] => {
  const result = data.listAdminRegistryUsers?.reduce<TCanaryNetworkState['users']>((acc, item) => {
    if (
      item?.userDetails?.email &&
      item?.userDetails?.userRole &&
      item?.userDetails?.requestStatus
    ) {
      acc.push(parser(item));
    }
    return acc;
  }, []);
  return result || [];
};

export const parseAggregatorRegistryUsersData = (
  data: ListAggregatorRegistryUsersQuery,
): TCanaryNetworkState['users'] => {
  const result = data.listAggregatorRegistryUsers?.reduce<TCanaryNetworkState['users']>(
    (acc, item) => {
      if (
        item?.userDetails?.email &&
        item?.userDetails?.userRole &&
        item?.userDetails?.requestStatus
      ) {
        acc.push(parser(item));
      }
      return acc;
    },
    [],
  );
  return result || [];
};

export const parseGsyUserRegistryUsersData = (
  data: ListGsyUserRegistryAssetsQuery,
  user: ArrayElement<TCanaryNetworkState['users']>,
): TCanaryNetworkState['users'] => {
  const result = [];

  if (data.listGsyUserRegistryAssets?.assetInformation) {
    result.push({
      ...user,
      // @ts-ignore
      aggregatorInformation: data.listGsyUserRegistryAssets.aggregatorInformation,
      // @ts-ignore
      assetsConfigured: data.listGsyUserRegistryAssets.assetInformation.reduce<
        ArrayElement<TCanaryNetworkState['users']>['assetsConfigured']
      >((acc, item) => {
        if (item?.uuid) {
          acc.push({
            uuid: item.uuid,
            registrationStatus: '',
          });
        }
        return acc;
      }, []),
    });
  }

  return result || [];
};

export const parseGridOperatorRegistryMarkets = (
  data: ListGridOperatorRegistryMarketsQuery,
  user: ArrayElement<TCanaryNetworkState['users']>,
): TCanaryNetworkState['users'] => {
  if (!data.listGridOperatorRegistryMarkets) return [];

  return [
    {
      ...user,
      assetsConfigured: data.listGridOperatorRegistryMarkets?.reduce<
        ArrayElement<TCanaryNetworkState['users']>['assetsConfigured']
      >((acc, item) => {
        if (item?.uuid) {
          acc.push({
            uuid: item.uuid,
            registrationStatus: '',
          });
        }
        return acc;
      }, []),
    },
  ];
};

export const parseUserToAdminRequestsData = (data: ListUserToAdminRequestsQuery) => {
  const parsed = data.listUserToAdminRequests?.reduce<TCommunitiesState['userToAdminRequests']>(
    (acc, item) => {
      if (item && item.configUuid) {
        if (acc[item.configUuid]) {
          acc[item.configUuid].push({
            type: item.requestType!,
            status: item.status!,
          });
        } else {
          acc[item.configUuid] = [
            {
              type: item.requestType!,
              status: item.status!,
            },
          ];
        }
      }

      return acc;
    },
    {},
  );
  if (parsed) return parsed;
  return {};
};

export const createCNName = (name: string, cnNamesList: string[]): string => {
  let resultName = name;
  let count = 1;
  while (cnNamesList.includes(resultName)) {
    resultName = name + ' ' + count;
    count++;
  }
  return resultName;
};
