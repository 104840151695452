import React, { useMemo, useState } from 'react';
import { TValidationObject, rules, validateFields } from 'src/utils/validation';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseInput } from 'src/components/BaseInput';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { TBaseErrorMessageProps } from 'src/components/BaseErrorMessage';
import { TBaseInputProps } from 'src/components/BaseInput';
import { TCoefficientAssetTileProps } from './CoefficientAssetTile.types';
import classnames from 'classnames';
import { get2dAssetIcon } from 'src/utils/assetIcons';
import s from './CoefficientAssetTile.module.scss';
import { selectReadOnly } from 'src/redux/configuration/configuration.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { useSelector } from 'react-redux';

export const CoefficientAssetTile: React.FC<TCoefficientAssetTileProps> = ({
  data,
  onAssetChoose,
  onAssetValueChange,
}) => {
  const { title, coefficientPercentage, uuid } = data;
  const scmMembers = useSelector(selectSCMHomeDetails);

  const readOnly = useSelector(selectReadOnly);
  const validators = () => ({
    name: [...((n) => [rules.required(n)])('Name')],
    coefficientPercentage: [
      ...((n) => [rules.required(n), rules.floatString(n), rules.range(n, 0, 1)])(
        'coefficientPercentage',
      ),
    ],
  });
  const initFormData = {
    coefficientPercentage: coefficientPercentage || 0,
  };
  const errorWidth = '0';
  const [formData, setFormData] = useState(initFormData);
  const [validationErrors, setValidationErrors] = useState<
    {
      [key in keyof typeof initFormData]?: TBaseErrorMessageProps['children'];
    }
  >({});

  const onInputChange: TBaseInputProps['onChange'] = ({ value, name }) => {
    if (typeof value !== 'string') return;

    setFormData((curr) => ({
      ...curr,
      [name]: value,
    }));

    let tempValue = parseFloat(value);
    if (typeof tempValue === 'number' && tempValue !== coefficientPercentage) {
      onAssetValueChange?.(data.uuid, tempValue);
    }
  };
  function handleFieldBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationsArr: TValidationObject[],
  ) {
    const { name } = e.target;
    const { errors } = validateFields({
      validators: {
        [name]: validationsArr,
      },
      formData,
    });

    if (errors) {
      if (errors[name]) {
        setValidationErrors((prevState) => ({
          ...prevState,
          [name]: errors[name],
        }));
      }
    } else {
      const newState = { ...validationErrors };
      delete newState[name];
      setValidationErrors(newState);
    }
  }

  const currentItem = useMemo(() => scmMembers.find((item) => item.uuid === data?.uuid), [
    data?.uuid,
    scmMembers,
  ]);

  return (
    <div
      className={s.container}
      // onClick={onAssetChoose ? () => onAssetChoose(data) : undefined}
    >
      <div className={s.headingText}>
        <div className={s.row}>
          <BaseIcon className={s.assetIcon} icon={get2dAssetIcon(data?.type)} />
          <div>
            <div className={s.assetTitle}>{title}</div>
            <div className={s.assetUser}>{currentItem?.email?.split('@')[0]}</div>
          </div>
        </div>
      </div>
      <div className={s.pill}>
        <FieldContainer className={classnames(s.inputField)}>
          <BaseInput
            className={s.input}
            showValidationIcon={false}
            value={formData.coefficientPercentage}
            name="coefficientPercentage"
            type={'text'}
            theme={'filled-white'}
            onChange={(val: any) => onInputChange(val)}
            autoComplete="off"
            disabled={readOnly}
            errorWidth={errorWidth}
            showErrorMessage={false}
            error={validationErrors.coefficientPercentage}
            onBlur={(e) => handleFieldBlur(e, validators().coefficientPercentage)}
          />
        </FieldContainer>
        {/* {coefficientPercentage ? coefficientPercentage : 0} */}
      </div>
    </div>
  );
};
