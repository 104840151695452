import {
  selectCommunityAsset,
  selectSimulationResults,
} from 'src/redux/configuration/configuration.selectors';

import React from 'react';
import { TBaseTagsProps } from './BaseTags.types';
import classnames from 'classnames';
import s from './BaseTags.module.scss';
import { useSelector } from 'react-redux';

export const BaseTags: React.FC<TBaseTagsProps> = function BaseTags({
  className,
  items,
  name,
  value,
  onChange,
  onTagClick,
  maxVisibleItems,
}) {
  const simulationResults = useSelector(selectSimulationResults);
  const communityAsset = useSelector(selectCommunityAsset);

  const handleChange = (index: number) => {
    if (!value) return;
    const newVal = [...value];
    if (value.includes(index)) {
      newVal.splice(newVal.indexOf(index), 1);
    } else {
      newVal.push(index);
    }
    onChange?.({ value: newVal, name });
  };

  const visibleItems = maxVisibleItems ? items.slice(0, maxVisibleItems) : items;

  return (
    <div className={`${s.tagsWrapper} ${className}`}>
      {visibleItems.map((item, index) => (
        <button
          type="button"
          key={index}
          name={name}
          disabled={item.disabled}
          onClick={() => {
            if (onChange) {
              handleChange(index);
            }
            onTagClick?.(item);
          }}
          className={classnames(s.tagButton, {
            [s.inactive]: value?.includes(index) || item.inactive,
          })}>
          <span className={s.buttonContent}>
            <div className={s.tagDot} style={{ backgroundColor: item.color }}></div>
            {item.name}
          </span>
        </button>
      ))}
      {maxVisibleItems && (
        <button type="button" onClick={() => handleChange(-1)} className={s.viewAllButton}>
          <span>View All</span>
        </button>
      )}
    </div>
  );
};
