import React, { useEffect, useRef } from 'react';

import { BaseClose } from 'src/components/BaseClose';
import GraphImage from 'src/assets/images/MarketingPage/graph.png';
import SocialNetworkImage from 'src/assets/images/MarketingPage/social.png';
import { TCardDetailProps } from './CardDetail.types';
import classnames from 'classnames';
import { getAppMode } from 'src/utils/appMode';
import s from './CardDetail.module.scss';

export const CardDetail: React.FC<TCardDetailProps> = ({
  data,
  onCloseClick,
  isEcoSystemChildView,
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const mode = getAppMode();
  const isMobile = mode === 'mobile';
  const { title, partners, banner, id } = data;
  const partnersLogos = partners.map((item) => ({
    url: item.fields.file.url,
    title: item.fields.title,
  }));
  const bannerURL = banner?.fields.file.url || '';

  const GridOperatortorDetails = () => (
    <>
      <div className={s.heading}>Grid Operator 2.0</div>
      <div className={s.text}>
        Prove concepts in simulated market conditions and expedite prototyping
      </div>
      <div className={s.text}>Mitigate grid management costs with new digital tools</div>
      <div className={s.text}>
        Co-shape local markets solutions to meet increasing customer demand
      </div>
      <div className={s.text}>Effectively cope with market decentralization</div>
      <div className={s.text}>Accelerate and cost-optimize open-source innovation </div>
      <div className={s.text}>
        Inform broader regulatory reform with peer-validated, rigorous research and testing
      </div>
    </>
  );

  const ProsumerDetails = () => (
    <>
      <div className={s.heading}>Empowering = Taking Agency</div>
      <div className={s.smHeading}>your ai agent</div>
      <div className={s.text}>
        Active market participation via intelligent trading agents maximises benefits
      </div>
      <div className={s.smHeading}>location</div>
      <div className={s.text}>Local trading reduces grid cost</div>
      <div className={s.smHeading}>energy source</div>
      <div className={s.text}>
        Select your energy source: renewables, just solar, or optimal price mix.
      </div>
      <div className={s.smHeading}>preferred trading partner</div>
      <div className={s.text}>
        Connect to favorite neighbor, cousin, or a local hospital or youth center you wish to
        support
      </div>
      <div className={s.smHeading}>forecast</div>
      <div className={s.text}>
        Provide forecasts to inform users investment in smart homes and communities
      </div>
    </>
  );

  const renderDetails = (value) => {
    switch (value) {
      case 1:
        return <GridOperatortorDetails />;
      case 4:
        return <ProsumerDetails />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView();
    }
  }, [headerRef]);

  return (
    <>
      <div
        ref={headerRef}
        className={classnames(s.content, {
          [s.noBorderRadius]: isEcoSystemChildView,
        })}>
        <div className={s.cardContainer}>
          {onCloseClick && <BaseClose className={s.closeBtn} onClick={onCloseClick} />}
          <div className={s.header}>
            <div className={s.title} onClick={() => onCloseClick && onCloseClick()}>
              {title}
            </div>
            {!isMobile && (
              <div className={s.logoContainer}>
                {partnersLogos.map((item) => (
                  <img className={s.partnerLogo} key={item.url} src={item.url} alt={item.title} />
                ))}
              </div>
            )}
          </div>
          {title === 'Aggregator' ? (
            <div className={classnames(s.bodySection, s.aggregatorContainer)}>
              <div className={classnames(s.image, s.box)}>
                <img src={bannerURL} alt={title} />
              </div>
              <div className={classnames(s.image, s.box)}>
                <h3>Minimize Cost to Serve</h3>
                <img src={GraphImage} />
              </div>
              <div className={classnames(s.image, s.box)}>
                <h3>Enable Social Networks</h3>
                <img src={SocialNetworkImage} />
              </div>
            </div>
          ) : (
            <div className={s.bodySection}>
              <div className={s.image}>
                <img src={bannerURL} alt={title} />
              </div>
              <div className={s.details}>{renderDetails(id)}</div>
            </div>
          )}
          {isMobile && (
            <div className={s.gridContainer}>
              {partnersLogos.map((item) => (
                <img className={s.gridItem} key={item.url} src={item.url} alt={item.title} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
