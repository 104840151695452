import { TFormSettingsDataProps } from 'src/components/FormSettingsData';

export type TMapSidebarConfigurationSettingsProps = {
  className?: string;
  style?: React.CSSProperties;
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
};

export enum EFormVariant {
  Basic = 'BASIC',
  Advanced = 'ADVANCED',
  // CanaryNetworks = 'CANARY_NETWORKS',
}

export enum EActiveTabState {
  General = 'GENERAL_INFORMATION',
  // MyCommunities = 'MY_COMMUNITIES',
  // CanaryNetworks = 'CANARY_NETWORKS',
}
