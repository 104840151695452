import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TChildrenPayload } from 'src/components/ConfigurationManagementCentral';
import { TAssetsSaveProps } from 'src/components/FormAssetsParams';
import { TLibrary } from 'src/components/LibraryChooser';
import { AreaInput } from 'src/graphql';
import {
  selectConfigurationCharacteristic,
  selectSettingsData,
  selectUsedAssetsNames,
} from 'src/redux/configuration/configuration.selectors';
import { TAsset, TTreeBranch } from 'src/typings/configuration.types';
import { destructureConfigTree } from 'src/utils/configuration/destructureConfigTree';
import { prepareAreaInput, TDeviceTypesInput } from 'src/utils/mutationsGates';
import { prepareDeviceInputFunctions } from 'src/utils/mutationsGates/prepareAreaInput/prepareDeviceInputFunctions';
import { v4 } from 'uuid';

type HandleNewAssetValues = Parameters<TChildrenPayload['handleAddNewAsset']>[0]['values'];

export default function useBuildSavingTreeBranch() {
  const usedAssetsNames = useSelector(selectUsedAssetsNames);
  const settingsData = useSelector(selectSettingsData);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);

  const buildCustomPVTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: TLibrary) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customPVUuid = v4();

    values.isCustomPV = true;

    const savingTreeBranch = {
      assets: {
        [customPVUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customPVUuid,
          isCustomPV: true,
        },
      },
      assetsTreeRelations: { [customPVUuid]: [] },
      rootAssetUuid: customPVUuid,
      assetsValues: { [customPVUuid]: values },
    };
    return savingTreeBranch;
  }, []);

  const buildRegularTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: TLibrary) => TTreeBranch
  >(
    (values, connectorAssetUuid, chosenAsset) => {
      const destructuredTree = destructureConfigTree(
        chosenAsset.scenarioData?.representation?.serialized,
        {
          assignNewUuids: true,
          parentUuid: connectorAssetUuid,
          assignGeoTagLocation: values.geoTagLocation,
          considerUsedAssetsNames: usedAssetsNames,
        },
      );
      destructuredTree.assetsValues[destructuredTree.rootAssetUuid as string] = values;

      return {
        ...destructuredTree,
        rootAssetUuid: destructuredTree.rootAssetUuid as string,
      };
    },
    [usedAssetsNames],
  );

  const buildCustomHouseTree = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: TLibrary) => TTreeBranch
  >((values, connectorAssetUuid, chosenAsset) => {
    const customHouseUuid = v4();

    return {
      assets: {
        [customHouseUuid]: {
          parentUuid: connectorAssetUuid,
          type: chosenAsset.type,
          uuid: customHouseUuid,
        },
      },
      assetsTreeRelations: { [customHouseUuid]: [] },
      rootAssetUuid: customHouseUuid,
      assetsValues: { [customHouseUuid]: values },
    };
  }, []);

  const createSavingTreeBranch = useCallback<
    (values: HandleNewAssetValues, connectorAssetUuid: string, chosenAsset: TLibrary) => TTreeBranch
  >(
    (values, connectorAssetUuid, chosenAsset) => {
      if (chosenAsset.isCustomPV) {
        return buildCustomPVTree(values, connectorAssetUuid, chosenAsset);
      } else if (!chosenAsset.isCustomHouse) {
        return buildRegularTree(values, connectorAssetUuid, chosenAsset);
      }
      return buildCustomHouseTree(values, connectorAssetUuid, chosenAsset);
    },
    [buildCustomPVTree, buildRegularTree, buildCustomHouseTree],
  );

  const createAreaInputFromSavingTreeBranch = useCallback<
    (savingTreeBranch: TTreeBranch, gridMarketInTreeBranch: TAsset | undefined) => AreaInput | null
  >(
    (savingTreeBranch, gridMarketInTreeBranch) => {
      return prepareAreaInput(
        gridMarketInTreeBranch
          ? {
              rootAssetUuid: savingTreeBranch.rootAssetUuid,
              assets: _.omit(savingTreeBranch.assets, [gridMarketInTreeBranch.uuid]),
              assetsTreeRelations: {
                ..._.omit(savingTreeBranch.assetsTreeRelations, [gridMarketInTreeBranch.uuid]),
                [savingTreeBranch.rootAssetUuid]: savingTreeBranch.assetsTreeRelations[
                  savingTreeBranch.rootAssetUuid
                ].filter((item) => item !== gridMarketInTreeBranch.uuid),
              },
              assetsValues: _.omit(savingTreeBranch.assetsValues, [gridMarketInTreeBranch.uuid]),
              settingsData,
              configurationCharacteristic,
            }
          : { ...savingTreeBranch, settingsData, configurationCharacteristic },
      );
    },
    [configurationCharacteristic, settingsData],
  );

  const createAreaInputFromSaveProps = useCallback<
    (payload: TAssetsSaveProps) => TDeviceTypesInput | undefined
  >(
    (payload: TAssetsSaveProps) => {
      const assetType: string = payload.assetType || 'Area';
      return prepareDeviceInputFunctions[assetType]({
        type: assetType,
        uuid: payload.assetUuid,
        assetsValues: { [payload.assetUuid]: payload.values },
        settingsData,
      });
    },
    [settingsData],
  );

  return {
    buildCustomHouseTree,
    buildRegularTree,
    buildCustomPVTree,
    createAreaInputFromSavingTreeBranch,
    createSavingTreeBranch,
    createAreaInputFromSaveProps,
  };
}
