import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React, { useContext } from 'react';

import { BaseIcon } from 'src/components/BaseIcon/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import s from './Read.module.scss';
import { routesConfig } from 'src/routes/routes.config';
import { Link } from 'react-router-dom';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';

export const Read: React.FC = () => {
  const { readEntry } = useContext(ContentfulContext);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const referencedEntry = getEntryWithId(node.data.target.sys.id);
        return (
          <a href={`/pages/${referencedEntry.fields.slug}`} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        if (children && children[0] === 'subscribe to our Newsletter') {
          return <Link to={routesConfig.mailChimp}>{children}</Link>;
        } else {
          return (
            <a href={`${node.data.uri}`} rel="noreferrer" target="_blank">
              {children}
            </a>
          );
        }
      },
    },
  };

  function getEntryWithId(entryId) {
    const mockEntry = {
      fields: {
        slug: entryId,
      },
    };

    return mockEntry;
  }

  return (
    <div className={s.content}>
      <div className={s.boxText}>
        {documentToReactComponents(readEntry?.[0]?.description as any, options)}
      </div>

      <div className={s.boxIcons}>
        <BaseLink target="_blank" to="https://github.com/gridsingularity">
          <BaseTooltipTrigger
            triggerClassName={''}
            tooltipClassName={s.helperTooltip}
            position="bottom"
            tooltipChildren={'Grid Singularity GitHub'}
            wordWrap={true}
            offset={20}>
            <BaseIcon className={s.icons} icon="github" size={24} />
          </BaseTooltipTrigger>
        </BaseLink>
        <BaseLink target="_blank" to="https://twitter.com/gridsingularity">
          <BaseTooltipTrigger
            triggerClassName={''}
            tooltipClassName={s.helperTooltip}
            position="bottom"
            tooltipChildren={'Grid Singularity Twitter Handle'}
            wordWrap={true}
            offset={20}>
            <BaseIcon className={s.icons} icon="twitter" size={24} />
          </BaseTooltipTrigger>
        </BaseLink>
        <BaseLink target="_blank" to="https://www.linkedin.com/company/grid-singularity">
          <BaseTooltipTrigger
            triggerClassName={''}
            tooltipClassName={s.helperTooltip}
            position="bottom"
            tooltipChildren={'Grid Singularity LinkedIn Profile'}
            wordWrap={true}
            offset={20}>
            <BaseIcon className={s.icons} icon="linkedin" size={24} />
          </BaseTooltipTrigger>
        </BaseLink>
        <BaseLink target="_blank" to="https://gsycommunity.slack.com/">
          <BaseTooltipTrigger
            triggerClassName={''}
            tooltipClassName={s.helperTooltip}
            position="bottom"
            tooltipChildren={'Grid Singularity Slack Channel'}
            wordWrap={true}
            offset={20}>
            <BaseIcon className={s.icons} icon="slack" size={22} />
          </BaseTooltipTrigger>
        </BaseLink>
        <BaseLink target="_blank" to="https://gridsingularity.medium.com/">
          <BaseTooltipTrigger
            triggerClassName={''}
            tooltipClassName={s.helperTooltip}
            position="bottom"
            tooltipChildren={'Grid Singularity Medium Blog'}
            wordWrap={true}
            offset={20}>
            <BaseIcon className={s.icons} icon="medium" size={24} />
          </BaseTooltipTrigger>
        </BaseLink>
      </div>
    </div>
  );
};
