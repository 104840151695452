import React, { useMemo } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { ConfigType } from 'src/graphql';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import { TListItemDefaultProps } from './ListItemDefault.types';
import classNames from 'classnames';
import classnames from 'classnames';
import s from './ListItemDefault.module.scss';
import { useConfigurationFlowState } from 'src/hooks/useConfigurationFlowState';
import { useSimulationButtons } from 'src/hooks/useSimulationButtons';
import { useSimulationStatus } from 'src/hooks/useSimulationStatus';

export const ListItemDefault: React.FC<TListItemDefaultProps> = ({
  style,
  place,
  belongsToLoggedUser,
  isLoggedIn,
  title,
  modalRefObject,
  className,
  description,
  jobId,
  configurationUuid,
  actionNumber,
  configType,
  onCommunityClick,
  onRunButtonClick,
  onStopButtonClick,
  onResultsClick,
  onMenuClick,
}) => {
  const { sidebarOpen } = useConfigurationFlowState();
  const { simulationStatus } = useSimulationStatus(jobId, !sidebarOpen, configurationUuid);

  const {
    runButtonState,
    runButtonOptions,
    stopButtonOptions,
    stopButtonState,
  } = useSimulationButtons({
    jobUuid: jobId,
    configurationUuid,
    simulationStatus,
  });

  const actionButtons = useMemo(() => {
    const buttons: {
      icon: TIconNames;
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    }[] = [];
    if (!belongsToLoggedUser) return buttons;

    if (runButtonState === 'rerun') {
      buttons.push({
        icon: 'bar-chart',
        onClick: onResultsClick,
      });
    } else {
      buttons.push({
        icon: runButtonOptions.icon,
        onClick: onRunButtonClick,
      });
    }

    if (stopButtonState === 'stop') {
      buttons.push({
        icon: stopButtonOptions.icon,
        onClick: onStopButtonClick,
      });
    }

    return buttons;
  }, [
    belongsToLoggedUser,
    onResultsClick,
    onRunButtonClick,
    onStopButtonClick,
    runButtonOptions.icon,
    runButtonState,
    stopButtonOptions.icon,
    stopButtonState,
  ]);

  return (
    <div className={`${s.blockOuter} ${className}`} onClick={onCommunityClick} style={style}>
      <div className={s.upperRow}>
        <div
          className={classnames(s.iconMainWrapper, {
            [s.iconMainWrapperCanary]: configType === ConfigType.CanaryNetwork,
          })}>
          <BaseIcon
            icon={configType === ConfigType.CanaryNetwork ? 'canary' : 'simulation'}
            className={s.iconMain}
            size={configType === ConfigType.CanaryNetwork ? 13 : 11}
          />
        </div>
        <div className={s.infoWrapper}>
          <BaseTooltipTrigger
            disabled={title.length < 30}
            position="top"
            tooltipChildren={title}
            offset={20}>
            <p className={s.title}>{title}</p>
          </BaseTooltipTrigger>
          <p className={s.description}>{description}</p>
        </div>
        <div className={s.iconsWrapper} onClick={(e) => e.stopPropagation()}>
          {belongsToLoggedUser && (
            <button type="button">
              <BaseIcon icon="user" className={s.iconUser} />
            </button>
          )}
          {isLoggedIn ? (
            <button
              type="button"
              ref={modalRefObject}
              onClick={() => onMenuClick(modalRefObject.current!)}>
              {<BaseIcon icon="more-horizontal" className={s.iconMore} />}
            </button>
          ) : null}
        </div>
      </div>
      <div className={s.lowerRow}>
        <BaseIcon icon="globe" className={s.iconLocation} />
        <p className={s.location}>{place}</p>
      </div>
      <div
        className={classNames(s.actionButtons, {
          [s.oneButton]: actionButtons.length === 1,
        })}>
        {actionButtons.map((btn, index) => (
          <button
            key={index}
            type="button"
            className={classnames(s[`action-${btn.icon}`])}
            onClick={(e) => btn.onClick(e)}>
            <BaseIcon icon={btn.icon} className={s.iconMain} />
            {actionNumber ? <span className={s.actionNumber}>{actionNumber}</span> : null}
          </button>
        ))}
      </div>
    </div>
  );
};
