import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import React, { useEffect } from 'react';
import {
  setActiveConfigurationUuid,
  setCommunityUser,
} from 'src/redux/configuration/configuration.slice';
import { setSCMCommunityManager, setSCMFlow } from 'src/redux/scm/scm.slice';

import { TemplateDefault } from 'src/templates/TemplateDefault';
import { routesConfig } from 'src/routes/routes.config';
import { useAppDispatch } from 'src/redux/store';
import { useAppLocation } from 'src/hooks/useAppLocation';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export type TParamsType = {
  configurationUuid: string;
  assetUuid: string;
};

export const ScmInvitation: React.FC = () => {
  const { updateAppFlow } = useAppFlow();
  const dispatch = useAppDispatch();
  const { configurationUuid }: TParamsType = useParams();
  const history = useHistory();
  const params = history.location.search;
  const searchParams = new URLSearchParams(params);
  const communityManager = searchParams.get('communityManager');

  useEffect(() => {
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
    if (communityManager) dispatch(setSCMCommunityManager(communityManager));
    // dispatch(setActiveConfigurationUuid(configurationUuid));
    history.push(routesConfig.singularityMapResults(configurationUuid));
  }, [dispatch, updateAppFlow, history, configurationUuid, communityManager]);

  return <TemplateDefault>SCM Invitation</TemplateDefault>;
};
