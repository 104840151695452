import { EViewType, TChartSavingsProps } from './KeyResultsSCM.types';

import { KeyResultInfoArea } from 'src/components/KeyResultsSCM/components/KeyResultInfoArea';
import { KeyResultProgress } from 'src/components/KeyResultsSCM/components/KeyResultProgress';
import React from 'react';
import { formatter } from 'src/utils/formatter';
import { getDetails } from 'src/components/KeyResultsSCM/KeyResultsSCMhelpers';
import s from './KeyResultsSCM.module.scss';

export const KeyResultsSCM: React.FC<TChartSavingsProps> = ({
  mode,
  currency,
  kpi,
  homeBillValue,
  homeBillInfo,
  homeSavings,
  homeSavingsInfo,
  communitySavings,
  communitySelfSufficiency,
  title = '⚡ Key Results',
  showToggle = false,
  horizontal = false,
}) => {
  const currencySymbol = formatter.getCurrencySymbol(currency);
  function normalizePercentage(value = 0) {
    return value.toFixed(1);
  }

  //  if (mode === 'Home' && isUserACommunityMember) {
  //    return null;
  //  }

  return (
    <div className={s.container}>
      <div className={s.header}>
        {title.length > 0 && <div className={s.title}>{title}</div>}
        {showToggle && (
          <button type="button" className={s.toggle}>
            <span>Monthly</span>
          </button>
        )}
      </div>
      {mode === 'Community' ? (
        <div>
          <KeyResultInfoArea
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(communitySavings)}
            resultInfo={getDetails(EViewType.CommunitySavings)}
            useInfoHelper={false}
          />
          <KeyResultInfoArea
            symbol="%"
            symbolPosition="right"
            value={normalizePercentage(communitySelfSufficiency)}
            resultInfo={getDetails(EViewType.CommunitySelfSufficiency)}
            useInfoHelper={false}
          />
        </div>
      ) : (
        <div className={horizontal ? s.horizontalWrapper : undefined}>
          <KeyResultInfoArea
            className={horizontal ? s.horizontalBlock : undefined}
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(homeBillValue)}
            resultInfo={getDetails(EViewType.EnergyBill)}
          />
          <KeyResultInfoArea
            className={horizontal ? s.horizontalBlock : undefined}
            symbol={currencySymbol}
            symbolPosition="left"
            value={normalizePercentage(homeSavings)}
            resultInfo={getDetails(EViewType.Savings)}
          />
          {/*<KeyResultInfoArea
            symbol="%"
            symbolPosition="right"
            value={normalizePercentage(kpi?.self_sufficiency)}
            resultInfo={getDetails(EViewType.SelfSufficiency)}
      />*/}
        </div>
      )}
      {/*<KeyResultProgress
        title="Energy Benchmark"
        percentage={40}
        badges={[
          {
            type: 'House',
            count: 3,
            onClick: () => {
              console.log('asda');
            },
          },
        ]}
      />*/}
    </div>
  );
};
