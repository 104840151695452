import * as THREE from 'three';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TTGMember, TTGProps } from './TeamGlobe.types';

import { Avatar } from './components/Avatar/Avatar';
import { TTGScreenPos } from './components/TeamGlobeController/TeamGlobeController.types';
import { TeamGlobeController } from './components/TeamGlobeController/TeamGlobeController';
import classnames from 'classnames';
import s from './TeamGlobe.module.scss';

const MAX_MEMBER_COUNT = 6;

export const TeamGlobe: React.FC<TTGProps> = ({
  className,
  members,
  countries,
  headquarters,
  onSelectMember,
}) => {
  const [tooltipMembers, setTooltipMembers] = useState<TTGMember[]>([]);
  const [multiRows, setMultiRows] = useState(false);
  const tgContainerRef = useRef<HTMLDivElement | null>(null);
  const tgTooltipRef = useRef<HTMLDivElement | null>(null);
  const tgRenderer = useRef<TeamGlobeController | null>(null);

  const tooltipCountry = useMemo(() => {
    if (!tooltipMembers.length) return '';

    return countries.find((country) => country.code === tooltipMembers[0].countryCode)?.name;
  }, [countries, tooltipMembers]);

  // Listener whenever object is hovered
  const handleHoveredObj = useCallback(
    (object: THREE.Mesh) => {
      const newTooltipMembers = members.filter(
        (member) => member.countryCode === object?.userData?.countryCode,
      );

      setTooltipMembers(newTooltipMembers);
      setMultiRows(newTooltipMembers.length > MAX_MEMBER_COUNT);
    },
    [members],
  );

  // Listener whenever hovered object position update
  const handleHoveredObjPosUpdate = useCallback((position: TTGScreenPos) => {
    if (tgTooltipRef.current) {
      tgTooltipRef.current.style.left = `${position.x}px`;
      tgTooltipRef.current.style.top = `${position.y}px`;
    }
  }, []);

  // Listener bio click
  const handleGoToBio = useCallback(
    (member: TTGMember) => {
      onSelectMember(member);
    },
    [onSelectMember],
  );

  // Init 3d canvas when react component is mounted
  useEffect(() => {
    if (tgContainerRef.current) {
      tgRenderer.current = new TeamGlobeController(tgContainerRef.current, countries, headquarters);
      tgRenderer.current.onHoveredObj = handleHoveredObj;
      tgRenderer.current.onUpdateHoveredObjPosition = handleHoveredObjPosUpdate;
    }

    return () => {
      tgRenderer.current?.dispose();
    };
  }, [countries, handleHoveredObj, handleHoveredObjPosUpdate, headquarters]);

  return (
    <div className={classnames(s.container, className)}>
      <div ref={tgContainerRef} className={s.tgContainer} />
      {tooltipMembers.length > 0 && (
        <div ref={tgTooltipRef} className={s.tgTooltip}>
          <p className={s.tgTooltipCountry}>{tooltipCountry}</p>
          <div className={classnames(s.tgTooltipList, multiRows ? s.tgTooltipListMultiRows : '')}>
            {tooltipMembers.map((member, index) => {
              return (
                <div
                  key={`team-globe-tooltip-member-${index}`}
                  className={classnames(
                    s.tgTooltipItem,
                    multiRows ? s.tgTooltipItemMultiRows : '',
                  )}>
                  <Avatar className={s.avatar} url={member.avatar} outline />
                  <div className={s.tgLabelContainer}>
                    <h3 className={s.tgTooltipTitle}>{member.name}</h3>
                    {/* <span className={s.tgTooltipLabel} onClick={() => handleGoToBio(member)}>
                      Read Bio
                    </span> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
