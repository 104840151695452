import { EModalSize, EPredefinedModalIds } from 'src/constants/modals';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { closeModal, openModal } from 'src/redux/modals/modals.slice';
import { selectIsModalOpened, selectModalContent } from 'src/redux/modals/modals.selectors';

import { BaseGenericModal } from 'src/components/BaseGenericModal';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import { BaseProgress } from 'src/components/BaseProgress';
import classnames from 'classnames';
import s from './ModalOperateProcess.module.scss';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const ModalOperateProcess: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [operateProgress, setOperateProgress] = useState(0);

  const isModalDeployOpened = useSelector(
    selectIsModalOpened(EPredefinedModalIds.MODAL_OPERATE_PROCESS),
  );
  const modalContent = useSelector(selectModalContent());
  const { title, subTitle, icon } = modalContent;

  const isUploadFailedErrorModal = useMemo(() => title === 'Upload Failed', [title]);

  useEffect(() => {
    if (!isModalDeployOpened && operateProgress !== 0) {
      setOperateProgress(0);
    }
    if (isModalDeployOpened) {
      const interval = setInterval(() => {
        setOperateProgress((time) => time + 10);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isModalDeployOpened, operateProgress]);

  const closeDeployProcessModal = () => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_OPERATE_PROCESS));
  };

  const handleFooterLink = () => {
    closeDeployProcessModal();
  };

  if (!isModalDeployOpened) return null;

  return (
    <>
      <div className={s.background} onClick={closeDeployProcessModal} />
      <BaseGenericModal
        size={EModalSize.XL550}
        modalId={EPredefinedModalIds.MODAL_OPERATE_PROCESS}
        className={s.modal}
        modalRef={modalRef}>
        <div className={classnames(s.modalContentWrapper, s.modalDeployProcess)}>
          {isUploadFailedErrorModal ? (
            <div className={s.errorIconContainer}>
              <BaseIcon icon={icon} size={30} />
            </div>
          ) : (
            <BaseIcon icon={icon} className={s.canaryBox} size={48} />
          )}
          <h2 className={s.modalTitle}>{title || 'Operate Community'}</h2>
          <p>{subTitle || 'Processing community configuration'}</p>
          {!isUploadFailedErrorModal && (
            <BaseProgress className={s.deployProcessProgressBar} progress={operateProgress} />
          )}
          <BaseLink type="button" onClick={handleFooterLink} className={s.plainLink}>
            Cancel
          </BaseLink>
        </div>
      </BaseGenericModal>
    </>
  );
};
