import { TWeeklyResultsIndicatorProps } from './WeeklyResultsIndicator.types';
import React, { useEffect, useMemo, useState } from 'react';
import s from './WeeklyResultsIndicator.module.scss';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseButtonSquare } from 'src/components/BaseButtonSquare';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/redux/store';
import { BACKEND_DATE_FORMATS, TMoment, UTCMoment } from 'src/utils/UTCMoment';
import {
  selectConfigType,
  selectResultsEndTime,
  selectResultsStartTime,
} from 'src/redux/configuration/configuration.selectors';
import {
  setResultsEndTime,
  setResultsStartTime,
} from 'src/redux/configuration/configuration.slice';
import { createWeekObject } from 'src/components/MapSidebar/components/MapSidebarResults/components/WeeklyResultsIndicator/WeeklyResultsIndicator.helpers';

export const WeeklyResultsIndicator: React.FC<TWeeklyResultsIndicatorProps> = ({
  settingsStartTime,
  settingsEndTime,
}) => {
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);

  const settingsStartTimeUTC = UTCMoment.fromBackend(
    settingsStartTime,
    BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
  );
  const settingsEndTimeUTC = UTCMoment.fromBackend(
    settingsEndTime,
    BACKEND_DATE_FORMATS.SIMULATION_RESULTS_START_END_TIME,
  );
  const configType = useSelector(selectConfigType);
  const resultsStartTime = useSelector(selectResultsStartTime);
  const resultsEndTime = useSelector(selectResultsEndTime);
  const [initialData, setInitialData] = useState({
    resultsStartTime,
    resultsEndTime,
  });

  const isCN = configType === 'CANARY_NETWORK';
  const initialStartTimeUtc = UTCMoment.utc(initialData.resultsStartTime);
  const initialEndTimeUtc = UTCMoment.utc(initialData.resultsEndTime);
  const initialStartEndDiff = initialEndTimeUtc.diff(initialStartTimeUtc);

  const update = (startUtc: TMoment, endUtc: TMoment, index: number) => {
    setSelectedWeekIndex(index);
    dispatch(setResultsStartTime(startUtc.toISOString()));
    dispatch(setResultsEndTime(endUtc.toISOString()));
    setOpened(false);
  };

  const weeksList = useMemo(() => {
    if (!initialData) return [];

    const currentWeekStartUTC = UTCMoment.utc().startOf('isoWeek');
    const firstWeekData = createWeekObject(
      settingsStartTimeUTC,
      settingsStartTimeUTC.clone().add(6, 'days'),
      {
        weekNum: 1,
      },
    );

    const weeks = [firstWeekData];

    if (isCN) {
      const currentWeekData = createWeekObject(initialStartTimeUtc, initialEndTimeUtc, {
        isCurrent: true,
      });
      weeks.push(currentWeekData);
      weeks.reverse();
    }

    let i = 1;
    while (
      (isCN ? currentWeekStartUTC : settingsEndTimeUTC).diff(weeks[weeks.length - 1].endUtc) > 1000
    ) {
      const startUtc = weeks[weeks.length - 1].endUtc.clone().add(1, 'day').startOf('day').clone();
      let endUtc = startUtc.clone().add(initialStartEndDiff).endOf('day');

      if (!isCN && endUtc.diff(settingsEndTimeUTC) > 0) {
        endUtc = settingsEndTimeUTC.endOf('day');
      }

      weeks.push(createWeekObject(startUtc, endUtc, { weekNum: ++i }));
    }

    return weeks;
  }, [
    initialData,
    initialEndTimeUtc,
    initialStartEndDiff,
    initialStartTimeUtc,
    isCN,
    settingsEndTimeUTC,
    settingsStartTimeUTC,
  ]);

  useEffect(() => {
    if (!initialData) {
      setInitialData({
        resultsStartTime,
        resultsEndTime,
      });
    }
  }, [initialData, resultsEndTime, resultsStartTime]);

  useEffect(() => {
    const selectedIndex = weeksList.findIndex((item) => {
      return item.endUtc.format('MMMM D') === initialEndTimeUtc.format('MMMM D');
    });

    if (selectedIndex) {
      setSelectedWeekIndex(selectedIndex);
    }
  }, [initialEndTimeUtc, weeksList]);

  if (settingsEndTimeUTC.diff(settingsStartTimeUTC, 'days') < 7) {
    return null;
  }

  return (
    <div
      className={classNames(s.container, {
        [s.opened]: opened,
      })}>
      <div className={s.valueButton} onClick={() => setOpened(!opened)}>
        <BaseButtonSquare icon="time" size="2" theme="flat-blue" />
        <p>
          {isCN && initialEndTimeUtc.toISOString() === resultsEndTime && (
            <strong>Current - </strong>
          )}
          {weeksList[selectedWeekIndex]?.startUtc?.format('MMMM D')} to{' '}
          {weeksList[selectedWeekIndex]?.endUtc?.format('MMMM D')}
        </p>
        <div className={s.icon}>
          <BaseIcon icon="arrow" size={10} />
        </div>
      </div>
      <div className={s.dropdown}>
        {weeksList.map(({ title, startUtc, endUtc }, i) => (
          <button key={i} type="button" onClick={() => update(startUtc, endUtc, i)}>
            <span></span> {title}
          </button>
        ))}
      </div>
    </div>
  );
};
