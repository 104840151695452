import * as d3 from 'd3';

import {
  FlowAssetRenderer,
  TFlowAssetRendererProps,
} from 'src/components/EnergyFlow/components/EnergyFlowContent/components/FlowAssetRenderer/';
import React, { useEffect, useRef, useState } from 'react';
import { TPolygonCoord, getPolygonData } from 'src/utils/polygonHelper';

import { BaseIcon } from 'src/components/BaseIcon';
import { EnergyBadge } from './components/EnergyBadge';
import { EnergyBadgeContainer } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadgeContainer/EnergyBadgeContainer';
import { TEnergyFlowContentProps } from './EnergyFlowContent.types';
import { appendTriangles } from 'src/components/EnergyFlow/components/EnergyFlowContent/EnergyFlowContentHelpers';
import s from './EnergyFlowContent.module.scss';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

//sin(Math.PI/8) = 0.38268343235
export const EnergyFlowContent: React.FC<TEnergyFlowContentProps> = ({
  isSidebarOpen,
  data,
  netTotalEnergy,
}) => {
  const drawContainerRef = useRef<HTMLDivElement>(null);
  const mainIconRef = useRef<SVGSVGElement>(null);
  const iconWrapperRef = useRef<HTMLDivElement>(null);

  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const [sortedPolygonPoints, setSortedPolygonPoints] = useState<Array<
    TFlowAssetRendererProps['polygonCoordinate']
  > | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!drawContainerRef.current) return;

      const containerData = drawContainerRef.current.getBoundingClientRect();
      const centerOfContainer: TPolygonCoord = {
        x: containerData.width / 2,
        y: containerData.height / 2,
      };
      const polygon = getPolygonData({
        radius: containerData.height / 2 - 20,
        center: centerOfContainer,
        edgeCount: 8,
      });

      const sortedPolygonPoints = polygon.sort((a, b) => {
        const dif = a.y - b.y;
        return dif !== 0 ? -dif : a.x - b.x;
      });

      const necessaryPolygonPoints = sortedPolygonPoints.slice(0, data.length);
      setSortedPolygonPoints(necessaryPolygonPoints);

      d3.select('#d3container').selectAll(`.${s.lineSelector}`).remove();
      const svg = d3.select('#d3container');
      svg.attr('width', containerData.width);
      svg.attr('height', containerData.height);

      necessaryPolygonPoints.forEach((item, index) => {
        const lineuuid = (data[index].flowType === 'Import'
          ? `importGradient${v4()}`
          : `exportGradient${v4()}`
        ).replace('.', '');

        const gradientColors: [string, string] =
          data[index].flowType === 'Import' ? ['#1A48ED', '#5DE273'] : ['#1A48ED', '#FC1355'];

        const defs = svg.append('defs').attr('class', `${s.lineSelector}`);

        const linearGradient = defs
          .append('linearGradient')
          .attr('id', lineuuid)
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', centerOfContainer.x - 10) // x position of the first end of the line
          .attr('y1', centerOfContainer.y - 10) // y position of the first end of the line
          .attr('x2', item.x) // x position of the second end of the line
          .attr('y2', item.y);

        linearGradient
          .append('stop')
          .attr('offset', 0)
          .attr('stop-color', gradientColors[0])
          .attr('stop-opacity', '1');

        linearGradient
          .append('stop')
          .attr('offset', 1)
          .attr('stop-color', gradientColors[1])
          .attr('stop-opacity', '1');

        svg
          .append('path') // attach a line
          .attr('stroke', `url(#${lineuuid})`)
          .attr('class', `${s.lineSelector}`)
          .attr('stroke-width', 5)
          .attr('stroke-opacity', 0.7)
          .attr('d', `M${centerOfContainer.x} ${centerOfContainer.y}L${item.x} ${item.y}`)
          .attr('x1', centerOfContainer.x) // x position of the first end of the line
          .attr('y1', centerOfContainer.y) // y position of the first end of the line
          .attr('x2', item.x) // x position of the second end of the line
          .attr('y2', item.y);

        appendTriangles({
          d3selection: svg,
          center: centerOfContainer,
          target: item,
          strokeWidth: 5,
          selector: s.lineSelector,
          flowType: data[index].flowType,
        });
      });
    });
  }, [drawContainerRef, sidebarExpanded, isSidebarOpen, data]);

  return (
    <div className={s.contentWrapper}>
      <div className={s.badgeRow}>
        <EnergyBadge title="Net Energy:" amount={`${netTotalEnergy} kWh`} size="M" />
      </div>
      <div className={s.energyContent} ref={drawContainerRef}>
        <svg id="d3container" width={0} height={0} className={s.lineContainer}>
          <defs>
            <linearGradient id="exportGradient" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#5DE273" />
              <stop offset="1" stopColor="#1A48ED" />
            </linearGradient>

            <linearGradient id="importGradient" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#FC1355" />
              <stop offset="1" stopColor="#1A48ED" />
            </linearGradient>
          </defs>
        </svg>
        <div className={s.testArea} />
        {sortedPolygonPoints?.map((coordinate, index) => {
          if (!data[index]) return null;

          return (
            <FlowAssetRenderer
              assetType={data[index].assetType}
              key={`${coordinate.x}${coordinate.y}`}
              polygonCoordinate={coordinate}
            />
          );
        })}
        <div className={s.mainIcon} ref={iconWrapperRef}>
          <BaseIcon icon="house-3d" size={90} svgRef={mainIconRef}></BaseIcon>
        </div>
      </div>
      <EnergyBadgeContainer />
    </div>
  );
};
