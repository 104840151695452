import {
  ENotificationPlace,
  TSingleNotification,
} from 'src/redux/notifications/notifications.types';

export const SelfSufficiencyInfoText =
  'This is the share of the total electricity demand of the community that is provided by the community’s renewable assets, reducing the reliance on the external electricity grid. This rate also represents the minimum share of renewable energy in your community energy mix.';

export const SelfConsumptionInfoText =
  'This is the share of the total electricity production produced by the community that is consumed in the community.';

export const CommunityEnergyProfileInfoText =
  'This graph shows the amount of energy that is generated and consumed in the energy community over the selected time period.';

export const SCMMyCommunityNotification: TSingleNotification = {
  notificationPlace: ENotificationPlace.HEADER,
  characterIcon: '☝',
  description:
    'You can also rerun the same simulation or go back to change member or market settings and run a new simulation to optimise configuration ahead of moving to operation.',
};
