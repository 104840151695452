import React, { useRef, useState } from 'react';

import { BaseIcon } from 'src/components/BaseIcon';
import classnames from 'classnames';
import s from '../GridMarket/GridMarket.module.scss';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';

type GridMarketModalHeaderProps = {
  formTitle: string;
  onClick?: () => void;
};

export const GridMarketModalHeader: React.FC<GridMarketModalHeaderProps> = ({
  formTitle,
  onClick,
}) => {
  return (
    <div className={classnames(s.paddingT)} onClick={onClick}>
      <header className={s.header}>
        <h3 className={s.title}>{formTitle}</h3>
      </header>
    </div>
  );
};
