import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BaseCheckbox } from 'src/components/BaseCheckbox';
import { BaseCounter } from 'src/components/BaseCounter';
import { BaseDateRangePicker } from 'src/components/BaseDateRangePicker';
import { BaseFileUpload } from 'src/components/BaseFileUpload';
import { BaseInput } from 'src/components/BaseInput';
import { BaseSelect } from 'src/components/BaseSelect';
import { BaseSlider } from 'src/components/BaseSlider';
import { BaseSwitch } from 'src/components/BaseSwitch';
import { BaseTextarea } from 'src/components/BaseTextarea';
import { CLOUD_COVERAGE_OPTIONS_FOR_CUSTOM_PV } from 'src/constants/application';
import { ConfigType } from 'src/graphql';
import { CustomPV } from 'src/components/CustomPV';
import { EFormVariant } from 'src/typings/base-types';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { InfoHelper } from '../InfoHelper';
import { LocationSearch } from 'src/components/LocationSearch';
import { TFieldsUnionWithValue } from 'src/utils/assetsFields/assetsFields.types';
import { TFormFieldsGeneratorProps } from './FormFieldsGenerator.types';
import { UTCMoment } from '../../utils/UTCMoment';
import classnames from 'classnames';
import { convertLngLat } from 'src/utils/worldMap/helpers';
import s from './FormFieldsGenerator.module.scss';
import { selectConfigType } from 'src/redux/configuration/configuration.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { useSelector } from 'react-redux';
import vars from 'src/assets/styles/utils/vars.module.scss';

export const FormFieldsGenerator: React.FC<TFormFieldsGeneratorProps> = ({
  className,
  style,
  fields,
  errors,
  onSubmit,
  onChange,
  onBlur,
  onLocationChange,
  id,
  focusField,
  theme,
  flyAfterLocationSelect,
  disableLocationField,
  readOnly,
  hasCustomPV,
  formVariant,
  azimuthValuesForCustomPV,
  fieldForm,
  setAzimuthValuesForCustomPV,
  isSCM = false,
}) => {
  const containerRef = useRef<HTMLFormElement>(null);
  const [capacityFields, setCapacityFields] = useState<TFieldsUnionWithValue[]>([]);
  const [tradingFields, setTradingFields] = useState<TFieldsUnionWithValue[]>([]);
  const [formFields, setFormFields] = useState<any[]>([]);
  const [showOrientationSection, setShowOrientationSection] = useState<boolean>(true);
  const configType = useSelector(selectConfigType);
  const isCanaryNetwork = configType === ConfigType.CanaryNetwork;
  const handleSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'] = (e) => {
    e.preventDefault();

    if (readOnly) return;

    onSubmit();
  };

  useEffect(() => {
    setTimeout(() => {
      if (focusField && containerRef.current) {
        const el:
          | HTMLInputElement
          | HTMLTextAreaElement
          | null = containerRef.current.querySelector(
          `input[name="${focusField}"], textarea[name="${focusField}"]`,
        );

        el?.focus();
      }
    });
  }, [focusField]);

  const isCustomPVSelected = useMemo(() => hasCustomPV && formVariant === EFormVariant.Advanced, [
    hasCustomPV,
    formVariant,
  ]);

  const renderInputFields = (inputFields, err, fieldsTheme = theme) => {
    const UIfromFields = inputFields.map((f, index) => {
      const containerProps = {
        inlineAlign: f.inlineAlign,
        // showTooltip: f.showTooltip,
        showTooltip: false,
        tooltipText: f.tooltipText,
        key: f.name,
      };

      const fieldProps: any = {
        disabled: f.disabled || readOnly,
        error: err?.[f.name],
        label: f.label,
        name: f.name,
        onChange,
      };
      if (fieldForm) fieldProps.fieldForm = fieldForm;

      const errorWidth = f.fullWidthErr && f.inlineAlign ? '200%' : undefined;
      const marginTopSmall =
        (fields[index - 1] || {}).type === 'switcher' ||
        ((fields[index - 2] || {}).type === 'switcher' && (fields[index - 1] || {}).inlineAlign);

      let calculatedTheme;
      let dateRangeTheme;
      switch (fieldsTheme) {
        case 'dark':
          calculatedTheme = 'line-dark';
          dateRangeTheme = 'light';
          break;
        case 'light':
        case undefined:
          calculatedTheme = 'line-light';
          dateRangeTheme = 'dark';
          break;
        default:
          calculatedTheme = fieldsTheme;
          dateRangeTheme = 'light';
          break;
      }

      switch (f.type) {
        case 'text':
          return (
            <FieldContainer
              {...containerProps}
              className={classnames(s.inputField, {
                [s.marginTopSmall]: marginTopSmall,
              })}>
              <BaseInput
                {...fieldProps}
                type="text"
                theme={calculatedTheme}
                value={f.value}
                showTooltip={f.showTooltip}
                tooltipText={f.tooltipText}
                errorWidth={errorWidth}
                autoComplete="off"
                onBlur={onBlur}
              />
            </FieldContainer>
          );

        case 'textarea':
          return (
            <FieldContainer
              {...containerProps}
              className={classnames(s.inputField, {
                [s.marginTopSmall]: marginTopSmall,
              })}>
              <BaseTextarea
                {...fieldProps}
                theme={calculatedTheme}
                inputHeight="2"
                value={f.value}
                errorWidth={errorWidth}
                onBlur={onBlur}
              />
            </FieldContainer>
          );

        case 'number':
          return (
            <FieldContainer
              {...containerProps}
              className={classnames(s.inputField, {
                [s.marginTopSmall]: marginTopSmall,
              })}>
              <BaseInput
                {...fieldProps}
                type="number"
                theme={calculatedTheme}
                value={f.value}
                unit={f.unit}
                errorWidth={errorWidth}
                onBlur={onBlur}
                showTooltip={f.showTooltip}
                tooltipText={f.tooltipText}
              />
            </FieldContainer>
          );

        case 'switcher':
          return (
            <FieldContainer {...containerProps} className={s.switchWrapper}>
              <BaseSwitch
                {...fieldProps}
                className={s.switch}
                value={f.value}
                options={f.options}
                theme={fieldsTheme === 'dark' ? 'light' : 'gradient-dark'}
                variant="horizontal-edge"
                showTooltip
                tooltipText={f.tooltipText}
              />
            </FieldContainer>
          );

        case 'location':
          return (
            <FieldContainer {...containerProps} className={s.locationField}>
              <LocationSearch
                {...fieldProps}
                disabled={disableLocationField || f.disabled || readOnly}
                value={f.value}
                onChange={({ name, ...rest }) => {
                  onChange({ name, value: convertLngLat(rest) });
                  onLocationChange?.({ name, ...rest });
                }}
                flyAfterSelect={flyAfterLocationSelect}
                theme={calculatedTheme}
                iconLeft={isSCM ? null : undefined}
                iconRight={'locate'}
                iconRightColor={vars['color-oh-so-green']}
                elevateLabel={true}
                dropdownClassName={s.locationSearchDropdown}
                onBlur={onBlur}
              />
            </FieldContainer>
          );

        case 'enum':
          return (
            <FieldContainer {...containerProps} className={s.selectField}>
              <BaseSelect
                {...fieldProps}
                value={f.value}
                options={f.options}
                theme={calculatedTheme}
                showTooltip
                tooltipText={f.tooltipText}
              />
            </FieldContainer>
          );

        case 'slider':
          return (
            <FieldContainer {...containerProps} className={s.sliderField}>
              <BaseSlider
                {...fieldProps}
                value={f.value}
                min={f.minVal}
                max={f.maxVal}
                step={f.step}
                label={`${f.label}: ${Array.isArray(f.value) ? f.value.join('–') : f.value} ${
                  f.unit || ''
                }`}
                showButtons={false}
              />
            </FieldContainer>
          );

        case 'file':
          return (
            <FieldContainer {...containerProps} className={s.fileUploadField}>
              <BaseFileUpload
                {...fieldProps}
                showTooltip
                tooltipText={f.tooltipText}
                value={f.value}
                theme={isSCM ? 'line-dark' : 'line-light'}
                allowDownload={f.allowDownload}
              />
            </FieldContainer>
          );

        case 'checkbox':
          return (
            <FieldContainer {...containerProps} className={s.checkboxField}>
              <BaseCheckbox
                {...fieldProps}
                value={f.value}
                theme={calculatedTheme === 'line-dark' ? 'gradient-dark-extended' : 'light'}
              />
            </FieldContainer>
          );

        case 'counter':
          return (
            <FieldContainer {...containerProps} className={s.counter}>
              <BaseCounter {...fieldProps} value={f.value} min={f.min} max={f.max} />
            </FieldContainer>
          );

        case 'dateRange':
          return (
            <FieldContainer {...containerProps} className={s.datePickerContainer}>
              <div className={s.lengthWrapper}>
                <div className={s.lengthText}>Simulation Length</div>
                <InfoHelper
                  className={s.lengthTooltip}
                  info="Choose the dates for the run time of the simulation below. Simulations can run from 1 day up to 1 month."
                />
              </div>
              <BaseDateRangePicker
                {...fieldProps}
                theme={dateRangeTheme}
                startValue={f.value.startDate}
                endValue={f.value.endDate}
                valueFormat={f.valueFormat}
                minDate={UTCMoment.utc(f.value.startDate).add(1, 'day').toDate()}
                maxDate={UTCMoment.utc(f.value.endDate).subtract(1, 'day').toDate()}
                onChange={({ startDate, endDate }) => {
                  onChange({
                    name: f.name,
                    value: { startDate: startDate, endDate: endDate },
                  });
                }}
                tags={[
                  {
                    value: 7,
                    label: '7 Days',
                    badge: 'Recommended',
                  },
                  {
                    value: 14,
                    label: '2 Weeks',
                  },
                  {
                    value: 30,
                    label: '1 Month',
                  },
                ]}
              />
            </FieldContainer>
          );

        default:
          break;
      }
    });
    return UIfromFields;
  };

  useEffect(() => {
    if (isCustomPVSelected) {
      const capacityFieldNames = ['capacityKw', 'cloudCoverage', 'powerProfile'];
      const canaryOnlyFieldNames = ['name', 'geoTagLocation', 'forecastStreamEnabled'];
      const defaultFieldNames = ['name', 'geoTagLocation', 'forecastStreamEnabled'];
      const tradingFieldNames = [
        'useMarketMakerRate',
        'initialSellingRate',
        'finalSellingRate',
        'energyRateDecreasePerUpdate',
        'fitToLimit',
        'updateInterval',
      ];
      const capacityInputFields = fields
        .filter((field) => capacityFieldNames.includes(field.name))
        .map((f) => {
          if (f.name === 'cloudCoverage' && f.type === 'enum') {
            // show/hide orientation section on the basis of dropdown value
            if (f.value !== 5 && !isSCM) {
              setShowOrientationSection(false);
            } else {
              setShowOrientationSection(true);
            }
            return {
              ...f,
              options: CLOUD_COVERAGE_OPTIONS_FOR_CUSTOM_PV, // update solar profile dropdown options
            };
          }
          return f;
        });

      const capacityFieldsTemp = renderInputFields(
        capacityInputFields,
        errors,
        isSCM ? 'dark' : 'light',
      );
      setCapacityFields(capacityFieldsTemp);
      if (!isSCM) {
        const tradingFieldsTemp = renderInputFields(
          fields.filter((field) => tradingFieldNames.includes(field.name)),
          errors,
          'light',
        );
        setTradingFields(tradingFieldsTemp);
      }

      let finalFieldArr = [] as any;
      if (isCanaryNetwork) {
        finalFieldArr = fields.filter((field) => canaryOnlyFieldNames.includes(field.name));
      } else {
        finalFieldArr = fields.filter((field) => defaultFieldNames.includes(field.name));
      }
      // picking first 2 elements from fields array for Express Tab
      // const slicedArray = fields.slice(0, 2);
      setFormFields(finalFieldArr);
    } else {
      setFormFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomPVSelected, fields, errors]);

  return (
    <form
      style={style}
      className={classnames(s.container, className)}
      onSubmit={handleSubmit}
      id={fieldForm ? undefined : id}
      ref={containerRef}>
      {renderInputFields(formFields, errors)}
      {isCustomPVSelected && (
        <CustomPV
          capacityFields={capacityFields}
          tradingFields={tradingFields}
          azimuthValuesForCustomPV={azimuthValuesForCustomPV}
          setAzimuthValuesForCustomPV={setAzimuthValuesForCustomPV}
          showOrientationSection={showOrientationSection}
          theme={isSCM ? 'light' : 'dark'}
        />
      )}
    </form>
  );
};
