import React from 'react';
import { IProps } from 'src/components/LandingPage/StatusBar/StatusBar.types';
import styles from './StatusBar.module.scss';

export function StatusBar({ page, totalPages }: IProps) {
  const indicator = 100 / totalPages;

  return (
    <div className={styles.container}>
      <div
        className={styles.activeBar}
        style={{ height: `${indicator}%`, top: `${page * indicator}%` }}></div>
    </div>
  );
}
