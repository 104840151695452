import { TAsset, TConfigTree, TUsedNames } from 'src/typings/configuration.types';
import {
  TCommonFieldValues,
  TValuesByFieldName,
} from 'src/utils/assetsFields/valuesByFieldName.types';

import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { convertHrsOfDay } from 'src/utils/assetsFields/valueConverters';
import { fieldNamesMapping } from 'src/utils/assetsFields/fieldNamesMapping';
import { getUniqueAssetName } from 'src/utils/fieldUtils';
import produce from 'immer';
import { v4 } from 'uuid';

type TOptions = {
  assignNewUuids?: boolean;
  parentUuid?: TAsset['parentUuid'];
  assignGeoTagLocation?: TCommonFieldValues['geoTagLocation'];
  considerUsedAssetsNames?: TUsedNames;
  onlyRootAsset?: boolean;
  noMarketMarker?: boolean;
};

function destructureConfigTree(
  serialized: string | undefined | null,
  options?: TOptions,
): Pick<TConfigurationState, 'assets' | 'assetsTreeRelations' | 'assetsValues' | 'rootAssetUuid'> {
  const {
    assignNewUuids = false,
    parentUuid,
    assignGeoTagLocation,
    considerUsedAssetsNames = [],
    onlyRootAsset,
    noMarketMarker,
  } = options || {};
  const parsedTree = serialized ? JSON.parse(serialized) : null;
  const usedAssetsNames: TUsedNames = [...considerUsedAssetsNames];

  const output: ReturnType<typeof destructureConfigTree> = {
    assets: {},
    assetsTreeRelations: {},
    assetsValues: {},
    rootAssetUuid: undefined,
  };

  if (!parsedTree) return output;

  const rootAssetUuid = genUuid(parsedTree);

  destructureAsset({ ...parsedTree, uuid: rootAssetUuid }, parentUuid);
  output.rootAssetUuid = rootAssetUuid;

  return output;

  /*
   *  Helper functions:
   */
  function genUuid(asset: TConfigTree): TAsset['uuid'] {
    return assignNewUuids ? v4() : asset.uuid;
  }

  function destructureAsset(asset: TConfigTree, parentUuid: TAsset['parentUuid']) {
    const assetUuid = asset.uuid;

    if (noMarketMarker && asset.type === 'MarketMaker') return;

    output.assets[assetUuid] = {
      parentUuid,
      type: asset.type,
      uuid: assetUuid,
    };

    // I kept the filter process in the below bc. Idk why added
    const assetChildren = asset.children
      ? asset.children
          .filter((a) => a.type)
          .map((item) => {
            return {
              ...item,
              // (optionally) Generate new uuids for children
              uuid: genUuid(item),
            };
          })
      : [];

    output.assetsTreeRelations[assetUuid] = onlyRootAsset
      ? []
      : assetChildren
          .filter((item) => (noMarketMarker ? item.type !== 'MarketMaker' : true))
          .map((a) => a.uuid);

    const mappings = fieldNamesMapping({ assetType: asset.type });

    const values = {};

    Object.entries(mappings).forEach(([beName, feName]) => {
      if (beName in asset) {
        switch (feName) {
          case 'hrsOfDay':
            values[feName] = convertHrsOfDay({ value: asset[beName], direction: 'forFE' });
            break;

          default:
            values[feName] = asset[beName];
        }
      }
    });

    output.assetsValues[assetUuid] = produce(values, (draftState: TValuesByFieldName) => {
      let name = draftState.name || '';

      if (assignGeoTagLocation) {
        draftState.geoTagLocation = assignGeoTagLocation;
      }

      if (usedAssetsNames.includes(name) && draftState.name) {
        name = getUniqueAssetName({ newName: name, usedAssetsNames: usedAssetsNames });
        draftState.name = name;
      }

      usedAssetsNames.push(name);
    });

    if (!onlyRootAsset) {
      assetChildren.forEach((child) => {
        destructureAsset(child, assetUuid);
      });
    }
  }
}

export { destructureConfigTree };
