import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScmCoefficientAlgorithm, SettingsDataFieldsFragment } from 'src/graphql';
import {
  selectAssets,
  selectAssetsTreeRelations,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigurationCharacteristic,
  selectReadOnly,
} from 'src/redux/configuration/configuration.selectors';
import {
  setSelectedAssetUuid,
  updateAssetValuesForUuid,
} from 'src/redux/configuration/configuration.slice';

import { BaseButton } from '../../../BaseButton';
import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseSelect } from 'src/components/BaseSelect';
import { CoefficientAssetTile } from './components/CoefficientAssetTile';
import { EAssetType } from 'src/components/WorldMap/components/ThreeboxController/ThreeboxController.types';
import { FieldContainer } from 'src/components/FormFieldsGenerator/components/FieldContainer';
import { InfoHelper } from 'src/components/InfoHelper';
import { SHARING_COEFFICIENT_OPTIONS } from 'src/constants/application';
import { TAsset } from 'src/typings/configuration.types';
import { TAssetType } from 'src/typings/base-types';
import { TCommunitySettingsFields } from 'src/utils/assetsFields/assetsFields.types';
import { TSettingsSaveProps } from 'src/components/FormSettingsData/FormSettingsData.types';
import { TSharingCoefficientDataProps } from './SharingCoefficient.types';
import { _DeepNonNullableObject } from 'utility-types/dist/mapped-types';
import { fieldTemplates } from 'src/components/FormSettingsData/formFields';
import { getAssetValues } from 'src/utils/assetsFields/fieldTemplatesWithValues';
import s from './SharingCoefficient.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { useAppDispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';

export const SharingCoefficient: React.FC<TSharingCoefficientDataProps> = ({ onSubmit }) => {
  const dispatch = useAppDispatch();

  const communityAssetUUid = useSelector(selectCommunityAsset)?.uuid || '';
  const COEFFICIENT_ERROR_MESSAGE = `Coefficients from all areas should sum to 1.0.`;

  const assets = useSelector(selectAssets);
  const readOnly = useSelector(selectReadOnly);
  const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
  const assetsValues = useSelector(selectAssetsValues);
  const containerRef = useRef<HTMLFormElement>(null);
  const configurationCharacteristic = useSelector(selectConfigurationCharacteristic);
  const settingsData = useSelector(selectSettingsData) as _DeepNonNullableObject<
    Omit<SettingsDataFieldsFragment, '__typename'>
  >;
  const [coefficientValue, setCoefficientValue] = useState(
    settingsData.scmCoefficientAlgorithm || ScmCoefficientAlgorithm.Static,
  );

  const [allFields, setAllFields] = useState(
    fieldTemplates({
      values: {
        ...settingsData,
        name: configurationCharacteristic.name,
        description: configurationCharacteristic.description,
        timezone: configurationCharacteristic.timezone,
        locationVisible: configurationCharacteristic.locationVisible,
      },
      configurationCharacteristic,
    }),
  );
  const [isValidForm, setValidationStatus] = useState(true);
  const getAssets = useCallback(
    (parentAssetChildrenUuids) => {
      const output: Array<any> = [];

      const gatherTheChildren = (childrenUuids: TAsset['uuid'][]) => {
        if (childrenUuids && childrenUuids.length) {
          childrenUuids.forEach((uuid) => {
            const asset = assets[uuid];
            const values = assetsValues[uuid];
            if (asset.type == EAssetType.AREA)
              output.push({
                title: values.name || '',
                subtitle: '',
                type: asset.type as TAssetType,
                uuid: asset.uuid,
                icon: 'mkt-maker-3d' as const,
                key: asset.uuid,
                coefficientPercentage: values.coefficientPercentage,
              });
          });
        }
      };

      gatherTheChildren(parentAssetChildrenUuids);

      return output;
    },
    [assets, assetsValues],
  );

  const communityAssets = useMemo(() => {
    const parentAssetChildrenUuids = assetsTreeRelations[communityAssetUUid];

    return getAssets(parentAssetChildrenUuids);
  }, [assetsTreeRelations, getAssets, communityAssetUUid]);

  const checkValidation = useCallback(() => {
    function checkArray(array) {
      return array.every((element) => element.coefficientPercentage <= 1);
    }
    const sum = communityAssets.reduce((accumulator, object) => {
      return accumulator + object.coefficientPercentage;
    }, 0);
    let errorVal = checkArray(communityAssets) && sum == 1;
    setValidationStatus(errorVal);
  }, [communityAssets]);

  useEffect(() => {
    checkValidation();
  }, [checkValidation]);

  const onValueChange = (uuid, value) => {
    if (typeof value === 'number') {
      const values = assetsValues[uuid];
      let newValue = { ...values };
      newValue.coefficientPercentage = value;
      dispatch(
        updateAssetValuesForUuid([
          {
            uuid: uuid,
            values: newValue,
          },
        ]),
      );
    }
  };

  const containerProps = {
    showTooltip: false,
    tooltipText: '',
    key: 'sharingCoefficient',
  };

  const handleSelectChange = ({ value }) => {
    setCoefficientValue(value);
  };

  const handleSubmit = () => {
    const values = getAssetValues(allFields.filter((f) => true)) as TCommunitySettingsFields &
      TSettingsSaveProps['communityAssetSettings'];

    values.scmCoefficientAlgorithm = coefficientValue;
    values.useMarketMakerRate = false;
    const {
      name,
      description,
      locationVisible,
      timezone,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      startEndDate,
      gridFeeConstant,
      // marketMakerRate,
      // gridFeePercentage,
      useMarketMakerRate,
      ...newSettingsData
    } = values;

    onSubmit({
      name,
      description,
      locationVisible,
      timezone: timezone ?? '',
      settingsData: newSettingsData,
      communityAssetSettings: {
        gridFeeConstant: gridFeeConstant,
      },
    });
  };
  return (
    <div className={s.container}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (readOnly) return;
          handleSubmit();
        }}
        id={'sharingcoefficient'}
        ref={containerRef}>
        <div className={s.headingText}>
          Sharing coefficient mechanism{' '}
          <InfoHelper
            info={
              'The sharing coefficient value refers to the fraction of the total energy generated in the community that has been allocated to a home. The sum of all the coefficients in the community must equal to 1 '
            }
          />
        </div>

        <FieldContainer {...containerProps} className={s.selectField}>
          <BaseSelect
            onChange={(val) => handleSelectChange(val)}
            name={'scmCoefficientAlgorithm'}
            // label=""
            value={coefficientValue}
            options={SHARING_COEFFICIENT_OPTIONS}
            theme={'filled-gray'}
            showTooltip
            disabled={readOnly}
            tooltipText={''}
          />
          {!isValidForm && <BaseErrorMessage>{COEFFICIENT_ERROR_MESSAGE}</BaseErrorMessage>}
        </FieldContainer>
        <div className={s.headingText}>Set Coefficient</div>
        {communityAssets.length &&
          communityAssets.map((asset) => (
            <div key={asset.uuid}>
              <CoefficientAssetTile
                data={asset}
                onAssetValueChange={(uuid, value) => {
                  onValueChange(uuid, value);
                }}
                onAssetChoose={(data) => {
                  if (data) {
                    dispatch(setSelectedAssetUuid(data.uuid));
                  }
                }}
              />
            </div>
          ))}
        <div className={s.formButtonsWrapper}>
          <BaseButton
            type="submit"
            className={s.formButton}
            form={'sharingcoefficient'}
            disabled={!isValidForm || readOnly}>
            Save Changes
          </BaseButton>
        </div>
      </form>
    </div>
  );
};
