import { TAssetType, TLibrariesTags } from 'src/typings/base-types';

import { LIBRARY_FILTERS_MAPPING } from 'src/constants/application';
import { TAssetBlockProps } from 'src/components/AssetBlock';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';

export type TListView = 'grid' | 'list' | '3grid';

export type TFilterItem = {
  label?: string;
  icon?: TIconNames;
  value: TLibrariesTags[];
};

export type TAssetsListWithSearchProps = {
  assets: Array<
    Pick<TAssetBlockProps, 'icon' | 'subtitle' | 'title' | 'description' | 'isCustomPV'> & {
      key: string;
      type: TAssetType;
    }
  >;
  searchQuery: string;
  activeFilter: TFilterItem['value'];
  disabledFilters?: Array<TLibrariesTags[]>;
  onSearchQueryChange(newQuery: TAssetsListWithSearchProps['searchQuery']): void;
  onFilterChange(newFilter: TFilterItem): void;
  onAssetChoose?(asset: any): void;

  onThreeDotsClick?(payload: { target: HTMLElement; asset: unknown }): void;
  isAllButtonRequiredInFilter?: boolean;
  loading?: boolean;
  error?: string;
  showGuide?: boolean;
  noResultsMessage?: string;
  paddingClassName?: string;
  theme?: 'dark' | 'light';
  showEnergyStatus?: boolean;
  showAssetsCount?: boolean;
  listViewProp?: TListView;
};

export enum AssetsListActiveFilter {
  HOME = 'Home',
  PV = 'PV',
  LOAD = 'Load',
  STORAGE = 'Storage',
}
