import React from 'react';
import { TTableSCMBillRowProps } from 'src/components/_charts/TableSCMBills/TableSCMBills.types';
import classNames from 'classnames';
import s from './TableSCMBills.module.scss';

export const TableSCMBillRow: React.FC<TTableSCMBillRowProps> = ({ bill, onBillClick }) => {
  const roundToTwoDecimals = (num: number) => {
    return Math.round(num * 100) / 100;
  };

  return (
    <tr onClick={() => onBillClick(bill.uuid)}>
      <td>
        <span className={s.borderLeft}>{bill.assetName}</span>
      </td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.boughtFromGrid)}</td>
      <td className={s.redText}>{roundToTwoDecimals(bill.spentToGrid)}</td>
      <td className={s.blueText}>{roundToTwoDecimals(bill.gridFees)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.soldToGrid)}</td>
      <td className={s.greenText}>{roundToTwoDecimals(bill.earnedFromGrid)}</td>
      <td className={s.greyBackground}>{roundToTwoDecimals(bill.homeBalanceKWh)}</td>
      <td
        className={classNames(
          bill.homeBalance > 0 ? s.redText : s.greenText,
          s.textLeft,
          s.totalText,
        )}>
        <span>{bill.homeBalance > 0 ? 'Paid' : 'Revenue'}</span>
        {roundToTwoDecimals(Math.abs(bill.homeBalance))}
      </td>
    </tr>
  );
};
