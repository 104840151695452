import {
  TFieldsArray,
  TFieldsVisibility,
  TFormSettingsDataProps,
} from 'src/components/FormSettingsData';

import { $Keys } from 'utility-types';
import { TAsset } from 'src/typings/configuration.types';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';
import { TFormAssetsParamsProps } from 'src/components/FormAssetsParams';

export type TMapSidebarResultsProps = {
  className?: string;
  showResultsTabOnClick?: boolean;
  hideResultsTab?: any;
  style?: React.CSSProperties;
  showHeaderTabs?: boolean;
  onSettingsDataSave?: TFormSettingsDataProps['onSubmit'];
  onAddNewAsset?: any;
  onAddNewAssetUnderUuid?: any;
  onAssetValuesSave?: TFormAssetsParamsProps['onSubmit'];
  onAssetValuesSavePromise?: TFormAssetsParamsProps['onSubmitPromise'];
  onChangeSettingsTab?: TFormAssetsParamsProps['onChangeSettingsTab'];
  onAssetRemove?(payload: { assetUuid: TAsset['uuid'] }): void;
  onCommunityRemove?({
    projectUuid,
  }: {
    projectUuid: NonNullable<TConfigurationState['projectUuid']>;
  }): Promise<void>;
};

export enum ECollaborationTab {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Grid = 'Grid',
}

export enum ECommunityTab {
  GridMarket = 'GRID_MARKET',
  GridAgent = 'GRID_AGENT',
}

export enum EResultsTab {
  Home = 'Home',
  Community = 'Community',
}

export type TPresentationNames =
  | 'KeyResults'
  | 'KeyResultsSCM'
  | 'AssetCount'
  | 'SS-SC'
  | 'ChartEnergyTradeProfile'
  | 'ChartDayProfile'
  | 'ChartPrices'
  | 'TradesBidsAndOffers'
  | 'TableEnergyBillsAndNetTraded'
  | 'AssetHighlight'
  | 'ChartSavings'
  | 'ChartAsset'
  | 'ChartAssetAgent'
  | 'ChartDeviceProfile'
  | 'EnergyFlow';

export enum EActiveTabState {
  Community = 'community',
  Settings = 'settings',
  Registry = 'registry',
  CollaborationSettings = 'collaborationSettings',
  Profile = 'profile',
  GridMarketResults = 'gridmarketresults',
  GridMarketSettings = 'gridmarketsettings',
  Results = 'results',
}

export type TKeysCollaborationTab = keyof typeof ECollaborationTab;

export type TCollaborationFields = Record<
  TKeysCollaborationTab,
  Pick<TFormSettingsDataProps, 'visibleFieldsInfo' | 'visibleFieldsOrder'>
>;
