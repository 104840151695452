import React, { useCallback } from 'react';

import { BaseInput } from 'src/components/BaseInput';
import { LocationSearch } from 'src/components/LocationSearch';
import { TUserTileFormProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetsView/AssetsView.types';
import classNames from 'classnames';
import s from './AssetsView.module.scss';

export const UserTileForm: React.FC<TUserTileFormProps> = ({
  name,
  email,
  address,
  zipCode,
  setFormData,
  isNewUser,
  formErrorAreas,
}) => {
  const formOnchange = useCallback(
    ({ name, value }) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setFormData],
  );

  return (
    <>
      <div className={classNames(s.fieldContainer)}>
        <BaseInput
          type="text"
          theme="line-dark"
          name="name"
          label="Full Name"
          value={name}
          error={formErrorAreas?.name}
          onChange={formOnchange}
        />
      </div>
      <div className={classNames(s.fieldContainer)}>
        <BaseInput
          type="text"
          theme="line-dark"
          name="email"
          label="Email"
          value={email}
          error={formErrorAreas?.email}
          onChange={formOnchange}
          disabled={isNewUser}
        />
      </div>
      <div className={classNames(s.fieldContainer)}>
        <LocationSearch
          iconLeft={null}
          theme="line-dark"
          name="address"
          label="Address"
          error={formErrorAreas?.address || formErrorAreas?.zipCode}
          value={address}
          onChange={(data) => {
            formOnchange({ name: 'address', value: data.place_name });

            const firstContext = data.context[0];
            if (firstContext.id.includes('postcode')) {
              const { text: postcode } = firstContext;
              formOnchange({ name: 'zipCode', value: postcode || '' });
            }
          }}
          className={s.locationSearch}
          elevateLabel={true}
        />
      </div>
      {/*<div className={classNames(s.address, s.fieldContainer)}>
        <BaseInput
          type="text"
          theme="line-dark"
          name="zipCode"
          label="Postal Code"
          value={zipCode}
          onChange={formOnchange}
        />
  </div>*/}
    </>
  );
};
