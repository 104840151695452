import React, { useCallback, useEffect, useState } from 'react';

import { RichTextContent } from 'contentful';
import { fetchEntries } from 'src/services/api/contentful';
import { urlize } from 'urlize';

interface IOfficesEntry {
  title: string;
  address: string;
}

interface IPrinciplesEntry {
  title: string;
  description: string;
  order: number;
}

interface IApproachEntry {
  description: string;
}

interface IReadEntry {
  description: string;
}
export interface ICountriesEntry {
  countryCode: string;
  headquarter: string;
  name: string;
}
export interface ITeamMembersEntry {
  id: string;
  name: string;
  country: string;
  countryCode: string;
  avatar: string;
  imageBackdrop: string;
  bio: string;
  area: string;
  order?: number;
}
export interface ITrendingAndNewsEntry {
  title: string;
  banner?: string;
  startDate?: string;
  endDate?: string;
  link: string;
  isOnline?: boolean;
  locale?: string;
  highlighted?: boolean;
  firstPage?: boolean;
  createdAt?: string;
  section: string[];
  type: string;
  date?: string;
  author?: string;
  content?: RichTextContent;
  category?: string;
  description?: string;
  shortDescription?: string;
}
export interface IWatchEntry {
  title: string;
  videoLink: string;
  category: string;
}
interface IJoinUsEntry {
  title: string;
  jobType: string;
  locale: string;
  link: string;
}

interface IPolicyEntry {
  createdAt: string;
  type: string;
  text: string;
}

interface IScmFaqEntry {
  type: string;
  text: string;
  faqs: RichTextContent;
}

interface IScmFeatures {
  type: string;
  text: string;
  faqs: RichTextContent;
  title?: string;
  subContent?: string;
  subtitle?: string;
  listOfFeature?: any[];
}

export interface IEcoSystemEntry {
  id: number;
  createdAt: string;
  title: string;
  description: string;
  partners: any[];
  banner: string;
}
interface IContentfulContextValue {
  officesEntry: IOfficesEntry[];
  principlesEntry: IPrinciplesEntry[];
  joinUsEntry: IJoinUsEntry[];
  approachEntry: IApproachEntry[];
  teamMembersEntry: ITeamMembersEntry[];
  trendingEntry: ITrendingAndNewsEntry[];
  watchEntry: IWatchEntry[];
  newsEntry: ITrendingAndNewsEntry[];
  countriesEntry: ICountriesEntry[];
  readEntry: IReadEntry[];
  privacyPolicy: IPolicyEntry;
  ecoSystemEntry: IEcoSystemEntry[];
  scmFAQs: IScmFaqEntry;
  scmFeatures: IScmFeatures[];
}

export enum EEntriesContentful {
  OFFICES = 'offices',
  OUR_PRINCIPLES = 'ourPrincipleDemocratise',
  OUR_APPROACH = 'ourApproach',
  OUR_TEAM_MEMBERS = 'ourTeamMembers',
  JOIN_US = 'joinUs',
  TRENDING = 'tranding',
  WATCH = 'watch',
  NEWS = 'news',
  COUNTRIES = 'countries',
  READ = 'read',
  PRIVACY_POLICY = 'privacyPolicy',
  ECO_SYSTEM = 'ecoSystem',
  SCM_FAQS = 'scmFaQs',
  SCM_FEATURES = 'scmLanding',
}

const ContentfulContext = React.createContext<IContentfulContextValue>({
  officesEntry: [],
  principlesEntry: [],
  joinUsEntry: [],
  approachEntry: [],
  teamMembersEntry: [],
  trendingEntry: [],
  watchEntry: [],
  newsEntry: [],
  countriesEntry: [],
  readEntry: [],
  privacyPolicy: {} as IPolicyEntry,
  ecoSystemEntry: [],
  scmFAQs: {} as IScmFaqEntry,
  scmFeatures: [],
});

export const ContentfulContextProvider: React.FC = ({ children }) => {
  const [officesEntry, setOfficesEntry] = useState<IOfficesEntry[]>([]);
  const [principlesEntry, setPrinciplesEntry] = useState<IPrinciplesEntry[]>([]);
  const [approachEntry, setApproachEntry] = useState<IApproachEntry[]>([]);
  const [joinUsEntry, setJoinUsEntry] = useState<IJoinUsEntry[]>([]);
  const [teamMembersEntry, setTeamMembersEntry] = useState<ITeamMembersEntry[]>([]);
  const [trendingEntry, setTrendingEntry] = useState<ITrendingAndNewsEntry[]>([]);
  const [watchEntry, setWatchEntry] = useState<IWatchEntry[]>([]);
  const [newsEntry, setNewsEntry] = useState<ITrendingAndNewsEntry[]>([]);
  const [countriesEntry, setCountriesEntry] = useState<ICountriesEntry[]>([]);
  const [readEntry, setReadEntry] = useState<IReadEntry[]>([]);
  const [privacyPolicy, setPrivacyPolicy] = useState({} as IPolicyEntry);
  const [ecoSystemEntry, setEcoSystemEntry] = useState<IEcoSystemEntry[]>([]);
  const [scmFAQs, setScmFAQs] = useState({} as IScmFaqEntry);
  const [scmFeatures, setScmFeatures] = useState<IScmFeatures[]>([]);

  const triggerfetchEntries = useCallback(async () => {
    try {
      const response = await fetchEntries();

      const offices = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.OFFICES)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, []);

      const principles = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.OUR_PRINCIPLES)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, [])
        .sort((a, b) => a?.order - b?.order);

      const approach = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.OUR_APPROACH)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, []);

      const read = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.READ)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, []);

      const joinUs = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.JOIN_US)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, []);

      const teamMembers = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.OUR_TEAM_MEMBERS)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, [])
        ?.map((e) => ({
          ...e,
          id: urlize(e?.name),
          imageBackdrop: e?.imageBackdrop?.fields?.file?.url,
          avatar: e?.avatar?.fields?.file?.url,
          countryCode: e?.countryCode?.fields?.countryCode,
        }));

      const trending = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.TRENDING)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt };
          acc.push(field);
          return acc;
        }, [])
        ?.map((e) => ({
          ...e,
          banner: e?.banner?.fields?.file?.url,
          type: 'trending',
        }));

      const watch = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.WATCH)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt };
          acc.push(field);
          return acc;
        }, []);

      const countries = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.COUNTRIES)
        .reduce((acc: any, value) => {
          acc.push(value.fields);
          return acc;
        }, []);

      const news = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.NEWS)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt, type: 'news' };
          acc.push(field);
          return acc;
        }, []);

      const policy = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.PRIVACY_POLICY)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt, type: 'policy' };
          acc.push(field);
          return acc;
        }, []);

      const ecoSystem = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.ECO_SYSTEM)
        .sort((a: any, b: any) => a?.id - b?.id)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt, type: 'ecoSystem' };
          acc.push(field);
          return acc;
        }, [])
        .sort((a: any, b: any) => a?.id - b?.id)
        ?.map((e) => ({
          ...e,
          introImage: e?.introImage?.fields?.file?.url,
        }));

      const scmFAQ = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.SCM_FAQS)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt, type: 'faq' };
          acc.push(field);
          return acc;
        }, []);

      const scmFeatures = response?.items
        ?.filter((e) => e.sys.contentType.sys.id === EEntriesContentful.SCM_FEATURES)
        .reduce((acc: any, value: any) => {
          const field = { ...value.fields, createdAt: value.sys.createdAt, type: 'scmfeatures' };
          acc.push(field);
          return acc;
        }, [])
        .sort((a, b) => a?.order - b?.order);

      setOfficesEntry(offices);
      setPrinciplesEntry(principles);
      setApproachEntry(approach);
      setJoinUsEntry(joinUs);
      setTeamMembersEntry(teamMembers);
      setTrendingEntry(trending);
      setWatchEntry(watch);
      setNewsEntry(news);
      setReadEntry(read);
      setCountriesEntry(countries);
      setPrivacyPolicy(policy?.length ? policy[0] : {});
      setEcoSystemEntry(ecoSystem);
      setScmFAQs(scmFAQ?.length ? scmFAQ[0] : {});
      setScmFeatures(scmFeatures);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    triggerfetchEntries();
  }, [triggerfetchEntries]);

  return (
    <ContentfulContext.Provider
      value={{
        officesEntry,
        countriesEntry,
        principlesEntry,
        joinUsEntry,
        approachEntry,
        teamMembersEntry,
        trendingEntry,
        watchEntry,
        newsEntry,
        readEntry,
        privacyPolicy,
        ecoSystemEntry,
        scmFAQs,
        scmFeatures,
      }}>
      {children}
    </ContentfulContext.Provider>
  );
};

export default ContentfulContext;
