import React, { useMemo } from 'react';

import { BaseErrorMessage } from 'src/components/BaseErrorMessage';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { InfoHelper } from 'src/components/InfoHelper';
import { TBaseSwitchProps } from './BaseSwitch.types';
import classnames from 'classnames';
import s from './BaseSwitch.module.scss';

export const BaseSwitch = function BaseSwitch({
  options,
  name,
  value,
  onChange,
  className,
  variant = 'horizontal-small',
  theme,
  disabled,
  label,
  error,
  showTooltip = false,
  tooltipText = '',
}: TBaseSwitchProps) {
  const values = options.map((p) => p.value);
  const activeIndex = options.findIndex((p) => p.value === value);
  const containerClassNames = useMemo(() => {
    return classnames(s.container, {
      [s['size-small']]: variant.includes('small'),
      [s['size-big']]: variant?.includes('big'),
      [s['size-edge']]: variant?.includes('edge'),
      [s['origin-horizontal']]: variant.includes('horizontal'),
      [s['origin-vertical']]: variant?.includes('vertical'),
      [s['theme-green']]: !theme || theme.includes('green'),
      [s['theme-softred']]: !theme || theme.includes('softred'),
      [s['theme-gray']]: !theme || theme.includes('gray'),
      [s['theme-gray-custom']]: theme?.includes('gray-custom'),
      [s['theme-dark']]: theme?.includes('dark'),
      [s['theme-light']]: theme?.includes('light'),
      [s['theme-contrast']]: theme?.includes('contrast'),
      [s['theme-gradient-dark']]: theme?.includes('gradient-dark'),
      [s.disabled]: disabled,
    });
  }, [variant, theme, disabled]);

  return (
    <div className={classnames(s.root, className)}>
      {label && (
        <label className={s.label}>
          {label}
          {showTooltip && tooltipText && (
            <InfoHelper className={s.lengthTooltip} info={tooltipText} />
          )}
        </label>
      )}

      <div className={containerClassNames}>
        <div className={classnames(s['items-wrapper'])}>
          <div className={classnames(s.items)}>
            {options.map((item, index) => (
              <BaseTooltipTrigger
                key={index}
                position="bottom"
                tooltipChildren={item.info}
                triggerClassName={classnames(s.item)}>
                <button
                  type="button"
                  key={index}
                  onClick={() => {
                    if (disabled) return;
                    onChange({ name, value: values.find((v) => v !== value) });
                  }}>
                  {item.text ? (
                    <span className={s.text}>{item.text}</span>
                  ) : (
                    <BaseIcon icon={item.icon} size={item.iconSize} />
                  )}
                </button>
              </BaseTooltipTrigger>
            ))}
          </div>
          <div className={classnames(s['active-item'])} data-active-index={activeIndex}>
            {options.map((item, index) => (
              <button key={index} type="button" className={classnames(s.item)}>
                {item.text ? (
                  <span className={s.text}>{item.text}</span>
                ) : (
                  <BaseIcon icon={item.icon} size={item.iconSize} />
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      <BaseErrorMessage className={s.error}>{error}</BaseErrorMessage>
    </div>
  );
};
