import React, { useEffect, useState } from 'react';

import { AssetCreateNewTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNewTabs';
import { AssetSettings } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetSettings';
import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { TAssetEditProps } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetEdit.types';
import { TTabsInfo } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNew.types';
import { get3dAssetIcon } from 'src/utils/assetIcons';
import s from './AssetCreateNew.module.scss';
import { useAssetCreateTabs } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/useAssetCreateTabs';

export const AssetEdit: React.FC<TAssetEditProps> = ({
  data,
  setMode,
  onUpdate,
  onAssetValuesSave,
}) => {
  const { onTabSelect, activeSettingsTab, setActiveSettingsTab } = useAssetCreateTabs({ data });

  return (
    <div className={s.createAsset}>
      <header className={s.header}>
        <p>Edit Asset</p>
        <BaseButton size="mini" theme="grey3" onClick={() => setMode('assetPreview')}>
          Cancel
        </BaseButton>
      </header>
      <div className={s.hero}>
        <div className={s.icon}>
          <BaseIcon icon={get3dAssetIcon(data.type)} />
        </div>
        <h5>{data.title}</h5>
        <p>{data.subtitle}</p>
      </div>
      <nav className={s.navigation}>
        <AssetCreateNewTabs
          onTabSelect={onTabSelect}
          activeSettingsTab={activeSettingsTab}
          mode={data.type}
        />
      </nav>
      <AssetSettings
        mainParentData={data}
        selectedChildUuid={data.uuid}
        newAssetData={data}
        mode={activeSettingsTab}
        setMode={setMode}
        onUpdate={onUpdate}
        onEditAsset={onAssetValuesSave}
        onSaveAction="edit"
      />
    </div>
  );
};
