import { InfoArea } from 'src/components/HomeEnergyInfo/InfoArea';
import React from 'react';
import { THomeEnergyInfoProps } from 'src/components/HomeEnergyInfo/HomeEnergyInfo.types';
import s from './HomeEnergyInfo.module.scss';

export const HomeEnergyInfo: React.FC<THomeEnergyInfoProps> = ({
  generatedValue,
  consumedValue,
}) => {
  return (
    <div className={s.mainWrapper}>
      <InfoArea icon={'solar-3d'} value={generatedValue} label="Energy Generated" />
      <InfoArea icon={'plug-3d'} value={consumedValue} label="Energy Consumed" />
    </div>
  );
};
