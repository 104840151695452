import { selectTokenAuth, selectUserRole } from 'src/redux/auth/auth.selectors';
import { useCallback, useMemo } from 'react';

import { EUserRoles } from 'src/typings/base-types';
import { TAsset } from 'src/typings/configuration.types';
import { TSCMHome } from 'src/redux/scm/scm.slice';
import { selectCommunityAsset } from 'src/redux/configuration/configuration.selectors';
import { selectSCMHomeDetails } from 'src/redux/scm/scm.selectors';
import { useSelector } from 'react-redux';

export type TUseCurrentUserItemForSCMResponse = {
  currentItem: TSCMHome | TAsset | undefined;
};

export function useCurrentUserItemForSCM(): TUseCurrentUserItemForSCMResponse {
  const scmMembers = useSelector(selectSCMHomeDetails);
  const userRole = useSelector(selectUserRole);
  const userInfo = useSelector(selectTokenAuth);
  const communityAsset = useSelector(selectCommunityAsset);

  const currentItem = useMemo(() => {
    if (userRole === EUserRoles.Admin) return communityAsset;

    return scmMembers.find((item) => item.email === userInfo?.email);
  }, [userRole, scmMembers, userInfo, communityAsset]);

  return {
    currentItem,
  };
}
