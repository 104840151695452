// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { APP_FLOW, useAppFlow } from 'src/hooks/useAppFlow';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import {
  engagementData,
  engagementData2,
  listOfTradingEmails,
} from 'src/components/NetworkGraph/NetworkGraph.config';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BaseLink } from 'src/components/BaseLink';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { ContentfulContextProvider } from 'src/contexts/ContentfulContext';
import { EPredefinedModalIds } from 'src/constants/modals';
import Fade from 'react-reveal/Fade';
import { Header } from 'src/components/Header';
import { NetworkGraph } from 'src/components/NetworkGraph';
// import required swiper modules
import { Pagination } from 'swiper';
import Zoom from 'react-reveal/Zoom';
import classnames from 'classnames';
import { closeModal } from 'src/redux/modals/modals.slice';
import feature1 from 'src/assets/images/Scm/feature1.png';
import feature2 from 'src/assets/images/Scm/feature2.png';
import feature3 from 'src/assets/images/Scm/feature3.png';
import feature4 from 'src/assets/images/Scm/feature4.png';
import featureImg1 from 'src/assets/images/Scm/icon-analytics.svg';
import featureImg2 from 'src/assets/images/Scm/icon-energy.svg';
import featureImg3 from 'src/assets/images/Scm/icon-collective.svg';
import featureImg4 from 'src/assets/images/Scm/icon-billing.svg';
import isEmpty from 'lodash/isEmpty';
import s from './Scm.module.scss';
import { setSCMFlow } from 'src/redux/scm/scm.slice';
import { useAppDispatch } from 'src/redux/store';
import { useHistory } from 'react-router';

const VIDEO_URL = 'https://phoenix-dev-assets.s3.eu-central-1.amazonaws.com/low-bitrate.mp4';

export const Scm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { updateAppFlow, resetSCMSteps } = useAppFlow();
  const headerColorMode = 'white';
  const history = useHistory();
  const vidRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const featureSection1 = useRef<HTMLDivElement>(null);
  const featureSection2 = useRef<HTMLDivElement>(null);
  const featureSection3 = useRef<HTMLDivElement>(null);
  const featureSection4 = useRef<HTMLDivElement>(null);
  const [isShowingArrow, setShowingArrow] = useState(true);
  let sections = {
    section0: featureSection1,
    section1: featureSection2,
    section2: featureSection3,
    section3: featureSection4,
  };
  let sectionIcons = {
    section0: featureImg1,
    section1: featureImg2,
    section2: featureImg3,
    section3: featureImg4,
  };

  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isMuted: false,
  });

  const handlePlayVideo = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  const [y, setY] = useState(0);

  const handleNavigation = useCallback(
    (e) => {
      let scrollVal = e.target.scrollTop;
      if (y < scrollVal) {
        setShowingArrow(false);
      } else if (scrollVal == 0) {
        setShowingArrow(true);
      }
      setY(scrollVal);
    },
    [y],
  );

  useEffect(() => {
    resetSCMSteps();
    updateAppFlow(APP_FLOW.SCM);
    dispatch(setSCMFlow(true));
  }, [dispatch, updateAppFlow, resetSCMSteps]);

  useEffect(() => {
    if (vidRef && vidRef.current) {
      playerState.isPlaying ? vidRef.current.play() : vidRef.current.pause();
    }
  }, [playerState.isPlaying, vidRef]);

  useEffect(() => {
    dispatch(closeModal(EPredefinedModalIds.MODAL_AUTH_LOGIN));
  }, [dispatch]);

  const executeScroll = (sectionName) => {
    let getSections = sections[sectionName];
    if (getSections && getSections.current)
      getSections?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };
  const RenderFeatureContent = () => {
    const { scmFeatures } = useContext(ContentfulContext);
    return (
      <>
        {scmFeatures &&
          scmFeatures.length > 0 &&
          scmFeatures?.map((item, key) => (
            <div key={key.toString()} className={s.col4}>
              <div className={s.features}>
                <img src={sectionIcons['section' + key]} />

                <h4>{item.title}</h4>
                <p>{item.subContent}</p>
                <a onClick={() => executeScroll('section' + key)}>More</a>
              </div>
            </div>
          ))}
      </>
    );
  };

  const RenderFeatureItem = (props) => {
    const { scmFeatures } = useContext(ContentfulContext);

    let contentForFeature = scmFeatures[props.itemNumber];
    let listOfFeature = isEmpty(contentForFeature) ? [] : contentForFeature.listOfFeature;
    if (isEmpty(contentForFeature)) {
      return <></>;
    } else {
      return (
        <div className={s.featureItem}>
          <img src={featureImg1} />
          <h2>{contentForFeature?.subtitle} </h2>
          <ul>
            {listOfFeature &&
              listOfFeature.length > 0 &&
              listOfFeature.map((item, index) => (
                <li key={index} className={s.customList}>
                  <div className={s.customCheck}>
                    <BaseIcon icon="check-mark" size={20} className={s.arrow} />
                  </div>
                  <div>
                    <p>{item}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className={s.scmlandingpage}>
      <ContentfulContextProvider>
        <Header isLandingPage headerColorMode={headerColorMode} />
        <div className={s.content} ref={containerRef} onScroll={handleNavigation}>
          <div className={s.container}>
            <div className={s.bannerwrapper}>
              <div className={s.section}>
                <div className={s.col2}>
                  <div className={s.banner}>
                    <h4 className={s.subTitle}>Social Community Manager</h4>
                    <h1 className={s.title}>
                      Management tools for <span>social energy communities</span>
                    </h1>
                    <BaseLink
                      theme="4"
                      to="mailto:contact@gridsingularity.com?subject=Requesting a Demo from SCM&body=Hi, %0D%0A %0D%0A I would like to get a demo of the social community manager.%0D%0A Please let me know what times are available this week.%0D%0A %0D%0A Best regards,%0D%0A [insert name]">
                      <BaseButton className={s.primary} theme="tertiary">
                        Request a Demo
                      </BaseButton>
                    </BaseLink>
                  </div>
                </div>
                <div className={classnames(s.col2, s.colHideXS)}>
                  <NetworkGraph />
                </div>
                <div className={s.bottomToTopFade}>
                  <Fade top>
                    <BaseButton
                      theme="tertiary"
                      className={`${s.scrollBtn} ${!isShowingArrow ? s.scrollBtnDisabled : {}}`}>
                      <BaseIcon className={s.buttonScroll} icon="arrow-down" size={24} />
                    </BaseButton>
                  </Fade>
                </div>
              </div>
            </div>

            <div className={classnames(s.section)}>
              <RenderFeatureContent />
            </div>

            <div className={s.section} ref={featureSection1}>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="0" />
              </div>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature1} />
                </div>
              </div>
            </div>

            <div className={classnames(s.section, s.sectionReverse)} ref={featureSection2}>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <h3>Preferred trading partners</h3>
                  <div className={s.tradingSection}>
                    <div className={s.tradingSection_emails}>
                      <div className={s.listOfUsers}>
                        {listOfTradingEmails.map((item, index) => (
                          <div key={index} className={s.listOfUsers_content}>
                            <div className={s.listOfUsers_Icon}>
                              <img src={item.image} />
                            </div>
                            <div className={s.listOfUsers_Info}>
                              <h4>{item.name}</h4>
                              <p>{item.email}</p>
                            </div>
                            <div className={s.listOfUsers_utility}>
                              <button type="button">
                                <BaseIcon icon="check-mark" size={11} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className={s.tradingSection_home}>
                        <img src={feature2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="1" />
              </div>
            </div>

            <div className={s.scmvideo} onClick={handlePlayVideo}>
              {/* <video muted playsInline ref={vidRef}>
              <source src={VIDEO_URL} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
              <iframe
                width="100%"
                height="580"
                src="https://www.youtube.com/embed/k96WdZPEoqU?rel=0&controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>

              {/* {playerState.isPlaying ? <></> : <div className={s.play}></div>} */}
            </div>

            <div className={s.section} ref={featureSection3}>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="2" />
              </div>
              <div className={s.col2}>
                {/* <div className={s.slideContainer}>
                <Swiper
                  slidesPerView={1}
                  modules={[Pagination]}
                  pagination={{
                    clickable: true,
                    clickableClass: s.bulletClickable,
                    type: 'bullets',
                    bulletElement: 'span',
                    bulletClass: s.timelineicon,
                    bulletActiveClass: s.timelineiconactive,
                    renderBullet: function (index, className) {
                      return '<span class="' + className + '">' + ' ' + '</span>';
                    },
                  }}
                  className={s.featureswiper}>
                  <SwiperSlide className={s.sliderItem}>
                    <div className={s.slideItem}>
                      {engagementData.map((item, index) => (
                        <div className={s.slideContent} key={index}>
                          <div className={s.slideIcon}>
                            <img src={item.image} />
                          </div>
                          <div className={s.slideInfo}>
                            <b>{item.name}</b>
                            <p>{item.email}</p>
                          </div>
                          <div className={s.slideUtility}>
                            <BaseIcon icon="more-horizontal" size={15} />{' '}
                            <div className={s.homeInfo}>
                              <div className={s.assetName}>{item.asset}</div>
                              <button type="button">
                                <BaseIcon icon="check-mark" size={11} />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={s.sliderItem}>
                    <div className={s.slideItem}>
                      {engagementData2.map((item, index) => (
                        <div className={s.slideContent} key={index}>
                          <div className={s.slideIcon}>
                            <img src={item.image} />
                          </div>
                          <div className={s.slideInfo}>
                            <b>{item.name}</b>
                            <p>{item.email}</p>
                          </div>
                          <div className={s.slideUtility}>
                            <BaseIcon icon="more-horizontal" size={15} />{' '}
                            <div className={s.homeInfo}>
                              <div className={s.assetName}>{item.asset}</div>
                              <button type="button">
                                <BaseIcon icon="check-mark" size={11} />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className={s.sliderItem}>
                    <div className={s.slideItem}>
                      {engagementData.map((item, index) => (
                        <div className={s.slideContent} key={index}>
                          <div className={s.slideIcon}>
                            <img src={item.image} />
                          </div>
                          <div className={s.slideInfo}>
                            <b>{item.name}</b>
                            <p>{item.email}</p>
                          </div>
                          <div className={s.slideUtility}>
                            <BaseIcon icon="more-horizontal" size={15} />{' '}
                            <div className={s.homeInfo}>
                              <div className={s.assetName}>{item.asset}</div>
                              <button type="button">
                                <BaseIcon icon="check-mark" size={11} />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div> */}

                <div className={s.featureImage}>
                  <img src={feature4} />
                </div>
              </div>
            </div>

            <div className={classnames(s.section, s.sectionReverse)} ref={featureSection4}>
              <div className={s.col2}>
                <div className={s.featureImage}>
                  <img src={feature3} />
                </div>
              </div>
              <div className={s.col2}>
                <RenderFeatureItem itemNumber="3" />
              </div>
            </div>
            {/* TODO : Hiding this section since content is not yet ready         */}
            {/* <div className={s.section}>
            <div className={s.register}>
              <BaseButton className={s.primary} theme="tertiary">
                Read here how to register and operate energy communities in your region
              </BaseButton>
            </div>
          </div> */}
          </div>
        </div>
      </ContentfulContextProvider>
    </div>
  );
};
