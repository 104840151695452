import { useEffect, useState } from 'react';

import { TTabsInfo } from 'src/components/MapSidebarSCM/components/MapSidebarCanary/components/AssetCreateNew/AssetCreateNew.types';

type TUseAssetCreateTabsResponse = {
  onTabSelect: (tab: TTabsInfo) => void;
  activeSettingsTab: TTabsInfo;
  setActiveSettingsTab: React.Dispatch<React.SetStateAction<TTabsInfo>>;
};
type TUseAssetCreateTabsArgs = { data: any };

export const useAssetCreateTabs = ({
  data,
}: TUseAssetCreateTabsArgs): TUseAssetCreateTabsResponse => {
  const [activeSettingsTab, setActiveSettingsTab] = useState<TTabsInfo>('basic');
  const onTabSelect = (tab: typeof activeSettingsTab) => setActiveSettingsTab(tab);

  useEffect(() => {
    const defaultAdvancedTabs = ['Storage', 'Load'];
    if (data && defaultAdvancedTabs.includes(data?.type)) {
      setActiveSettingsTab('advanced');
      return;
    }
    setActiveSettingsTab('basic');
  }, [data]);

  return {
    onTabSelect,
    activeSettingsTab,
    setActiveSettingsTab,
  };
};
