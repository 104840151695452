import { ChartEnergyProfile, TChartProfileInfo } from 'src/components/ChartEnergyProfile';
import { Line, LineChart } from 'recharts';
import React, { useEffect, useMemo, useState } from 'react';
import {
  TResolutionButtonState,
  TSCMSimulationGraphData,
} from 'src/components/SimulationResultsPresentation/CustomResultPresentation/SCMMyHome';

import { BaseSelect } from 'src/components/BaseSelect';
import { ChartBrushWrapper } from 'src/components/ChartBrushWrapper';
import { ChartEnergyProfileBrush } from 'src/components/ChartEnergyProfile/ChartEnergyProfileBrush';
import { DataAggregationResolution } from 'src/graphql';
import { EnergyBadge } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadge';
import { EnergyBageDot } from 'src/components/EnergyFlow/components/EnergyFlowContent/components/EnergyBadgeDot';
import { InfoHelper } from 'src/components/InfoHelper';
import { TEnergyProfileComponentProps } from 'src/components/EnergyProfileComponent/EnergyProfileComponent.types';
import { dumpdata } from 'src/components/EnergyProfileComponent/dumpdata';
import { pickKpiData } from 'src/utils/pickKpiData';
import s from './EnergyProfileComponent.module.scss';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { setCommunityKpi } from 'src/redux/scm/scm.slice';
import { useAccumulatedResults } from 'src/hooks/useAccumulatedResults';
import { useAppDispatch } from 'src/redux/store';
import { useGraphBrush } from 'src/hooks/useGraphBrush';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const EnergyProfileComponent: React.FC<TEnergyProfileComponentProps> = ({
  title,
  info,
  mode,
  field,
  initialData = dumpdata,
}) => {
  const {
    queries: { getAccumulatedResultsDaily, getAccumulatedResultsMonthly },
    resultsDataDaily,
    resultsDataMonthly,
  } = useAccumulatedResults({
    initialRunQueries: [],
    mode: field,
  });

  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const [localMode, setLocalMode] = useState<TResolutionButtonState>(mode);
  // create localCommunityKpi state
  const [localCommunityKpi, setLocalCommunityKpi] = useState<TSCMSimulationGraphData>(initialData);

  const { data, graphIndexes, setDebouncedGraphIndexes } = useGraphBrush<TChartProfileInfo>({
    initialData: localCommunityKpi,
  });
  const [graphKey, setGraphKey] = useState<string>(v4());

  useEffect(() => {
    if (localMode === DataAggregationResolution.Daily) {
      getAccumulatedResultsDaily();
    } else {
      getAccumulatedResultsMonthly();
    }
  }, [localMode, getAccumulatedResultsDaily, getAccumulatedResultsMonthly]);

  //create a useEffect to dispatch the setCommunityKpiDifference
  useEffect(() => {
    const data =
      localMode === DataAggregationResolution.Daily ? resultsDataDaily : resultsDataMonthly;
    if (data?.kpi) {
      setLocalCommunityKpi(pickKpiData(JSON.parse(data.kpi)));
    }
  }, [resultsDataDaily, resultsDataMonthly, localMode]);

  useEffect(() => setGraphKey(v4()), [sidebarExpanded, initialData, localMode, localCommunityKpi]);

  // set localMode if mode changes
  useEffect(() => {
    setLocalMode(mode);
  }, [mode]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3>{title}</h3>
        <InfoHelper info={info} />
      </div>
      <div className={s.container}>
        <div style={{ height: 300 }}>
          <ChartEnergyProfile data={data} mode={mode} />
        </div>
        <div className={s.brushArea}>
          <div className={s.selectWrapper}>
            <BaseSelect
              name="selectedDay"
              value={localMode}
              options={[
                {
                  label: 'Daily',
                  value: DataAggregationResolution.Daily,
                },
                {
                  label: 'Monthly',
                  value: DataAggregationResolution.Monthly,
                },
              ]}
              onChange={({ value }) => {
                setLocalMode(value as TResolutionButtonState);
              }}
              theme="filled-gray"
              className={s.select}
            />
          </div>
          <div className={s.brushWrapper}>
            <ChartEnergyProfileBrush
              key={`${graphKey}-brush`}
              data={localCommunityKpi}
              setGraphIndexes={(val) => {
                setDebouncedGraphIndexes(val);
              }}
              startIndex={graphIndexes.startIndex}
              endIndex={graphIndexes.endIndex}
            />
          </div>
        </div>
        <div className={s.badgeRow}>
          <EnergyBadge
            title="Generation"
            size="M"
            icon={<EnergyBageDot backgroundClassname={s.bgGreen} />}
          />
          <EnergyBadge
            title="Consumption"
            size="M"
            icon={<EnergyBageDot backgroundClassname={s.bgRed} />}
          />
        </div>
      </div>
    </div>
  );
};
