import { EChartTooltips, getChartTooltip } from 'src/constants/chartTooltips';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BaseButton } from 'src/components/BaseButton';
import { BaseIcon } from 'src/components/BaseIcon';
import { BasePills } from 'src/components/BasePills';
import { ChartWrapper } from 'src/components/ChartWrapper';
import { CustomScrollbar } from 'src/components/CustomScrollbar';
import { TTableEnergyBillsAndNetTradedProps } from './TableEnergyBillsAndNetTraded.types';
import classnames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './TableEnergyBillsAndNetTraded.module.scss';
import { selectSettingsData } from 'src/redux/configuration/configuration.selectors';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import { useSelector } from 'react-redux';

const scoreboardTypes = ['Bought', 'Sold', 'Total'];

export const TableEnergyBillsAndNetTraded: React.FC<TTableEnergyBillsAndNetTradedProps> = ({
  isCommunity,
  priceEnergyAreaBalance,
}) => {
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(2);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const calculateData = () => {
    const result: {
      scrollBar: any[];
      gridMarketOrCommunity: any;
      fees: any;
      total: any;
      showFeeColumn: boolean;
      showScroll: boolean;
    } = {
      scrollBar: [],
      gridMarketOrCommunity: null,
      fees: null,
      total: null,
      showFeeColumn: false,
      showScroll: false,
    };
    if (!priceEnergyAreaBalance) return result;

    Object.keys(priceEnergyAreaBalance).forEach((key) => {
      const value = priceEnergyAreaBalance[key];
      if (value.market_fee !== 0) {
        result.showFeeColumn = true;
      }
      if (key === 'Accumulated Trades') return;
      else if (key === 'External Trades') {
        if (isCommunity) {
          result.gridMarketOrCommunity = { ...value, title: 'Grid Market' };
        } else {
          result.gridMarketOrCommunity = { ...value, title: 'Community' };
        }
      } else if (key === 'Market Fees') {
        const allZero = Object.values(value).every((x) => x === 0);
        if (!allZero) {
          if (sidebarExpanded) {
            result.fees = { ...value, title: 'Market Grid Fees' };
          } else {
            result.fees = { ...value, title: 'Grid Fees' };
          }
        }
      } else if (key === 'Totals') {
        result.total = {
          ...value,
          title: ['Total Bought', 'Total Sold', 'Total Balance', 'Totals'],
        };
      } else {
        result.scrollBar.push({ ...value, title: key });
      }
    });

    if (result.scrollBar.length > 3) {
      result.showScroll = true;
    }
    return result;
  };
  const data = calculateData();

  const tableRef = useRef(0);
  const scrollRef = useRef<null | HTMLElement>(null);

  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  const { currency } = useSelector(selectSettingsData);
  const currencySymbol = formatter.getCurrencySymbol(currency);

  const filtersComponent = (
    <BasePills
      className={s.chartDayPills}
      name="trade"
      theme="dark-gray"
      size="large"
      value={selectedTypeIndex}
      items={scoreboardTypes}
      onChange={({ value }) => setSelectedTypeIndex(value)}
    />
  );

  const ExpandRow: React.FC<{ value }> = ({ value }) => {
    if (!value) return null;
    return (
      <>
        <span className={classnames(s.firstColumn, s.title)}>
          {value?.title && (typeof value.title === 'string' ? value.title : value.title[3])}
        </span>
        <span className={s.energy}>{formatter.toOptionalFixed(value.bought, 2)}</span>
        <span className={classnames(s.negetive, s.colored, { [s.neutral]: value.spent === 0 })}>
          {formatter.toOptionalFixed(value.spent, 2)}
        </span>
        {data.showFeeColumn && (
          <span
            className={classnames(s.negetive, s.colored, { [s.neutral]: value.market_fee === 0 })}>
            {formatter.toOptionalFixed(value.market_fee, 2)}
          </span>
        )}
        <span className={s.energy}>{formatter.toOptionalFixed(value.sold, 2)}</span>
        <span className={classnames(s.positive, s.colored, { [s.neutral]: value.earned === 0 })}>
          {formatter.toOptionalFixed(value.earned, 2)}
        </span>
        <span
          className={classnames(s.energy, s.colored, {
            [s.positive]: value.total_energy < 0,
            [s.neutral]: value.total_energy === 0,
            [s.negetive]: value.total_energy > 0,
          })}>
          {formatter.toOptionalFixed(Math.abs(value.total_energy), 2)}
        </span>
        <span
          className={classnames(s.firstColumn, {
            [s.positive]: value.total_cost < 0,
            [s.neutral]: value.total_cost === 0,
            [s.negetive]: value.total_cost > 0,
          })}>
          {totalCostValue(value.total_cost)}
        </span>
      </>
    );
  };
  const ExpandedSidebarView: React.FC = () => {
    return (
      <div className={s.BigTable}>
        <div
          className={classnames(s.topHeader, {
            [s.withFee]: data.showFeeColumn,
            [s.withoutFee]: !data.showFeeColumn,
          })}>
          <span></span>
          <span>Bought</span>
          <span>Sold</span>
          <span>Total Balance</span>
        </div>
        <div
          className={classnames(s.mainHeader, {
            [s.withFee]: data.showFeeColumn,
            [s.withoutFee]: !data.showFeeColumn,
          })}>
          <span className={classnames(s.firstColumn, s.title, s.bold)}>Asset</span>
          <span className={s.energy}>Energy (kWh)</span>
          <span>Paid ({currencySymbol})</span>
          <span className={s.energy}>Energy (kWh)</span>
          <span>Revenue ({currencySymbol})</span>
          <span className={s.energy}>Energy (kWh)</span>
          <span>Total ({currencySymbol})</span>
        </div>
        {data.showFeeColumn && (
          <div className={s.subHeader}>
            <span></span>
            <span>Trade Cost ({currencySymbol})</span>
            <span>Grid Fees ({currencySymbol})</span>
            <span></span>
          </div>
        )}
        <div
          className={classnames(s.body, {
            [s.withFee]: data.showFeeColumn,
            [s.withoutFee]: !data.showFeeColumn,
          })}>
          {data.scrollBar.map((item, index) => (
            <ExpandRow value={item} key={index} />
          ))}
          <ExpandRow value={data.gridMarketOrCommunity} />
          {data.fees && <ExpandRow value={data.fees} />}
          <ExpandRow value={data.total} />
        </div>
      </div>
    );
  };

  const totalCostValue = (value: number) => {
    let status = 'Neutral';
    if (value < 0) {
      status = 'Revenue';
    }
    if (value > 0) {
      status = 'Paid';
    }
    return (
      <>
        {status}
        <br />
        <b className={s.bold}>
          {sidebarExpanded ? '' : currencySymbol} {formatter.toOptionalFixed(Math.abs(value), 2)}
        </b>
      </>
    );
  };
  const showScrollToBottom = () => {
    setTimeout(() => {
      setScrollToBottom(true);
      setScrollToTop(false);
    }, 10);
  };
  const showScrollToTop = () => {
    setTimeout(() => {
      setScrollToBottom(false);
      setScrollToTop(true);
    }, 10);
  };
  const onScrollY = () => {
    setTimeout(() => {
      const curr = scrollRef.current;
      if (curr && curr?.scrollTop > curr?.scrollHeight / 2) {
        if (!scrollToTop) {
          showScrollToTop();
        }
      } else {
        if (!scrollToBottom) {
          showScrollToBottom();
        }
      }

      tableRef.current = curr?.scrollTop || 0;
    }, 10);
  };
  const doScroll = (toTop) => {
    const curr = scrollRef.current;
    if (curr) {
      if (toTop) {
        curr.scrollTop = 0;
      } else {
        curr.scrollTop = curr.scrollHeight;
      }
    }
  };

  const CompactRow: React.FC<{ value }> = ({ value }) => {
    return (
      <div className={s.miniItem}>
        <span className={s.title}>
          {value?.title &&
            (typeof value.title === 'string' ? value.title : value.title[selectedTypeIndex])}
        </span>
        <span
          className={classnames(
            s.energy,
            selectedTypeIndex === 2
              ? {
                  [s.positive]: value.total_energy < 0,
                  [s.neutral]: value.total_energy === 0,
                  [s.negetive]: value.total_energy > 0,
                }
              : [s.gray],
          )}>
          {selectedTypeIndex === 0
            ? formatter.toOptionalFixed(value.bought, 2)
            : selectedTypeIndex === 1
            ? formatter.toOptionalFixed(value.sold, 2)
            : formatter.toOptionalFixed(Math.abs(value.total_energy), 2)}{' '}
          kWh
        </span>
        <span
          className={classnames({
            [s.positive]:
              (selectedTypeIndex === 2 && value.total_cost < 0) || selectedTypeIndex === 1,
            [s.neutral]:
              (selectedTypeIndex === 2 && value.total_cost === 0) ||
              (selectedTypeIndex === 0 && value.spent + value.market_fee === 0) ||
              (selectedTypeIndex === 1 && value.earned === 0),
            [s.negetive]:
              (selectedTypeIndex === 2 && value.total_cost > 0) || selectedTypeIndex === 0,
          })}>
          {selectedTypeIndex === 0
            ? currencySymbol + ` ${formatter.toOptionalFixed(value.spent + value.market_fee, 2)}`
            : selectedTypeIndex === 1
            ? currencySymbol + ' ' + formatter.toOptionalFixed(value.earned, 2)
            : totalCostValue(value.total_cost)}
        </span>
      </div>
    );
  };
  useEffect(() => {
    if (data.showScroll) {
      const curr = scrollRef.current;
      if (curr) {
        curr.scrollTop = tableRef.current;
      }
    }
  }, [scrollToBottom, scrollToTop, data.showScroll]);

  useEffect(() => {
    if (data.showScroll) {
      tableRef.current = 0;
      showScrollToBottom();
    }
  }, [selectedTypeIndex, data.showScroll]);

  useEffect(() => {
    //prevent pulsing/jumping up and dowm buttons while simulation is running
    tableRef.current = -10;
  }, [priceEnergyAreaBalance]);

  const CompactSidbarView: React.FC = () => {
    return (
      <div>
        {filtersComponent}
        <div className={s.miniTable}>
          {tableRef.current !== -10 && (
            <BaseButton
              className={classnames(s.buttonExpand, s.topButton, {
                [s.notDisplay]: !scrollToTop,
              })}
              theme="grey"
              size="small"
              onClick={() => doScroll(true)}>
              <BaseIcon icon="arrow-left" size={10} className={s.arrow} />
            </BaseButton>
          )}
          <CustomScrollbar
            containerRef={(el) => {
              scrollRef.current = el;
              if (el && data.showScroll && tableRef.current == 0) {
                showScrollToBottom();
              }
            }}
            className={s.scroll}
            onScrollY={onScrollY}>
            {data.scrollBar.map((item, index) => (
              <CompactRow value={item} key={index} />
            ))}
            {data.gridMarketOrCommunity && <CompactRow value={data.gridMarketOrCommunity} />}
          </CustomScrollbar>
          <BaseButton
            className={classnames(s.buttonExpand, s.bottomButton, {
              [s.notDisplay]: !scrollToBottom && tableRef.current !== -10,
            })}
            theme="grey"
            size="small"
            onClick={() => doScroll(false)}>
            <BaseIcon icon="arrow-left" size={10} className={s.arrow} />
          </BaseButton>
        </div>
        {data.fees && <CompactRow value={data.fees} />}
        {data.total && <CompactRow value={data.total} />}
      </div>
    );
  };
  return (
    <ChartWrapper
      title="Energy bills and net energy traded"
      info={getChartTooltip(EChartTooltips.TOOLTIP_ENERGY_BILLS, currencySymbol)}>
      {data && (
        <div className={s.container}>
          {sidebarExpanded ? <ExpandedSidebarView /> : <CompactSidbarView />}
        </div>
      )}
    </ChartWrapper>
  );
};
