import { ELayersGroup, TStylesScrolling, detectiOS, layerPositions } from 'src/pages/MarketDesign';

import { Loader } from 'src/components/LoadingWrapper';
import React from 'react';
import { TProps } from './InitialLayer.types';
import classnames from 'classnames';
import s from './InitialLayer.module.scss';

const VIDEO_URL = 'https://phoenix-dev-assets.s3.eu-central-1.amazonaws.com/low-bitrate.mp4';

const Logo = React.lazy(() => import('src/components/LandingPage/LazyImages/Logo'));
const IphoneVideo = React.lazy(() => import('src/components/LandingPage/LazyImages/IphoneVideo'));

export const MainTitle: React.FC<TProps> = ({ currentLayer, scaleScrolling = 0, scrollTo }) => {
  const activedTitle = {
    minSize: 6.0,
  };

  const ItemOneValidation = currentLayer < layerPositions.simulation;

  const ItemTwoValidation =
    currentLayer >= layerPositions.simulation &&
    (!layerPositions?.ecosystem || currentLayer < layerPositions.ecosystem);

  const ItemThreeValidation = currentLayer >= layerPositions.ecosystem;

  const blackText = currentLayer === 16;

  const smallTextColors = (layer: number) => {
    if (layer === layerPositions[ELayersGroup.INITIAL]) {
      return '';
    }

    if (layer === layerPositions[ELayersGroup.DEPLOYMENT]) {
      return s.customColorTextTwo;
    }

    return s.customColorTextOne;
  };

  return (
    <div className={classnames(s.textContainer)}>
      <div
        onClick={() => (layerPositions?.exchanges ? scrollTo(layerPositions.exchanges) : {})}
        className={`${s.headingText} ${ItemOneValidation && blackText && s.blackText}`}
        style={scrollingFontSize(
          scaleScrolling,
          currentLayer,
          ItemOneValidation
            ? activedTitle
            : {
                darker: true,
              },
        )}>
        {ItemOneValidation ? (
          <>
            We Build
            <br /> Grid-Aware
            <br /> Energy Markets
          </>
        ) : (
          <span className={smallTextColors(currentLayer)}>We Build Grid-Aware Energy Markets</span>
        )}
      </div>
      <div
        onClick={() => (layerPositions?.simulation ? scrollTo(layerPositions.simulation) : {})}
        className={`${s.headingText} ${ItemTwoValidation && blackText && s.blackText}`}
        style={scrollingFontSize(
          scaleScrolling,
          currentLayer,
          ItemTwoValidation
            ? activedTitle
            : {
                darker: true,
              },
        )}>
        {ItemTwoValidation ? (
          <>
            Enabling
            <br /> Continuous
            <br /> Innovation
          </>
        ) : (
          <span className={smallTextColors(currentLayer)}>Enabling Continuous Innovation</span>
        )}
      </div>
      <div
        onClick={() => (layerPositions?.ecosystem ? scrollTo(layerPositions.ecosystem) : {})}
        className={`${s.headingText} ${ItemThreeValidation && blackText && s.blackText}`}
        style={scrollingFontSize(
          scaleScrolling,
          currentLayer,
          ItemThreeValidation
            ? activedTitle
            : {
                darker: true,
              },
        )}>
        {ItemThreeValidation ? (
          <>
            For An Open
            <br /> Ecosystem
          </>
        ) : (
          <span className={smallTextColors(currentLayer)}>For An Open Ecosystem</span>
        )}
      </div>
    </div>
  );
};

export const InitialLayer: React.FC<TProps> = ({ scrollTo }) => {
  const isOS = detectiOS();

  return (
    <>
      <div className={s.content}>
        <div className={s.bgFractuals}>
          {isOS ? (
            <React.Suspense fallback={<Loader />}>
              <IphoneVideo />
            </React.Suspense>
          ) : (
            <video loop autoPlay muted playsInline>
              <source src={VIDEO_URL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <React.Suspense fallback={<Loader />}>
          <Logo className={s.logoBadge} />
        </React.Suspense>
      </div>
      <div className={s.scrollButtonContainer}>
        <div className={s.quoteText}>
          <div className={s.text}>
            Look deep into nature, and then you will <br />
            understand everything better.
          </div>
          <div className={s.bold}>Albert Einstein</div>
        </div>
      </div>
    </>
  );
};

function scrollingFontSize(scrollUntilNextLayer: number, layer: number, styles?: TStylesScrolling) {
  const maxFontSize = styles?.maxSize || 8.75;
  const minFontSize = styles?.minSize || 2.9;
  const darkerBright = styles?.darker ? 1 - scrollUntilNextLayer : 1;
  const minBright = styles?.minBright || 0.4;
  const unit = 'vh';

  if (layer === 0) {
    const calcBetweenScrollAndFonSize = scrollUntilNextLayer * maxFontSize;
    const fontSize = maxFontSize - calcBetweenScrollAndFonSize;

    return {
      fontSize: `${fontSize <= minFontSize ? minFontSize : fontSize}${unit}`,
      // filter: `brightness(${darkerBright <= minBright ? minBright : darkerBright})`,
      // color: `${darkerBright <= minBright ? 'red' : 'red'})`,
    };
  }

  if (layer > 0) {
    return {
      fontSize: `${minFontSize}${unit}`,
      // filter: `brightness(${styles?.darker ? minBright : 1})`,
      // color: `${darkerBright <= minBright ? 'red' : 'red'})`,
    };
  }

  return { fontSize: `${maxFontSize}${unit}` };
}
