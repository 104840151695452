import React, { useMemo } from 'react';
import { LibraryOutput } from 'src/graphql';

import { BaseIcon } from 'src/components/BaseIcon';
import { TIconNames } from 'src/components/BaseIcon/IconNames.types';
import s from './DashboardAssets.module.scss';
import classNames from 'classnames';

export const DashboardAssetsTableItem: React.FC<{
  data: LibraryOutput;
  className: string;
  assetIcon: TIconNames;
  updateGridData(): void;
  onClick(uuid: string): void;
  onThreeDotsClick?;
}> = ({ data, className, assetIcon, updateGridData, onClick, onThreeDotsClick }) => {
  const representation = useMemo(() => {
    return data?.scenarioData?.representation?.serialized
      ? JSON.parse(data?.scenarioData?.representation?.serialized)
      : null;
  }, [data?.scenarioData?.representation?.serialized]);

  return (
    <tr className={classNames(className, s.tableItem)} onClick={() => onClick(data.uuid!)}>
      <td>
        <div className="details">
          <div>
            <BaseIcon icon={assetIcon} size={60} />
          </div>
          <div className="userDetails">
            <h6>{data.name}</h6>
          </div>
        </div>
      </td>
      <td>
        {data.scenarioData?.deviceCountNumber
          ? data.scenarioData?.deviceCountNumber + ' Assets'
          : '---'}
      </td>
      <td>
        <div className="user">{data.user}</div>
      </td>
      <td>21, August 2021</td>
      <td>
        {representation?.type === 'Area' ? (
          <div className="assets">
            <div>
              <div>
                <BaseIcon icon="pv" size={11} />
                <span>
                  {representation?.children?.filter((item) => item.type === 'PV').length || 0}
                </span>
              </div>
              <span>PVs</span>
            </div>
            <div>
              <div>
                <BaseIcon icon="battery" size={11} />
                <span>
                  {representation?.children?.filter((item) => item.type === 'Storage').length || 0}
                </span>
              </div>
              <span>Batteries</span>
            </div>
            <div>
              <div>
                <BaseIcon icon="plug" size={11} />
                <span>
                  {representation?.children?.filter((item) => item.type === 'Load').length || 0}
                </span>
              </div>
              <span>Loads</span>
            </div>
            <div>
              <div>
                <BaseIcon icon="book" size={11} />
                <span>{data.scenarioData?.areaCountNumber}</span>
              </div>
              <span>PowerPlant</span>
            </div>
          </div>
        ) : (
          '---'
        )}
      </td>
      <td>
        <div className="actions">
          <button type="button">
            <BaseIcon icon="info" size={14} />
          </button>
          <button
            type="button"
            onClick={(e) => onThreeDotsClick(e, { target: e.target as HTMLElement, lib: data })}>
            <BaseIcon icon="more-horizontal" size={14} />
          </button>
        </div>
      </td>
    </tr>
  );
};
