export type TPolygonCoord = {
  x: number;
  y: number;
};

type TGetPolygonDataArgs = {
  radius: TGetPointsOfLineArgs['radius'];
  center: TGetPointsOfLineArgs['center'];
  edgeCount: TCentralAngleArgs['edgeCount'];
  rotation?: number;
};

type TGetPointsOfLineArgs = {
  radius: number;
  center: TPolygonCoord;
  centeralAngle: ReturnType<typeof centralAngle>;
};

type TCentralAngleArgs = {
  index: number;
  edgeCount: number;
};

const centralAngle = ({ index, edgeCount }: TCentralAngleArgs): number =>
  (2 * index * Math.PI) / edgeCount;

const getPointsOfLine = ({
  radius,
  center,
  centeralAngle,
}: TGetPointsOfLineArgs): TPolygonCoord => ({
  x: center.x + Math.sin(centeralAngle) * radius,
  y: center.y - Math.cos(centeralAngle) * radius,
});

export const getPolygonData = ({
  radius,
  center,
  edgeCount,
  rotation = 0,
}: TGetPolygonDataArgs): Array<TPolygonCoord> =>
  [...Array(edgeCount)].map((item, index) => {
    const calculatedRad = (rotation * Math.PI) / 180;
    const centeralAngle = centralAngle({ index, edgeCount }) + calculatedRad;
    return getPointsOfLine({ radius, center, centeralAngle });
  });
