import {
  EOrientationParam,
  EOrientationView,
  TOrientationParam,
  TOrientationProps,
} from './Orientation.types';
import React, { useEffect, useState } from 'react';

import { OrientationAdvanced } from '../OrientationAdvanced';
import { OrientationBasic } from '../OrientationBasic';
import { TabNav } from 'src/components/TabNav';
import classnames from 'classnames';
import s from './Orientation.module.scss';

export const Orientation: React.FC<TOrientationProps> = ({
  className,
  azimuthValuesForCustomPV,
  setAzimuthValuesForCustomPV,
  theme,
}) => {
  const [view, setView] = useState<EOrientationView>(EOrientationView.BASIC);
  const [orientationParam, setOrientationParam] = useState<TOrientationParam>(
    azimuthValuesForCustomPV || {
      [EOrientationParam.AZIMUTH]: 0,
      [EOrientationParam.TILT]: 0,
    },
  );

  useEffect(() => {
    setAzimuthValuesForCustomPV?.(orientationParam);
  }, [orientationParam, setAzimuthValuesForCustomPV]);

  return (
    <div className={classnames(s.orientation, className)}>
      <TabNav
        nav={[
          {
            label: 'Basic',
            onClick() {
              setView(EOrientationView.BASIC);
            },
            isActive: view === EOrientationView.BASIC,
          },
          {
            label: 'Advanced',
            onClick() {
              setView(EOrientationView.ADVANCED);
            },
            isActive: view === EOrientationView.ADVANCED,
          },
        ]}
        theme={theme === 'light' ? '1' : '2'}
      />
      {view === EOrientationView.BASIC && (
        <OrientationBasic
          theme={theme}
          orientationParam={orientationParam}
          setOrientationParam={setOrientationParam}
        />
      )}
      {view === EOrientationView.ADVANCED && (
        <OrientationAdvanced
          theme={theme}
          orientationParam={orientationParam}
          setOrientationParam={setOrientationParam}
        />
      )}
    </div>
  );
};
