import { ContentfulContextProvider, IEcoSystemEntry } from 'src/contexts/ContentfulContext';
import React, { Fragment, useContext, useState } from 'react';

import APIGreen from 'src/assets/images/MarketingPage/apiGreen.svg';
import APIRed from 'src/assets/images/MarketingPage/api.svg';
import App from 'src/assets/images/MarketingPage/app.svg';
import { CardDetail } from '.../../../src/pages/MarketDesign/components/CardDetail';
import ContentfulContext from 'src/contexts/ContentfulContext';
import { Fade } from 'react-reveal';
import { HoverBgCard } from '.../../../src/pages/MarketDesign/components/HoverBgCard';
import { TEcoSystemPageProps } from './EcoSystem.types';
import { TProps } from 'src/components/LandingPage/MarketDesign/EcoSystem';
import s from './EcoSystem.module.scss';

const RenderCards: React.FC<TEcoSystemPageProps> = (props) => {
  const { isEcoSystemChildView, setIsEcoSystemChildView } = props;
  const { ecoSystemEntry } = useContext(ContentfulContext);
  const [showCards, setShowCards] = useState(true);
  const [selectedEcoSystem, setSelectedEcoSystem] = useState<IEcoSystemEntry | null>();

  const toggleCardsView = () => {
    setShowCards((prevState) => !prevState);
    if (setIsEcoSystemChildView) {
      setIsEcoSystemChildView(showCards);
    }
  };

  const renderImage = (value) => {
    switch (value) {
      case 0:
        return (
          <div className={s.imgContainer}>
            <img src={APIRed} />
          </div>
        );
      case 1:
        return (
          <div className={s.imgContainer}>
            <img src={APIGreen} />
          </div>
        );
      case 2:
        return (
          <div className={s.imgContainer}>
            <img className={s.icon} src={App} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {showCards ? (
        <div className={s.cards}>
          {ecoSystemEntry.map((item, index) => {
            return (
              <Fragment key={`${index}_${item.createdAt}`}>
                <div
                  onClick={() => {
                    if (item.id !== 2) {
                      setSelectedEcoSystem(item);
                      toggleCardsView();
                    }
                  }}>
                  <HoverBgCard data={item} isClickAble={item.id !== 2} />
                </div>
                {renderImage(index)}
              </Fragment>
            );
          })}
        </div>
      ) : (
        <CardDetail
          data={selectedEcoSystem}
          onCloseClick={() => {
            toggleCardsView();
            setSelectedEcoSystem(null);
          }}
          {...props}
        />
      )}
    </>
  );
};

export const EcoSystem: React.FC<TEcoSystemPageProps> = (props) => (
  <ContentfulContextProvider>
    <div className={s.content}>
      <RenderCards {...props} />
    </div>
  </ContentfulContextProvider>
);

export const EcoSystemMain: React.FC<TProps> = ({ show }) => {
  return (
    <Fade opposite collapse={!show} when={show} delay={1000}>
      <div className={s.ecosystemWrapper}>
        <EcoSystem />
      </div>
    </Fade>
  );
};
