import React, { useMemo, useState } from 'react';
import { TListItemBlockAsset, TListItemBlockProps } from './ListItemBlock.types';

import { BaseIcon } from 'src/components/BaseIcon';
import { ChartHouseSummary } from 'src/components/_charts/ChartHouseSummary';
import { ConfigType } from 'src/graphql';
import { LoadingWrapper } from 'src/components/LoadingWrapper';
import { TAssetsInfo } from 'src/typings/simulation.types';
import classNames from 'classnames';
import { formatter } from 'src/utils/formatter';
import s from './ListItemBlock.module.scss';
import { useHouseSummaryResults } from 'src/hooks/useHouseSummaryResults';
import { useSelector } from 'react-redux';
import {
  selectResultsEndTime,
  selectResultsStartTime,
} from 'src/redux/configuration/configuration.selectors';
import { UTCMoment } from 'src/utils/UTCMoment';

export const ListItemBlock: React.FC<TListItemBlockProps> = ({
  loading,
  communityId,
  jobId,
  title,
  belongsToLoggedUser,
  isLoggedIn,
  place,
  modalRefObject,
  configType,
  onCommunityClick,
  onMenuClick,
}) => {
  // TODO: Assets Info
  const [assetsInfo, setAssetsInfo] = useState<TAssetsInfo>();
  const summaryResults = useHouseSummaryResults({ assetUuid: communityId, jobId });
  const resultStartTime = useSelector(selectResultsStartTime);
  const resultsEndTime = useSelector(selectResultsEndTime);

  const startUnix = useMemo(() => {
    return UTCMoment.fromBackend(resultStartTime).startOf('day').unix();
  }, [resultStartTime]);

  const endUnix = useMemo(() => {
    return UTCMoment.fromBackend(resultsEndTime).endOf('day').unix();
  }, [resultsEndTime]);

  const assets: TListItemBlockAsset[] = [
    {
      type: 'Load',
      icon: 'plug',
      amount: 0,
    },
    {
      type: 'Area',
      icon: 'factory',
      amount: 0,
    },
    {
      type: 'PV',
      icon: 'pv',
      amount: 0,
    },
    {
      type: 'Storage',
      icon: 'battery',
      amount: 0,
    },
  ];

  const getAssetCount = (asset: TListItemBlockAsset): number => {
    switch (asset.type) {
      case 'Load': {
        if (assetsInfo) {
          return assetsInfo['number_of_load_type'];
        } else {
          return 0;
        }
      }
      case 'Area': {
        if (assetsInfo) {
          return assetsInfo['number_of_house_type'];
        } else {
          return 0;
        }
      }
      case 'PV': {
        if (assetsInfo) {
          return assetsInfo['number_of_pv_type'];
        } else {
          return 0;
        }
      }
      case 'PV': {
        if (assetsInfo) {
          return assetsInfo['number_of_storage_type'];
        } else {
          return 0;
        }
      }
    }
    return 0;
  };

  return (
    <div
      className={classNames(s.itemCard, s.container)}
      key={`list-item-card-${communityId}`}
      onClick={onCommunityClick}>
      <div className={s.upperRow}>
        <div
          className={classNames(s.iconMainWrapper, {
            [s.iconMainWrapperCanary]: configType === ConfigType.CanaryNetwork,
          })}>
          <BaseIcon
            icon={configType === ConfigType.CanaryNetwork ? 'canary' : 'simulation'}
            className={s.iconMain}
            size={configType === ConfigType.CanaryNetwork ? 13 : 11}
          />
        </div>
        <div className={s.infoWrapper}>
          <p className={s.title}>{title}</p>
        </div>
        <div className={s.iconsWrapper} onClick={(e) => e.stopPropagation()}>
          {belongsToLoggedUser && (
            <button type="button">
              <BaseIcon icon="user" size={15} className={s.iconUser} />
            </button>
          )}
          {isLoggedIn ? (
            <button
              type="button"
              ref={modalRefObject}
              onClick={() => onMenuClick(modalRefObject.current)}></button>
          ) : null}
        </div>
      </div>
      <div className={s.location}>
        <BaseIcon icon="location" size={15} className={s.iconLocation} />
        <p>{place}</p>
      </div>
      <LoadingWrapper
        loading={summaryResults.loading && !summaryResults.data}
        style={{ height: 76 }}
        onClick={(e) => e.stopPropagation()}>
        {() => (
          <ChartHouseSummary
            // TODO read avg days from results
            avgDays="7"
            simulationStartDate={summaryResults.currentMarket}
            netEnergyData={summaryResults.netEnergyData}
            pricesData={summaryResults.pricesData}
            currentMarket={summaryResults.currentMarket}
            isLoading={summaryResults.loading && !summaryResults.data}
            onClick={(e) => e.stopPropagation()}
            startUnix={startUnix}
            endUnix={endUnix}
          />
        )}
      </LoadingWrapper>

      <div>
        <ul className={s.assetsList}>
          {assets.map((asset, index) => (
            <li key={index}>
              <button
                type="button"
                className={s.assetSquareBtn}
                onClick={(e) => e.stopPropagation()}>
                <BaseIcon icon={asset.icon} size={16.25} />
                {getAssetCount(asset) > 0 && (
                  <label className={s.assetsAmount}>
                    {formatter.prefixWithZeros(getAssetCount(asset))}
                  </label>
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
